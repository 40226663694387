import {isZeroDate, readBackendDate} from '../../../helpers/dates'

export const getEditParameterFormInitialValue = parameter => {
  const {value, ends_at, begins_at} = parameter

  return {
    startDate: new Date(readBackendDate(begins_at)),
    endDate: isZeroDate(ends_at) ? '' : new Date(readBackendDate(ends_at)),
    value: value
  }
}

export const getEditDateParameterFormInitialValue = parameter => {
  const {value, ends_at, begins_at} = parameter

  return {
    startDate: new Date(readBackendDate(begins_at)),
    endDate: isZeroDate(ends_at) ? '' : new Date(readBackendDate(ends_at)),
    value: new Date(readBackendDate(value))
  }
}

export const getEditTextArrayParameterFormInitialValue = parameter => {
  const {value, ends_at, begins_at} = parameter

  return {
    startDate: new Date(readBackendDate(begins_at)),
    endDate: isZeroDate(ends_at) ? '' : new Date(readBackendDate(ends_at)),
    value: value.split(',')
  }
}
