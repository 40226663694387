import React from 'react'

import ActionTextButton from '../Templates/ActionTextButton'

const DeleteTextButton = ({text = 'Eliminar', color = 'red', className, isDisable, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-${color} ${color} ${className}`}
      onClick={onClick}
      icon="trash"
      text={text}
      isDisable={isDisable}
    />
  )
}

export default DeleteTextButton
