import Proptypes from 'prop-types'
import React, {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {STATUS_HISTORY} from '../../../helpers/payroll'
import {capitalize} from '../../../helpers/string-format'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {deleteLeaveNovelty} from '../../../services/leaveNovelty'
import {notifySuccessful} from '../../../services/notification'
import NoveltyImage from '../../../static/images/stories/leaves.svg'
import Select from '../../Atoms/Forms/Select'
import NoveltyEmptyState from '../../Organisms/Emptys/NoveltyEmptyState'
import {initialStateAction} from '../../Organisms/Tables/NoveltyTable'
import Table from '../../Wrappers/Table'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import Sort from '../Sort'
import LeaveNoveltyRow from './Rows/LeaveNoveltyRow'

const LeaveNoveltyTable = ({
  setAction,
  novelties,
  setFilter,
  setSortOption,
  defaultSortOption,
  reloadNovelties,
  setReloadNovelties
}) => {
  const dispatch = useDispatch()
  const {activeProcess} = useContext(PayrollContext)
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const leaveTypes = !isNull(cacheGeneral) && cacheGeneral.data?.leave_types
  leaveTypes.unshift({id: 0, description: 'TODOS'})

  const [leavetypeID, setLeaveTypeID] = useState(0)

  const handleOnSelectEmployeeInput = contractID => {
    setFilter({key: 'contract_id', value: contractID})
  }

  const handleOnChangeLeaveType = e => {
    const value = e.target.value
    setLeaveTypeID(value)
    setFilter({key: 'leave_type_id', value: value})
  }

  const handleOnSelectSortOption = option => {
    setSortOption(option)
  }

  const handleOnDelete = noveltyID => {
    deleteLeaveNovelty(noveltyID, response => {
      setAction(initialStateAction)
      setReloadNovelties(!reloadNovelties)
      dispatch(notifySuccessful('Hemos eliminado la novedad exitosamente'))
    })
  }

  return (
    <>
      <div className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4">
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />

        <Select
          className="small"
          id="leave_type"
          name="leave_type"
          label="Tipo"
          placeholder
          onChange={handleOnChangeLeaveType}
          value={leavetypeID}
        >
          {Array.isArray(leaveTypes) &&
            leaveTypes.map(leaveType => (
              <option key={leaveType.id} value={leaveType.id}>
                {capitalize(leaveType.description)}
              </option>
            ))}
        </Select>

        <Sort
          options={[defaultSortOption, {key: 'leave_type_id', value: 'Tipo'}]}
          defaultSortOption={defaultSortOption}
          onSelect={handleOnSelectSortOption}
        />
      </div>
      {isEmpty(novelties) ? (
        <NoveltyEmptyState
          title={isEqual(activeProcess?.status, STATUS_HISTORY) ? '¡Oh! no hay novedades' : 'Incapacidades'}
          description={
            isEqual(activeProcess?.status, STATUS_HISTORY) ? (
              <>
                Este proceso no tuvo novedades de <span className="s-color-text">incapacidades</span>.
              </>
            ) : (
              <>
                Aún no tienes novedades, da click en <span className="s-color-text">Agrega una novedad</span> para
                comenzar.
              </>
            )
          }
          image={NoveltyImage}
        />
      ) : (
        <Table heads={['Empleado', 'Tipo', 'Inicio', 'Fin', 'Días', 'Acciones']}>
          {Array.isArray(novelties) &&
            novelties.map(novelty => (
              <LeaveNoveltyRow novelty={novelty} key={novelty.id} setAction={setAction} onDelete={handleOnDelete} />
            ))}
        </Table>
      )}
    </>
  )
}

LeaveNoveltyTable.propTypes = {
  novelties: Proptypes.array.isRequired
}

LeaveNoveltyTable.defaultProps = {
  novelties: []
}

export default LeaveNoveltyTable
