import initialStateEmployeeRelation from 'helpers/employee'
import React from 'react'

import NewEmployeeNavigationButton from '../Molecules/NewEmployeeNavigationButton'
import NewEmployeeSteps from '../Molecules/NewEmployeeSteps'
import Container from '../Wrappers/Container'
import FormDataWrapper from '../Wrappers/FormDataWrapper'
import PanelTemplate from '../Wrappers/PanelTemplate'
import TabsWrapper from '../Wrappers/TabsWrapper'
import ContractEmployeeForm from './Forms/ContractEmployeeForm'
import PaymentEmployeeForm from './Forms/PaymentEmployeeForm'
import PersonalDataEmployeeForm from './Forms/PersonalDataEmployeeForm'
import RetentionEmployeeForm from './Forms/RetentionEmployeeForm'
import SocialSecurityEmployeeForm from './Forms/SocialSecurityEmployeeForm'

const CreateNewEmployee = () => {
  return (
    <TabsWrapper defaultActive={1}>
      <div className="lg-cols-2">
        <div>
          <NewEmployeeSteps />
          <Container>
            <FormDataWrapper defaultData={initialStateEmployeeRelation}>
              <form className="s-mb-0">
                <div className="l-block">
                  {/* Datos personales */}
                  <PanelTemplate index={1} onlyHidden>
                    <PersonalDataEmployeeForm />
                  </PanelTemplate>

                  {/* Contrato */}
                  <PanelTemplate index={2} onlyHidden>
                    <ContractEmployeeForm />
                  </PanelTemplate>

                  {/* Seguridad social */}
                  <PanelTemplate index={3} onlyHidden>
                    <SocialSecurityEmployeeForm />
                  </PanelTemplate>

                  {/* Pago */}
                  <PanelTemplate index={4} onlyHidden>
                    <PaymentEmployeeForm />
                  </PanelTemplate>

                  {/* Retención de la fuente */}
                  <PanelTemplate index={5} onlyHidden>
                    <RetentionEmployeeForm />
                  </PanelTemplate>
                </div>
                <NewEmployeeNavigationButton />
              </form>
            </FormDataWrapper>
          </Container>
        </div>
      </div>
    </TabsWrapper>
  )
}

export default CreateNewEmployee
