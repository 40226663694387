import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'

import routes from '../../../config/routes'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {capitalize} from '../../../helpers/string-format'
import {isEqual, isNull} from '../../../helpers/utils'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'
import {EMPLOYEES_DEFAULT_SORT_OPTION} from '../../../modules/employees-panel/constants/filter'
import DownloadLinkReportProvider from '../../../modules/employees-panel/providers/DownloadLinkReport'
import EmployeesSectionModalsWrapperProvider from '../../../modules/employees-panel/providers/EmployeesSectionModalsWrapper'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getEmployees} from '../../../services/employees'
import EmployeesPanel from '../Panels/EmployeesPanel'

const EmployeesSection = () => {
  const history = useHistory()
  const {path} = useRouteMatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatus = !isNull(cacheGeneral) && cacheGeneral.data?.contract_statuses

  const {data, loading, page, setPage, isLastPage, setSortOption, setFilterOption, setSearchValue} =
    useFetchWithConditions(EMPLOYEES_DEFAULT_SORT_OPTION, 20, getEmployees)

  const redirectToRootEmployeePage = () => history.push(path)

  const handleAddEmployee = () => {
    history.push(`${path}${routes.employees.new}`)
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleSelectEmployee = employee => {
    history.push(`${path}${routes.employees.query}/${employee.employee_hash}`)
  }

  const handleChangeSort = option => {
    redirectToRootEmployeePage()
    setSortOption(option)
  }

  const handleChangeFilter = option => {
    redirectToRootEmployeePage()
    setFilterOption(option)
  }

  const handleSearchEmployee = value => {
    setSearchValue(value)
  }

  const handlerScrollEnd = () => {
    if (isLastPage) return

    setPage(page + 1)
  }

  return (
    <DownloadLinkReportProvider>
      <EmployeesSectionModalsWrapperProvider>
        <EmployeesPanel
          loading={loading && isEqual(page, 1)} // only show skeleton if the page is equal to 1
          tag={{descriptionField: 'contract_status', colorField: 'contract_status_color'}}
          limitEmployees={100}
          defaultSortOption={EMPLOYEES_DEFAULT_SORT_OPTION}
          sortOptions={[EMPLOYEES_DEFAULT_SORT_OPTION, {key: 'last_name', value: 'Apellido'}]}
          filterOptions={
            Array.isArray(contractStatus)
              ? contractStatus.map(status => ({
                  key: status.id,
                  value: capitalize(status.description)
                }))
              : []
          }
          showAddEmployeeButton
          onClickAddEmployee={handleAddEmployee}
          onSelectEmployee={handleSelectEmployee}
          onChangeFilter={handleChangeFilter}
          onChangeSort={handleChangeSort}
          onChangeSearch={handleSearchEmployee}
          onScrollEnd={handlerScrollEnd}
          employees={data}
        />
      </EmployeesSectionModalsWrapperProvider>
    </DownloadLinkReportProvider>
  )
}

export default EmployeesSection
