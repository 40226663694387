import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Redirect} from 'react-router-dom'

import SearchInput from '../components/Atoms/Forms/SearchInput'
import SquareCompanyItem from '../components/Atoms/Items/SquareCompanyItem'
import Logo from '../components/Atoms/Logo'
import FullPageLoader from '../components/Organisms/FullPageLoader'
import routes from '../config/routes'
import {isEmpty, isUndefined} from '../helpers/utils'
import {selectors as employerSelectors} from '../redux/ducks/employer'
import {selectors as userSelectors} from '../redux/ducks/user'
import {getEmployers, signInWithEmployer} from '../services/employer'
import {getUserData} from '../services/user'

const SelectedCompany = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [search, setSearch] = useState('')
  const [employersFilter, setEmployersFilter] = useState([])

  const employers = useSelector(employerSelectors.getAll)
  useEffect(() => {
    // remove any data of employer session like TOKEN_EMPLOYER_KEY, in this page only must
    // exists the first token.
    sessionStorage.clear()

    if (isUndefined(employers)) dispatch(getEmployers())
    if (!isUndefined(employers)) setEmployersFilter(employers)
  }, [employers, dispatch])

  const user = useSelector(userSelectors.get)
  useEffect(() => {
    if (isUndefined(user.email)) dispatch(getUserData())
  }, [user, dispatch])

  const handleSearchChange = e => {
    const value = e.target.value
    setSearch(value)

    if (isEmpty(value)) {
      setEmployersFilter(employers)
      return
    }

    setEmployersFilter(
      employers.filter(
        employer =>
          employer.business_name.toLowerCase().includes(value.toLowerCase()) ||
          employer.short_name.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleAddCompany = () => history.push(routes.newEmployer)

  return (
    <main className="selected-company-section l-big-section s-to-center s-column s-center s-cross-center">
      {isUndefined(employers) ? (
        <FullPageLoader />
      ) : isEmpty(employers) ? (
        <Redirect to={routes.newEmployer} />
      ) : (
        <>
          <div className="s-mb-32px from-lg" />
          <Logo
            className="s-mb-32px"
            size={{
              height: 's-h-40px'
            }}
          />
          <h3 className="s-mb-12px" data-testid="user-name">
            <span role="img" aria-label="Hola">
              👋
            </span>{' '}
            ¡Hola {user.name}!
          </h3>
          <p className="s-mb-32px">¿Con qué empresa trabajarás hoy?</p>
          <SearchInput
            id="company-search"
            className="s-100 s-mb-32px s-cols-3"
            value={search}
            onChange={handleSearchChange}
          />
          <div className="company-grid s-100">
            {employersFilter.map(employer => (
              <SquareCompanyItem
                key={employer.id}
                name={employer.short_name}
                thumbnail={employer.thumbnail}
                onClick={() => dispatch(signInWithEmployer(employer.id))}
                data-testid="company-item"
              />
            ))}
            <SquareCompanyItem addButton onClick={handleAddCompany} />
          </div>
        </>
      )}
    </main>
  )
}

export default SelectedCompany
