import React from 'react'

import NoteBoard from '../../../static/images/stories/note-board.svg'

const NotesContractPanelEmptyState = ({className}) => {
  return (
    <div className={`s-column s-cross-center s-main-center ${className}`}>
      <div />
      <img className="s-40 m-20 lg-50 s-mb-12px" src={NoteBoard} alt="notas contracto" width="200px" height="200px" />
      <p className="small s-color-light-text s-center s-mb-6px">Aún no has agregado notas.</p>
    </div>
  )
}

export default NotesContractPanelEmptyState
