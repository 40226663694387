import React, {useEffect, useState} from 'react'

import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'
import {searchMunicipality} from '../../../services/municipalities'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatMunicipalityList from '../FloatMunicipalityList'

const SelectPlaceLaborMunicipalityInput = ({
  id = 'place_labor_municipality_id',
  name = 'place_labor_municipality_id',
  label = 'Ciudad de trabajo',
  placeholder = 'Escribe para buscar',
  departmentID = 0,
  isRequired = false,
  className = '',
  onSelect = () => {},
  defaultValue = '',
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue)
  const [list, setList] = useState([])
  const [listIndex, setListIndex] = useState({active: 0, max: 0})

  useEffect(() => {
    setValue('')
    setList([])
    setListIndex({active: 0, max: 0})
  }, [departmentID])

  useEffect(() => {
    setValue(defaultValue)
    setList([])
    setListIndex({active: 0, max: 0})
  }, [defaultValue])

  const handleOnChangeInput = newValue => {
    setValue(newValue)

    // clear the municipality because the user change the input value and not to selected a
    // new municipality
    onSelect(0, '')

    if (isEqual(departmentID, 0) || isEmpty(newValue)) {
      setList([])
      setListIndex({active: 0, max: 0})
      return
    }

    searchMunicipality(departmentID, newValue, response => {
      const size = isEmpty(response) ? 0 : len(response) - 1

      setList(response)
      setListIndex({active: 0, max: size})
    })
  }

  const handleKeyPress = e => {
    if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

    e.preventDefault()

    if (isEqual(e.key, 'Enter')) {
      handleOnSelect(null)
      return
    }

    moveIndexPosition(e.key)
  }

  const moveIndexPosition = move => {
    setListIndex(currentIndex => {
      if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
        return currentIndex
      }

      if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
        return currentIndex
      }

      const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

      return {active: newActiveIndex, max: currentIndex?.max}
    })
  }

  const handleOnSelect = newIndex => {
    const ix = isNull(newIndex) ? listIndex.active : newIndex
    const municipalitySelected = list[ix]

    setList([municipalitySelected])
    setListIndex({active: 0, max: 0})
    setValue(municipalitySelected?.description)
    onSelect(municipalitySelected?.id, municipalitySelected?.description)
  }

  return (
    <div className={className}>
      <FloatContainer isInputTheContainer container={{className: 's-relative z-tooltip'}}>
        <FormInput
          type="search"
          className="search-input"
          isRequired={isRequired}
          isDisabled={isEqual(departmentID, 0)}
          label={label}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            handleOnChangeInput(e.target.value)
          }}
          onKeyDown={handleKeyPress}
          autoComplete="nope"
          {...rest}
        />
        <FloatMunicipalityList list={list} onSelect={handleOnSelect} activeIndex={listIndex?.active} />
      </FloatContainer>
    </div>
  )
}

export default SelectPlaceLaborMunicipalityInput
