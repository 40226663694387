import {isEmpty} from 'helpers/utils'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'

const TreeSelect = ({data, id, className, label, placeholder, isRequired, isDisabled, isReadOnly, onChange}) => {
  const handleOnChange = (currentNode, selectedNodes) => {
    if (isEmpty(selectedNodes)) {
      onChange(null)
      return
    }

    onChange(currentNode)
  }

  // we use "useMemo" as wrapper of DropdownTreeSelect because this component handles its
  // own status and if the parent component is re-render we lose the status, it using useMemo
  // the component "tree" isn't rerender if its props in the array not changed
  const tree = useMemo(() => {
    const getPlaceholder = () => {
      return typeof placeholder === 'boolean' || placeholder === 'female'
        ? `Elige ${placeholder === 'female' ? 'una' : 'un'} ${label.split(' ')[0].toLowerCase()}`
        : // Si no se quiere un placeholder genérico, entonces se escribe a mano
          placeholder
    }

    return (
      <DropdownTreeSelect
        id={id}
        data={!Array.isArray(data) ? [] : data}
        onChange={handleOnChange}
        mode="radioSelect"
        disabled={isDisabled}
        readOnly={isReadOnly}
        texts={{placeholder: getPlaceholder()}}
      />
    )
    // we don't include handleOnChange function in the dependency list
  }, [data, isDisabled, isReadOnly, id, label, placeholder]) // eslint-disable-line

  return (
    <div className={`form-item ${className} ${isRequired && 'is-required'}`}>
      {tree}
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

TreeSelect.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
}

TreeSelect.defaultProps = {
  data: [],
  id: '',
  className: '',
  label: '',
  placeholder: '',
  isRequired: false,
  isReadOnly: false,
  isDisabled: false,
  onChange: () => {}
}

export default TreeSelect
