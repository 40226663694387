import React, {forwardRef, useRef} from 'react'

import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import DatePickerInput from '../../../../components/Molecules/Pickers/DatePickerInput'
import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {isEqual} from '../../../../helpers/utils'
import {TYPE_ALL, TYPE_SELECTION} from '../../constants/form'
import useVacationsBookForm from '../../hooks/form/useVacationsBookForm'
import MultiSelectEmployeeInput from '../MultiSelectEmployeeInput'

const VacationBookModal = forwardRef(({}, ref) => {
  const {resetForm, setFormData, formData, handleSubmit} = useVacationsBookForm({modalRef: ref})
  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      contractIds: isEqual(value, TYPE_ALL) ? [] : state.contractIds
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <>
        <h3>Libro de Vacaciones</h3>
        <form onSubmit={handleSubmit} className="s-mb-0">
          <DatePickerInput
            className="s-mb-4"
            label="Fecha de corte"
            name="requestDate"
            id="requestDate"
            value={formData.requestDate}
            onChange={date => setFormData(state => ({...state, requestDate: date}))}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isRequired
          />
          <div className="ed-grid s-grid-1 rows-gap s-mb-2">
            <div>
              <RadioButtonsWrapper label="Empleados" className="s-mb-1" isRequired>
                <RadioButton
                  name="selectionType"
                  label="Todos"
                  value={TYPE_ALL}
                  onChange={updateRadioButton}
                  checked={isEqual(formData.selectionType, TYPE_ALL)}
                />
                <RadioButton
                  name="selectionType"
                  label="Una selección"
                  value={TYPE_SELECTION}
                  onChange={updateRadioButton}
                  checked={isEqual(formData.selectionType, TYPE_SELECTION)}
                />
              </RadioButtonsWrapper>

              {isEqual(formData.selectionType, TYPE_SELECTION) && (
                <MultiSelectEmployeeInput placeholder="Escribe para buscar empleados" setFormData={setFormData} />
              )}
            </div>
          </div>
          <button className="button" type="submit">
            Descargar
          </button>
        </form>
      </>
    </ModalTemplate>
  )
})

VacationBookModal.displayName = 'VacationBookModal'

export default VacationBookModal
