import React from 'react'

import NoteBoard from '../../../static/images/stories/note-board.svg'

const NotesPayrollEmptyState = ({onAdd}) => {
  return (
    <div className="s-column s-cross-center s-main-center">
      <img className="s-40 s-mb-12px" src={NoteBoard} alt="notas vacías" width="200px" height="200px" />
      <p className="smaller s-color-light-text s-center s-mb-6px">
        Las notas te ayudan a recordar lo sucesos importantes del mes
      </p>
      <button type="button" className="button small" onClick={onAdd}>
        Crea tu primera nota
      </button>
    </div>
  )
}

export default NotesPayrollEmptyState
