import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/employee-entities-history'

export const getEmployeesEntitiesByContractIDAndFundType = (contractID, entityTypeID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/contracts/${contractID}/entity-types/${entityTypeID}`, response => callback(response))
}

export const createEmployeeSocialSecurityEntity = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateEmployeeSocialSecurityEntity = (id, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${id}`, payload, response => callback(response))
}

export const deleteEmployeeSocialSecurityEntity = (id, contractID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${id}/contracts/${contractID}`, response => callback(response))
}
