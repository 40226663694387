import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/recurrent-novelties'

export const getRecurrentNoveltiesByHashProcess = (processHash, params, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${processHash}?${params}`, callback)
}

export const createRecurrentNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateRecurrentNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteRecurrentNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getRecurrentNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}

export const getRecurrentHistoryNoveltyByIDAndProcessID = (noveltyID, processID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}/processes/${processID}`, callback)
}
