import {useEffect, useState} from 'react'

import {ALL_OPTION} from '../components/Molecules/Filter'
import {NONE_OPTION} from '../components/Molecules/Sort'
import {isEmpty, isEqual} from '../helpers/utils'

const useFetchWithConditions = (defaultSortOption, limit, service) => {
  const [page, setPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(false)
  const [sortOption, setSortOption] = useState(defaultSortOption)
  const [filterOption, setFilterOption] = useState(ALL_OPTION)
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState('')

  const resetPagination = () => {
    setPage(1)
    setIsLastPage(false)
  }

  // build the initial URLSearchParams in mount component
  useEffect(() => {
    const params = new URLSearchParams()
    params.append('page', 1)
    params.append('limit', limit)
    params.append('sort', 'id')

    setParams(params.toString())
  }, [limit])

  // effect when the sortOption changes: only update query params and reset pagination
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      let sortValue = isEqual(sortOption.key, NONE_OPTION.key) ? 'id' : sortOption.key

      params.set('sort', sortValue)
      params.set('page', 1)
      return params.toString()
    })

    resetPagination()
  }, [sortOption])

  // effect when the filterOption changes: only update query params and reset pagination
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)

      params.set('filter', filterOption.key)
      params.set('page', 1)
      if (isEqual(filterOption.key, ALL_OPTION.key)) params.delete('filter')

      return params.toString()
    })

    resetPagination()
  }, [filterOption])

  // effect when the searchValue changes: only update query params and reset pagination
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)

      params.set('search', searchValue)
      params.set('page', 1)
      if (isEmpty(searchValue)) params.delete('search')

      return params.toString()
    })

    resetPagination()
  }, [searchValue])

  // effect when the page changes.
  useEffect(() => {
    // if the new value of page is equal to 1 then returns, because others hooks (sortOption,
    // filterOption and searchValue) already changed the query params.
    if (isEqual(page, 1)) return

    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      params.set('page', page)

      return params.toString()
    })
  }, [page])

  // effect when the query params was changed, this happens when the others hooks are
  // launched (sortOption, filterOption, searchValue and page)
  useEffect(() => {
    if (isEmpty(params)) return

    setLoading(true)

    const fetchParams = new URLSearchParams(params)
    const page = fetchParams.get('page')

    service(
      params,
      response => {
        // when backend answers with a size less than the limit, it means that is the last page
        if (response.length < limit) setIsLastPage(true)

        // if the query param has a page with 1 as value then replace the data state completely
        if (page === '1') {
          setData(response)
          setLoading(false)
          return
        }

        // if isn't the page 1 then append the response to current data state
        setData(prevData => {
          prevData.push(...response)
          return prevData
        })
        setLoading(false)
      },
      error => {
        setLoading(false)
        console.error('useFetchWithConditions():', error)
      }
    )
  }, [limit, params]) // eslint-disable-line

  return {
    data,
    loading,
    page,
    setPage,
    isLastPage,
    setSortOption,
    setFilterOption,
    setSearchValue
  }
}

export default useFetchWithConditions
