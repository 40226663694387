import React from 'react'

import NoveltyTable from '../components/Organisms/Tables/NoveltyTable'

const Novelties = () => {
  // TODO agrear skeleton cuando se hagan las peticiones para cargar la página de novedades

  return (
    <main className="ed-grid row-gap">
      <NoveltyTable />
    </main>
  )
}

export default Novelties
