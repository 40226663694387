import React from 'react'

import {capitalize} from '../../../helpers/string-format'
import Badge from '../../Atoms/Badge'
import UserAvatar from '../../Atoms/Templates/UserAvatar'

const UserItem = ({name, email, picture, isActive}) => {
  // const deleteModal = useRef()

  // const handleOpenDeleteModal = () => {
  //   deleteModal.current.openModal()
  // }

  return (
    <>
      <article className="s-mb-0 s-cross-start nowrap">
        <UserAvatar className="s-mr-8px" picture={picture} userName={name} />
        <div className="s-mr-4px">
          <p className="s-mb-0 small">{capitalize(name)}</p>
          <div className="s-cross-start nowrap">
            <p className="s-mb-0 smaller s-color-light-text s-mr-8px">{email}</p>
            <Badge text={isActive ? 'Activo' : 'Inactivo'} color={isActive ? 's-bg-blue' : 's-bg-red'} />
          </div>
        </div>
        {/* <DropdownButton className="s-to-right" icon="vertical-dots">
          <DropdownItem text="Cambiar Rol" />
          <DropdownItem text="Eliminar" onClick={handleOpenDeleteModal} />
        </DropdownButton> */}
      </article>
      {/* <DeleteModal ref={deleteModal} /> */}
    </>
  )
}

UserItem.propTypes = {}

export default UserItem
