import React, {forwardRef, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {PROCESS_PAYROLL} from '../../../helpers/payroll'
import {isEmpty, isEqual} from '../../../helpers/utils'
import {notifyError} from '../../../services/notification'
import RadioButton from '../../Atoms/Forms/RadioButton'
import MultiSelectEmployeeInput from '../../Molecules/Selects/MultiSelectEmployeeInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const TYPE_SELECTION = 'SELECTION'
const TYPE_ALL = 'ALL'

const KIND_PDF_ZIP = 'zip'
const KIND_PDF_ONE_FILE = 'one_file'

const initialState = {
  selection_type: TYPE_ALL,
  contract_ids: [],
  format_file: 'pdf',
  kind: KIND_PDF_ZIP
}

const PayslipReportModal = forwardRef(({processType, onGenerate = () => {}}, ref) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialState)
  const multiSelectRef = useRef()

  const handleGenerate = () => {
    if (isEqual(formData.selection_type, TYPE_SELECTION)) {
      formData.contract_ids = multiSelectRef.current.getContractIDs()

      if (isEmpty(formData.contract_ids)) {
        dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
        return
      }
    }

    onGenerate(formData)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the contract list if the selection_type changed
      contract_ids: isEqual(name, 'selection_type') ? [] : state.contract_ids
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Comprobantes de Nómina</h3>
      <div className="ed-grid s-grid-1 rows-gap s-mb-2">
        <div>
          <RadioButtonsWrapper label="Empleados" className="s-mb-1">
            <RadioButton
              name="selection_type"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_ALL)}
            />

            <RadioButton
              name="selection_type"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>

          {isEqual(formData.selection_type, TYPE_SELECTION) && (
            <MultiSelectEmployeeInput ref={multiSelectRef} placeholder="Escribe para buscar empleados" />
          )}
        </div>

        <RadioButtonsWrapper label="Formato">
          <RadioButton
            name="format_file"
            label="PDF"
            value="pdf"
            onChange={updateRadioButton}
            checked={isEqual(formData.format_file, 'pdf')}
          />

          {isEqual(processType, PROCESS_PAYROLL) && (
            <RadioButton
              name="format_file"
              label="Excel"
              value="xls"
              onChange={updateRadioButton}
              checked={isEqual(formData.format_file, 'xls')}
            />
          )}
        </RadioButtonsWrapper>

        {isEqual(formData.format_file, 'pdf') && (
          <RadioButtonsWrapper label="Generar como" className="s-mb-2">
            <RadioButton
              name="kind"
              label="Archivo ZIP"
              value={KIND_PDF_ZIP}
              onChange={updateRadioButton}
              checked={isEqual(formData.kind, KIND_PDF_ZIP)}
            />

            <RadioButton
              name="kind"
              label="Un solo PDF"
              value={KIND_PDF_ONE_FILE}
              onChange={updateRadioButton}
              checked={isEqual(formData.kind, KIND_PDF_ONE_FILE)}
            />
          </RadioButtonsWrapper>
        )}
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

PayslipReportModal.displayName = 'PayslipReportModal'

export default PayslipReportModal
