import React from 'react'

import {isString} from '../../../helpers/utils'
import PayrollSummaryItem from '../../Molecules/Items/PayrollSummaryItem'
import CarouselGrid from '../../Wrappers/CarouselGrid'
import Container from '../../Wrappers/Container'

const iconName = description => {
  if (!isString(description)) return null

  description = description.toLowerCase()
  if (description.includes('empleado')) return 'user'
  if (description.includes('social')) return 'shield'
  if (description.includes('contrato')) return 'document'

  return 'information'
}

const SummaryPayrollPanel = ({summary}) => {
  return (
    <Container>
      <h3>Resumen general</h3>
      <CarouselGrid
        breakpoints={{
          lg: 2,
          m: 2,
          s: 2
        }}
      >
        {Array.isArray(summary) &&
          summary.map((item, index) => (
            <PayrollSummaryItem key={index} icon={iconName(item.name)} title={item.name} content={`${item.value}`} />
          ))}
      </CarouselGrid>
    </Container>
  )
}

export default SummaryPayrollPanel
