import React from 'react'

import {useEditingStateContext} from '../../providers/EditingState'

const EditParameterFormFooter = () => {
  const {setIsEditing} = useEditingStateContext()

  return (
    <footer className="flex s-flex-gap-12px">
      <button className="button" type="submit">
        Guardar
      </button>
      <button className="button ghost" type="button" onClick={() => setIsEditing(false)}>
        Cancelar
      </button>
    </footer>
  )
}

export default EditParameterFormFooter
