import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useRef, useState} from 'react'

import EmployeeMasterReportModal from '../components/modals/EmployeeMasterReportModal'
import VacationBookModal from '../components/modals/VacationBookModal'

const EmployeesSectionModalsWrapperContext = createContext({})

const EmployeesSectionModalsWrapperProvider = ({children}) => {
  const MasterEmployeesModalRef = useRef()
  const VacationBookModalRef = useRef()
  const [isDownloadingReport, setisDownloadingReport] = useState(false)

  return (
    <EmployeesSectionModalsWrapperContext.Provider
      value={{
        MasterEmployeesModalRef,
        VacationBookModalRef,
        isDownloadingReport,
        setisDownloadingReport
      }}
    >
      {children}
      <EmployeeMasterReportModal ref={MasterEmployeesModalRef} />
      <VacationBookModal ref={VacationBookModalRef} />
    </EmployeesSectionModalsWrapperContext.Provider>
  )
}

export const useEmployeesSectionModalsWrapperContext = () => useContext(EmployeesSectionModalsWrapperContext)

EmployeesSectionModalsWrapperProvider.propTypes = {
  children: PropTypes.node
}

EmployeesSectionModalsWrapperProvider.defaultProps = {
  children: null
}

export default EmployeesSectionModalsWrapperProvider
