import React from 'react'

import EditTextButton from '../../../../components/Atoms/Buttons/EditTextButton'
import TableRow from '../../../../components/Wrappers/TableRow'
import {shortFormatDate} from '../../../../helpers/dates'
import {getFormatParameter, PARAM_STRING} from '../../../../helpers/parameter'
import {useEditingStateContext} from '../../providers/EditingState'

const ParameterValuesHistoryItem = ({paramType = PARAM_STRING, parameter, isEditable}) => {
  const {begins_at, ends_at, value} = parameter
  const {setIsEditing} = useEditingStateContext()

  return (
    <TableRow>
      <p className="m-center">{shortFormatDate(begins_at)}</p>
      <p className="m-center">{shortFormatDate(ends_at) || '- - -'}</p>
      <p className="m-right">{getFormatParameter(paramType, value)}</p>
      {isEditable && (
        <div className="s-cross-center s-main-center">
          <EditTextButton text="Editar" onClick={() => setIsEditing(true)} className="s-w-fit" />
        </div>
      )}
    </TableRow>
  )
}

export default ParameterValuesHistoryItem
