import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {readBackendDate, shortFormatDate, writeBackendDate} from '../../../helpers/dates'
import {fullName} from '../../../helpers/string-format'
import {isEmpty, isEqual, isNull, isString, len} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getEmployees} from '../../../services/employees'
import {notifyError, notifySuccessful, notifyWarning} from '../../../services/notification'
import {deleteProcess, editProcess} from '../../../services/process'
import CancelTextButton from '../../Atoms/Buttons/CancelTextButton'
import SaveTextButton from '../../Atoms/Buttons/SaveTextButton'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import DischargedEmployee from '../../Molecules/DischargedEmployee'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import DeleteModal from '../Modals/DeleteModal'

//----------------------------------------------------------------------------------------
// Employee Item
//----------------------------------------------------------------------------------------

const initialStateEmployeeItem = {
  id: 0,
  termination_reason_id: 0,
  termination_date: null
}

const formFieldsEmployeeItem = {
  id: 'Empleado',
  termination_reason_id: 'Motivo de terminación',
  termination_date: 'Fecha de terminación'
}

const rulesEmployeeItem = {
  id: 'required|integer|not_in:0',
  termination_reason_id: 'required|integer|not_in:0',
  termination_date: 'required|date'
}

//----------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------

const ProcessEditExecSettlementForm = ({data, setDashboardData, handleCloseModal, signalOpen}) => {
  const dispatch = useDispatch()
  const deleteModal = useRef()

  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const reasons = !isNull(cacheGeneral) && cacheGeneral.data?.termination_reasons

  const [currentEmployeeItem, setCurrentEmployeeItem] = useState(initialStateEmployeeItem)
  const [isDisable, setIsDisable] = useState(false)

  const [contracts, setContracts] = useState([])
  const [initialStateContracts, setInitialStateContracts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [idToDelete, setIdToDelete] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    getEmployees(`page=1&limit=100&sort=first_name&contract_ids=${data.contract_ids.join(',')}`, response => {
      if (isNull(response)) {
        setIsLoading(false)
        return
      }

      setContracts(response)
      setInitialStateContracts(getInitialState(response))
      setIsLoading(false)
    })
  }, [signalOpen, data])

  const handleEditEmployee = data => {
    setCurrentEmployeeItem(state => ({
      ...state,
      id: data.contract_id,
      termination_date: readBackendDate(data.termination_date),
      termination_reason_id: data.termination_reason_id
    }))
  }

  const handleOnChangeTerminationReasonID = e => {
    const value = e.target.value

    setCurrentEmployeeItem(state => ({
      ...state,
      termination_reason_id: Number(value)
    }))
  }

  const handleSaveEmployeeItem = () => {
    const validator = new ValidatorForm(currentEmployeeItem, rulesEmployeeItem)
    validator.setAttributeNames(formFieldsEmployeeItem)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    const updatedContracts = [...contracts]

    let indexToUpdate = updatedContracts.findIndex(record => isEqual(record.contract_id, currentEmployeeItem.id))
    updatedContracts[indexToUpdate] = {
      ...updatedContracts[indexToUpdate],
      termination_date: currentEmployeeItem.termination_date,
      termination_reason_id: currentEmployeeItem.termination_reason_id
    }

    setContracts(updatedContracts)
    setCurrentEmployeeItem(initialStateEmployeeItem)
  }

  const handleCancelEmployeeItemForm = () => {
    setCurrentEmployeeItem(initialStateEmployeeItem)
  }

  const handleEditProcess = () => {
    setIsDisable(true)

    const payloadContracts = getPayloadContracts(contracts, initialStateContracts)

    if (isEmpty(payloadContracts)) {
      dispatch(notifyWarning('¡Upps! no realizaste ningún cambio para editar'))
      setIsDisable(false)
      return
    }

    const payload = {
      hash: data.hash,
      process_type_code: data.process_type_code,
      contracts: payloadContracts
    }

    editProcess(payload)
      .then(response => {
        setIsDisable(false)
        dispatch(notifySuccessful('¡Genial! el proceso fue editado con éxito'))
        handleCloseModal()
      })
      .catch(error => {
        setIsDisable(false)
        dispatch(notifyError(error))
      })
  }

  const handleOpenDeleteModal = id => {
    setIdToDelete(id)
    deleteModal.current.openModal()
  }

  const cancelDeleteContract = id => {
    setIdToDelete(0)
  }

  const handleDeleteContract = id => {
    const payload = {
      hash: data.hash,
      process_type_code: data.process_type_code,
      includes_all_contracts: false,
      contracts: [{id: id}]
    }

    deleteProcess(data.hash, payload)
      .then(response => {
        setDashboardData(state => ({
          ...state,
          payroll_processes: {
            ...state.payroll_processes,
            data: [
              ...state.payroll_processes.data.map(p => {
                if (isEqual(p.hash, data.hash)) {
                  return {
                    ...p,
                    contract_ids: [...p.contract_ids.filter(cId => cId !== id)]
                  }
                }

                return p
              })
            ]
          }
        }))

        dispatch(notifySuccessful('Listo! el empleado fue eliminado del proceso con éxito'))
      })
      .catch(error => {
        dispatch(notifyError(error))
      })
      .finally(() => {
        setIdToDelete(0)
        deleteModal.current.closeModal()
        handleCloseModal()
      })
  }

  return (
    <form className="ed-grid form-grid form s-mb-0">
      <div>
        <h3 className="s-center">Edición {data.description}</h3>
        <p className="s-center">{`Periodo de aplicación: ${shortFormatDate(
          data.begins_at_application_period
        )} - ${shortFormatDate(data.ends_at_application_period)}`}</p>
      </div>

      <div>
        <div className="s-main-justify s-cross-center s-mb-2">
          <span className="t4">Empleados</span>
        </div>

        <div className="ed-grid rows-gap s-gap-2">
          {isLoading ? (
            <div className="s-cross-center s-main-center">
              <span className="loading blue s-mr-1"></span>
              <span>Cargando...</span>
            </div>
          ) : (
            <>
              {/* Lista de empleados */}
              {Array.isArray(contracts) &&
                contracts.map(record => {
                  if (isEqual(record.contract_id, currentEmployeeItem.id)) {
                    return (
                      // employee form edition
                      <div
                        key={record.contract_id}
                        className="ed-grid rows-gap s-gap-1 m-gap-2 s-pxy-1 m-pxy-2 s-bg-body normal-radius"
                      >
                        <FormInput label={'Empleado'} value={fullName(record)} disabled />

                        <div className="ed-grid m-grid-2 rows-gap s-gap-1 m-gap-2">
                          <Select
                            isRequired
                            label={formFieldsEmployeeItem.termination_reason_id}
                            name="termination_reason_id"
                            placeholder
                            value={currentEmployeeItem.termination_reason_id}
                            onChange={handleOnChangeTerminationReasonID}
                          >
                            {Array.isArray(reasons) &&
                              reasons.map(reason => (
                                <option key={reason.id} value={reason.id}>
                                  {reason.description}
                                </option>
                              ))}
                          </Select>

                          <DatePickerInput
                            label={formFieldsEmployeeItem.termination_date}
                            isRequired
                            value={currentEmployeeItem.termination_date}
                            minDate={readBackendDate(data.begins_at_application_period)}
                            maxDate={readBackendDate(data.ends_at_application_period)}
                            onChange={date =>
                              setCurrentEmployeeItem(state => ({
                                ...state,
                                termination_date: date
                              }))
                            }
                          />
                        </div>
                        <div className="flex">
                          <SaveTextButton className="s-mr-4px" onClick={handleSaveEmployeeItem} />
                          <CancelTextButton onClick={handleCancelEmployeeItemForm} />
                        </div>
                      </div>
                    )
                  }

                  return (
                    <DischargedEmployee
                      key={record.contract_id}
                      data={record}
                      terminationReasons={reasons}
                      onEdit={handleEditEmployee}
                      onDiscard={len(contracts) > 1 ? handleOpenDeleteModal : null}
                    />
                  )
                })}
            </>
          )}
        </div>
      </div>

      {/* Botones para cancelar o liquidar*/}
      <div className="buttons-container">
        <button type="button" onClick={handleCloseModal} className="button cancel">
          Cancela
        </button>
        <button className="button" type="button" onClick={handleEditProcess} disabled={isDisable}>
          {isDisable ? 'Editando...' : 'Editar'}
        </button>
      </div>

      <DeleteModal
        ref={deleteModal}
        identifier={idToDelete}
        title="¿Quieres eliminar este empleado?"
        content="Una vez realizado, el empleado será eliminado del proceso de liquidación y quedará activo."
        deleteFunction={handleDeleteContract}
        cancelFunction={cancelDeleteContract}
      />
    </form>
  )
}

function getInitialState(contracts) {
  let result = {}
  if (!Array.isArray(contracts)) return result

  contracts.forEach(c => {
    result[c.contract_id] = c
  })

  return result
}

function getPayloadContracts(contracts, initialStateContracts) {
  let result = []
  if (!Array.isArray(contracts)) return result

  contracts.forEach(c => {
    const initialState = initialStateContracts[c.contract_id]

    let terminationDate = c.termination_date
    if (!isString(terminationDate)) {
      terminationDate = writeBackendDate(terminationDate)
    }

    if (
      isEqual(c.termination_reason_id, initialState.termination_reason_id) &&
      isEqual(terminationDate.slice(0, 10), initialState.termination_date.slice(0, 10))
    ) {
      return
    }

    result.push({
      id: c.contract_id,
      termination_reason_id: c.termination_reason_id,
      termination_date: c.termination_date
    })
  })

  return result
}

export default ProcessEditExecSettlementForm
