import React from 'react'

import {shortFormatDate} from '../../helpers/dates'
import {getAvatar} from '../../helpers/static-images'
import {capitalize, fullName} from '../../helpers/string-format'
import {isEmpty, isEqual, isNull} from '../../helpers/utils'
import Icon from '../Atoms/Icon'
import UserAvatar from '../Atoms/Templates/UserAvatar'

const DischargedEmployee = ({data, terminationReasons, onDiscard = null, onEdit = null}) => {
  const terminationReason = !Array.isArray(terminationReasons)
    ? ''
    : terminationReasons.find(reason => isEqual(reason.id, data?.termination_reason_id)).description

  return (
    <article className="s-mb-0 normal-radius s-bg-body s-pxy-1 m-pxy-2">
      <header className="s-main-justify nowrap s-mb-4px">
        <div className="s-cross-center">
          <UserAvatar
            className="s-mr-8px"
            picture={
              isEmpty(data?.thumbnail || data?.employee?.thumbnail)
                ? getAvatar(data?.id || data?.employee?.id, data?.gender || data?.employee?.gender)
                : data?.thumbnail || data?.employee?.thumbnail
            }
          />
          <div>
            <p className="s-mb-0 small">{fullName(data?.employee || data)}</p>
            <p className="s-mb-0 s-color-light-text smaller">
              <span>
                {data?.identification_type || data?.employee?.identification_type}{' '}
                {data?.identification_number || data?.employee?.identification_number}
              </span>
            </p>
          </div>
        </div>

        <div className="s-cross-start">
          {!isNull(onEdit) && (
            <div
              role="button"
              aria-pressed="false"
              onClick={() => {
                onEdit(data)
              }}
            >
              <div className="hover-change s-pxy-4px normal-radius cursor-pointer s-cross-center s-main-center">
                <Icon svg="edit" />
              </div>
            </div>
          )}

          {!isNull(onDiscard) && (
            <div
              role="button"
              aria-pressed="false"
              onClick={() => {
                onDiscard(data?.contract_id || data?.employee?.contract_id)
              }}
            >
              <div className="hover-change s-pxy-4px normal-radius cursor-pointer s-cross-center s-main-center">
                <Icon svg="cross" />
              </div>
            </div>
          )}
        </div>
      </header>

      <div className="ed-grid s-grid-2 smaller s-mb-4px">
        <p className="s-mb-0">{capitalize(terminationReason)}</p>
        <span className="s-right">{shortFormatDate(data?.termination_date)}</span>
        <span className="s-color-light-text code-font">{data?.contract_hash || data?.employee?.contract_hash}</span>
      </div>
    </article>
  )
}

DischargedEmployee.propTypes = {}

export default DischargedEmployee
