import classNames from 'classnames'
import React from 'react'

import TextArea from '../../../../components/Atoms/Forms/TextArea'
import FormInput from '../../../../components/Atoms/Templates/FormInput'
import {useSelectedParameterContext} from '../../providers/SelectedParameter'

const ParameterDetails = () => {
  const {selectedParameter} = useSelectedParameterContext()
  const {data_type: dataType, abbreviation, description, remarks} = selectedParameter

  return (
    <>
      <div className="ed-grid s-grid-2 s-mb-2">
        <FormInput isDisabled value={dataType} label="Tipo de valor" />
        <FormInput isDisabled value={abbreviation} label="Código" />
      </div>
      <TextArea isDisabled value={remarks} label="Decripción" className={classNames('s-w-full s-mb-4')} />

      <hr className="s-w-full" />

      <div>
        <h4 className="s-mb-1">Valor</h4>
        <p>{description}</p>
      </div>
    </>
  )
}

export default ParameterDetails
