import React from 'react'
import {useLocation} from 'react-router-dom'

import {QUERY_PARAM_HASH_CONTRACT} from '../../../config/routes'
import {
  ELECTRONIC_PAYROLL_ACCEPTED,
  ELECTRONIC_PAYROLL_DRAFT,
  ELECTRONIC_PAYROLL_FAILED,
  ELECTRONIC_PAYROLL_IN_PROCESS,
  ELECTRONIC_PAYROLL_REJECTED
} from '../../../helpers/electronic-payroll'
import {history} from '../../../helpers/history'
import {isEqual} from '../../../helpers/utils'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'
import {getElectronicPayrollEmployeeItems} from '../../../services/electronicPayroll'
import EmployeesPanel from '../Panels/EmployeesPanel'

const EmployeesElectronicPayrollSection = ({year, month}) => {
  const {search} = useLocation()
  const defaultSortOption = {key: 'first_name', value: 'Nombre'}

  const handleGetElectronicPayrollEmployees = (queryParams, callback, callbackErr) => {
    const params = new URLSearchParams(queryParams)
    params.set('year', year)
    params.set('month', month)

    getElectronicPayrollEmployeeItems(params.toString(), callback, callbackErr)
  }

  const {data, loading, page, setPage, isLastPage, setSortOption, setFilterOption, setSearchValue} =
    useFetchWithConditions(defaultSortOption, 20, handleGetElectronicPayrollEmployees)

  const unSelectEmployee = () => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.delete(QUERY_PARAM_HASH_CONTRACT)

    history.replace({search: `?${newQueryParams.toString()}`})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleSelectEmployee = employee => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.set(QUERY_PARAM_HASH_CONTRACT, employee.contract_hash)

    history.replace({search: `?${newQueryParams.toString()}`})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleChangeSort = key => {
    unSelectEmployee()
    setSortOption(key)
  }

  const handleChangeFilter = key => {
    unSelectEmployee()
    setFilterOption(key)
  }

  const handleSearchEmployee = value => {
    setSearchValue(value)
  }

  const handlerScrollEnd = () => {
    if (isLastPage) return

    unSelectEmployee()
    setPage(page + 1)
  }

  return (
    <EmployeesPanel
      employees={data}
      loading={loading && isEqual(page, 1)} // only show skeleton if the page is equal to 1
      limitEmployees={100}
      defaultSortOption={defaultSortOption}
      sortOptions={[defaultSortOption, {key: 'last_name', value: 'Apellido'}]}
      filterOptions={[
        {key: ELECTRONIC_PAYROLL_DRAFT, value: 'Borrador'},
        {key: ELECTRONIC_PAYROLL_IN_PROCESS, value: 'En proceso'},
        {key: ELECTRONIC_PAYROLL_ACCEPTED, value: 'Aceptado'},
        {key: ELECTRONIC_PAYROLL_FAILED, value: 'Fallido'},
        {key: ELECTRONIC_PAYROLL_REJECTED, value: 'Rechazado'}
      ]}
      onSelectEmployee={handleSelectEmployee}
      onChangeFilter={handleChangeFilter}
      onChangeSort={handleChangeSort}
      onChangeSearch={handleSearchEmployee}
      onScrollEnd={handlerScrollEnd}
    />
  )
}

export default EmployeesElectronicPayrollSection
