import classes from 'classnames'
import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import routes from '../../config/routes'
import {FloatContainerContext} from '../../contexts/contexts'
import {isEqual} from '../../helpers/utils'
import {selectors as employerSelectors} from '../../redux/ducks/employer'
import {signInWithEmployer} from '../../services/employer'
import AddButton from '../Atoms/Buttons/AddButton'
import CompanyItem from '../Atoms/Items/CompanyItem'

const CompanySelector = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const employers = useSelector(employerSelectors.getAll)
  const employerSession = useSelector(employerSelectors.getSession)

  const {setActive} = useContext(FloatContainerContext)

  const handleEmployerClick = id => {
    // ignore if the employer clicked is equal to the active employer session
    if (isEqual(id, employerSession.id)) {
      setActive(false)
      return
    }

    dispatch(signInWithEmployer(id))
    setActive(false)
  }

  const handleAddCompany = () => history.push(routes.newEmployer)

  return (
    <div className="company-selector select-none float-container s-absolute">
      <div className="ed-grid full row-gap gap-1">
        {employers.map(employer => (
          <CompanyItem
            key={employer.id}
            data-testid="company-item"
            className={classes('hover-change s-pxy-8px small-radius cursor-pointer', {
              'is-active blue': employer.id === employerSession.id
            })}
            src={employer.thumbnail}
            companyName={employer.short_name}
            onClick={() => handleEmployerClick(employer.id)}
          />
        ))}
        <AddButton onClick={handleAddCompany} text="Nueva empresa" />
      </div>
    </div>
  )
}

export default CompanySelector
