import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getPeriodDescription, getPeriodRange} from '../../../helpers/period'
import {
  canClosePeriod,
  hasCloseAllProcessesInPeriod,
  hasPaidAllProcessesInPeriod,
  hasUniqueProcessTypeInPeriod
} from '../../../helpers/process-type'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {closePayPeriod} from '../../../services/employerPayPeriods'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {getPayPeriodsByYearAndMonth} from '../../../services/payPeriods'
import WarningImg from '../../../static/images/stories/warning.svg'
import PeriodItem from '../../Molecules/Items/PeriodItem'
import CarouselGrid from '../../Wrappers/CarouselGrid'
import Container from '../../Wrappers/Container'
import ConfirmModal from '../Modals/ConfirmModal'

const INITIAL_STATE_MODAL = {
  title: '',
  content: null,
  hasCodeConfirmation: false,
  hasHideButtons: false,
  period: null
}

const PeriodPanel = ({periods, processes, setDashboardData}) => {
  const dispatch = useDispatch()
  const confirmModalClosePeriod = useRef()
  const config = useSelector(configProcessTypeSelectors.getAll)

  const [periodToClose, setPeriodToClose] = useState(INITIAL_STATE_MODAL)
  const [isProcessingClosePeriod, setIsProcessingClosePeriod] = useState(false)

  const handleOpenConfirmModalClosePeriod = period => {
    const canClose = canClosePeriod(config, processes, period?.pay_period?.id)

    if (canClose) {
      setPeriodToClose({
        title: 'Cierra el periodo',
        content: closePeriodContent(period),
        hasCodeConfirmation: true,
        hasHideButtons: false,
        pay_period: period?.pay_period
      })
    } else {
      setPeriodToClose({
        title: 'Primero debes',
        content: alertClosePeriodContent(config, processes, period),
        hasCodeConfirmation: false,
        hasHideButtons: true,
        pay_period: period?.pay_period
      })
    }

    confirmModalClosePeriod.current.openModal()
  }

  const handleOnClosePeriod = () => {
    confirmModalClosePeriod.current.closeModal()
    setIsProcessingClosePeriod(true)

    closePayPeriod(
      periodToClose.pay_period.id,
      response => {
        // query and update the pay period of the month
        getPayPeriodsByYearAndMonth(periodToClose.pay_period.year, periodToClose.pay_period.month, responsePeriods => {
          setDashboardData(state => ({
            ...state,
            pay_periods: {
              ...state.pay_periods,
              data: [...responsePeriods]
            }
          }))
        })
        dispatch(notifySuccessful('El periodo fue cerrado correctamente'))
        setIsProcessingClosePeriod(false)
        setPeriodToClose(INITIAL_STATE_MODAL)
      },
      error => {
        dispatch(notifyError(error))
        setIsProcessingClosePeriod(false)
        setPeriodToClose(INITIAL_STATE_MODAL)
      }
    )
  }

  return (
    <Container>
      <h3>Periodos</h3>
      <CarouselGrid
        breakpoints={{
          lg: 2,
          m: 2,
          s: 2
        }}
      >
        {Array.isArray(periods) &&
          periods.map(period => (
            <PeriodItem
              key={period.pay_period.id}
              icon={period.is_active ? 'lock-open' : 'lock'}
              title={getTitle(period.pay_frequency?.description)}
              content={getPeriodRange(period.pay_period?.begins_at, period.pay_period?.ends_at)}
            >
              {period.is_active && (
                <button
                  className="button micro ghost"
                  disabled={isProcessingClosePeriod}
                  onClick={() => handleOpenConfirmModalClosePeriod(period)}
                >
                  {isProcessingClosePeriod ? 'Cerrando...' : 'Cierra'}
                </button>
              )}
            </PeriodItem>
          ))}
      </CarouselGrid>

      {/* Modal para cerrar el periodo */}
      <ConfirmModal
        ref={confirmModalClosePeriod}
        hasCodeConfirmation={periodToClose.hasCodeConfirmation}
        hasHideButtons={periodToClose.hasHideButtons}
        confirmFunction={handleOnClosePeriod}
        title={periodToClose.title}
        content={periodToClose.content}
      />
    </Container>
  )
}

function getTitle(frequency) {
  return `Periodo ${frequency.toLowerCase()}`
}

function closePeriodContent(periodToClose) {
  return (
    <>
      ¿Estás seguro de cerrar el{' '}
      <span className="s-color-title">
        {getPeriodDescription(
          periodToClose?.pay_period?.begins_at,
          periodToClose?.pay_period?.ends_at,
          periodToClose?.pay_frequency?.description
        )}
      </span>
      ? Esta acción no se puede revetir.
    </>
  )
}

function alertClosePeriodContent(config, processes, period) {
  return (
    <div className="ed-grid s-grid-3 s-gap-2">
      <img src={WarningImg} alt="Warning" width="200px" height="200px" />
      <div className="s-cols-2 s-left s-cross-center">
        <p className="s-mb-0">
          <span>{hasUniqueProcessTypeInPeriod(config, processes, period?.pay_period?.id) ? '✅' : '❌'}</span> Tener un
          proceso tipo NÓMINA
        </p>
        <p className="s-mb-0">
          <span>{hasCloseAllProcessesInPeriod(config, processes, period?.pay_period?.id) ? '✅' : '❌'}</span> Cerrar
          todos tus procesos
        </p>
        <p className="s-mb-0">
          <span>{hasPaidAllProcessesInPeriod(config, processes, period?.pay_period?.id) ? '✅' : '❌'}</span> Pagar a
          todos tus empleados
        </p>
      </div>
    </div>
  )
}

export default PeriodPanel
