import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/base-concepts'

export const getBasesOfAConcept = (conceptID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/concepts/${conceptID}`, callback)
}

export const createBaseConcept = (payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.post(RESOURCE, payload, callback, callbackError)
}

export const deleteBaseConcept = (baseID, conceptID, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.delete(`${RESOURCE}/bases/${baseID}/concepts/${conceptID}`, callback, callbackError)
}
