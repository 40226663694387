import PropTypes from 'prop-types'
import React from 'react'

const TooltipContainer = props => {
  const {className, children} = props
  return <div className={`tooltip-container ${className}`}>{children}</div>
}

TooltipContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

TooltipContainer.defaultProps = {
  className: ''
}

export default TooltipContainer
