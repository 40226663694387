import React, {forwardRef} from 'react'

import {isFunction} from '../../../helpers/utils'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const DeleteModal = forwardRef((props, ref) => {
  const {
    identifier = '',
    deleteFunction,
    cancelFunction = null,
    title = '¿Quieres eliminar este elemento?',
    content = 'Una vez que lo hagas, no hay forma de recuperarlo'
  } = props

  const handleCloseModal = () => {
    if (isFunction(cancelFunction)) {
      cancelFunction(identifier)
    }

    ref.current.closeModal()
  }

  const handleDelete = () => {
    if (isFunction(deleteFunction)) {
      deleteFunction(identifier)
    }
  }

  return (
    <ModalTemplate ref={ref} className="s-center">
      <h3>{title}</h3>
      <p className="s-mb-24px">{content}</p>
      <div className="buttons-container">
        <button className="button cancel" onClick={handleCloseModal}>
          No, cancelar
        </button>
        <button className="button delete" onClick={handleDelete}>
          Sí, eliminar
        </button>
      </div>
    </ModalTemplate>
  )
})

DeleteModal.displayName = 'DeleteModal'

export default DeleteModal
