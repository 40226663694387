import React from 'react'

import {isEmpty} from '../../../helpers/utils'
import ParameterValuesHistoryItem from '../../../modules/payroll-parameters/components/table/ParameterValuesHistoryItem'
import {MANDATORY_TABLE_COLUMNS, NO_MANDATORY_TABLE_COLUMNS} from '../../../modules/payroll-parameters/data/table'
import {useEditingStateContext} from '../../../modules/payroll-parameters/providers/EditingState'
import {useParameterValuesContext} from '../../../modules/payroll-parameters/providers/ParameterValues'
import {useSelectedParameterContext} from '../../../modules/payroll-parameters/providers/SelectedParameter'
import Table from '../../Wrappers/Table'

const ParameterValuesHistory = () => {
  const {selectedParameter, handleReturnToList} = useSelectedParameterContext()
  const {isEditing} = useEditingStateContext()
  const {parameterValues} = useParameterValuesContext()

  if (isEmpty(parameterValues) || isEditing) return null

  const {data_type: dataType, is_mandatory_employer: isEditable} = selectedParameter

  return (
    <>
      <Table
        heads={isEditable ? MANDATORY_TABLE_COLUMNS : NO_MANDATORY_TABLE_COLUMNS}
        className="small whitespace-nowrap s-mb-2"
        classNameTh="s-bg-title s-color-body-alt border-xy s-center"
      >
        <ParameterValuesHistoryItem
          key={selectedParameter.id}
          isEditable={isEditable}
          parameter={parameterValues[parameterValues.length - 1]}
          paramType={dataType}
        />
      </Table>
      <button className="button ghost" onClick={handleReturnToList}>
        Atras
      </button>
    </>
  )
}

export default ParameterValuesHistory
