import PropTypes from 'prop-types'
import React, {forwardRef, useState} from 'react'

import {randomID} from '../../../helpers/utils'
import ConfirmCodeImg from '../../../static/images/stories/confirm-code.svg'
import FormInput from '../../Atoms/Templates/FormInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const ConfirmModal = forwardRef((props, ref) => {
  const {confirmFunction, title, content, hasCodeConfirmation, hasHideButtons} = props
  const [confirmationCode, setConfirmationCode] = useState(randomID(4))
  const [inputConfirmationCode, setInputConfirmationCode] = useState('')

  const handleOnChangeOpen = isOpen => {
    if (isOpen) return

    setConfirmationCode(randomID(4))
    setInputConfirmationCode('')
  }

  const handleOnChangeInput = e => {
    const value = e.target.value
    setInputConfirmationCode(value)
  }

  const handleCloseModal = () => {
    ref.current.closeModal()
  }

  return (
    <ModalTemplate ref={ref} className="medium s-center" onChangeOpen={handleOnChangeOpen}>
      <h3>{title}</h3>
      {hasCodeConfirmation && <img src={ConfirmCodeImg} alt="Código de confirmación" width="200px" height="200px" />}
      <div className="s-mb-24px">{content}</div>
      {hasCodeConfirmation && (
        <div className="s-mb-24px ed-grid s-grid-4">
          <span className="s-cols-4 s-mb-1">
            Escribe el código <span className="s-color-blue">{confirmationCode}</span> para confirmar
          </span>
          <FormInput
            value={inputConfirmationCode}
            onChange={handleOnChangeInput}
            align="center"
            className="s-cols-2 s-x-2"
          />
        </div>
      )}
      {!hasHideButtons && (
        <div className="buttons-container">
          {!hasCodeConfirmation && (
            <button className="button cancel" onClick={handleCloseModal}>
              No, cancelar
            </button>
          )}
          <button
            className="button"
            onClick={confirmFunction}
            disabled={hasCodeConfirmation && confirmationCode !== inputConfirmationCode}
          >
            {hasCodeConfirmation ? 'Aceptar' : 'Sí, continuar'}
          </button>
        </div>
      )}
    </ModalTemplate>
  )
})

ConfirmModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node
}

ConfirmModal.defaultProps = {
  title: '¿Estas seguro que quieres realizar esta acción?',
  content: ''
}

ConfirmModal.displayName = 'ConfirmModal'

export default ConfirmModal
