import PropTypes from 'prop-types'
import React from 'react'
import DatePicker from 'react-datepicker'

const YearPickerInput = ({label, className, isRequired, id, name, value, onChange, ...rest}) => {
  return (
    <div className={`form-item year-picker ${className}`}>
      <DatePicker
        id={id}
        name={name}
        selected={value}
        onChange={onChange}
        showYearPicker
        dateFormat="yyyy"
        required={isRequired}
        className="date-picker"
        yearItemNumber={9}
        {...rest}
      />
      {label && (
        <label htmlFor={id} className={isRequired && 'required'}>
          {label}
        </label>
      )}
    </div>
  )
}

YearPickerInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  id: PropTypes.string
}

YearPickerInput.defaulProps = {
  isRequired: false,
  id: ''
}

export default YearPickerInput
