import {redirectRoute} from '../config/routes'
import {history} from '../helpers/history'
import {RequestWithAuthorization} from '../helpers/request'
import {actions as employerActions} from '../redux/ducks/employer'

const TOKEN_EMPLOYER_KEY = process.env.REACT_APP_TOKEN_EMPLOYER_KEY
const RESOURCE = '/employers'

export const getEmployers = () => dispatch => {
  const request = new RequestWithAuthorization()
  request.get(RESOURCE, response => dispatch(employerActions.set(response)))
}

export const signInWithEmployer = employerID => dispatch => {
  const request = new RequestWithAuthorization()
  request.get(`/login-with-employer?employerID=${employerID}`, response => {
    // it save employerToken in sessionStorage for persist the token when of page is
    // reloaded, it saved in session for allow have a window per employer
    sessionStorage.setItem(TOKEN_EMPLOYER_KEY, response.token)
    dispatch(employerActions.signIn(employerID))

    if (history.location.pathname !== redirectRoute.privateEmployer) {
      history.push(redirectRoute.privateEmployer)
    }
  })
}

export const createNewEmployer = payload => dispatch => {
  const request = new RequestWithAuthorization()
  request.post(RESOURCE, payload, response => {
    dispatch(employerActions.new(response))
    history.push(redirectRoute.private)
  })
}
