import React, {useEffect} from 'react'

import SettingHeader from '../Headers/SettingHeader'
import BanksPanel from '../Panels/BanksPanel'
import BasicCompanyInformationPanel from '../Panels/BasicCompanyInformationPanel'
import BranchOfficePanel from '../Panels/BranchOfficePanel'
import JobsPanel from '../Panels/JobsPanel'
import UsersPanel from '../Panels/UsersPanel'

const EmployerSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="lg-cols-2">
        <SettingHeader
          icon="building"
          title="Empresa"
          description="Aquí podrás editar los datos de tu empresa y administrar tus usuarios, sucursales, centros de trabajo, cuentas y cargos"
        />
      </div>

      <div>
        <div className="ed-grid row-gap">
          <BasicCompanyInformationPanel />
          <UsersPanel />
          <BanksPanel />
        </div>
      </div>

      <div>
        <div className="ed-grid row-gap">
          <BranchOfficePanel />
          <JobsPanel />
        </div>
      </div>
    </>
  )
}

export default EmployerSettings
