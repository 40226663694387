import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import DashboardBirthday from '../components/Organisms/Sections/DashboardBirthday'
import DashboardLastProcess from '../components/Organisms/Sections/DashboardLastProcess'
// import DashboardSuscription from '../components/Organisms/Sections/DashboardSuscription'
import DashboardProgress from '../components/Organisms/Sections/DashboardProgress'
import DashboardWelcome from '../components/Organisms/Sections/DashboardWelcome'
import DashboardSkeleton from '../components/Organisms/Skeleton/DashboardSkeleton'
import {isNull} from '../helpers/utils'
import {selectors as employerSelectors} from '../redux/ducks/employer'
import {getProcessOfActiveMonth} from '../services/processHeader'

const Dashboard = () => {
  const employerSession = useSelector(employerSelectors.getSession)

  const [processes, setProcesses] = useState(null)
  const [month, setMonth] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)

    // reset state by change employer
    setProcesses(null)
    setMonth(null)

    getProcessOfActiveMonth(data => {
      if (data?.processes) setProcesses(data.processes)
      if (data?.month) setMonth(data.month - 1)
    })
  }, [employerSession])

  return (
    <main className="ed-grid m-grid-6 dashboard">
      {isNull(processes) ? (
        <DashboardSkeleton />
      ) : (
        <div className="ed-grid m-cols-6 lg-cols-4 m-grid-2 lg-x-2 rows-gap">
          <DashboardWelcome />
          <DashboardProgress processes={processes} month={month} />
          <DashboardBirthday month={month} limitEmployees={200} />
          {/* Disable for the MVP */}
          {/* <DashboardSuscription /> */}
          <DashboardLastProcess processes={processes} />
        </div>
      )}
    </main>
  )
}

export default Dashboard
