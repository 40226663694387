import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Chips from 'react-chips'

const FormChipsInput = props => {
  const {label, value, id, className, handleChange, isRequired} = props

  return (
    <div className={classNames(className, 'form-item')}>
      <Chips className="chips" value={value} onChange={handleChange} />

      {label && (
        <label htmlFor={id} className={classNames({required: isRequired})}>
          {label}
        </label>
      )}
      <p className="s-mb-0 smaller">
        * usa la tecla
        <b>&quot;Tab&quot;</b> o <b>&quot;,&quot;</b> para separar los items
      </p>
    </div>
  )
}

FormChipsInput.propTypes = {
  label: PropTypes.node,
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool
}

FormChipsInput.defaultProps = {
  value: [],
  id: '',
  className: '',
  label: ''
}

export default FormChipsInput
