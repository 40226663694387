import React from 'react'
import {useSelector} from 'react-redux'

import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import Icon from '../../Atoms/Icon'
import CompanyItem from '../../Atoms/Items/CompanyItem'

const SidebarHeader = () => {
  const sessionEmployer = useSelector(employerSelectors.getSession)

  return (
    <header className="cursor-pointer select-none s-mb-16px s-px-12px s-py-8px hover-change normal-radius">
      <div className="s-cross-center nowrap">
        <CompanyItem
          className="s-w-full"
          data-testid="session-data"
          src={sessionEmployer.thumbnail}
          companyName={sessionEmployer.short_name}
        />
        <Icon className="s-to-right flex-none" svg="double-arrow" size="1rem" />
      </div>
    </header>
  )
}

export default SidebarHeader
