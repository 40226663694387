import React from 'react'

import {MASTER_EMPLOYEES_MODAL_DATA, VACATIONS_BOOK_MODAL_DATA} from '../constants/reports'
import {useEmployeesSectionModalsWrapperContext} from '../providers/EmployeesSectionModalsWrapper'

const useGetEmployeesReportoptions = () => {
  const {MasterEmployeesModalRef, VacationBookModalRef} = useEmployeesSectionModalsWrapperContext()

  const EMPLOYEES_REPORT_OPTIONS = [
    {
      ...MASTER_EMPLOYEES_MODAL_DATA,
      onClick: () => MasterEmployeesModalRef.current.openModal()
    },
    {
      ...VACATIONS_BOOK_MODAL_DATA,
      onClick: () => VacationBookModalRef.current.openModal()
    }
  ]

  return {EMPLOYEES_REPORT_OPTIONS}
}

export default useGetEmployeesReportoptions
