import PropTypes from 'prop-types'
import React, {useState} from 'react'

import {AccordionWrapperContext} from '../../contexts/contexts'

const AccordionWrapper = props => {
  const {defaultActive, children} = props
  const [currentAccordionActive, setCurrentAccordionActive] = useState(defaultActive)

  return (
    <AccordionWrapperContext.Provider
      value={{
        currentAccordionActive,
        setCurrentAccordionActive
      }}
    >
      {children}
    </AccordionWrapperContext.Provider>
  )
}

AccordionWrapper.propTypes = {
  defaultActive: PropTypes.number,
  children: PropTypes.node.isRequired
}

AccordionWrapper.defaultProps = {
  defaultActive: null
}

export default AccordionWrapper
