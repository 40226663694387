import {
  PARAM_ARRAY_NUMBER,
  PARAM_ARRAY_STRING,
  PARAM_BOOLEAN,
  PARAM_DATE,
  PARAM_NUMBER,
  PARAM_STRING
} from '../../../helpers/parameter'

export const BADGE_TYPES = {
  [PARAM_NUMBER]: {label: 'Número', color: 's-bg-green'},
  [PARAM_STRING]: {label: 'Texto', color: 's-bg-text'},
  [PARAM_BOOLEAN]: {label: 'Booleano', color: 's-bg-red'},
  [PARAM_DATE]: {label: 'Fecha', color: 's-bg-alt-red'},
  [PARAM_ARRAY_NUMBER]: {label: 'Lista de números', color: 's-bg-blue'},
  [PARAM_ARRAY_STRING]: {label: 'Lista de textos', color: 's-bg-alt-yellow'}
}
