import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

import {useSelectedParameterContext} from './SelectedParameter'

const EditingStateContext = createContext({})

const EditingStateProvider = ({children}) => {
  const {selectedParameter: parameter} = useSelectedParameterContext()

  const [isEditing, setIsEditing] = useState(Boolean(parameter.is_mandatory_employer))

  return (
    <EditingStateContext.Provider
      value={{
        isEditing,
        setIsEditing
      }}
    >
      {children}
    </EditingStateContext.Provider>
  )
}

export const useEditingStateContext = () => useContext(EditingStateContext)

EditingStateProvider.propTypes = {
  children: PropTypes.node
}

EditingStateProvider.defaultProps = {
  children: null
}

export default EditingStateProvider
