import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import ElectronicPayrollHeader from '../components/Organisms/Headers/ElectronicPayrollHeader'
import ElectronicPayrolSection from '../components/Organisms/Sections/ElectronicPayrollSection'
import {redirectRoute} from '../config/routes'
import {PayrollContext} from '../contexts/contexts'
import {COLOMBIA_CODE} from '../helpers/country'
import {isEmpty, isEqual, isUndefined} from '../helpers/utils'
import {selectors as employerSelectors} from '../redux/ducks/employer'
import {getElectronicPayrollSummary} from '../services/electronicPayroll'

const ElectronicPayroll = () => {
  const history = useHistory()
  const employerSession = useSelector(employerSelectors.getSession)
  const {payrollDate} = useContext(PayrollContext)

  const [summary, setSummary] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isUndefined(employerSession)) return

    if (!isEqual(employerSession?.code_iso_3, COLOMBIA_CODE)) {
      history.push(redirectRoute.privateEmployer)
      return
    }
  }, [employerSession]) // eslint-disable-line

  useEffect(() => {
    if (!employerSession?.electronic_payroll_metadata?.is_enabled || !payrollDate) return

    fetchSummary()
  }, [employerSession?.electronic_payroll_metadata?.is_enabled, payrollDate])

  const fetchSummary = () => {
    setIsLoading(true)

    getElectronicPayrollSummary(payrollDate?.year, payrollDate?.month)
      .then(response => {
        if (isEmpty(response)) {
          setSummary(null)
          setIsLoading(false)
          return
        }

        // we set a timestamp mark to identify when we call the summary
        response.timestamp = new Date().getTime()
        setSummary(response)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  const promiseFetchSummaryWithoutLoading = () => {
    return new Promise((resolve, reject) => {
      getElectronicPayrollSummary(payrollDate?.year, payrollDate?.month)
        .then(response => {
          if (isEmpty(response)) {
            setSummary(null)
            resolve('OK')
            return
          }

          // we set a timestamp mark to identify when we call the summary
          response.timestamp = new Date().getTime()
          setSummary(response)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  return (
    <main className="ed-grid rows-gap">
      <ElectronicPayrollHeader
        summary={summary}
        onUpdateSummary={fetchSummary}
        onUpdateSummaryPromise={promiseFetchSummaryWithoutLoading}
      />

      <ElectronicPayrolSection
        isEnable={employerSession?.electronic_payroll_metadata?.is_enabled}
        summary={summary}
        isLoadingSummary={isLoading}
        onUpdateSummaryPromise={promiseFetchSummaryWithoutLoading}
      />
    </main>
  )
}

export default ElectronicPayroll
