import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyBanks, getLocalCache} from '../../../helpers/cache'
import {PAYMENT_EMPLOYEES} from '../../../helpers/payroll'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getDepositsByProcessID} from '../../../services/depositPayment'
import PaymentsEmptyState from '../../Organisms/Emptys/PaymentsEmptyState'
import PaymentAuthorizationModal from '../../Organisms/Modals/PaymentAuthorizationModal'
import Table from '../../Wrappers/Table'
import ApprovalPaymentRow from './Rows/ApprovalPaymentRow'

const ApprovalTable = ({setActiveOptionPage, updateSummary}) => {
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
  const banks = !isNull(cacheBanks) && cacheBanks.data?.banks

  const {activeProcess} = useContext(PayrollContext)
  const [depositPayments, setDepositPayments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    setIsLoading(true)
    getDepositsByProcessID(activeProcess.id, source, r => {
      // update summary with the information of the deposit payments
      updateSummary(summary => {
        const employeesWithDeposit = isEmpty(r)
          ? 0
          : r.reduce((total, d) => (!d.is_paid ? d.employees + total : total), 0)
        const employeesPaid = isEmpty(r) ? 0 : r.reduce((total, d) => (d.is_paid ? d.employees + total : total), 0)
        const employeesPayable = summary.total_employees - (employeesWithDeposit + employeesPaid)

        return {
          ...summary,
          employees_payable: employeesPayable,
          employees_with_deposit: employeesWithDeposit,
          employees_paid: employeesPaid
        }
      })

      setDepositPayments(isEmpty(r) ? [] : r)
      setIsLoading(false)
    })

    // clean up after this effect (unmount component):
    return () => {
      source.cancel()
    }
  }, [activeProcess.id, updateSummary])

  const handleDeleteDeposit = (depositID, employeesNumber) => {
    setDepositPayments(depositPayments.filter(d => d.id !== depositID))

    // update summary when a deposit is delete, only can deleted the deposit not paid
    updateSummary(summary => {
      return {
        ...summary,
        employees_payable: summary.employees_payable + employeesNumber,
        employees_with_deposit: summary.employees_with_deposit - employeesNumber
      }
    })
  }

  const handleApproveDeposit = (depositID, employeesNumber) => {
    setDepositPayments(
      depositPayments.map(d => {
        if (isEqual(d.id, depositID)) {
          d.is_paid = true
        }

        return d
      })
    )

    // update summary
    updateSummary(summary => {
      return {
        ...summary,
        employees_with_deposit: summary.employees_with_deposit - employeesNumber,
        employees_paid: summary.employees_paid + employeesNumber
      }
    })
  }

  return (
    <>
      {isLoading ? (
        <Skeleton height={400} />
      ) : isEmpty(depositPayments) ? (
        <PaymentsEmptyState
          description={
            <>
              <p>Aún no tienes depositos de pagos, ¿Qué tal si empiezas ahora?</p>
              <button type="button" className="button small" onClick={() => setActiveOptionPage(PAYMENT_EMPLOYEES)}>
                Crear Deposito
              </button>
            </>
          }
        />
      ) : (
        <Table heads={['Código', 'Empleados', 'Método de pago', 'Banco seleccionado', 'Neto', 'Estado', '']}>
          {Array.isArray(depositPayments) &&
            depositPayments.map(depositPayment => (
              <ApprovalPaymentRow
                key={depositPayment?.id}
                code={depositPayment?.code}
                depositPaymentID={depositPayment?.id}
                processID={activeProcess.id}
                employeesNumber={depositPayment?.employees}
                paymentMethod={depositPayment?.payment_method}
                bankName={banks.find(b => isEqual(b.id, depositPayment.bank_id))?.short_name || ''}
                bankAccount={depositPayment?.bank_account_number}
                total={depositPayment?.total}
                isPaid={depositPayment?.is_paid}
                employerSession={employerSession}
                onDelete={handleDeleteDeposit}
                onApprove={handleApproveDeposit}
              />
            ))}
        </Table>
      )}
      <PaymentAuthorizationModal />
    </>
  )
}

export default ApprovalTable
