import PropTypes from 'prop-types'
import React from 'react'

import {shortFormatDate} from '../../../helpers/dates'
import {STATUS_HISTORY} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'
import Icon from '../../Atoms/Icon'

const PerPeriodItem = ({perPeriodNovelty}) => {
  const isHistory = isEqual(perPeriodNovelty.status, STATUS_HISTORY)

  return (
    <article
      className={`notification-item s-pxy-4px s-mb-0 normal-radius ${
        isHistory ? 's-bg-body' : perPeriodNovelty?.is_rest_day ? 's-bg-yellow' : 's-bg-blue-50'
      }`}
    >
      <div className="s-cross-start nowrap">
        <div className={'s-mr-8px'}>
          {isHistory ? (
            <Icon size="24px" svg="lock" />
          ) : (
            <Icon
              size="24px"
              color={perPeriodNovelty?.is_rest_day ? 'var(--alt-yellow-accent)' : 'var(--blue-accent)'}
              svg="information-fill"
            />
          )}
        </div>

        <div>
          <p className="small s-mb-0">
            <span className="s-color-title">
              {` ${shortFormatDate(perPeriodNovelty.begins_at)} - ${shortFormatDate(
                perPeriodNovelty.ends_at
              )} ${perPeriodNovelty?.is_rest_day ? ' | Dominical' : ''}`}
            </span>
          </p>
          <p className="s-mb-0 smaller s-color-light-text">{isHistory ? 'Histórico' : 'Pendiente'}</p>
        </div>
      </div>
    </article>
  )
}

PerPeriodItem.propTypes = {
  perPeriodNovelty: PropTypes.shape({}).isRequired
}

PerPeriodItem.defaultProps = {
  perPeriodNovelty: {}
}

export default PerPeriodItem
