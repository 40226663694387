import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import routes from '../../../config/routes'
import {capitalize} from '../../../helpers/string-format'
import {signOut} from '../../../services/user'
import FloatContainer from '../../Wrappers/FloatContainer'
import NavItem from '../Items/NavItem'
import UserAvatar from '../Templates/UserAvatar'

const HeaderUserAvatar = () => {
  const user = useSelector(store => store.userReducer)

  const dispatch = useDispatch()
  const handleSignOut = () => dispatch(signOut())

  return (
    <div className="s-relative">
      <FloatContainer>
        <div className="header-user-avatar lg-bg-body-alt background-transition lg-hover-bg-border s-cross-center cursor-pointer lg-pr-16px">
          <UserAvatar className="lg-mr-8px" picture={user.picture} userName={user.name} />
          <p className="from-lg small s-mb-0">{capitalize(user.name)}</p>
        </div>
        <div className="float-container s-right-16px lg-right-0">
          <ul className="list-container s-column s-flex-gap-4px ">
            <NavItem text="Perfil" icon="user" url={routes.profile} />
            <NavItem className="logout" text="Cerrar sesión" icon="exit" onClick={handleSignOut} />
          </ul>
        </div>
      </FloatContainer>
    </div>
  )
}

export default HeaderUserAvatar
