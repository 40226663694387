export const TYPE_SELECTION = 'SELECTION'
export const TYPE_ALL = 'ALL'

export const EMPLOYEE_MASTER_FORM_INITIAL_STATE = {
  selection_type: TYPE_ALL,
  statuses_index: []
}

export const VACATIONS_BOOK_FORM_INITIAL_STATE = {
  requestDate: new Date(),
  selectionType: TYPE_ALL,
  contractIds: []
}

export const VACATIONS_BOOK_FORM_RULES = {
  requestDate: 'date|required',
  contractIds: 'required_if:selectionType,SELECTION|array'
}

export const VACATIONS_BOOK_FORM_FIELD_NAMES = {
  requestDate: '"Fecha de corte"',
  contractIds: '"Empleados"'
}
