// keys of the contract object
export const KEY_BRANCH_OFFICE_ID = 'branch_office_id'
export const KEY_PLACE_LABOR_MUNICIPALITY_ID = 'place_labor_municipality_id'
export const KEY_ORGANIZATIONAL_STRUCTURE_ID = 'organizational_struct_id'
export const KEY_HIRE_DATE = 'hire_date'
export const KEY_EXPIRATION_DATE = 'expiration_date'
export const KEY_TRIAL_DATE = 'trial_period_date'
export const KEY_PAY_FREQUENCY_ID = 'pay_frequency_id'
export const KEY_ALTERNATE_CODE = 'alternate_code'
export const KEY_MONTH_HOURS = 'month_hours'
export const KEY_DIMENSIONS = 'dimensions'

// other keys for the logic
export const _KEY_REST_DAYS = 'rest_days'

export const LABELS = {
  [KEY_BRANCH_OFFICE_ID]: 'Sucursal',
  [KEY_PLACE_LABOR_MUNICIPALITY_ID]: 'Ciudad de trabajo',
  [KEY_ORGANIZATIONAL_STRUCTURE_ID]: 'Área',
  [KEY_HIRE_DATE]: 'Inicio de contrato',
  [KEY_EXPIRATION_DATE]: 'Finalización de contrato',
  [KEY_TRIAL_DATE]: 'Fin período de prueba',
  [KEY_PAY_FREQUENCY_ID]: 'Frecuencia de pago',
  [KEY_ALTERNATE_CODE]: 'Código Alterno',
  [KEY_MONTH_HOURS]: 'Horas Mes',
  [_KEY_REST_DAYS]: 'Días de descanso'
}

export const RULES = {
  [KEY_BRANCH_OFFICE_ID]: 'integer|required|not_in:0',
  [KEY_PLACE_LABOR_MUNICIPALITY_ID]: 'integer|required|not_in:0',
  [KEY_ORGANIZATIONAL_STRUCTURE_ID]: 'string|required|min:1|max:21',
  [KEY_HIRE_DATE]: 'date|required',
  [KEY_EXPIRATION_DATE]: `date|after_or_equal:${KEY_HIRE_DATE}`,
  [KEY_TRIAL_DATE]: `date|after:${KEY_HIRE_DATE}`,
  [KEY_PAY_FREQUENCY_ID]: 'integer|required|not_in:0',
  [KEY_ALTERNATE_CODE]: 'string',
  [KEY_MONTH_HOURS]: 'numeric|min:8|max:240|required'
}
