import React, {forwardRef} from 'react'

import PayrollCalculate from '../../../static/images/stories/coffee-break.svg'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const PendingElectronicPayrollModal = forwardRef((props, ref) => {
  const handleCloseModal = () => {
    ref.current.closeModal()
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium">
      <h3>¡Vaya!</h3>
      <p className="l-block">
        Este proceso es un poco demorado, no somos nosotros son ellos 🙊, pero fresco ve por un cafe y nosotros te
        avisamos cuando terminemos, también puedes darle click al botón actualizar para ir viendo los cambios.
      </p>
      <div>
        <img src={PayrollCalculate} alt="" style={{maxHeight: '400px'}} />
      </div>
      <button className="button" onClick={handleCloseModal}>
        Vale
      </button>
    </ModalTemplate>
  )
})

PendingElectronicPayrollModal.displayName = 'PendingElectronicPayrollModal'

export default PendingElectronicPayrollModal
