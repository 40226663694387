import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import {isEmpty, isNull} from '../../../helpers/utils'
import {updateConceptPriorityOrder} from '../../../services/concept'
import {notifyError, notifySuccessful, notifyWarning} from '../../../services/notification'
import Concept from '../../Molecules/Templates/Concept'
import Container from '../../Wrappers/Container'

const ConceptPriorityOrderPanel = ({list, onUpdateOrder}) => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [newIndex, setNewIndex] = useState(null)
  const [previousList, setPreviousList] = useState([])

  const handleDragStart = () => {
    if (isEdit) return

    setPreviousList(list)
    setIsEdit(true)
  }

  const handleOnDrop = (indexDropZone, e) => {
    e.preventDefault()
    setNewIndex(indexDropZone)
  }

  // handleonDragOver this method is mandatory for that the onDrop event work
  const handleonDragOver = e => e.preventDefault()

  const handleDragEnd = indexDragItem => {
    if (isNull(newIndex)) return

    const currentDragging = list[indexDragItem]
    const newList = Array.from(list)

    newList.splice(indexDragItem, 1)
    newList.splice(newIndex, 0, currentDragging)

    setNewIndex(null)
    onUpdateOrder(newList)
  }

  const handleCancelEdit = () => {
    onUpdateOrder(previousList)
    setPreviousList([])
    setNewIndex(null)
    setIsEdit(false)
    dispatch(notifyWarning('Proceso cancelado'))
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleSave = () => {
    updateConceptPriorityOrder(
      {priority_list: list.map(c => c.id)},
      response => {
        setPreviousList([])
        setIsEdit(false)
        dispatch(notifySuccessful('¡Listo! el orden de prioridad fue actualizado'))
      },
      error => dispatch(notifyError(error))
    )
  }

  return (
    <Container>
      <p className="small">
        Arrastra y suelta el concepto en la posición que desees, el orden le indica a{' '}
        <span className="s-color-title">nomineros</span> que concepto se debe cálcular primero y cuál después. No
        olvides guardar tus cambios.
      </p>

      <div className="ed-grid s-gap-1 m-gap-2 m-grid-3 m-cols-3 row-gap s-mb-3">
        {Array.isArray(list) &&
          list.map((c, index) => (
            <section
              key={c.id}
              className="draggable s-bg-body normal-radius s-px-1 s-py-1 s-mb-0"
              draggable
              onDragStart={handleDragStart}
              onDragEnd={e => handleDragEnd(index)}
              onDrop={e => handleOnDrop(index, e)}
              onDragOver={handleonDragOver}
            >
              <div className="sequence">{index + 1}</div>
              <Concept concept={c} onSelect={() => {}} className="flex-grow" />
            </section>
          ))}
      </div>

      {!isEmpty(list) && (
        <>
          <button className="button ghost s-mr-1" disabled={!isEdit} onClick={handleCancelEdit}>
            Cancelar
          </button>
          <button className="button" disabled={!isEdit} onClick={handleSave}>
            Guardar
          </button>
        </>
      )}
    </Container>
  )
}

export default ConceptPriorityOrderPanel
