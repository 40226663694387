import React from 'react'

import {getFormatCurrency} from '../../../helpers/format-number'
import {CASH, CHECK, WIRE_TRANSFER} from '../../../helpers/payroll'
import {capitalize} from '../../../helpers/string-format'
import {isEqual} from '../../../helpers/utils'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import PayrollSummaryItem from '../../Molecules/Items/PayrollSummaryItem'
import FloatContainer from '../../Wrappers/FloatContainer'

const SummaryPaymentsTableSection = ({
  employeesCount,
  totalNetPay,
  paymentMethod,
  setPaymentMethod,
  employerSession
}) => {
  return (
    <section className="summary s-border-bottom-border m-border-bottom-transparent s-mb-0 ed-grid rows-gap s-grid-1 m-grid-3 s-bg-body-alt s-pb-16px m-pt-16px s-px-16px m-px-24px lg-px-32px">
      <PayrollSummaryItem icon="user" title="Empleados seleccionados" content={`${employeesCount}`} />
      <PayrollSummaryItem
        icon="cash"
        title="Total a pagar"
        content={`${getFormatCurrency(
          totalNetPay,
          employerSession?.code_iso_3,
          !employerSession?.not_include_decimals_in_calculation
        )}`}
      />
      <div>
        <FloatContainer>
          <PayrollSummaryItem
            className="cursor-pointer"
            isDropdown
            icon="document"
            title="Pagar con"
            content={capitalize(paymentMethod)}
          />
          <div className="float-container">
            {[CHECK, CASH, WIRE_TRANSFER].map(method => (
              <DropdownItem
                key={method}
                text={capitalize(method)}
                isActive={isEqual(method, paymentMethod)}
                onClick={() => setPaymentMethod(method)}
              >
                {method}
              </DropdownItem>
            ))}
          </div>
        </FloatContainer>
      </div>
    </section>
  )
}

export default SummaryPaymentsTableSection
