import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import FullPageLoader from '../components/Organisms/FullPageLoader'
import routes from '../config/routes'

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const OAuth = () => {
  let query = useQuery()
  let history = useHistory()

  useEffect(() => {
    const token = query.get('access-token')

    if (token) {
      localStorage.setItem(TOKEN_KEY, token)
      history.push(routes.employers)
    }
  }, [query, history])

  return <FullPageLoader />
}

export default OAuth
