import React, {forwardRef, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {getMonth} from '../../../helpers/dates'
import {getFormatNumber} from '../../../helpers/format-number'
import {mediaToM} from '../../../helpers/media-queries'
import {isNull, stringToHashNumber} from '../../../helpers/utils'
import {useMedia} from '../../../hooks/useMedia'
import useObjectURL from '../../../hooks/useObjectURL'
import {notifyError} from '../../../services/notification'
import {generatePILA, getPILAFile, getPILASummary} from '../../../services/pila'
import Icon from '../../Atoms/Icon'
import TabItem from '../../Atoms/TabItem'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import TabsWrapper from '../../Wrappers/TabsWrapper'
import LastProcessEmptyState from '../Emptys/LastProcessEmptyState'
import ConfirmModal from './ConfirmModal'

const SocialSecurityModal = forwardRef(({year, month}, ref) => {
  const dispatch = useDispatch()
  const toM = useMedia(mediaToM)
  const confirmReGenerateModal = useRef()

  const [totalPILA, setTotalPILA] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const {setObject, objectURL} = useObjectURL(null)
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const downloadLinkReport = useRef()

  const handleCheckStatusPILA = isOpen => {
    if (!isOpen) return

    getPILASummary(year, month, setTotalPILA)
  }

  const handleGeneratePILA = () => {
    setIsLoading(true)

    generatePILA(
      year,
      month,
      () => {
        getPILASummary(year, month, data => {
          setTotalPILA(data)
          setIsLoading(false)
        })
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  const handleOpenConfirmReGenerate = () => {
    confirmReGenerateModal.current.openModal()
  }

  const handleConfirmReGenerate = () => {
    confirmReGenerateModal.current.closeModal()
    handleGeneratePILA()
  }

  const handleDownloadPILAFile = format => {
    setIsLoadingDownloadReport(true)

    getPILAFile(
      year,
      month,
      format,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  return (
    <ModalTemplate ref={ref} className="s-center large" onChangeOpen={handleCheckStatusPILA}>
      <h3 className="s-mb-1">
        Seguridad Social {getMonth(month - 1)} {year}
      </h3>
      {!isNull(totalPILA) && <h4 className="s-color-blue">{getFormatNumber(totalPILA.total)}</h4>}
      {isNull(totalPILA) && (
        <LastProcessEmptyState
          title="Vaya, aún no has generado tu seguridad social"
          subtitle="¿Qué tal si lo haces ahora?"
          captionActionButton="Generar"
          onClickActionButton={handleGeneratePILA}
          buttonSize="normal"
          isDisableActionButton={isLoading}
        />
      )}

      {!isNull(totalPILA) && (
        <div>
          <TabsWrapper defaultActive={stringToHashNumber('EPS')}>
            <div className="s-mb-1 s-cross-center s-main-center">
              {Array.isArray(totalPILA?.entity_types) &&
                totalPILA.entity_types.map(t => (
                  <TabItem key={t.entity_type} index={stringToHashNumber(t.entity_type)} className="s-mr-1">
                    {t.entity_type}
                  </TabItem>
                ))}
            </div>

            {Array.isArray(totalPILA?.entity_types) &&
              totalPILA.entity_types.map(t => (
                <PanelTemplate key={t.entity_type} index={stringToHashNumber(t.entity_type)}>
                  <div style={{maxHeight: '45vh', overflow: 'auto'}} className="s-mb-1">
                    <PreviewMarkdown content={getTable(t)} />
                  </div>
                </PanelTemplate>
              ))}
          </TabsWrapper>
          <div className="ed-grid s-grid-1 m-grid-2 s-gap-1 row-gap">
            <div className="m-cross-start">
              <button
                className={`button ghost icon m-mr-1 ${toM && 's-50'}`}
                onClick={() => handleDownloadPILAFile('txt')}
                disabled={isLoadingDownloadReport}
              >
                <div className="s-cross-center s-main-center">
                  <Icon svg="document" className="s-mr-4px" />
                  <span>PILA.txt</span>
                </div>
              </button>
              <button
                className={`button ghost icon  ${toM && 's-50'}`}
                onClick={() => handleDownloadPILAFile('xlsx')}
                disabled={isLoadingDownloadReport}
              >
                <div className="s-cross-center s-main-center">
                  <Icon svg="excel" className="s-mr-4px" />
                  <span>Planilla</span>
                </div>
              </button>
            </div>
            <button className="button" onClick={handleOpenConfirmReGenerate} disabled={isLoading}>
              Generar de Nuevo
            </button>
          </div>
        </div>
      )}

      <ConfirmModal
        ref={confirmReGenerateModal}
        confirmFunction={handleConfirmReGenerate}
        title="¿Estas seguro que quieres re-generar la seguridad social?"
        content="Esta acción no se puede revertir, la seguridad social actual será sobreescrita."
      />

      {/* This link is hidden, we only use for download the payroll report */}
      <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
        file downloaded
      </a>
    </ModalTemplate>
  )
})

const getTable = entity => {
  let rows = ''

  entity.entities.forEach(e => {
    rows += `| *${e.code_fund}* | ${e.nit}-${e.dv} | ${e.business_name} | ${e.employees_count} | ${getFormatNumber(e.amount)} |\n`
  })

  return `
  | Código | Nit | Fondo | Empleados | Total |
  | :--- | :--- | :--- | :--- | ---: |
  ${rows}| | | **Total** | **${entity.employees_count}** | **${getFormatNumber(entity.amount)}** |
  `
}

SocialSecurityModal.displayName = 'SocialSecurityModal'

export default SocialSecurityModal
