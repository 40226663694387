import PropTypes from 'prop-types'
import React from 'react'

import Icon from '../Icon'

const PanelInformationItem = ({
  twoColumns,
  title,
  text,
  hasAction,
  iconAction,
  handleAction,
  colorText,
  classesText
}) => {
  return (
    <div className={`s-column ${twoColumns && 's-cols-2'}`}>
      {hasAction ? (
        <p className="s-mb-0 s-color-light-text small s-cross-center">
          <span className="s-mr-1">{title}</span>
          <Icon svg={iconAction} onClick={handleAction} className="s-color-blue blue cursor-pointer" />
        </p>
      ) : (
        <p className="s-mb-0 s-color-light-text small">{title}</p>
      )}

      <p className={`s-mb-0 smaller min-height-smaller ${colorText} ${classesText}`}>{text}</p>
    </div>
  )
}

PanelInformationItem.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  hasAction: PropTypes.bool,
  iconAction: PropTypes.string,
  handleAction: PropTypes.func,
  classesText: PropTypes.string,
  colorText: PropTypes.string.isRequired,
  twoColumns: PropTypes.bool
}

PanelInformationItem.defaultProps = {
  twoColumns: false,
  title: '',
  text: '',
  hasAction: false,
  iconAction: 'edit',
  handleAction: () => {},
  colorText: 's-color-lighter-text',
  classesText: ''
}

export default PanelInformationItem
