import {isDate, isEqual, isNull, isUndefined} from './utils'

// The JS dates started since January 1, 1970, 00:00:00 UTC
export const JS_ZERO_DATE = new Date(Date.UTC(1970, 0, 1, 0, 0, 0, 0))

export const isJSZeroDate = date => isEqual(date.getTime(), 0)

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const shortMonths = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

const weekDays = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

export const backendWeekDays = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
export const shortBackendWeekDays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

const DAY_MILISECONDS = 1000 * 60 * 60 * 24

export const getWeekDay = index => weekDays[index]

export const getMonth = index => months[index]

export const getShortMonths = index => shortMonths[index]

export const addDays = (date, days) => {
  if (!isDate(date)) date = new Date()

  let timeDate = date.getTime()
  return new Date((timeDate += DAY_MILISECONDS * days))
}

export const subDays = (date, days) => {
  if (!isDate(date)) date = new Date()

  let timeDate = date.getTime()
  return new Date((timeDate -= DAY_MILISECONDS * days))
}

export const addMonths = (date, months) => {
  if (isNull(date)) date = new Date()

  const copy = new Date(date)
  copy.setMonth(copy.getMonth() + months)
  return subDays(copy, 1)
}

export const diffDays = (startDate, endDate) => {
  return Math.ceil((endDate - startDate) / DAY_MILISECONDS)
}

export const startDateOfMonth = date => {
  if (isNull(date)) date = new Date()

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1)
}

export const startDateOfNextMonth = date => {
  date = readBackendDate(date)
  if (isNull(date)) date = new Date()

  let year = date.getUTCFullYear()
  let month = date.getUTCMonth() + 1

  if (month === 12) {
    month = 0
    year++
  }

  return new Date(year, month, 1)
}

export const isZeroDate = date => isEqual(date, '0001-01-01T00:00:00Z')

// IMPORTANT: Las fechas en Backend las guardamos en UTC por lo que siempre el server devolverá
// el timeZone en cero "Z" última parte de la fecha ISO, entonces para que el navegador
// no la cambie a tiempo local las operaciones con fechas las debemos realizar
// en UTC (getUTCDate, getUTCMonth etc)
export const shortFormatDate = date => {
  if (isZeroDate(date) || isUndefined(date) || isNull(date)) return null

  try {
    let local = new Date(date) // Tiempo Local COL

    return `${local.getUTCDate().toString().padStart(2, '0')} ${
      shortMonths[local.getUTCMonth()]
    } ${local.getUTCFullYear()}`
  } catch (e) {
    return null
  }
}

export const stringFormatDate = date => {
  if (isZeroDate(date) || isUndefined(date) || isNull(date)) return null

  try {
    let local = new Date(date) // Tiempo Local COL
    return `${local.getUTCFullYear()}-${String(local.getUTCMonth() + 1).padStart(2, '0')}-${String(
      local.getUTCDate()
    ).padStart(2, '0')}`
  } catch (e) {
    return null
  }
}

// readBackendDate backend handles the dates as UTC, when backend answer the dates has the
// format iso "2021-12-21T00:00:00Z"  the format Z means this date time has no offset
// from UTC  (or 0 hour offset).
// example:
// - input:  "2021-12-21T00:00:00Z" : 21 Dec 2021 00:00:00 GMT
// - output: "2021-12-21T05:00:00Z" : 21 Dec 2021 00:00:00 GMT-0500 (Colombia Standard Time)
export const readBackendDate = dateUTC => {
  if (isZeroDate(dateUTC) || isUndefined(dateUTC) || isNull(dateUTC)) return null

  try {
    let date = new Date(dateUTC)
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  } catch (e) {
    return null
  }
}

// readBackendDateAndTime backend handles the dates as UTC, when backend answer the dates has the
// format iso "2021-04-30T09:45:11Z"  the format Z means this date time has no offset
// from UTC  (or 0 hour offset).
// example:
// - input:  "2021-04-30T09:45:11Z" : 30 Apr 2021 09:45:11 GMT
// - output: "2021-04-30T09:45:11.000-05:00" : 30 Apr 2021 09:45:11 GMT-0500 (Colombia Standard Time)
export const readBackendDateAndTime = dateUTC => {
  if (isZeroDate(dateUTC) || isUndefined(dateUTC) || isNull(dateUTC)) return null

  try {
    let date = new Date(dateUTC)

    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  } catch (e) {
    return null
  }
}

// writeBackendDate backend handles the dates as UTC, we need to sent the date with the
// format iso "2021-12-21T00:00:00Z" the format Z means this date time has no offset
// from UTC  (or 0 hour offset).
// example:
// - input:   "2021-12-21T05:00:00Z" : 21 Dec 2021 00:00:00 GMT-0500 (Colombia Standard Time)
// - output:  "2021-12-21T00:00:00Z" : 21 Dec 2021 00:00:00 GMT
export const writeBackendDate = localDate => {
  try {
    let date = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()))

    return date.toISOString()
  } catch (e) {
    return null
  }
}
