import React, {useEffect} from 'react'

import SettingHeader from '../Headers/SettingHeader'

const PayrollElectronicSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="lg-cols-2">
        <SettingHeader
          icon="rocket"
          title="Nómina electronica"
          description="Habilita Nómina electronica con la DIAN"
          isComingSoon
        />
      </div>

      <div></div>
    </>
  )
}

export default PayrollElectronicSettings
