import routes from '../config/routes'
import {isString, isUndefined} from './utils'

// process types
export const PROCESS_PAYROLL = 'NOMINA'
export const PROCESS_PAYROLL_ADJUSTMENT = 'NOMINA_AJUSTE'
export const PROCESS_PAYROLL_ADVANCE = 'NOMINA_ANTICIPO'
export const PROCESS_VACATION_ADVANCE = 'VACACIONES_ANTICIPO'
export const PROCESS_SETTLEMENT = 'LIQUIDACION'
export const PROCESS_SETTLEMENT_ADJUSTMENT = 'LIQUIDACION_AJUSTE'
export const PROCESS_PRIMA = 'PRIMA'

// process type run_by
export const PROCESS_TYPE_RUN_BY_PERIOD = 'PERIOD'
export const PROCESS_TYPE_RUN_BY_MONTH = 'MONTH'
export const PROCESS_TYPE_RUN_BY_MONTH_WITH_CLOSED_PERIODS = 'MONTH_WITH_CLOSED_PERIODS'

// process type executors
export const PROCESS_EXEC_BY_FREQUENCY = 'BY_FREQUENCY'
export const PROCESS_EXEC_BY_MONTH = 'BY_MONTH'
export const PROCESS_EXEC_SETTLEMENT = 'SETTLEMENT'

// process actions
export const ACTION_RUN = 'RUN'
export const ACTION_APPROVE = 'APPROVE'

// execution process status
export const EXEC_STATUS_PENDING = 'PENDING'
export const EXEC_STATUS_COMPLETED = 'COMPLETED'

// process status
export const STATUS_HISTORY = 'HISTORY'
export const STATUS_PRE_PAYROLL = 'PRE_PAYROLL'

// process status execution
export const STATUS_SUCCESSFUL = 'SUCCESSFUL'
export const STATUS_FAILURE = 'FAILURE'

// stages
export const STAGE_NOVELTIES = 'NOVELTIES'
export const STAGE_PAYSHEET = 'PAYSHEET'
export const STAGE_PAYMENTS = 'PAYMENTS'

// actions
export const EDIT = 'EDIT'
export const CREATE = 'CREATE'
export const SHOW = 'SHOW'

// payment section
export const PAYMENT_EMPLOYEES = 'PAYMENT_EMPLOYEES'
export const PAYMENT_DEPOSITS = 'PAYMENT_DEPOSITS'

// Pay Methods
export const WIRE_TRANSFER = 'TRANSFERENCIA BANCARIA'
export const CASH = 'EFECTIVO'
export const CHECK = 'CHEQUE'

// stageHash
export const STAGE_NOVELTY_HASH = '#novedades'
export const STAGE_PAYSHEET_HASH = '#nomina'
export const STAGE_PAYMENT_HASH = '#pagos'

export const objStageHash = {
  [STAGE_NOVELTY_HASH]: 1,
  [STAGE_PAYSHEET_HASH]: 2,
  [STAGE_PAYMENT_HASH]: 3
}

export const processStage = {
  [STAGE_NOVELTIES]: {
    order: objStageHash[STAGE_NOVELTY_HASH],
    code: STAGE_NOVELTIES,
    name: 'Novedades',
    percentage: '25%',
    route: routes.payroll.novelties,
    hash: STAGE_NOVELTY_HASH
  },
  [STAGE_PAYSHEET]: {
    order: objStageHash[STAGE_PAYSHEET_HASH],
    code: STAGE_PAYSHEET,
    name: 'Nómina',
    percentage: '50%',
    route: routes.payroll.payslip,
    hash: STAGE_PAYSHEET_HASH
  },
  [STAGE_PAYMENTS]: {
    order: objStageHash[STAGE_PAYMENT_HASH],
    code: STAGE_PAYMENTS,
    name: 'Pagos',
    percentage: '75%',
    route: routes.payroll.payments,
    hash: STAGE_PAYMENT_HASH
  }
}

export const findProcessStage = code => {
  if (!isString(code)) return null

  code = code.toUpperCase()
  if (isUndefined(processStage[code])) return null

  return processStage[code]
}
