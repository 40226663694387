import PropTypes from 'prop-types'
import React, {useState} from 'react'

import {isEqual} from '../../helpers/utils'
import DropdownItem from '../Atoms/Items/DropdownItem'
import DropdownButton from '../Molecules/Dropsdown/DropdownButton'

export const NONE_OPTION = {key: 0, value: 'Ninguno'}

const Sort = ({options, defaultSortOption, onSelect, className, showContent}) => {
  const [selected, setSelected] = useState(defaultSortOption)

  const handleClick = option => {
    setSelected(option)
    onSelect(option)
  }

  return (
    <DropdownButton
      icon="order"
      content={showContent ? (isEqual(selected.key, NONE_OPTION.key) ? 'Ordenar' : selected.value) : ''}
      isSelected={!isEqual(selected.key, NONE_OPTION.key)}
      className={className}
    >
      <DropdownItem
        text={NONE_OPTION.value}
        isActive={isEqual(selected.key, NONE_OPTION.key)}
        onClick={() => handleClick(NONE_OPTION)}
      />
      {options.map(option => (
        <DropdownItem
          key={option.key}
          text={option.value}
          isActive={isEqual(selected.key, option.key)}
          onClick={() => handleClick(option)}
        />
      ))}
    </DropdownButton>
  )
}

Sort.propTypes = {
  options: PropTypes.array.isRequired,
  defaultSortOption: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  showContent: PropTypes.bool
}

Sort.defaultProps = {
  options: [],
  defaultSortOption: NONE_OPTION,
  className: '',
  onSelect: () => {},
  showContent: true
}

export default Sort
