import React, {useEffect, useRef, useState} from 'react'

import {URL_BASE_HELP} from '../../../helpers/routes'
import {isEmpty} from '../../../helpers/utils'
import {getBySlug, searchHelpArticles} from '../../../services/helpArticles'
import SearchInput from '../../Atoms/Forms/SearchInput'
import Icon from '../../Atoms/Icon'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'

const HelpPanel = ({isShow, slug, onClose, onFinish}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [translate, setTranslate] = useState({x: 0, y: 0})
  const [content, setContent] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [previousSlug, setPreviousSlug] = useState('')

  const container = useRef()
  const contentContainer = useRef()

  const handleActiveDragging = () => setIsDragging(true)
  const handleCancelDragging = () => setIsDragging(false)

  useEffect(() => {
    if (isEmpty(slug)) return

    getBySlug(slug, response => {
      setContent(response.content)
      setPreviousSlug(slug)
      onFinish('')
    })
  }, [slug])

  useEffect(() => {
    if (isShow) return

    setTranslate({x: 0, y: 0})
    setContent('')
  }, [isShow])

  const handlePointerMove = e => {
    if (!isDragging) return

    e.preventDefault()

    if (!container.current) return

    if (container.current.getBoundingClientRect().top <= 0 && e.clientY <= 50) return

    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY
    })
  }

  const handleSearch = e => {
    e.preventDefault()

    searchHelpArticles(searchValue, response => {
      setContent(getContentSearch(response, previousSlug))

      if (!contentContainer.current) return
      contentContainer.current.scrollTo({top: 0, behavior: 'smooth'})
    })
  }

  return (
    isShow && (
      <div
        ref={container}
        className="help-panel"
        style={{
          transform: `translateX(${translate.x}px) translateY(${translate.y}px)`
        }}
      >
        <header
          className="help-header"
          onPointerDown={handleActiveDragging}
          onPointerUp={handleCancelDragging}
          onPointerMove={handlePointerMove}
          onPointerLeave={handleCancelDragging}
        >
          <span className="t2">Ayuda</span>
          <Icon svg="cross" size="24" onClick={onClose} className="cursor-pointer" />
        </header>
        <div ref={contentContainer} className="help-content">
          <div className="s-fixed z-fixed s-90 s-py-1 s-bg-white">
            <form onSubmit={handleSearch} className="s-mb-0">
              <SearchInput
                placeholder="En que podemos ayudarte?"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </form>
          </div>
          <div className="s-py-4"></div>
          <PreviewMarkdown content={content} />
        </div>
      </div>
    )
  )
}

function getContentSearch(response, back) {
  if (isEmpty(response)) return `No se encontraron resultados.\n\n[Regresar](${URL_BASE_HELP}${back})`

  let result = ''
  response.forEach(({title, slug}) => {
    result = `${result} - [${title}](${URL_BASE_HELP}${slug}) \n`
  })

  return result
}

export default HelpPanel
