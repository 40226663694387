import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {getAvatar} from '../../../helpers/static-images'
import {fullName} from '../../../helpers/string-format'
import {isEmpty, isUndefined} from '../../../helpers/utils'
import Badge from '../../Atoms/Badge'
import Icon from '../../Atoms/Icon'
import UserAvatar from '../../Atoms/Templates/UserAvatar'

const Employee = ({employee, onSelectEmployee, tag}) => {
  return (
    <article className="flex s-mb-0 s-cross-center nowrap" onClick={() => onSelectEmployee(employee)}>
      <UserAvatar
        picture={
          isEmpty(employee.thumbnail)
            ? getAvatar(employee.contract_id || employee.id, employee.gender)
            : employee.thumbnail
        }
        className="s-mr-8px"
      />
      <div className="overflow-ellipsis s-100">
        <p className="small s-mb-0 flex s-cross-center nowrap">
          <span
            className={classNames('s-mr-8px overflow-ellipsis', {
              's-90': !isUndefined(employee.has_alerts) && employee.has_alerts
            })}
          >
            {fullName(employee)}
          </span>
          {!isUndefined(employee.has_alerts) && employee.has_alerts && (
            <Icon svg="information" color="var(--alt-red-accent)" />
          )}
        </p>

        <div className="s-cross-center smaller s-color-light-text">
          <p className="s-mb-0 s-mr-8px">
            {employee.identification_type}. {employee.identification_number}
          </p>
          {tag?.descriptionField && <Badge text={employee[tag.descriptionField]} color={employee[tag.colorField]} />}
        </div>
      </div>
    </article>
  )
}

Employee.propTypes = {
  employee: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    middle_name: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    surname: PropTypes.string,
    identification_number: PropTypes.string.isRequired,
    identification_type: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    tag: PropTypes.object,
    has_alerts: PropTypes.bool
  }).isRequired,
  onSelectEmployee: PropTypes.func
}

Employee.defaultProps = {
  employee: {
    first_name: '',
    middle_name: '',
    last_name: '',
    surname: '',
    identification_number: '',
    identification_type: '',
    gender: '',
    thumbnail: '',
    tag: {},
    has_alerts: false
  },
  onSelectEmployee: () => {}
}

export default Employee
