import PropTypes from 'prop-types'
import React, {useRef} from 'react'

import BranchOfficeImage from '../../../static/images/stories/branch-office.svg'
import BranchOfficeModal from '../Modals/BranchOfficeModal'

const BranchOfficeEmptyState = ({size}) => {
  const modal = useRef()

  const handleOpenModal = () => modal.current.openModal()

  return (
    <div className="s-column s-center s-cross-center s-main-center m-90 s-to-center">
      <div className="l-block" />
      <img className="lg-25 s-mb-16px" src={BranchOfficeImage} alt="sucursal de la empresa" />
      <p className={`${size} s-mb-16px s-color-light-text`}>
        Esto se ve un poco vacío.
        <br />
        Empieza agregando las sucursales de tu empresa, así podrás asignarlas a tus empleados.
      </p>
      <button className={`button ${size}`} type="button" onClick={handleOpenModal}>
        Crea una nueva sucursal
      </button>

      <BranchOfficeModal ref={modal} />
    </div>
  )
}

BranchOfficeEmptyState.propTypes = {
  size: PropTypes.string
}

BranchOfficeEmptyState.defaultProps = {
  size: ''
}

export default BranchOfficeEmptyState
