import React from 'react'

import DatePickerInput from '../../../../components/Molecules/Pickers/DatePickerInput'

const EditParameterFormDates = ({formData, setFormData, isDisabled}) => {
  const {startDate, endDate} = formData

  return (
    <div className="ed-grid s-grid-2 s-mb-2">
      <DatePickerInput
        label="Fecha inicial"
        id="startDate"
        value={startDate}
        onChange={date => setFormData(state => ({...state, startDate: date}))}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isRequired
        disabled={isDisabled}
      />
      <DatePickerInput
        label="Fecha final"
        id="endDate"
        value={endDate}
        onChange={date => setFormData(state => ({...state, endDate: date}))}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={isDisabled}
      />
    </div>
  )
}

export default EditParameterFormDates
