import classNames from 'classnames'
import React, {useEffect, useState} from 'react'

import {isEmpty} from '../../../helpers/utils'
import {BADGE_TYPES} from '../../../modules/payroll-parameters/data/badges'
import {getShortedParameterDescription} from '../../../modules/payroll-parameters/helpers/parameters'
import {useSelectedParameterContext} from '../../../modules/payroll-parameters/providers/SelectedParameter'
import Badge from '../../Atoms/Badge'
import EditTextButton from '../../Atoms/Buttons/EditTextButton'
import SeeTextButton from '../../Atoms/Buttons/SeeTextButton'
import SearchInput from '../../Atoms/Forms/SearchInput'
import Icon from '../../Atoms/Icon'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'

const GlobalParameterSettingPanelList = ({list}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filterList, setFilterList] = useState(list)
  const {setSelectedParameter} = useSelectedParameterContext()

  useEffect(() => {
    setFilterList(list)
    setSearchValue('')
  }, [list])

  const handleSearch = e => {
    let value = e.target.value
    setSearchValue(value)

    if (isEmpty(value)) {
      setFilterList(list)
      return
    }

    value = value.toLowerCase()
    const newList = list.filter(
      p =>
        p.abbreviation.toLowerCase().includes(value) ||
        p.description.toLowerCase().includes(value) ||
        p.remarks.toLowerCase().includes(value)
    )
    setFilterList(newList)
  }

  return (
    <>
      <div className="s-main-justify s-mb-1">
        <p className="small">Consulta y edita los parámetros de nómina.</p>
        <div className="s-cross-center">
          <SearchInput className="s-mr-1 small" value={searchValue} onChange={handleSearch} placeholder="Busca aquí" />
        </div>
      </div>

      <Table
        heads={['Parámetro', 'Acciones']}
        className="table-template"
        classNameTh="border-bottom-white-4"
        classNameHeader="sticky-element-to-header z-fixed"
      >
        {Array.isArray(filterList) &&
          filterList.map(p => (
            <TableRow key={p.id}>
              <div className="ed-grid gap-1 rows-gap">
                <div className="s-cross-center s-overflow-hidden">
                  <p className="smaller s-color-title s-mb-0 s-mr-8px">{p.abbreviation}</p>
                  <Badge
                    text={BADGE_TYPES[p.data_type].label}
                    color={BADGE_TYPES[p.data_type].color}
                    className="s-mr-1"
                  />
                  {!p.is_mandatory_employer && (
                    <div title="Administrado por nomineros" className="s-mr-1">
                      <Icon svg="nomineros" size="10" />
                    </div>
                  )}
                </div>
                <div className="smaller">
                  <PreviewMarkdown content={getShortedParameterDescription(p?.remarks)} />
                </div>
              </div>
              <>
                {p.is_mandatory_employer && <EditTextButton onClick={() => setSelectedParameter(p)} />}
                {!p.is_mandatory_employer && (
                  <SeeTextButton className={'transparent-hover'} onClick={() => setSelectedParameter(p)} />
                )}
              </>
            </TableRow>
          ))}
      </Table>
    </>
  )
}

export default GlobalParameterSettingPanelList
