import React from 'react'
import {Redirect, Switch, useRouteMatch} from 'react-router-dom'

import PayrollWrapper from '../components/Wrappers/PayrollWrapper'
import {redirectRoute} from '../config/routes'
import PrivateEmployer from '../config/Routes/PrivateEmployer'
import PayrollMonth from './PayrollMonth'

const Payroll = () => {
  let {path} = useRouteMatch()

  return (
    <PayrollWrapper>
      <Switch>
        <PrivateEmployer path={path}>
          <PayrollMonth />
        </PrivateEmployer>
        <Redirect to={redirectRoute.privateEmployer} />
      </Switch>
    </PayrollWrapper>
  )
}

export default Payroll
