import {nanoid} from 'nanoid'
import {useEffect, useState} from 'react'

import {NONE_OPTION} from '../components/Molecules/Sort'
import {isEmpty, isEqual} from '../helpers/utils'

const useFetchNoveltiesWithConditions = (processHash, defaultSortOption, limit, service) => {
  const [page, setPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(true)
  const [sortOption, setSortOption] = useState(defaultSortOption)
  const [filter, setFilter] = useState({key: '', value: 0})
  const [isLoading, setIsLoading] = useState(false)
  const [novelties, setNovelties] = useState([])
  const [reloadNovelties, setReloadNovelties] = useState(false)
  const [params, setParams] = useState('')

  const resetPagination = () => {
    setPage(1)
    setIsLastPage(false)
  }

  // build the initial URLSearchParams in mount component
  useEffect(() => {
    const params = new URLSearchParams()
    params.append('page', 1)
    params.append('limit', limit)
    params.append('sort', 'id')

    setParams(params.toString())
  }, [limit])

  // effect when the sortOption changes: only update query params and reset pagination
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      let sortValue = isEqual(sortOption.key, NONE_OPTION.key) ? 'id' : sortOption.key

      params.set('sort', sortValue)
      params.set('page', 1)
      return params.toString()
    })

    resetPagination()
  }, [sortOption])

  // effect when the filter changes: only update query params and reset pagination
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)

      params.set(filter.key, filter.value)
      params.set('page', 1)
      if (isEqual(filter.value, 0)) params.delete(filter.key)

      return params.toString()
    })

    resetPagination()
  }, [filter])

  // effect when reload changes: only update to page one, reset pagination and sets a fake
  // nanoid for that the params changes and the fetch effect can be executed
  useEffect(() => {
    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)

      params.set('page', 1)
      params.set('reload-id', nanoid())

      return params.toString()
    })

    resetPagination()
  }, [reloadNovelties])

  // effect when the page changes.
  useEffect(() => {
    // if the new value of page is equal to 1 then returns, because others hooks (sortOption,
    // filterOption, searchValue and reload) already changed the query params.
    if (isEqual(page, 1)) return

    setParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      params.set('page', page)

      return params.toString()
    })
  }, [page])

  // effect when the query params was changed, this happens when the others hooks are
  // launched (sortOption, filterOption, searchValue, reload and page)
  useEffect(() => {
    if (isEmpty(params)) return

    const fetchParams = new URLSearchParams(params)
    const page = fetchParams.get('page')

    setIsLoading(true)
    service(processHash, params, response => {
      // when backend answers with a size less than the limit, it means that is the last page
      if (response.length < limit) {
        setIsLastPage(true)
      }

      // if the query param has a page with 1 as value then replace the data state completely
      if (page === '1') {
        setNovelties(response)
        setIsLoading(false)
        return
      }

      // if isn't the page 1 then append the response to current data state
      setNovelties(prevData => [...prevData, ...response])
      setIsLoading(false)
    })
  }, [params, processHash, limit]) // eslint-disable-line

  return {
    novelties,
    page,
    setPage,
    isLastPage,
    setSortOption,
    setFilter,
    reloadNovelties,
    setReloadNovelties,
    isLoading
  }
}

export default useFetchNoveltiesWithConditions
