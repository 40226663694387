import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const SIZES = {
  xs: 'smallest',
  s: 'small',
  m: 'normal'
}

const Badge = ({text, color, isGhost, className, size}) => {
  return (
    <p
      className={classNames(
        {
          's-bg-white border-xy': isGhost,
          [color]: !isGhost,
          's-color-white': !isGhost,
          [SIZES[size]]: size
        },
        className,
        'badge s-cross-center s-main-center s-mb-0 small-radius s-px-4px'
      )}
    >
      {text}
    </p>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
}

Badge.defaultProps = {
  color: 's-bg-blue',
  className: '',
  text: '',
  size: 'xs'
}

export default Badge
