import PropTypes from 'prop-types'
import React from 'react'

import Icon from '../../Atoms/Icon'
import Tag from '../../Atoms/Tag'

const SettingHeader = ({title, icon, isComingSoon}) => {
  return (
    <div className="lg-cols-2 s-cross-center">
      <Icon svg={icon} className="s-mr-1" size="24" />
      <h2 className="s-mb-0 s-mr-1">{title}</h2>
      {isComingSoon && <Tag text="⭐ Muy Pronto" color="blue" />}
    </div>
  )
}

SettingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

SettingHeader.defaultProps = {
  title: '',
  description: '',
  icon: 'settings'
}

export default SettingHeader
