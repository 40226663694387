import PropTypes from 'prop-types'
import React from 'react'

import ActionButton from '../Templates/ActionButton'

const AddActionButton = ({onClick}) => {
  return <ActionButton className="blue" onClick={onClick} icon="plus" />
}

AddActionButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default AddActionButton
