import React, {useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import ProcessHeader from '../components/Organisms/Headers/ProcessHeader'
import PayrollProcessSkeleton from '../components/Organisms/Skeleton/PayrollProcessSkeleton'
import TabsWrapper from '../components/Wrappers/TabsWrapper'
import {activeMonthRoute} from '../config/routes'
import {PayrollContext} from '../contexts/contexts'
import {isEmpty, isNull, isUndefined} from '../helpers/utils'
import {selectors as employerMonthSelector} from '../redux/ducks/employerMonth'
import {notifyError} from '../services/notification'
import {getByHash} from '../services/processHeader'
import PayrollProcessTabs from './PayrollProcessTabs'

const PayrollProcesses = () => {
  const {hash} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const activeMonth = useSelector(employerMonthSelector.active)

  const {activeProcess, setActiveProcess, payrollDate} = useContext(PayrollContext)

  useEffect(() => {
    let isApiSubscribed = true

    getByHash(hash, data => {
      if (!isApiSubscribed) return

      // response with NotFound status has the empty body
      if (isEmpty(data)) {
        history.push(activeMonthRoute(activeMonth))
        dispatch(notifyError(`El proceso ${hash} no existe`))
        return
      }

      // checks if the process belongs to the payrollDate selected
      if (payrollDate.month !== data.month || payrollDate.year !== data.year) {
        history.push(activeMonthRoute(activeMonth))
        return
      }

      setActiveProcess(data)
    })

    // clean up after this effect (unmount component):
    return function cleanup() {
      isApiSubscribed = false
    }
  }, [hash, payrollDate, activeMonth]) // eslint-disable-line

  return isNull(activeProcess) || isUndefined(activeProcess) ? (
    <PayrollProcessSkeleton />
  ) : (
    <main className="payroll-process ed-grid rows-gap s-gap-1">
      {/* default index is zero because we don't know the first stage for every process, so we avoid to render an invalid stage*/}
      <TabsWrapper defaultActive={0}>
        <ProcessHeader />
        <PayrollProcessTabs />
      </TabsWrapper>
    </main>
  )
}

export default PayrollProcesses
