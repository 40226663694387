import PropTypes from 'prop-types'
import React from 'react'

import {capitalize} from '../../../helpers/string-format'
import {isEmpty} from '../../../helpers/utils'
import Badge from '../../Atoms/Badge'
import TextIcon from '../../Atoms/TextIcon'

const BranchOfficeInformationSection = props => {
  const {badge, description, address, phone, image, ccf} = props

  return (
    <section className="s-center s-mb-1">
      <div className="s-mb-8px s-relative s-main-center">
        <img className="s-w-64px s-h-64px" src={image} alt="imagen de sucursal" />
        <div className="s-main-center s-absolute s-bottom-0">
          <Badge text={badge} />
        </div>
      </div>
      <h4 className="s-mb-1">{capitalize(description)}</h4>
      <div className="smaller s-main-center s-mb-1">
        {!isEmpty(address) && <TextIcon className="s-mb-8px s-100 s-main-center" icon="pin" text={address} />}
        <div className="s-cross-center s-flex-gap-16px">
          {!isEmpty(phone) && <TextIcon icon="cellphone" text={phone} />}
          {!isEmpty(ccf) && <TextIcon icon="users" text={capitalize(ccf)} />}
        </div>
      </div>
    </section>
  )
}

BranchOfficeInformationSection.propTypes = {
  description: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  image: PropTypes.string,
  ccf: PropTypes.string
}

BranchOfficeInformationSection.defaultProps = {
  description: ''
}

export default BranchOfficeInformationSection
