import React from 'react'

import FormInput from '../../../../components/Atoms/Templates/FormInput'
import useTextParameterForm from '../../hooks/form/useTextParameterForm'
import {useParameterValuesContext} from '../../providers/ParameterValues'
import EditParameterFormDates from './EditParameterFormDates'
import EditParameterFormFooter from './EditParameterFormFooter'

const NumberParameterForm = () => {
  const {parameterValues} = useParameterValuesContext()
  const lastValue = parameterValues[parameterValues.length - 1]
  const {is_fixed} = lastValue

  const {setFormData, formData, handleSubmit} = useTextParameterForm({
    parameter: lastValue
  })

  const handleChangeInput = e => {
    const {value} = e.target

    setFormData(state => ({
      ...state,
      value
    }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <EditParameterFormDates formData={formData} setFormData={setFormData} isDisabled={is_fixed} />
      <div className="ed-grid s-grid-2 s-mb-4">
        <FormInput
          className="s-mb-4"
          id="value"
          name="value"
          label="Valor del parámetro"
          type="number"
          onChange={handleChangeInput}
          value={formData.value}
        />
      </div>
      <EditParameterFormFooter />
    </form>
  )
}

export default NumberParameterForm
