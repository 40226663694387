import PropTypes from 'prop-types'
import React from 'react'

import TextIcon from '../TextIcon'

const ActionTextButton = ({className, onClick, icon, text, isDisable}) => {
  const handleOnClick = () => {
    if (isDisable) return

    onClick()
  }

  return (
    <TextIcon
      className={`${
        isDisable ? 'cursor-not-allowed' : 'cursor-pointer'
      } hover-change small small-radius s-px-4px select-none ${className}`}
      onClick={handleOnClick}
      icon={icon}
      text={text}
    />
  )
}

ActionTextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

ActionTextButton.defaultProps = {
  onClick: () => {},
  icon: '',
  text: ''
}

export default ActionTextButton
