import PropTypes from 'prop-types'
import React from 'react'

import CompanyAvatar from '../Avatars/CompanyAvatar'

const CompanyItem = ({className, src, companyName, ...rest}) => {
  return (
    <div title={companyName} className={`s-cross-center nowrap ${className}`} {...rest}>
      <CompanyAvatar className="s-mr-6px flex-none" name={companyName} src={src} />
      <h4 className="s-mb-0 overflow-ellipsis">{companyName}</h4>
    </div>
  )
}

CompanyItem.propTypes = {
  src: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default CompanyItem
