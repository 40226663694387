import PropTypes from 'prop-types'
import React from 'react'

import {getURLImage} from '../../../helpers/utils'
import DefaultAvatar from '../../../static/images/utils/default-user.svg'

const UserAvatar = props => {
  const {className, picture, size, userName, ...rest} = props

  // if the source is an image broken then render a standard avatar
  const handleImgError = e => {
    e.target.src = DefaultAvatar
  }

  return picture ? (
    <div className={`user-avatar ${className} ${size}`} {...rest}>
      <img src={getURLImage(picture)} alt="Avatar del usuario" onError={handleImgError} />
    </div>
  ) : (
    <div className={`user-avatar s-bg-blue s-cross-center s-main-center ${className}`}>
      <p className="t4 s-color-white s-mb-0">{userName.substring(0, 1).toUpperCase()}</p>
    </div>
  )
}

UserAvatar.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  picture: PropTypes.string,
  userName: PropTypes.string.isRequired
}

UserAvatar.defaultProps = {
  size: '',
  className: '',
  picture: DefaultAvatar,
  userName: 'A'
}

export default UserAvatar
