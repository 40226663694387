import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions} from '../redux/ducks/payPeriods'

const RESOURCE = '/pay-period-relations'

export const getActivePayPeriods = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/employer-active-periods`,
    response => dispatch(actions.set(response)),
    error => console.error('pay periods:', error)
  )
}

export const getPreviousPayPeriods = (payFrequencyID, date, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/employer-last-periods?frequency-id=${payFrequencyID}&date=${date}`, callback)
}

export const getPayPeriodsByYearAndMonth = (year, month, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}?year=${year}&month=${month}`, callback)
}
