import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as concepts} from '../redux/ducks/concepts'

const RESOURCE = '/concepts-relation'

export const getEmployerConcepts = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    RESOURCE,
    response => {
      dispatch(concepts.set(response))
    },
    error => console.error('employer concepts:', error)
  )
}

export const getConceptList = (callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/list`, callback, callbackErr)
}

export const getConceptPriorityOrder = (callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/priority-order`, callback, callbackErr)
}

export const updateConceptPriorityOrder = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/priority-order`, payload, callback, callbackErr)
}

export const createConcept = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, callback, callbackErr)
}

export const updateEmployerConcept = (id, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${id}`, payload, callback, callbackErr)
}

export const createOverrideConcept = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(`${RESOURCE}/override`, payload, callback, callbackErr)
}

export const updateOverrideConcept = (conceptID, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/override/concepts/${conceptID}`, payload, callback, callbackErr)
}

export const deleteOverrideConcept = (conceptID, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/override/concepts/${conceptID}`, callback, callbackErr)
}
