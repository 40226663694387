import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'

import ChipEmployee from '../../../components/Atoms/ChipEmployee'
import FormInput from '../../../components/Atoms/Templates/FormInput'
import FloatEmployeeList from '../../../components/Molecules/FloatEmployeeList'
import FloatContainer from '../../../components/Wrappers/FloatContainer'
import {isAnyEqual, isEmpty, isEqual, isNull, isUndefined, len} from '../../../helpers/utils'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'
import {getEmployees} from '../../../services/employees'
import {EMPLOYEES_DEFAULT_SORT_OPTION} from '../constants/filter'

const MultiSelectEmployeeInput = forwardRef(
  ({
    id = 'employee_id',
    name = 'employee',
    label = '',
    placeholder = 'Escribe para buscar',
    initialValue = [],
    isRequired = false,
    isDisabled = false,
    setFormData,
    ...rest
  }) => {
    const [inputValue, setInputValue] = useState('')
    const [contractsSelected, setContractsSelected] = useState(initialValue)
    const [list, setList] = useState([])
    const [listIndex, setListIndex] = useState({active: 0, max: 0})
    const {data: employees, setSearchValue} = useFetchWithConditions(EMPLOYEES_DEFAULT_SORT_OPTION, 20, getEmployees)

    useEffect(() => {
      const getContractIDs = () => {
        if (!Array.isArray(contractsSelected)) return []

        return contractsSelected.map(c => c.contract_id)
      }

      setFormData(state => ({
        ...state,
        contractIds: getContractIDs()
      }))
    }, [contractsSelected])

    const handleFilterOptions = () => {
      if (isEmpty(contractsSelected)) {
        const size = isEmpty(employees) ? 0 : len(employees) - 1
        setList(employees)
        setListIndex({active: 0, max: size})
        return
      }

      // // we ignore the contracts ids that already was selected
      const filterList = employees.filter(e =>
        isUndefined(contractsSelected.find(c => isEqual(c.contract_id, e.contract_id)))
      )

      const size = isEmpty(filterList) ? 0 : len(filterList) - 1
      setList(filterList)
      setListIndex({active: 0, max: size})
    }

    const handleOnChangeInput = newValue => {
      setInputValue(newValue)

      if (isEmpty(newValue)) {
        setList([])
        setListIndex({active: 0, max: 0})
        return
      }

      if (len(newValue) < 3) return

      setSearchValue(newValue)
      handleFilterOptions(newValue)
    }

    const handleOnSelect = newIndex => {
      if (isEmpty(list)) return

      const ix = isNull(newIndex) ? listIndex.active : newIndex
      const employeeSelected = list[ix]

      setInputValue('')
      setList([])
      setListIndex({active: 0, max: 0})
      setContractsSelected(state => [...state, employeeSelected])
    }

    const handleKeyPress = e => {
      if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

      e.preventDefault()

      if (isEqual(e.key, 'Enter')) {
        handleOnSelect(null)
        return
      }

      moveIndexPosition(e.key)
    }

    const moveIndexPosition = move => {
      setListIndex(currentIndex => {
        if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
          return currentIndex
        }

        if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
          return currentIndex
        }

        const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

        return {active: newActiveIndex, max: currentIndex?.max}
      })
    }

    const deleteContractSelected = contractID => {
      setContractsSelected(state => [...state.filter(c => c.contract_id !== contractID)])
    }

    return (
      <div>
        <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
          <FormInput
            type="search"
            isRequired={isRequired}
            label={label}
            name={name}
            id={id}
            placeholder={placeholder}
            value={inputValue}
            onChange={e => {
              handleOnChangeInput(e.target.value)
            }}
            autoComplete="off"
            isDisabled={isDisabled}
            onKeyDown={handleKeyPress}
            className="s-mb-1"
            {...rest}
          />
          {!isDisabled && (
            <FloatEmployeeList employees={list} onSelectEmployee={handleOnSelect} activeIndex={listIndex?.active} />
          )}
        </FloatContainer>
        <div className="s-cross-center s-main-center s-overflow-auto" style={{gap: '0.3rem', maxHeight: '10rem'}}>
          {Array.isArray(contractsSelected) &&
            contractsSelected.map(c => (
              <ChipEmployee
                key={c.contract_id}
                employee={c}
                classColorFont="s-color-blue-500"
                classColorBorder="s-border-blue-400"
                onDelete={() => deleteContractSelected(c.contract_id)}
              />
            ))}
        </div>
      </div>
    )
  }
)

MultiSelectEmployeeInput.displayName = 'MultiSelectEmployeeInput'

export default MultiSelectEmployeeInput
