import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {len} from '../../../helpers/utils'
import {deleteJob, updateJob} from '../../../services/jobs'
import FloatContainer from '../../Wrappers/FloatContainer'
import CancelTextButton from '../Buttons/CancelTextButton'
import SaveTextButton from '../Buttons/SaveTextButton'
import Icon from '../Icon'
import DropdownItem from './DropdownItem'

const JobItem = props => {
  const {job} = props
  const [isEditable, setIsEditable] = useState(false)
  const dispatch = useDispatch()
  const contentContainer = useRef()

  const handleDeleteJob = () => dispatch(deleteJob(job.id))

  const handleEditJob = () => {
    setIsEditable(true)
    setTimeout(() => {
      contentContainer.current.focus()
    }, 0)
  }

  const handleCancelEditJob = () => {
    setIsEditable(false)
    contentContainer.current.innerHTML = job.description
  }

  const handleSaveJob = () => {
    setIsEditable(false)
    if (len(contentContainer.current.innerHTML) < 3) {
      contentContainer.current.innerHTML = job.description
      return
    }

    dispatch(updateJob(job.id, {code: job.code, description: contentContainer.current.innerHTML}))
  }

  return (
    <div className="s-relative s-mr-8px s-cross-center nowrap">
      <p className="code-font s-color-light-text small s-mb-0 s-mr-4px">{job.code}</p>
      <p
        className="s-color-light-text small s-mb-0 s-mr-4px"
        contentEditable={isEditable}
        suppressContentEditableWarning
        ref={contentContainer}
      >
        {job.description}
      </p>
      <footer className="s-cross-center">
        {isEditable && (
          <>
            <SaveTextButton className="s-mr-4px" onClick={handleSaveJob} />
            <CancelTextButton onClick={handleCancelEditJob} />
          </>
        )}
      </footer>
      <FloatContainer>
        <Icon svg="vertical-dots" className="flex-none cursor-pointer" size="0.9rem" />
        <div className="float-container ed-grid rows-gap s-gap-1">
          <DropdownItem text="Editar" onClick={handleEditJob} />
          <DropdownItem text="Eliminar" onClick={handleDeleteJob} />
        </div>
      </FloatContainer>
    </div>
  )
}

export default JobItem
