import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {isEmpty} from '../../../helpers/utils'
import Icon from '../Icon'

const ActionButton = ({icon, content, isSelected, className, onClick, isDisabled, isMain = false}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'action-button s-cross-center s-main-center outline-none normal-radius s-h-32px',
        {'s-bg-blue-500 s-border-blue-500': isMain},
        {
          'background-transition s-border-body-alt s-bg-body-alt s-hover-bg-body': !isMain
        },
        {'s-w-32px': isEmpty(content)},
        {blue: isSelected},
        {'cursor-pointer': !isDisabled},
        {'cursor-not-allowed': isDisabled},
        className
      )}
      disabled={isDisabled}
    >
      <Icon
        className={classNames('icon', {'s-mr-4px': !isEmpty(content)})}
        svg={icon}
        color={isMain ? 'var(--white-color)' : 'var(--text-color)'}
      />
      {!isEmpty(content) && (
        <span className={`content smaller ${isMain ? 's-color-white' : 's-color-text'}`}>{content}</span>
      )}
    </button>
  )
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  content: PropTypes.string,
  isSelected: PropTypes.bool
}

ActionButton.defaultProps = {
  className: '',
  content: '',
  onClick: () => {}
}

export default ActionButton
