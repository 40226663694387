import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {
  CONCEPT_DEDUCTION,
  CONCEPT_DEDUCTION_SYSTEM,
  CONCEPT_EARNING,
  CONCEPT_EARNING_SYSTEM,
  CONCEPT_NET_PAY,
  CONCEPT_NOT_OPERATION,
  CONCEPT_PROVISION
} from '../../../helpers/concepts'
import {ORIGIN_INTEGRATION_NBC} from '../../../helpers/origin'
import {isUndefined} from '../../../helpers/utils'
import SAP_LOGO from '../../../static/images/utils/SAP-logo.png'
import Badge from '../../Atoms/Badge'

const MAP_COLOR = {
  [CONCEPT_EARNING]: {is_ghost: false, color: 's-bg-green'},
  [CONCEPT_DEDUCTION]: {is_ghost: false, color: 's-bg-red'},
  [CONCEPT_EARNING_SYSTEM]: {is_ghost: true, color: 's-color-alt-green'},
  [CONCEPT_DEDUCTION_SYSTEM]: {is_ghost: true, color: 's-color-alt-red'},
  [CONCEPT_PROVISION]: {is_ghost: false, color: 's-bg-blue'},
  [CONCEPT_NOT_OPERATION]: {is_ghost: false, color: 's-bg-text'},
  [CONCEPT_NET_PAY]: {is_ghost: false, color: 's-bg-blue-800'}
}

const MAP_ORIGIN = {
  [ORIGIN_INTEGRATION_NBC]: {logo: SAP_LOGO}
}

const Concept = ({concept, onSelect, className}) => {
  const originLogo = MAP_ORIGIN[concept?.origin]?.logo

  return (
    <article
      className={`flex s-mb-0 s-cross-center nowrap overflow-ellipsis ${className}`}
      onClick={() => onSelect(concept.id || concept.concept_id)}
    >
      <div className="overflow-ellipsis s-100">
        <p className="small s-mb-0 flex s-cross-center nowrap">
          <span
            className={classNames('s-mr-8px overflow-ellipsis', {
              's-90': !isUndefined(concept.has_alerts) && concept.has_alerts
            })}
          >
            {concept.concept_description || concept.description}
          </span>
        </p>

        <div className="s-cross-center smaller s-color-light-text">
          <p className="s-mb-0 s-mr-8px">{concept.concept_code || concept.code}</p>
          <Badge
            text={concept.concept_type_description}
            color={MAP_COLOR[concept.concept_type_description].color}
            isGhost={MAP_COLOR[concept.concept_type_description].is_ghost}
            className={originLogo && 's-mr-8px'}
          />
          {originLogo && <img src={originLogo} alt="origen" style={{width: '2rem'}} />}
        </div>
      </div>
    </article>
  )
}

Concept.propTypes = {
  concept: PropTypes.shape({
    concept_id: PropTypes.number,
    concept_description: PropTypes.string,
    description: PropTypes.string,
    concept_code: PropTypes.string,
    code: PropTypes.string,
    concept_type_description: PropTypes.string
  }),
  onSelect: PropTypes.func
}

Concept.defaultProps = {
  concept: {
    concept_id: 0,
    concept_description: '',
    description: '',
    concept_code: '',
    code: '',
    concept_type_description: ''
  },
  onSelect: () => {}
}

export default Concept
