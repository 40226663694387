import React, {forwardRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Checkbox from '../../../../components/Atoms/Forms/Checkbox'
import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {getCacheKeyGeneral, getLocalCache} from '../../../../helpers/cache'
import {capitalize} from '../../../../helpers/string-format'
import {isEmpty, isEqual, isNull} from '../../../../helpers/utils'
import {selectors as employerSelectors} from '../../../../redux/ducks/employer'
import {notifyError} from '../../../../services/notification'
import {getEmployeeMaster} from '../../../../services/payrollReports'
import {EMPLOYEE_MASTER_FORM_INITIAL_STATE, TYPE_ALL, TYPE_SELECTION} from '../../constants/form'
import {useDownloadLinkReportContext} from '../../providers/DownloadLinkReport'
import {useEmployeesSectionModalsWrapperContext} from '../../providers/EmployeesSectionModalsWrapper'

const EmployeeMasterReportModal = forwardRef(({}, ref) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const {setisDownloadingReport} = useEmployeesSectionModalsWrapperContext()
  const {setObject, setFileNameDownloaded, downloadLinkReport} = useDownloadLinkReportContext()

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatus = !isNull(cacheGeneral) && cacheGeneral.data?.contract_statuses

  const [formData, setFormData] = useState(EMPLOYEE_MASTER_FORM_INITIAL_STATE)

  const handleGenerate = () => {
    let statusesList = []

    if (isEqual(formData.selection_type, TYPE_SELECTION)) {
      if (isEmpty(formData.statuses_index)) {
        dispatch(notifyError('¡Upps! debes seleccionar al menos un estado'))
        return
      }

      statusesList = formData.statuses_index.map(i => contractStatus[i].description.toUpperCase())
    }

    setisDownloadingReport(true)

    getEmployeeMaster(
      statusesList,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setisDownloadingReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setisDownloadingReport(false)
      }
    )
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(EMPLOYEE_MASTER_FORM_INITIAL_STATE)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the statuses_index list if the selection_type changed
      statuses_index: isEqual(name, 'selection_type') ? [] : state.statuses_index
    }))
  }

  const handleOnChangeStatusContract = (ix, isChecked) => {
    if (isChecked) {
      formData.statuses_index.push(ix)
      return
    }

    formData.statuses_index = formData.statuses_index.filter(i => i !== ix)
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Maestro Empleados</h3>
      <div className="s-mb-4">
        <div className="ed-grid s-mb-2">
          <RadioButtonsWrapper label="Estados de contracto">
            <RadioButton
              name="selection_type"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_ALL)}
            />

            <RadioButton
              name="selection_type"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>
        </div>

        {isEqual(formData.selection_type, TYPE_SELECTION) && (
          <div className="ed-grid s-grid-2 m-grid-3 rows-gap ">
            {Array.isArray(contractStatus) &&
              contractStatus.map((s, ix) => (
                <Checkbox
                  key={s.id}
                  label={capitalize(s.description)}
                  onChange={e => handleOnChangeStatusContract(ix, e.target.checked)}
                />
              ))}
          </div>
        )}
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

EmployeeMasterReportModal.displayName = 'EmployeeMasterReportModal'

export default EmployeeMasterReportModal
