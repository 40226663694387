import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import routes, {activeMonthRoute} from '../../config/routes'
import {URL_BASE_HELP} from '../../helpers/routes'
import {selectors as employerMonthSelector} from '../../redux/ducks/employerMonth'
import {signOut} from '../../services/user'
import Icon from '../Atoms/Icon'
import NavItem from '../Atoms/Items/NavItem'
import Tag from '../Atoms/Tag'

const MainMenu = () => {
  const dispatch = useDispatch()
  const activeMonth = useSelector(employerMonthSelector.active)

  const handleSignOut = () => dispatch(signOut())

  return (
    <div className="flex flex-grow s-overflow-y-auto">
      <nav className="s-column flex-grow">
        <ul className="list-container ed-grid row-gap s-gap-1 full">
          <NavItem text="Inicio" icon="home" url={routes.home} />
          <NavItem text="Empleados" icon="users" url={routes.employees.base} />
          <NavItem text="Nómina" icon="document" url={activeMonthRoute(activeMonth)} />
          <NavItem text="Configuración" icon="settings" url={routes.settings.base} />
          <NavItem text="Perfil" icon="user" url={routes.profile} />
        </ul>

        <hr className="to-lg s-pt-12px s-mb-12px" />

        <ul className="lg-to-bottom list-container ed-grid full row-gap s-gap-1">
          <li className="help-launcher nav-item normal-radius s-color-text background-transition cursor-pointer">
            <span className="help-launcher s-color-current nowrap s-cross-center s-px-12px s-py-12px lg-py-8px decoration-none">
              <Icon svg="help" size="1rem" className="help-launcher s-mr-8px" />
              <span className="help-launcher whitespace-nowrap">Ayuda</span>
            </span>
          </li>

          <NavItem className="logout" text="Cerrar sesión" icon="exit" onClick={handleSignOut} />
        </ul>
      </nav>
    </div>
  )
}

export default MainMenu
