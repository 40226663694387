import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useRef, useState} from 'react'

import useObjectURL from '../../../hooks/useObjectURL'

const DownloadLinkReportContext = createContext({})

const DownloadLinkReportProvider = ({children}) => {
  const {setObject, objectURL} = useObjectURL(null)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const downloadLinkReport = useRef()

  return (
    <DownloadLinkReportContext.Provider
      value={{
        setObject,
        objectURL,
        fileNameDownloaded,
        setFileNameDownloaded,
        downloadLinkReport
      }}
    >
      {children}
      {/* This link is hidden, we only use for download the report */}
      <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
        file downloaded
      </a>
    </DownloadLinkReportContext.Provider>
  )
}

export const useDownloadLinkReportContext = () => useContext(DownloadLinkReportContext)

DownloadLinkReportProvider.propTypes = {
  children: PropTypes.node
}

DownloadLinkReportProvider.defaultProps = {
  children: null
}

export default DownloadLinkReportProvider
