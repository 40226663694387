import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEmpty, isEqual, isNull, isUndefined, len} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getEmployees} from '../../../services/employees'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatEmployeeList from '../FloatEmployeeList'

const SelectEmployeeForTermination = ({
  isRequired,
  label,
  name,
  id,
  placeholder,
  onSelect,
  initialValue,
  payFrequencyID,
  ignoreContractIDIn,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue)
  const [list, setList] = useState([])
  const [listIndex, setListIndex] = useState({active: 0, max: 0})

  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatuses = cacheGeneral.data?.contract_statuses
  const queryContractStatuses = getContractStatuses(contractStatuses)

  const handleFilter = searchValue => {
    getEmployees(
      `page=1&limit=20&pay_frequency_id=${payFrequencyID}&search=${searchValue}&sort=first_name${queryContractStatuses}`,
      response => {
        if (isNull(ignoreContractIDIn)) {
          const size = isEmpty(response) ? 0 : len(response) - 1

          setList(response)
          setListIndex({active: 0, max: size})
          return
        }

        // we ignore the contracts ids that already was selected
        const filterList = response.filter(employee =>
          isUndefined(ignoreContractIDIn.find(elem => isEqual(elem, employee.contract_id)))
        )

        const size = isEmpty(filterList) ? 0 : len(filterList) - 1
        setList(filterList)
        setListIndex({active: 0, max: size})
      }
    )
  }

  const handleOnChangeInput = newValue => {
    setValue(newValue)

    // clear the employee because the user change the input value and not to selected
    // a new employee from the list
    onSelect(null)

    if (isEmpty(newValue)) {
      setList([])
      setListIndex({active: 0, max: 0})
      return
    }

    handleFilter(newValue)
  }

  const handleKeyPress = e => {
    if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

    e.preventDefault()

    if (isEqual(e.key, 'Enter')) {
      handleOnSelect(null)
      return
    }

    moveIndexPosition(e.key)
  }

  const moveIndexPosition = move => {
    setListIndex(currentIndex => {
      if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
        return currentIndex
      }

      if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
        return currentIndex
      }

      const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

      return {active: newActiveIndex, max: currentIndex?.max}
    })
  }

  const handleOnSelect = newIndex => {
    const ix = isNull(newIndex) ? listIndex.active : newIndex
    const employeeSelected = list[ix]

    setList([employeeSelected])
    setListIndex({active: 0, max: 0})
    setValue(fullName(employeeSelected))
    onSelect(employeeSelected)
  }

  return (
    <div>
      <FloatContainer isInputTheContainer container={{className: 's-relative'}}>
        <FormInput
          type="search"
          isRequired={isRequired}
          label={label}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            handleOnChangeInput(e.target.value)
          }}
          autoComplete="off"
          onKeyDown={handleKeyPress}
          {...rest}
        />
        <FloatEmployeeList employees={list} onSelectEmployee={handleOnSelect} activeIndex={listIndex?.active} />
      </FloatContainer>
    </div>
  )
}

const getContractStatuses = contractStatuses => {
  if (!Array.isArray(contractStatuses)) return ''

  const args = contractStatuses
    .filter(status => isAnyEqual(status.description, ['CREADO', 'ACTIVO']))
    .map(status => status.id)
    .join(',')

  if (isEmpty(args)) return ''

  return `&contract_statuses_id=${args}`
}

SelectEmployeeForTermination.protTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.function,
  initialValue: PropTypes.string,
  payFrequencyID: PropTypes.number
}

SelectEmployeeForTermination.defaulProps = {
  label: '',
  name: '',
  id: '',
  placeholder: '',
  onSelect: () => {},
  initialValue: '',
  payFrequencyID: 1
}

export default SelectEmployeeForTermination
