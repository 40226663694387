import React, {useContext} from 'react'
import {useSelector} from 'react-redux'

import {FormDataContext} from '../../../contexts/contexts'
import {getCacheKeyBanks, getLocalCache} from '../../../helpers/cache'
import {isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'

const PaymentEmployeeForm = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
  const banks = !isNull(cacheBanks) && cacheBanks.data?.banks

  const {formData, setFormData, updateFormField} = useContext(FormDataContext)

  const handleOnChangePaymentMethod = e => {
    updateFormField(e)

    // reset the fields of WireTransfer
    setFormData(state => ({
      ...state,
      bank_id: 0,
      account_type: 'CUENTA DE AHORROS',
      account_number: ''
    }))
  }

  const handleOnChangeAccountType = e => {
    updateFormField(e)

    // reset the account_number field
    setFormData(state => ({
      ...state,
      account_number: ''
    }))
  }

  const handleOnChangeAccountNumber = e => {
    const value = e.target.value

    if (isEqual(formData.account_type, 'EMAIL')) {
      updateFormField(e)
      return
    }

    const pattern = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '') || pattern.test(value)) updateFormField(e)
  }

  return (
    <>
      <div className="form-grid ed-grid m-grid-2 lg-grid-3">
        <Select
          label="Método de pago"
          name="payment_method"
          id="payment_method"
          value={formData.payment_method}
          onChange={handleOnChangePaymentMethod}
          placeholder
        >
          {['CHEQUE', 'EFECTIVO', 'TRANSFERENCIA BANCARIA'].map(paymentMethod => (
            <option key={paymentMethod} value={paymentMethod}>
              {paymentMethod}
            </option>
          ))}
        </Select>

        {isEqual(formData.payment_method, 'TRANSFERENCIA BANCARIA') && (
          <>
            <Select
              label="Banco"
              placeholder
              isRequired
              name="bank_id"
              id="bank_id"
              value={formData.bank_id}
              onChange={updateFormField}
            >
              {Array.isArray(banks) &&
                banks.map(bank => (
                  <option key={bank.id} value={bank.id}>
                    {bank.short_name}
                  </option>
                ))}
            </Select>

            <Select
              label="Tipo de cuenta"
              placeholder
              isRequired
              name="account_type"
              id="account_type"
              value={formData.account_type}
              onChange={handleOnChangeAccountType}
            >
              {['CUENTA DE AHORROS', 'CUENTA CORRIENTE', 'EMAIL'].map(accountType => (
                <option key={accountType} value={accountType}>
                  {accountType}
                </option>
              ))}
            </Select>
            <FormInput
              label="No. de cuenta"
              placeholder="12 3456 7890"
              isRequired
              name="account_number"
              id="account_number"
              value={formData.account_number}
              onChange={handleOnChangeAccountNumber}
            />
          </>
        )}
      </div>

      {isEqual(formData.payment_method, 'TRANSFERENCIA BANCARIA') && (
        <p className="smaller s-main-center s-pt-4 s-mb-0">
          Los campos con <span className="s-color-blue">*</span> son obligatorios
        </p>
      )}
    </>
  )
}

export default PaymentEmployeeForm
