import React from 'react'

import ParameterSettingSkeleton from '../../../../components/Organisms/Skeleton/ParameterSettingSkeleton'
import ParameterValuesHistory from '../../../../components/Organisms/Tables/ParameterValuesHistory'
import Container from '../../../../components/Wrappers/Container'
import useGetParameterValues from '../../hooks/useGetParameterValues'
import EditingStateProvider from '../../providers/EditingState'
import ParameterValuesProvider from '../../providers/ParameterValues'
import {useSelectedParameterContext} from '../../providers/SelectedParameter'
import EditParameterForm from '../form/EditParameterForm'
import ParameterDetails from './ParameterDetails'
import SingleParameterHeader from './SingleParameterHeader'

const SingleParameterView = () => {
  const {selectedParameter: parameter} = useSelectedParameterContext()

  const {
    data: parameterValues,
    isError,
    isLoading
  } = useGetParameterValues({parameter, isMandatory: parameter.is_mandatory_employer})

  if (!parameter || !parameterValues || isError || isLoading) return <ParameterSettingSkeleton />

  return (
    <EditingStateProvider>
      <ParameterValuesProvider parameterValues={parameterValues}>
        <Container>
          <SingleParameterHeader />
          <ParameterDetails />
          <ParameterValuesHistory />
          <EditParameterForm />
        </Container>
      </ParameterValuesProvider>
    </EditingStateProvider>
  )
}

export default SingleParameterView
