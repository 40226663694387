import PropTypes from 'prop-types'
import React from 'react'

import FormInput from '../Templates/FormInput'

const SearchInput = ({className, placeholder, value, onChange, id}) => {
  return (
    <FormInput
      className={className}
      placeholder={placeholder}
      id={id}
      type="search"
      value={value}
      onChange={onChange}
    />
  )
}

SearchInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

SearchInput.defaultProps = {
  placeholder: 'Empieza a buscar',
  value: '',
  id: '',
  onChange: () => {}
}

export default SearchInput
