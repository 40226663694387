import React, {useEffect} from 'react'
import {Redirect, Switch, useHistory, useRouteMatch} from 'react-router-dom'

import SettingsEmptyState from '../components/Organisms/Emptys/SettingsEmptyState'
import SettingsPanel from '../components/Organisms/Panels/SettingsPanel'
import AccountingSettings from '../components/Organisms/Sections/AccountingSettings'
import EmployerSettings from '../components/Organisms/Sections/EmployerSettings'
import OrganizationalStructureSettings from '../components/Organisms/Sections/OrganizationalStructureSettings'
import PayrollBasesSettings from '../components/Organisms/Sections/PayrollBasesSettings'
import PayrollConceptSettings from '../components/Organisms/Sections/PayrollConceptSettings'
import PayrollElectronicSettings from '../components/Organisms/Sections/PayrollElectronicSettings'
import PayrollParameterSettings from '../components/Organisms/Sections/PayrollParameterSettings'
import ProcessCalculateSettings from '../components/Organisms/Sections/ProcessCalculateSettings'
import PhonePanel from '../components/Wrappers/PhonePanel'
import TranslatePhonePanel from '../components/Wrappers/TranslatePhonePanel'
import routes, {redirectRoute} from '../config/routes'
import PrivateEmployer from '../config/Routes/PrivateEmployer'

const Settings = () => {
  let {path, isExact} = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <PhonePanel
        container={{
          className: 'ed-grid s-gap-0 m-gap-2 lg-gap-4 m-grid-2 lg-grid-3'
        }}
        onClickComeBack={() => {
          history.push(routes.settings.base)
        }}
      >
        <div>
          {/* Sidebar */}
          <SettingsPanel />
        </div>
        <TranslatePhonePanel
          className={`s-gap-1 m-gap-2 lg-gap-4 ed-grid lg-grid-2 lg-cols-2 row-gap ${
            // isExact only when render <SettingsEmptyState />, this allow center the
            // <SettingsEmptyState /> and other wise align the page in cross-start
            !isExact && 's-cross-start'
          }`}
        >
          <Switch>
            {/* employer */}
            <PrivateEmployer exact path={`${path}${routes.settings.employer}`}>
              <EmployerSettings />
            </PrivateEmployer>

            {/* organizational structure */}
            <PrivateEmployer exact path={`${path}${routes.settings.organizationalStructure}`}>
              <OrganizationalStructureSettings />
            </PrivateEmployer>

            {/* payroll parameters */}
            <PrivateEmployer exact path={`${path}${routes.settings.parameters}`}>
              <PayrollParameterSettings />
            </PrivateEmployer>

            {/* payroll concepts */}
            <PrivateEmployer exact path={`${path}${routes.settings.concepts}`}>
              <PayrollConceptSettings />
            </PrivateEmployer>

            {/* procesos de cálculo */}
            <PrivateEmployer exact path={`${path}${routes.settings.processes}`}>
              <ProcessCalculateSettings />
            </PrivateEmployer>

            {/* payroll bases */}
            <PrivateEmployer exact path={`${path}${routes.settings.bases}`}>
              <PayrollBasesSettings />
            </PrivateEmployer>

            {/* accounting */}
            <PrivateEmployer exact path={`${path}${routes.settings.accounting}`}>
              <AccountingSettings />
            </PrivateEmployer>

            {/* payroll electronic */}
            <PrivateEmployer exact path={`${path}${routes.settings.payrollElectronic}`}>
              <PayrollElectronicSettings />
            </PrivateEmployer>

            {/* empty state */}
            <PrivateEmployer exact path={`${path}`}>
              <SettingsEmptyState />
            </PrivateEmployer>
            <Redirect to={redirectRoute.privateEmployer} />
          </Switch>
        </TranslatePhonePanel>
      </PhonePanel>
    </main>
  )
}

export default Settings
