import PropTypes from 'prop-types'
import React from 'react'

import Logo from '../Atoms/Logo'

const WelcomeContainer = ({children, title, submit, id}) => {
  const handleSubmit = e => {
    e.preventDefault()
    submit(e.target)
  }

  return (
    <main className="lg-pt-4 l-big-section">
      <section className="n-container welcome-container ed-grid s-center s-to-center">
        <Logo className="l-block" />
        <form id={id} onSubmit={handleSubmit} className="ed-grid row-gap s-gap-2 m-gap-3 s-mb-0">
          <h1 className="t3 s-mb-0">{title}</h1>
          {children}
        </form>
      </section>
    </main>
  )
}

WelcomeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
  // submit: PropTypes.func.isRequired,
}

export default WelcomeContainer
