import React, {forwardRef} from 'react'

import Checkbox from '../../Atoms/Forms/Checkbox'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Select from '../../Atoms/Forms/Select'
import TabItem from '../../Atoms/TabItem'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import TabsWrapper from '../../Wrappers/TabsWrapper'

const BasicCompanyInformationModal = forwardRef((props, ref) => {
  return (
    <ModalTemplate ref={ref}>
      <TabsWrapper>
        <div className="s-cross-center s-main-center tabs-container s-mb-24px">
          <TabItem index={0}>Preferencia de pago</TabItem>
          <TabItem index={1}>Pila</TabItem>
          <TabItem index={2}>Leyes</TabItem>
        </div>

        <form action="#" className="ed-grid rows-gap s-mb-0 form-grid">
          {/* Conentido preferencia de pagos */}
          <PanelTemplate className="ed-grid rows-gap" index={0} onlyHidden>
            <div className="ed-grid s-grid-2">
              <div>
                <div className="form-item s-mb-6px">
                  <label className="required">Frecuencia de pago</label>
                </div>
                <Checkbox name="biweekly" label="Quincenal" className="s-main-center" />
              </div>
              <Checkbox name="monthly" label="Mensual" className="s-main-center s-cross-end" />
            </div>

            <DatePickerInput label="Período inicial" isRequired />
          </PanelTemplate>

          {/* Contenido Pila*/}
          <PanelTemplate className="ed-grid rows-gap" index={1} onlyHidden>
            <Select label="ARL" name="" isRequired>
              <option value="#">Seguros Suramericana S.A.</option>
            </Select>

            <DatePickerInput label="Fecha de constitución" isRequired />
          </PanelTemplate>

          {/* Contenido leyes */}
          <PanelTemplate className="ed-grid rows-gap" index={2} onlyHidden>
            <div className="ed-grid s-grid-2 rows-gap">
              <RadioButtonsWrapper label="¿Aplica ley 590?">
                <RadioButton name="gender" label="Sí" value="yes" />
                <RadioButton name="gender" label="No" value="no" />
              </RadioButtonsWrapper>

              <RadioButtonsWrapper label="¿Aplica ley 1429?">
                <RadioButton name="gender" label="Sí" value="yes" />
                <RadioButton name="gender" label="No" value="no" />
              </RadioButtonsWrapper>

              <RadioButtonsWrapper label="¿Aplica ley 1607?">
                <RadioButton name="gender" label="Sí" value="yes" />
                <RadioButton name="gender" label="No" value="no" />
              </RadioButtonsWrapper>
            </div>
          </PanelTemplate>

          <div className="buttons-container">
            <button className="button cancel">Cancelar</button>
            <button className="button">Actualiza la información</button>
          </div>
        </form>
      </TabsWrapper>
    </ModalTemplate>
  )
})

BasicCompanyInformationModal.displayName = 'BasicCompanyInformationModal'

export default BasicCompanyInformationModal
