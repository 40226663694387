import React, {useEffect, useState} from 'react'
import {Doughnut} from 'react-chartjs-2'

import {capitalize} from '../../helpers/string-format'
import {isEmpty, isEqual} from '../../helpers/utils'

const MonthProgress = ({processes}) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    let style = getComputedStyle(document.body)
    let blue100 = style.getPropertyValue('--blue-100')
    let blue200 = style.getPropertyValue('--blue-200')
    let blue300 = style.getPropertyValue('--blue-300')
    let blue400 = style.getPropertyValue('--blue-400')
    let blue500 = style.getPropertyValue('--blue-500')
    let blue600 = style.getPropertyValue('--blue-600')
    let blue700 = style.getPropertyValue('--blue-700')
    let blue800 = style.getPropertyValue('--blue-800')
    let blue900 = style.getPropertyValue('--blue-900')

    const colors = [blue100, blue200, blue300, blue400, blue500, blue600, blue700, blue800, blue900]

    setData(getDataSet(processes, colors))
  }, [processes])

  return data ? (
    <div className="s-relative">
      <div className="s-to-center s-80 m-65 lg-75">
        <Doughnut
          data={data}
          width={70}
          height={70}
          legend={{
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 12,
              fontFamily: 'Varela Round',
              fontSize: 14,
              fontColor: '#596973',
              padding: 8
            }
          }}
          options={{
            maintainAspectRatio: true
          }}
        />
      </div>
    </div>
  ) : null
}

export const getDataSet = (processes, colors) => {
  if (isEmpty(processes) || isEmpty(colors)) return {}

  let unique = [...new Set(processes.map(process => process.process_type_description))]

  let data = []
  unique.forEach(name => {
    let total = processes.reduce((accum, process) => {
      if (!isEqual(process.process_type_description, name)) return accum

      return accum + 1
    }, 0)

    data.push({label: capitalize(name), total: total, percentage: 0})
  })

  return {
    datasets: [
      {
        data: [...data.map(d => d.total)],
        backgroundColor: [...getDataSetColors(colors, data.length)],
        hoverBackgroundColor: [...getDataSetColors(colors, data.length)],
        hoverBorderWidth: [...data.map(d => 0)],
        weight: 2
      }
    ],
    labels: [...data.map(d => d.label)]
  }
}

export const getDataSetColors = (colors, total) => {
  const range = Math.floor(colors.length / total)
  let counter = 1
  const dataSetColors = []

  colors.forEach((color, index) => {
    if (!isEqual(index + 1, counter) || dataSetColors.length >= total) return

    dataSetColors.push(color)
    counter += range
  })

  return dataSetColors
}

export default MonthProgress
