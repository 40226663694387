import React, {forwardRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import ValidatorForm from '../../../helpers/validator'
import {createEmployerNote, updateEmployerNote} from '../../../services/employerNotes'
import {notifyError} from '../../../services/notification'
import TextArea from '../../Atoms/Forms/TextArea'
import FormInput from '../../Atoms/Templates/FormInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const formFields = {
  employer_month_id: 'ID del mes de la empresa',
  title: 'Título',
  description: 'Descripción'
}

const rules = {
  employer_month_id: 'required|integer',
  title: 'required|string',
  description: 'required|string'
}

const NewNoteModal = forwardRef(({note, setNote, onAdd, onEdit}, ref) => {
  const dispatch = useDispatch()

  const [isDisabled, setIsDisabled] = useState(false)

  const handleCloseModal = () => {
    ref.current !== null && ref.current.closeModal()
  }

  const handleInputChange = event => {
    setNote({
      ...note,
      [event.target.name]: event.target.value
    })
  }

  const handleCreateNote = e => {
    setIsDisabled(true)

    const validator = new ValidatorForm(note, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsDisabled(false)
      return
    }

    createEmployerNote(
      note,
      response => {
        onAdd(response)
        setIsDisabled(false)
        handleCloseModal()
      },
      error => {
        dispatch(notifyError(error))
        setIsDisabled(false)
      }
    )
  }

  const handleEditNote = () => {
    setIsDisabled(true)

    const validator = new ValidatorForm(note, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsDisabled(false)
      return
    }

    updateEmployerNote(
      note.id,
      note,
      response => {
        onEdit(response)
        setIsDisabled(false)
        handleCloseModal()
      },
      error => {
        dispatch(notifyError(error))
        setIsDisabled(false)
      }
    )
  }

  return (
    <ModalTemplate ref={ref} className="medium s-center">
      <h3>{note.isEdit ? 'Editar ' : 'Crear '} nota</h3>
      <form className="s-mb-0 ed-grid rows-gap form-grid">
        <FormInput
          name="title"
          label={formFields.title}
          placeholder="Agrega un título"
          autocomplete="off"
          onChange={handleInputChange}
          value={note.title}
          isRequired
        />
        <TextArea
          name="description"
          align="left"
          className="s-left"
          label={formFields.description}
          placeholder="Agrega una descripción"
          autocomplete="off"
          onChange={handleInputChange}
          value={note.description}
          isRequired
        />

        <div className="buttons-container">
          <button className="button cancel" type="button" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button
            className="button"
            type="button"
            onClick={note.isEdit ? handleEditNote : handleCreateNote}
            disabled={isDisabled}
          >
            {isDisabled ? (note.isEdit ? 'Editando...' : 'Creando...') : note.isEdit ? 'Editar nota' : 'Crear nota'}
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
})

NewNoteModal.displayName = 'NewNoteModal'

export default NewNoteModal
