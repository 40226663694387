import PropTypes from 'prop-types'
import React from 'react'

const Container = ({className, children}) => {
  return <section className={`n-container s-mb-0 ${className}`}>{children}</section>
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

Container.defaultProps = {
  className: ''
}

export default Container
