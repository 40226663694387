import PropTypes from 'prop-types'
import React from 'react'

import Icon from '../../Atoms/Icon'

const PeriodItem = props => {
  const {className, icon, title, content, isDropdown, children} = props
  return (
    <article className={`payroll-summary-item flex nowrap s-mb-0 ${className}`}>
      <div className="flex-none s-mr-4px s-pt-2px">
        <Icon svg={icon} />
      </div>
      <div>
        <div className="s-cross-center nowrap">
          <p className="s-mb-0 small s-mr-8px">{title}</p>
          {isDropdown && <Icon svg="down" size="0.75rem" />}
        </div>
        <div className="s-cross-center">
          <p className="content t3 s-color-blue s-mr-1 s-mb-0">{content}</p>
          {children}
        </div>
      </div>
    </article>
  )
}

PeriodItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isDropdown: PropTypes.bool
}

PeriodItem.defaultProps = {
  className: '',
  title: '',
  content: '',
  isDropdown: false
}

export default PeriodItem
