import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {
  KEY_NOVELTY_ABSENCES,
  KEY_NOVELTY_LEAVES,
  KEY_NOVELTY_OCCASIONAL,
  KEY_NOVELTY_RECURRENT,
  KEY_NOVELTY_VACATIONS
} from '../../../helpers/novelties'
import {CREATE, STATUS_HISTORY} from '../../../helpers/payroll'
import {isEqual, isNull, len} from '../../../helpers/utils'
import {selectors as employerSelector} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import SelectCategory from '../../Molecules/Selects/SelectCategory'
import {TableHeader} from '../../Molecules/Templates/TableHeader'
import {TableBody} from '../Containers/TableBody'
import AbsenceNovelty from '../Sections/AbsenceNovelty'
import LeaveNovelty from '../Sections/LeaveNovelty'
import OccasionalNovelty from '../Sections/OccasionalNovelty'
import RecurrentNovelty from '../Sections/RecurrentNovelty'
import VacationNovelty from '../Sections/VacationNovelty'

const OBJ_CATEGORIES = {
  [KEY_NOVELTY_OCCASIONAL]: {
    key: KEY_NOVELTY_OCCASIONAL,
    icon: 'clock',
    name: 'Novedades Ocasionales',
    caption: 'Las ingresas en cada período',
    is_coming_soon: false
  },
  [KEY_NOVELTY_ABSENCES]: {
    key: KEY_NOVELTY_ABSENCES,
    icon: 'hand',
    name: 'Ausentismos',
    caption: 'Ausencias remuneradas y NO remuneradas',
    is_coming_soon: false
  },
  [KEY_NOVELTY_LEAVES]: {
    key: KEY_NOVELTY_LEAVES,
    icon: 'user-minus',
    name: 'Incapacidades',
    caption: 'Incapacidades y licencias médicas',
    is_coming_soon: false
  },
  [KEY_NOVELTY_VACATIONS]: {
    key: KEY_NOVELTY_VACATIONS,
    icon: 'sun',
    name: 'Vacaciones',
    caption: 'Vacaciones disfrutadas o en dinero',
    is_coming_soon: false
  },
  [KEY_NOVELTY_RECURRENT]: {
    key: KEY_NOVELTY_RECURRENT,
    icon: 'calendar',
    name: 'Novedades Recurrentes',
    caption: 'Crealas una sola vez y te las incluimos en cada período',
    is_coming_soon: false
  }
}

export const initialStateAction = {
  type: null,
  noveltyID: 0
}

const NoveltyTable = () => {
  const [action, setAction] = useState(initialStateAction)
  const {activeProcess} = useContext(PayrollContext)

  const employer = useSelector(employerSelector.getSession)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const configProcess = configObj[activeProcess?.process_type_code]

  const [activeCategory, setActiveCategory] = useState('')
  const [categories, setCategories] = useState([])

  const [signalOpenOccasionaNoveltyBulk, setSignalOpenOccasionaNoveltyBulk] = useState(null)

  const handleChangeCategory = code => {
    setActiveCategory(code)
    setAction(initialStateAction)
    setSignalOpenOccasionaNoveltyBulk(null)
  }

  useEffect(() => {
    const options = configProcess?.config?.rules?.novelties[employer?.code_iso_3]

    if (!Array.isArray(options) || isEqual(len(options), 0)) {
      setCategories([])
      setActiveCategory('')
      setSignalOpenOccasionaNoveltyBulk(null)
      return
    }

    setCategories(options)
    setActiveCategory(options[0])
    setSignalOpenOccasionaNoveltyBulk(null)
  }, [configProcess, employer?.code_iso_3])

  const handleOnClickOccasionalNoveltyBulk = () => {
    setSignalOpenOccasionaNoveltyBulk(Date.now())
  }

  return (
    <TableBody className="novelty-table">
      <TableHeader>
        <SelectCategory
          config={OBJ_CATEGORIES}
          categories={categories}
          activeCategory={activeCategory}
          onSelect={handleChangeCategory}
        />

        <div className="m-cross-center s-flex-gap-12px flex-grow m-main-end s-column m-row">
          {isNull(action.type) && !isEqual(activeProcess?.status, STATUS_HISTORY) && (
            <>
              <button
                type="button"
                className="button small"
                onClick={() =>
                  setAction({
                    type: CREATE,
                    noveltyID: 0
                  })
                }
              >
                Agrega una novedad
              </button>

              {isEqual(activeCategory, KEY_NOVELTY_OCCASIONAL) && (
                <button
                  type="button"
                  className="button small ghost s-order-1 m-order-2"
                  onClick={handleOnClickOccasionalNoveltyBulk}
                >
                  Cargue masivo
                </button>
              )}
            </>
          )}
        </div>
      </TableHeader>

      {isEqual(activeCategory, KEY_NOVELTY_OCCASIONAL) && (
        <OccasionalNovelty action={action} setAction={setAction} signalBulk={signalOpenOccasionaNoveltyBulk} />
      )}
      {isEqual(activeCategory, KEY_NOVELTY_RECURRENT) && <RecurrentNovelty action={action} setAction={setAction} />}
      {isEqual(activeCategory, KEY_NOVELTY_ABSENCES) && <AbsenceNovelty action={action} setAction={setAction} />}
      {isEqual(activeCategory, KEY_NOVELTY_LEAVES) && <LeaveNovelty action={action} setAction={setAction} />}
      {isEqual(activeCategory, KEY_NOVELTY_VACATIONS) && <VacationNovelty action={action} setAction={setAction} />}
    </TableBody>
  )
}

export default NoveltyTable
