import React from 'react'

const Checkbox = ({label, className, name, checked, onChange}) => {
  return (
    <label className={`checkbox-item ${className}`}>
      <input className="cursor-pointer" name={name} type="checkbox" onChange={onChange} checked={checked} />
      {label && <span>{label}</span>}
    </label>
  )
}

export default Checkbox
