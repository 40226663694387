import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../contexts/contexts'
import {mediaToM} from '../../helpers/media-queries'
import {isAnyEqual, isEmpty, isEqual} from '../../helpers/utils'
import {useMedia} from '../../hooks/useMedia'
import {selectors as employerSelectors} from '../../redux/ducks/employer'
import DropdownItem from '../Atoms/Items/DropdownItem'
import DropdownButton from '../Molecules/Dropsdown/DropdownButton'

const NewProcess = ({config, processes, onSelect, className}) => {
  const toM = useMedia(mediaToM)

  const {payrollDate} = useContext(PayrollContext)
  const employerSession = useSelector(employerSelectors.getSession)

  const handleClick = (processType, processesByType) => onSelect(processType, processesByType)

  return (
    <DropdownButton
      icon="plus"
      content={toM ? '' : 'Nuevo'}
      floatDirection={toM ? 'left' : 'right'}
      sizeFloatContainer="m"
      className={className}
      isMain
    >
      {Array.isArray(config) &&
        config.map(option => {
          if (!option?.is_active) return null

          // by country
          if (!Array.isArray(option?.config?.countries)) return null
          if (!isAnyEqual(employerSession?.code_iso_3, option?.config?.countries)) {
            return null
          }

          // by parameter isProcessEnable
          if (
            !isEmpty(option?.config?.rules?.run?.is_process_enable?.param) &&
            !option?.config?.rules?.run?.is_process_enable?.value
          ) {
            return null
          }

          // by parameter months
          if (!isEmpty(option?.config?.rules?.run?.months?.param)) {
            if (
              !Array.isArray(option?.config?.rules?.run?.months?.value) ||
              !isAnyEqual(payrollDate.month, option?.config?.rules?.run?.months?.value)
            ) {
              return null
            }
          }

          return (
            <DropdownItem
              key={option.id}
              text={option.description}
              onClick={() =>
                handleClick(
                  option,
                  processes.filter(p => isEqual(p?.process_type_code, option?.code))
                )
              }
            />
          )
        })}
    </DropdownButton>
  )
}

NewProcess.propTypes = {
  config: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  processes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string
}

NewProcess.defaultProps = {
  config: [],
  options: [],
  processes: [],
  className: '',
  onSelect: () => {}
}

export default NewProcess
