import React, {useContext} from 'react'

import {FloatContainerContext} from '../../contexts/contexts'
import {isEmpty, isEqual} from '../../helpers/utils'
import Concept from './Templates/Concept'

const FloatConceptList = ({concepts = [], onSelect = () => {}, activeIndex = 0}) => {
  const {setActive} = useContext(FloatContainerContext)

  const handleOnClick = index => {
    setActive(false)
    onSelect(index)
  }

  return (
    <div className={'float-container size-m s-column s-flex-gap-12px'}>
      {isEmpty(concepts) ? (
        <div className="s-cross-center smaller s-color-light-text">No hay resultados</div>
      ) : (
        Array.isArray(concepts) &&
        concepts.map((concept, index) => (
          <div
            className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
              isEqual(index, activeIndex) && 'is-active'
            }`}
            key={concept.id}
          >
            <Concept concept={concept} onSelect={conceptID => handleOnClick(index)} />
          </div>
        ))
      )}
    </div>
  )
}

export default FloatConceptList
