import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {writeBackendDate} from '../../../../helpers/dates'
import {isEmpty} from '../../../../helpers/utils'
import ValidatorForm from '../../../../helpers/validator'
import {notifyError, notifySuccessful} from '../../../../services/notification'
import {updateParameterEmployerDetailValueByID} from '../../../../services/processParameter'
import {EDIT_PARAMETER_FORM_FIELD_NAMES} from '../../constants/form/field-names'
import {STRING_PARAMETER_FORM_RULES} from '../../constants/form/rules'
import {getEditParameterFormInitialValue} from '../../helpers/initial-state'
import {useEditingStateContext} from '../../providers/EditingState'
import {useSelectedParameterContext} from '../../providers/SelectedParameter'

const useTextParameterForm = ({parameter}) => {
  const dispatch = useDispatch()
  const {setIsEditing} = useEditingStateContext()
  const INITAL_VALUES = getEditParameterFormInitialValue(parameter)
  const [formData, setFormData] = useState(INITAL_VALUES)
  const {setSelectedParameter} = useSelectedParameterContext()

  const handleSubmit = e => {
    e.preventDefault()

    const validator = new ValidatorForm(formData, STRING_PARAMETER_FORM_RULES)
    validator.setAttributeNames(EDIT_PARAMETER_FORM_FIELD_NAMES)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    const payload = {
      begins_at: writeBackendDate(formData.startDate),
      ends_at: isEmpty(formData.endDate) ? parameter.ends_at : writeBackendDate(formData.endDate),
      value: formData.value
    }

    updateParameterEmployerDetailValueByID(
      parameter.process_parameter_id,
      payload,
      response => {
        dispatch(notifySuccessful('Parámetro editado exitosamente'))
        setIsEditing(false)
        setSelectedParameter(state => ({...state, value: response[0].value}))
      },
      () => {
        setIsEditing(false)
      }
    )
  }

  return {
    formData,
    setFormData,
    handleSubmit
  }
}

export default useTextParameterForm
