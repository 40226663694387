import React from 'react'

import PayslipImg from '../../../static/images/stories/paysheet.svg'
import Icon from '../../Atoms/Icon'
import Container from '../../Wrappers/Container'

const StagePaysheetEmptyState = ({processName = 'nómina'}) => {
  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center s-to-center">
      <div className="l-block" />
      <img className="s-mb-2" src={PayslipImg} alt="Comprobante de nómina" width="200px" height="200px" />
      <h3>Tu proceso {processName.toLocaleLowerCase()} esta vacío</h3>
      <p className="s-color-light-text">
        Da click en el botón{' '}
        <span className="s-color-text">
          <Icon svg="bolt" size="12" className="s-mr-2px" />
          Calcula
        </span>{' '}
        y tus empleados aparecerán aquí.
      </p>
    </Container>
  )
}

export default StagePaysheetEmptyState
