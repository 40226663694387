import PropTypes from 'prop-types'
import React from 'react'

import {getURLImage} from '../../../helpers/utils'

const CompanyAvatar = ({className, name, src, onClick, size, tn, classColorText}) => {
  return (
    <div onClick={onClick} className={`company-avatar ${size} ${className}`}>
      {src ? (
        <img className="s-w-full s-h-full object-contain" src={getURLImage(src)} alt={`Logo de ${name}`} />
      ) : (
        <div className={`${classColorText} small-radius s-cross-center s-main-center s-w-full s-h-full`}>
          <p className={`${tn} s-color-white s-mb-0`}>{name.substring(0, 1).toUpperCase()}</p>
        </div>
      )}
    </div>
  )
}

CompanyAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  tn: PropTypes.string,
  classColorText: PropTypes.string
}

CompanyAvatar.defaultProps = {
  onClick: () => {},
  className: '',
  src: '',
  size: 's-w-24px s-h-24px',
  tn: 't4',
  classColorText: 's-bg-blue'
}

export default CompanyAvatar
