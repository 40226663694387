import React, {forwardRef, useContext, useState} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {isEmpty} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import useObjectURL from '../../../hooks/useObjectURL'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {bulkOccasionalNovelty} from '../../../services/occasionalnovelty'
import DropContainer from '../../Molecules/DropContainer'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const rules = {
  file: 'required|string'
}

const fieldNames = {
  file: 'Archivo'
}

const OccasionalNoveltyBulk = forwardRef(({reloadNovelties, setReloadNovelties = () => {}}, ref) => {
  const dispatch = useDispatch()
  const {activeProcess} = useContext(PayrollContext)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const configProcess = configObj[activeProcess?.process_type_code]

  const {object, setObject, objectURL} = useObjectURL(null)
  const [error, setError] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const handleFileChange = async acceptedFiles => {
    if (isEmpty(acceptedFiles)) return

    setObject(acceptedFiles[0])
  }

  const handleUpload = () => {
    const payload = {
      file: object?.name,
      pay_period_id: activeProcess?.pay_period_id
    }

    // always add the process_id if the process type has marked "has_novelty_by_process"
    if (configProcess?.config?.rules?.has_novelty_by_process) {
      payload.process_id = activeProcess.id
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(fieldNames)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    payload.file = object
    setIsUploading(true)

    bulkOccasionalNovelty(payload)
      .then(response => {
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('¡Genial! tus novedades fueron cargadas con éxito'))
        ref.current.closeModal()
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => {
        setObject(null)
        setIsUploading(false)
      })
  }

  const handleOpenModal = isOpen => {
    if (!isOpen) {
      setObject(null)
      setError('')
    }
  }

  return (
    <ModalTemplate ref={ref} className="s-center jumbo" onChangeOpen={handleOpenModal}>
      <h3 className={isUploading ? 's-mb-4' : ''}>Cargue Masivo</h3>
      {error ? (
        <div>
          <p>Se encontraron los siguientes errores:</p>
          <PreviewMarkdown content={getError(error)} showLineNumbers={false} />
        </div>
      ) : isUploading ? (
        <div>
          <span className="loading blue s-mr-1"></span>
          <p>Cargando...</p>
        </div>
      ) : (
        <>
          <div className="ed-grid s-grid-1 s-mb-2">
            <p>
              Carga tus novedades fácil y rápido usando un archivo de excel que tenga una hoja llamada{' '}
              <span className="s-color-title">novedades</span>, puedes ver un ejemplo de la estructura{' '}
              <a
                href="https://docs.google.com/spreadsheets/d/1XNpJ_IyqGc5r9lmpr3Qyb8wIzg98bhck_pqEVQ4f6-A/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                aquí
              </a>
            </p>
            <DropContainer
              id="drop-container-file"
              label={fieldNames.file}
              handleFiles={handleFileChange}
              file={objectURL}
              typeFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              limitFiles={1}
              icon="excel"
            />
          </div>

          <button className="button" onClick={handleUpload}>
            Cargar
          </button>
        </>
      )}
    </ModalTemplate>
  )
})

function getError(error) {
  return '```yaml\n' + error + '\n```'
}

OccasionalNoveltyBulk.displayName = 'OccasionalNoveltyBulk'

export default OccasionalNoveltyBulk
