import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {TabsWrapperContext} from '../../../contexts/contexts'
import {isEmpty} from '../../../helpers/utils'
import {getBases} from '../../../services/base'
import {getConceptList, getConceptPriorityOrder} from '../../../services/concept'
import {notifyError} from '../../../services/notification'
import TabTemplateBasic from '../../Atoms/Templates/TabTemplateBasic'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import TabsWrapper from '../../Wrappers/TabsWrapper'
import ConceptPriorityOrderPanel from '../Panels/ConceptPriorityOrderPanel'
import ConceptSettingPanel from '../Panels/ConceptSettingPanel'
import ConceptSettingSkeleton from '../Skeleton/ConceptSettingSkeleton'

const PayrollConceptSettings = () => {
  const dispatch = useDispatch()
  const {setTabActive} = useContext(TabsWrapperContext)

  const [conceptList, setConceptList] = useState([])
  const [orderPriority, setOrderPriority] = useState([])
  const [bases, setBases] = useState([])

  useEffect(() => {
    fetchConceptsList()
    fetchOrderPriorityConcepts()
    fetchBases()
  }, [])

  const fetchOrderPriorityConcepts = () => {
    getConceptPriorityOrder(
      response => setOrderPriority(response),
      error => dispatch(notifyError(error))
    )
  }

  const fetchConceptsList = () => {
    getConceptList(
      response => setConceptList(response),
      error => dispatch(notifyError(error))
    )
  }

  const fetchBases = () => {
    getBases(
      response => setBases(response),
      error => dispatch(notifyError(error))
    )
  }

  const handleOnClickTab = index => setTabActive(index)

  return (
    <>
      <div className="s-py-1">
        <div className="tab-container s-cross-center">
          {[
            {id: 1, name: 'Conceptos'},
            {id: 2, name: 'Prioridad de Conceptos'}
          ].map(t => (
            <TabTemplateBasic key={t.id} index={t.id} className="tab-item" onClick={() => handleOnClickTab(t.id)}>
              {t.name}
            </TabTemplateBasic>
          ))}
        </div>
      </div>

      <PanelTemplate index={1} onlyHidden>
        <TabsWrapper defaultActive={1}>
          {isEmpty(conceptList) ? (
            <ConceptSettingSkeleton />
          ) : (
            <ConceptSettingPanel
              list={conceptList}
              setList={setConceptList}
              orderPriorityList={orderPriority}
              setOrderPriority={setOrderPriority}
              bases={bases}
            />
          )}
        </TabsWrapper>
      </PanelTemplate>

      <PanelTemplate index={2} onlyHidden>
        <ConceptPriorityOrderPanel list={orderPriority} onUpdateOrder={setOrderPriority} />
      </PanelTemplate>
    </>
  )
}

export default PayrollConceptSettings
