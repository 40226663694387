import PropTypes from 'prop-types'
import React from 'react'

import EditTextButton from '../../Atoms/Buttons/EditTextButton'
import Icon from '../../Atoms/Icon'
import Container from '../../Wrappers/Container'

const IconPanelContainer = props => {
  const {title, icon, children, container, editFunction, additionalAction} = props

  return (
    <Container>
      <header className="flex s-color-text s-mb-16px s-cross-center">
        <Icon svg={icon} size="1.2em" className="s-mr-8px" />
        <h4 className="s-mb-0 s-color-current">{title}</h4>

        <div className="flex s-to-right">
          {additionalAction && <div className="s-mr-8px">{additionalAction}</div>}

          {editFunction && <EditTextButton onClick={editFunction} />}
        </div>
      </header>
      <div className={container.className}>{children}</div>
    </Container>
  )
}

IconPanelContainer.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  additionalAction: PropTypes.node,
  children: PropTypes.node.isRequired,
  container: PropTypes.shape({
    className: PropTypes.string
  })
}

IconPanelContainer.defaultProps = {
  container: {
    className: ''
  }
}

export default IconPanelContainer
