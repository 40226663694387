import React from 'react'

import SelectedParameterProvider from '../../../modules/payroll-parameters/providers/SelectedParameter'
import Container from '../../Wrappers/Container'
import GlobalParameterSettingPanelList from './GlobalParameterSettingPanelList'

const GlobalParameterSettingPanel = ({list}) => {
  return (
    <SelectedParameterProvider>
      <Container>
        <GlobalParameterSettingPanelList list={list} />
      </Container>
    </SelectedParameterProvider>
  )
}

export default GlobalParameterSettingPanel
