import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

import ValidatorForm from '../../../helpers/validator'
import {notifyError} from '../../../services/notification'
import {signIn} from '../../../services/user'
import GoogleIcon from '../../../static/images/utils/google.svg'
import SAPIcon from '../../../static/images/utils/SAP.svg'
import OAuthButton from '../../Atoms/Buttons/OAuthButton'
import PasswordInput from '../../Atoms/Forms/PasswordInput'
import FormInput from '../../Atoms/Templates/FormInput'
import WelcomeContainer from '../../Wrappers/WelcomeContainer'

const API_URL = process.env.REACT_APP_API_URL

const LoginForm = () => {
  const dispatch = useDispatch()

  const submit = form => {
    const credentials = {
      email: form.email.value,
      password: form.password.value
    }

    const rules = {email: 'required|email', password: 'required'}
    const validation = new ValidatorForm(credentials, rules)
    if (validation.fails()) {
      dispatch(notifyError(validation.errors()))
      return
    }
    dispatch(signIn(credentials))
  }

  return (
    <WelcomeContainer title="Bienvenido" submit={submit}>
      {/* Botones para iniciar sesión por OAuth*/}
      <div className="ed-grid row-gap s-gap-12px">
        <OAuthButton icon={GoogleIcon} src={`${API_URL}oauth?type=google`} content="Continua con Google" />
        <OAuthButton icon={SAPIcon} src={`${API_URL}oauth?type=sap-nbc`} content="Continua con SAP (nbc)" />
      </div>

      {/* Iniciar sesión con correo electrónico */}
      <FormInput id="login-email" label="Correo electrónico" name="email" placeholder="juan@hotmail.com" type="email" />
      <div>
        <PasswordInput className="s-mb-12px" />
        <div className="flex smaller">
          {/* <Checkbox label="Recuérdame" /> */}
          <Link to="/recuperar" className="s-to-right">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </div>
      <div>
        <input type="submit" value="Inicia sesión" className="button full s-mb-12px" />
        {/* <p className="s-mb-0 small">
          ¿No tienes una cuenta? <Link to="/registro">Regístrate</Link>
        </p> */}
      </div>
    </WelcomeContainer>
  )
}

export default LoginForm
