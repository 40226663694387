import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEmpty, isEqual, isNull} from '../../../helpers/utils'
import useFetchNoveltiesWithConditions from '../../../hooks/useFetchNoveltiesWithConditions'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getAbsenceNoveltiesByHashProcess, getAbsenceNoveltyByID} from '../../../services/absenceNovelties'
import LoadMoreButton from '../../Atoms/Buttons/LoadMoreButton'
import AbsenceNoveltyTable from '../../Molecules/Tables/AbsenceNoveltyTable'
import NoveltyContainer from '../Containers/NoveltyContainer'
import AbsenceNoveltyForm from '../Forms/AbsenceNoveltyForm'

const initialState = {
  contract_id: 0,
  absence_type_id: 0,
  concept_id: 0,
  concept_description: '',
  begins_at: '',
  ends_at: '',
  code: '',
  note: '',
  has_discount_rest_day: false,
  can_pause_contract: false,
  process_hash: '',
  is_discount: false
}

const AbsenceNovelty = ({action, setAction}) => {
  const employerSession = useSelector(employerSelectors.getSession)

  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(initialState)
  const {activeProcess} = useContext(PayrollContext)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const absenceTypes = !isNull(cacheGeneral) && cacheGeneral.data?.absence_types
  const defaultSortOption = {key: 'contract_id', value: 'Empleado'}

  const handleIsDiscountAbsence = absenceTypeID => {
    const result = absenceTypes.filter(
      absenceType => isEqual(absenceType.id, Number(absenceTypeID)) && absenceType.is_discount
    )

    return !isEmpty(result)
  }

  useEffect(() => {
    setIsOpen(false)

    if (isNull(action.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(action.type, CREATE)) {
      setDataForm(initialState)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(action.type, [EDIT, SHOW])) {
      getAbsenceNoveltyByID(
        action.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            absence_type_id: response.absence_type_id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            code: response.code,
            note: response.note,
            has_discount_rest_day: response.has_discount_rest_day,
            can_pause_contract: response.can_pause_contract,
            status: response.status,
            absence_per_periods: response.absence_per_periods,
            is_discount: handleIsDiscountAbsence(response.absence_type_id)
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [action]) // eslint-disable-line

  const {
    novelties,
    page,
    isLastPage,
    isLoading,
    reloadNovelties,
    setFilter,
    setSortOption,
    setPage,
    setReloadNovelties
  } = useFetchNoveltiesWithConditions(activeProcess.hash, defaultSortOption, 5, getAbsenceNoveltiesByHashProcess)

  const handlerOnClickAddPage = () => {
    if (isLastPage) return
    setPage(page + 1)
  }

  return (
    <>
      <NoveltyContainer
        isOpenForm={isOpen}
        form={
          <AbsenceNoveltyForm
            initialState={dataForm}
            action={action}
            setAction={setAction}
            reloadNovelties={reloadNovelties}
            setReloadNovelties={setReloadNovelties}
          />
        }
        table={
          <AbsenceNoveltyTable
            setAction={setAction}
            novelties={novelties}
            setFilter={setFilter}
            setSortOption={setSortOption}
            defaultSortOption={defaultSortOption}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
      />
      {!isLastPage && !isLoading && (
        <LoadMoreButton description="Carga más novedades" onClick={handlerOnClickAddPage} />
      )}
    </>
  )
}

AbsenceNovelty.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf([SHOW, CREATE, EDIT, null]),
    noveltyID: PropTypes.number
  }),
  setAction: PropTypes.func
}

AbsenceNovelty.defaultProps = {
  action: {type: null, noveltyID: 0},
  setAction: () => {}
}

export default AbsenceNovelty
