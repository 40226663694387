import {isEmpty, isEqual, isString, isUndefined} from './utils'

export const CONCEPT_EARNING = 'INGRESO'
export const CONCEPT_DEDUCTION = 'DESCUENTO'
export const CONCEPT_EARNING_SYSTEM = 'INGRESO INTERNO'
export const CONCEPT_DEDUCTION_SYSTEM = 'DESCUENTO INTERNO'
export const CONCEPT_PROVISION = 'PROVISION'
export const CONCEPT_NOT_OPERATION = 'NO OPERA'
export const CONCEPT_NET_PAY = 'NETOS'

export const getConceptsNoveltyByProcessType = (conceptsNovelties, conceptsByProcess, processTypeID) => {
  const processConcepts = conceptsByProcess.find(c => isEqual(c.process_type_id, processTypeID))

  if (isUndefined(processConcepts)) return []

  // Filtra para obtener solo los conceptos que corresponden al proceso actual
  return conceptsNovelties.filter(concept => processConcepts?.concept_ids.find(id => id === concept.id))
}

export const getConceptTypeByConceptCode = code => {
  if (isEmpty(code) || !isString(code)) return CONCEPT_EARNING

  if (isEqual(code.slice(0, 2), 'II')) return CONCEPT_EARNING_SYSTEM
  if (isEqual(code.slice(0, 2), 'DI')) return CONCEPT_DEDUCTION_SYSTEM
  if (isEqual(code.slice(0, 2), 'PR')) return CONCEPT_PROVISION
  if (isEqual(code.slice(0, 2), 'NO')) return CONCEPT_NOT_OPERATION
  if (isEqual(code.slice(0, 1), 'I')) return CONCEPT_EARNING
  if (isEqual(code.slice(0, 1), 'D')) return CONCEPT_DEDUCTION

  return ''
}
