import {createDuck} from 'redux-duck'

import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/jobs')

// Define action types
export const SET_JOBS = duck.defineType('SET_JOBS')
export const ADD_JOBS = duck.defineType('ADD_JOBS')
export const EDIT_JOBS = duck.defineType('EDIT_JOBS')
export const REMOVE_JOBS = duck.defineType('REMOVE_JOBS')

// Define action creators
export const actions = {
  set: duck.createAction(SET_JOBS),
  add: duck.createAction(ADD_JOBS),
  edit: duck.createAction(EDIT_JOBS),
  remove: duck.createAction(REMOVE_JOBS)
}

// Define selectors
export const selectors = {
  getAll: store => store.jobsReducer.all
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_JOBS]: (state, {payload}) => ({
      ...state,
      all: [...payload]
    }),
    [ADD_JOBS]: (state, {payload}) => ({
      ...state,
      all: [...state.all, payload].sort((a, b) => (a.description.toUpperCase() < b.description.toUpperCase() ? -1 : 1))
    }),
    [EDIT_JOBS]: (state, {payload}) => {
      let jobs = state.all.filter(job => job.id !== payload.id) // delete the old job
      jobs.push(payload) // add the new job

      jobs.sort((a, b) => (a.description.toUpperCase() < b.description.toUpperCase() ? -1 : 1))

      return {
        ...state,
        all: [...jobs]
      }
    },
    [REMOVE_JOBS]: (state, {payload}) => ({
      ...state,
      all: [...state.all.filter(job => job.id !== payload)]
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState
  },
  initialState
)
