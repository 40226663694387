import PropTypes from 'prop-types'
import React from 'react'

import {mediaFromM} from '../../helpers/media-queries'
import {useMedia} from '../../hooks/useMedia'
import DeleteTextButton from '../Atoms/Buttons/DeleteTextButton'
import EditTextButton from '../Atoms/Buttons/EditTextButton'
import SeeTextButton from '../Atoms/Buttons/SeeTextButton'
import DropdownItem from '../Atoms/Items/DropdownItem'
import DropdownButton from './Dropsdown/DropdownButton'

const TableOptionsButton = props => {
  const {deleteFunction, editFunction, seeFunction, iconSee = 'eye', textSee = 'Ver'} = props
  const fromM = useMedia(mediaFromM)

  if (!deleteFunction && !editFunction && !seeFunction) {
    return null
  }

  return fromM ? (
    <DropdownButton icon="vertical-dots">
      {seeFunction && <DropdownItem text={<SeeTextButton icon={iconSee} text={textSee} />} onClick={seeFunction} />}
      {editFunction && <DropdownItem text={<EditTextButton />} onClick={editFunction} />}
      {deleteFunction && <DropdownItem text={<DeleteTextButton />} onClick={deleteFunction} />}
    </DropdownButton>
  ) : (
    <div className="s-cross-center s-flex-gap-16px">
      {seeFunction && <SeeTextButton onClick={seeFunction} icon={iconSee} text={textSee} />}
      {editFunction && <EditTextButton onClick={editFunction} />}
      {deleteFunction && <DeleteTextButton onClick={deleteFunction} />}
    </div>
  )
}

TableOptionsButton.propTypes = {
  deleteFunction: PropTypes.func,
  editFunction: PropTypes.func,
  seeFunction: PropTypes.func
}

export default TableOptionsButton
