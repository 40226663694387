import RadioButton from 'components/Atoms/Forms/RadioButton'
import RadioButtonsWrapper from 'components/Wrappers/RadioButtonsWrapper'
import {getCacheKeyGeneral, getLocalCache} from 'helpers/cache'
import {fieldNamesTaxEdition, rulesTaxEdition} from 'helpers/employee'
import ValidatorForm from 'helpers/validator'
import React, {forwardRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateTax} from 'services/employees'
import {notifyError} from 'services/notification'

import {isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const RetentionEditModal = forwardRef(({formData, setFormData, onUpdate}, ref) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const identifications = !isNull(cacheGeneral) && cacheGeneral.data?.identification_types
  const [isLoading, setIsLoading] = useState(false)

  const updateFormField = e => {
    const target = e.target
    let value = isEqual(target.type, 'checkbox') ? target.checked : target.value
    const name = target.name

    if (name.endsWith('_id')) value = Number(value)
    if (isEqual(target.type, 'number')) value = Number(value)

    if (isEqual(target.type, 'radio') && isEqual(value, 'true')) value = true
    if (isEqual(target.type, 'radio') && isEqual(value, 'false')) value = false

    setFormData(state => ({...state, contract: {...state.contract, [name]: value}}))
  }

  const updateFormFieldDependent = e => {
    let value = e.target.value
    const name = e.target.name

    if (name.endsWith('_id')) value = Number(value)
    setFormData(state => ({...state, dependent: {...state.dependent, [name]: value}}))
  }

  const updateFormFieldTextToNumber = e => {
    const value = e.target.value
    const name = e.target.name

    const pattern = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '')) {
      setFormData(state => ({...state, contract: {...state.contract, [name]: ''}}))
    }

    if (pattern.test(value)) {
      setFormData(state => ({
        ...state,
        contract: {...state.contract, [name]: Number(value)}
      }))
    }
  }

  const handleOnChangeTaxProcedure = e => {
    updateFormField(e)

    // if procedure change to 1 reset the rate taxes to zero
    if (isEqual(e.target.value, '1')) {
      setFormData(state => ({...state, contract: {...state.contract, rate_taxes: 0}}))
    }
  }

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const handleUpdateRetentionData = () => {
    const payload = {
      tax_relief_health: Number(formData.contract.tax_relief_health),
      tax_relief_living_place: Number(formData.contract.tax_relief_living_place),
      is_tax_dependents: formData.contract.is_tax_dependents,
      is_tax_dependents_raw: formData.contract.is_tax_dependents ? 'TRUE' : 'FALSE',
      method_taxes: Number(formData.contract.method_taxes),
      rate_taxes: formData.contract.rate_taxes
    }

    if (formData.contract.is_tax_dependents) {
      payload.dependent = {
        employer_id: formData.contract.employer_id,
        contract_id: formData.contract.id,
        identification_type_id: Number(formData.dependent.identification_type_id),
        identification_number: formData.dependent.identification_number,
        first_name: formData.dependent.first_name,
        middle_name: formData.dependent.middle_name,
        last_name: formData.dependent.last_name,
        surname: formData.dependent.surname,
        relationship_type: formData.dependent.relationship_type
      }
    }

    const validator = new ValidatorForm(payload, rulesTaxEdition)
    validator.setAttributeNames(fieldNamesTaxEdition)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    setIsLoading(true)

    updateTax(
      formData.contract.id,
      payload,
      response => {
        setIsLoading(false)

        onUpdate(state => ({
          ...state,
          contract: {
            ...state.contract,
            tax_relief_health: response.tax_relief_health,
            tax_relief_living_place: response.tax_relief_living_place,
            is_tax_dependents: response.is_tax_dependents,
            method_taxes: response.method_taxes,
            rate_taxes: response.rate_taxes
          },
          dependent: {
            id: response?.dependent.id,
            employer_id: response?.dependent.employer_id,
            contract_id: response?.dependent.contract_id,
            identification_type_id: response?.dependent.identification_type_id,
            identification_number: response?.dependent.identification_number,
            first_name: response?.dependent.first_name,
            middle_name: response?.dependent.middle_name,
            last_name: response?.dependent.last_name,
            surname: response?.dependent.surname,
            relationship_type: response?.dependent.relationship_type
          }
        }))

        handleCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <ModalTemplate ref={ref} className="large">
      <h3 className="s-left s-mb-0">Retención en la fuente</h3>
      <p className="small s-color-light-text s-mb-24px">Edita la información de la retención en la fuente.</p>
      <form className="s-mb-0">
        <div className="form-grid ed-grid m-grid-2 lg-grid-3 l-block">
          <Select
            isRequired
            label="Método de Retención"
            name="method_taxes"
            id="method_taxes"
            placeholder
            value={formData.contract.method_taxes}
            onChange={handleOnChangeTaxProcedure}
          >
            <option value="1">Procedimiento 1</option>
            <option value="2">Procedimiento 2</option>
          </Select>

          {(isEqual(formData.contract.method_taxes, '2') || isEqual(formData.contract.method_taxes, 2)) && (
            <FormInput
              isRequired
              label="% de retención"
              name="rate_taxes"
              id="rate_taxes"
              placeholder="% RTF"
              type="number"
              min="0.00"
              step=".01"
              value={formData.contract.rate_taxes}
              onChange={updateFormField}
            />
          )}

          <FormInput
            label="Deducible de vivienda"
            name="tax_relief_living_place"
            id="tax_relief_living_place"
            placeholder="Deducible"
            align="right"
            value={formData.contract.tax_relief_living_place}
            onChange={updateFormFieldTextToNumber}
          />

          <FormInput
            label="Deducible medicina pagada"
            name="tax_relief_health"
            id="tax_relief_health"
            placeholder="Deducible medicina"
            align="right"
            value={formData.contract.tax_relief_health}
            onChange={updateFormFieldTextToNumber}
          />

          <RadioButtonsWrapper label="Deducible dependiente">
            <RadioButton
              name="is_tax_dependents"
              label="Sí"
              value={true}
              onChange={updateFormField}
              checked={isEqual(formData.contract.is_tax_dependents, true)}
            />
            <RadioButton
              name="is_tax_dependents"
              label="No"
              value={false}
              onChange={updateFormField}
              checked={isEqual(formData.contract.is_tax_dependents, false)}
            />
          </RadioButtonsWrapper>

          {formData.contract.is_tax_dependents && (
            <div className="m-cols-2 lg-cols-3">
              <p className="s-color-title normal s-center">Información del Dependiente</p>
              <div className="form-grid ed-grid m-grid-3 s-mb-3">
                <Select
                  isRequired
                  label="Tipo de identificación"
                  name="identification_type_id"
                  id="identification_type_id"
                  placeholder
                  value={formData?.dependent?.identification_type_id}
                  onChange={updateFormFieldDependent}
                >
                  {Array.isArray(identifications) &&
                    identifications.map(identification => (
                      <option key={identification.id} value={identification.id}>
                        {identification.description}
                      </option>
                    ))}
                </Select>
                <FormInput
                  isRequired
                  label="No. de identificatión"
                  name="identification_number"
                  id="identification_number"
                  value={formData?.dependent?.identification_number}
                  onChange={updateFormFieldDependent}
                />
                <FormInput
                  isRequired
                  label="Parentesco"
                  name="relationship_type"
                  id="relationship_type"
                  value={formData?.dependent?.relationship_type}
                  onChange={updateFormFieldDependent}
                />
              </div>

              <div className="form-grid ed-grid m-grid-4">
                <FormInput
                  isRequired
                  label="Primer nombre"
                  name="first_name"
                  id="first_name"
                  value={formData?.dependent?.first_name}
                  onChange={updateFormFieldDependent}
                />

                <FormInput
                  label="Segundo nombre"
                  name="middle_name"
                  id="middle_name"
                  value={formData?.dependent?.middle_name}
                  onChange={updateFormFieldDependent}
                />

                <FormInput
                  isRequired
                  label="Primer apellido"
                  name="last_name"
                  id="last_name"
                  value={formData?.dependent?.last_name}
                  onChange={updateFormFieldDependent}
                />

                <FormInput
                  label="Segundo apellido"
                  name="surname"
                  id="surname"
                  value={formData?.dependent?.surname}
                  onChange={updateFormFieldDependent}
                />
              </div>
            </div>
          )}
        </div>

        <div className="s-column s-cross-center">
          <p className="smaller">
            Los campos con <span className="s-color-blue">*</span> son obligatorios
          </p>
          <div className="s-column m-row m-main-center s-flex-gap-16px s-100">
            <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={handleCancel}>
              Cancelar
            </button>
            <button
              type="button"
              className="button ghost s-order-1 m-order-3"
              onClick={handleUpdateRetentionData}
              disabled={isLoading}
            >
              {isLoading ? 'Actualizando...' : 'Actualizar'}
            </button>
          </div>
        </div>
      </form>
    </ModalTemplate>
  )
})

RetentionEditModal.displayName = 'RetentionEditModal'

export default RetentionEditModal
