import React, {useContext, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {TabsWrapperContext} from '../../../contexts/contexts'
import {baseConceptClassifications, listbaseConceptClassification} from '../../../helpers/base'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {
  KEY_NOT_APPLY,
  KEY_NOVELTY_ABSENCES,
  KEY_NOVELTY_LEAVES,
  KEY_NOVELTY_OCCASIONAL,
  KEY_NOVELTY_SALARY,
  KEY_NOVELTY_VACATIONS
} from '../../../helpers/novelties'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isEmpty, isEqual} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {actions as conceptActions} from '../../../redux/ducks/concepts'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {createBaseConcept, deleteBaseConcept} from '../../../services/baseConcept'
import {
  createConcept,
  createOverrideConcept,
  deleteOverrideConcept,
  updateConceptPriorityOrder,
  updateEmployerConcept,
  updateOverrideConcept
} from '../../../services/concept'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {appendConceptIDToProcessType, removeConceptIDOfProcessType} from '../../../services/processTypeConcept'
import Breadcrumbs from '../../Atoms/Breadcrumbs'
import DeleteTextButton from '../../Atoms/Buttons/DeleteTextButton'
import LinkTextButton from '../../Atoms/Buttons/LinkTextButton'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Select from '../../Atoms/Forms/Select'
import TextArea from '../../Atoms/Forms/TextArea'
import Icon from '../../Atoms/Icon'
import Tag from '../../Atoms/Tag'
import FormInput from '../../Atoms/Templates/FormInput'
import Tooltip from '../../Atoms/Templates/Tooltip'
import SelectBaseInput from '../../Molecules/Selects/SelectBaseInput'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import Base from '../../Molecules/Templates/Base'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import TooltipContainer from '../Containers/TooltipContainer'

const formFields = {
  concept_type_id: 'Tipo Concepto',
  code: 'Código',
  description: 'Nombre',
  is_fixed: 'Modo de cálculo',
  note: 'Notas u observaciones',
  formula: 'Formula',
  rate_param: 'Tarifa',
  quantity_param: 'Cantidad',
  begins_at_param: 'Fecha inicial',
  ends_at_param: 'Fecha final',
  base_param: 'Base',
  classification: 'Tipo de novedad',
  absence_type_id: 'Tipo de ausencia'
}

const rules = {
  concept_type_id: 'required|integer|not_in:0',
  description: 'required|string',
  is_fixed: 'required|boolean',
  note: 'string',
  formula: 'required|string',
  rate_param: 'string',
  quantity_param: 'string',
  begins_at_param: 'string',
  ends_at_param: 'string',
  base_param: 'string',
  classification: 'in:LEAVE,ABSENCE,VACATION,SALARY,OCCASIONAL_NOVELTY,N/A',
  // we don't validate if the absence_type_id is not zero here, we only validate in the Create operation in the logic because
  // the backend don't support in update operation
  'classification_metadata.absence.absence_type_id': 'required_if:classification,ABSENCE|integer'
}

const ConceptSettingPanelForm = ({formData, setFormData, setList, orderPriorityList, setOrderPriority, bases}) => {
  const dispatch = useDispatch()
  const refSectionProcesses = useRef(null)
  const refSectionFormulation = useRef(null)

  const {setTabActive} = useContext(TabsWrapperContext)
  const employerSession = useSelector(employerSelectors.getSession)
  const cache = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const conceptTypes =
    cache.data.concept_types && isEqual(formData.action, CREATE)
      ? cache.data.concept_types.filter(c => c.can_append)
      : cache.data.concept_types
  const absenceTypes = cache.data.absence_types
  const isDisabled = isEqual(formData.action, SHOW) || formData.data.is_block || formData.data.is_standard

  const [isLoading, setIsLoading] = useState(false)

  // Processes
  const configProcessTypes = useSelector(configProcessTypeSelectors.getAll)

  const processTypeList =
    Array.isArray(configProcessTypes) &&
    configProcessTypes.filter(c => formData?.processes?.findIndex(i => i.code === c.code) === -1)
  const [processTypeCode, setProcessTypeCode] = useState('')
  const [isLoadingProcessType, setIsLoadingProcessType] = useState(false)

  // Bases
  const baseList =
    Array.isArray(bases) && bases.filter(b => formData?.bases?.findIndex(fb => fb.base_id === b.id) === -1)
  const [baseID, setBaseID] = useState(0)
  const [classificationBase, setClassificationBase] = useState('')
  const [isLoadingBase, setIsLoadingBase] = useState(false)

  const backListConcepts = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setProcessTypeCode('')
    setTabActive(1)
  }

  const moveToFormulaEditor = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setTabActive(3)
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  const handleOnChangeInputTextOverride = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        override: {
          ...state.data.override,
          [name]: value
        }
      }
    }))
  }

  const handleOnChangeInputTextNumber = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        [name]: Number(value)
      }
    }))
  }

  const handleOnChangeIsFixed = e => {
    const value = e.target.value
    const isFixed = isEqual(value, 'true')
    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        is_fixed: isFixed
      }
    }))
  }

  const handleOnChangeClassification = e => {
    const value = e.target.value
    const isAbs = isEqual(value, KEY_NOVELTY_ABSENCES)

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        classification: value,
        // reset the classification metadata
        classification_metadata: {
          absence: {
            absence_type_id: 0
          }
        },
        // reset the parameters
        quantity_param: isAbs ? 'DIAS_AUSENTISMO' : '',
        begins_at_param: isAbs ? 'FECHA_INICIAL' : '',
        ends_at_param: isAbs ? 'FECHA_FINAL' : '',
        formula: isAbs ? 'resultado = $.SALARIO_ACTUAL / 30 * $.DIAS_AUSENTISMO;' : 'resultado = $.VALOR_NOVEDAD;'
      }
    }))
  }

  const handleOnChangeAbsenceTypeID = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        classification_metadata: {
          ...state.data.classification_metadata,
          absence: {
            ...state.data.classification_metadata.absence,
            absence_type_id: Number(value)
          }
        }
      }
    }))
  }

  const handleOnSelectPriorityOrder = conceptID => {
    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        priority_order_before_id: Number(conceptID)
      }
    }))
  }

  const handleSave = () => {
    setIsLoading(true)

    const validation = new ValidatorForm(formData.data, rules)
    validation.setAttributeNames(formFields)

    if (validation.fails()) {
      dispatch(notifyError(validation.errors()))
      setIsLoading(false)
      return
    }

    if (isEqual(formData.action, CREATE)) {
      handleOnSaveNew()
      return
    }

    handleOnSaveEdit()
  }

  const handleOnSaveNew = () => {
    if (isEqual(formData.data.priority_order_before_id, 0)) {
      dispatch(notifyError('¡Upps! debes seleccionar un concepto para el orden de prioridad'))
      setIsLoading(false)
      return
    }

    if (
      isEqual(formData?.data?.classification, KEY_NOVELTY_ABSENCES) &&
      isEqual(formData?.data?.classification_metadata?.absence?.absence_type_id, 0)
    ) {
      dispatch(notifyError('¡Upps! debes seleccionar un tipo de ausencia'))
      setIsLoading(false)
      return
    }

    createConcept(
      formData.data,
      response => {
        // Add the new concept to the list
        setList(state => [response, ...state])

        // Add the concept to the priority and update priority list
        let ix = orderPriorityList.findIndex(i => i.id === formData.data.priority_order_before_id)
        ix = ix === -1 ? 0 : ix

        let newOrderPriority = [...orderPriorityList]
        newOrderPriority.splice(ix, 0, response)

        updateConceptPriorityOrder(
          {priority_list: newOrderPriority.map(c => c.id)},
          resp => setOrderPriority(newOrderPriority),
          error => dispatch(notifyError(error))
        )

        // Move to EDIT form
        setFormData({action: EDIT, data: response, processes: [], bases: []})
        setTabActive(2)
        setIsLoading(false)

        // Move to section for add processes and bases
        refSectionProcesses.current.scrollIntoView({behavior: 'smooth'})

        // Add the new concept to the global state of the concepts
        dispatch(conceptActions.appendConcepts(response))

        // if the classification is ABSENCE we need to update the global state
        if (isEqual(formData.data.classification, KEY_NOVELTY_ABSENCES)) {
          dispatch(conceptActions.appendAbsenceTypeConcept(response?.classification_metadata?.absence))
        }

        dispatch(notifySuccessful('¡Listo! hemos creado el concepto, ahora agregalo a las bases y procesos'))
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const handleOnSaveEdit = () => {
    updateEmployerConcept(
      formData.data.id,
      formData.data,
      response => {
        setList(state => [...state.map(c => (c.id !== response.id ? c : {...c, ...response}))])

        setIsLoading(false)
        window.scrollTo({top: 0, behavior: 'smooth'})
        dispatch(notifySuccessful('¡Listo! hemos actualizado el concepto'))
      },
      error => {
        setIsLoading(false)
        window.scrollTo({top: 0, behavior: 'smooth'})
        dispatch(notifyError(error))
      }
    )
  }

  const enableOverriding = () => {
    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        is_override: true,
        override: {
          ...state.data.override,
          concept_id: state.data.id
        }
      }
    }))

    // Move to section for customize formula
    refSectionFormulation.current.scrollIntoView({behavior: 'smooth'})
  }

  const saveOverride = () => {
    setIsLoading(true)

    if (isEqual(formData.data.override.formula, '')) {
      dispatch(notifyError('¡Upps! debes configurar una formula para el concepto'))
      setIsLoading(false)
      return
    }

    // Update override
    if (formData?.data?.override?.id) {
      updateOverride()
      return
    }

    createOverride()
  }

  const updateOverride = () => {
    updateOverrideConcept(
      formData?.data?.override?.concept_id,
      formData?.data?.override,
      response => {
        setList(state => [
          ...state.map(c => (c.id !== response.concept_id ? c : {...c, is_override: true, override: {...response}}))
        ])

        dispatch(notifySuccessful('¡Listo! hemos guardado tu personalización'))
        window.scrollTo({top: 0, behavior: 'smooth'})
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const createOverride = () => {
    createOverrideConcept(
      formData?.data?.override,
      response => {
        // we update of form data for set the id, so the user can edit the next time
        setFormData(state => ({
          ...state,
          data: {
            ...state.data,
            override: {...response}
          }
        }))

        // update the list concepts
        setList(state => [
          ...state.map(c =>
            c.id !== response.concept_id ? c : {...c, is_override: true, override: {...c.override, ...response}}
          )
        ])

        dispatch(notifySuccessful('¡Listo! hemos creado tu personalización'))
        window.scrollTo({top: 0, behavior: 'smooth'})
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const deleteOverride = () => {
    setIsLoading(true)

    deleteOverrideConcept(
      formData?.data?.override?.concept_id,
      response => {
        // we update of form data for remove of override
        setFormData(state => ({
          ...state,
          data: {
            ...state.data,
            is_override: false,
            override: {}
          }
        }))

        // remove the override data of the list concepts
        setList(state => [
          ...state.map(c => (c.id !== formData?.data?.id ? c : {...c, is_override: false, override: {}}))
        ])

        dispatch(notifySuccessful('¡Listo! hemos eliminado tu personalización'))
        window.scrollTo({top: 0, behavior: 'smooth'})
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const handleAddProcessType = () => {
    setIsLoadingProcessType(true)
    const process = configProcessTypes.find(p => isEqual(p.code, processTypeCode))

    appendConceptIDToProcessType(
      {process_type_id: process.id, concept_id: formData.data.id},
      response => {
        setFormData(state => ({
          ...state,
          processes: [...state.processes, process]
        }))

        // Add the concept to the global state of the concepts
        dispatch(conceptActions.appendProcessConcept(response))

        setProcessTypeCode('')
        setIsLoadingProcessType(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingProcessType(false)
      }
    )
  }

  const handleDeleteProcessType = code => {
    setIsLoadingProcessType(true)
    const process = configProcessTypes.find(p => isEqual(p.code, code))

    removeConceptIDOfProcessType(
      process.id,
      formData.data.id,
      response => {
        setFormData(state => ({
          ...state,
          processes: [...state.processes.filter(p => !isEqual(p.code, code))]
        }))

        // Remove the concept of the global state of the concepts
        dispatch(conceptActions.removeProcessConcept(response))

        setProcessTypeCode('')
        setIsLoadingProcessType(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingProcessType(false)
      }
    )
  }

  const handleAddBase = () => {
    setIsLoadingBase(true)

    createBaseConcept(
      {
        base_id: Number(baseID),
        concept_id: formData.data.id,
        classification: classificationBase
      },
      response => {
        setFormData(state => ({
          ...state,
          bases: [...state.bases, response]
        }))

        setBaseID(0)
        setClassificationBase('')
        setIsLoadingBase(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingBase(false)
      }
    )
  }

  const handleDeleteBase = baseID => {
    setIsLoadingBase(true)

    deleteBaseConcept(
      baseID,
      formData.data.id,
      response => {
        setFormData(state => ({
          ...state,
          bases: [...state.bases.filter(b => !isEqual(b.base_id, baseID))]
        }))

        setBaseID(0)
        setClassificationBase('')
        setIsLoadingBase(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingBase(false)
      }
    )
  }

  return (
    <>
      <Breadcrumbs className="s-mb-1">
        <p onClick={backListConcepts}>Conceptos</p>
        <p>{isEqual(formData.action, CREATE) ? 'Nuevo' : `${formData.data.code} ${formData.data.description}`}</p>
      </Breadcrumbs>

      <div className="s-mb-4 s-cross-center">
        {formData.data.is_standard && (
          <Tag text="Concepto Estándar" color="blue" bgColor="s-bg-light-blue" icon="nomineros" className="s-mr-1" />
        )}

        {formData.data.is_blocked && <Tag text="🔒 Bloqueado" color="text" bgColor="s-bg-border" className="s-mr-1" />}
      </div>

      <form className="form ed-grid m-grid-2 lg-grid-3 form-grid s-mb-4">
        <Select
          isRequired
          placeholder
          isDisabled={!isEqual(formData.action, CREATE)}
          name="concept_type_id"
          label={formFields.concept_type_id}
          value={formData.data.concept_type_id}
          onChange={handleOnChangeInputTextNumber}
        >
          {Array.isArray(conceptTypes) &&
            conceptTypes.map(item => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
        </Select>

        <FormInput
          isRequired
          isDisabled={isDisabled}
          label={formFields.description}
          name="description"
          id="description"
          value={formData.data.description}
          onChange={handleOnChangeInputText}
          align="left"
          className="lg-cols-2"
        />

        <TextArea
          isDisabled={isDisabled}
          className="s-h-full m-cols-2 lg-cols-3"
          name="note"
          id="note"
          placeholder="Escribe una nota"
          value={formData.data.note}
          onChange={handleOnChangeInputText}
          align="left"
          label={formFields.note}
          textarea={{
            className: 's-h-full'
          }}
        />

        <div className="flex">
          <RadioButtonsWrapper label={formFields.is_fixed} isRequired>
            <RadioButton
              disabled={isDisabled}
              name="is_fixed"
              label="Fijo"
              value={true}
              onChange={handleOnChangeIsFixed}
              checked={isEqual(formData.data.is_fixed, true)}
            />
            <RadioButton
              disabled={isDisabled}
              name="is_fixed"
              label="por Novedad"
              value={false}
              onChange={handleOnChangeIsFixed}
              checked={isEqual(formData.data.is_fixed, false)}
            />
          </RadioButtonsWrapper>

          <TooltipContainer className="s-cross-start s-py-2px">
            <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
            <Tooltip>
              <div className="whitespace-nowrap">
                <p className="smaller s-mb-0">
                  Fijo: <span className="s-color-lighter-text">Se evalua siempre para cada contrato.</span>
                </p>
                <p className="smaller s-mb-0">
                  Por Novedad:{' '}
                  <span className="s-color-lighter-text">Solo se cálcula si el contrato tiene una novedad.</span>
                </p>
              </div>
            </Tooltip>
          </TooltipContainer>
        </div>

        {isEqual(formData.action, CREATE) && (
          <SelectConceptInput
            isRequired
            name="priority_order_before_id"
            id="priority_order_before_id"
            label={'Cálcular antes del concepto'}
            placeholder="Busca por código o nombre"
            onSelect={handleOnSelectPriorityOrder}
            concepts={orderPriorityList}
          />
        )}
      </form>

      <div className="s-mb-2">
        <hr />
        <h4 className="s-mb-2" ref={refSectionProcesses}>
          Clasificación
        </h4>
        <p className="small">Tipo de novedad al que pertenece el concepto.</p>
        <div className="form ed-grid m-grid-1 form-grid s-mb-4">
          <RadioButtonsWrapper label={formFields.classification} isRequired>
            <RadioButton
              disabled={!isEqual(formData.action, CREATE)}
              name="classification"
              label="Normal"
              value={KEY_NOVELTY_OCCASIONAL}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOVELTY_OCCASIONAL)}
            />
            <RadioButton
              disabled={!isEqual(formData.action, CREATE)}
              name="classification"
              label="Ausencia"
              value={KEY_NOVELTY_ABSENCES}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOVELTY_ABSENCES)}
            />
            <RadioButton
              disabled={true}
              name="classification"
              label="Incapacidad"
              value={KEY_NOVELTY_LEAVES}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOVELTY_LEAVES)}
            />
            <RadioButton
              disabled={true}
              name="classification"
              label="Vacaciones"
              value={KEY_NOVELTY_VACATIONS}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOVELTY_VACATIONS)}
            />
            <RadioButton
              disabled={true}
              name="classification"
              label="Salario"
              value={KEY_NOVELTY_SALARY}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOVELTY_SALARY)}
            />
            <RadioButton
              disabled={true}
              name="classification"
              label="N/A"
              value={KEY_NOT_APPLY}
              onChange={handleOnChangeClassification}
              checked={isEqual(formData.data.classification, KEY_NOT_APPLY)}
            />
          </RadioButtonsWrapper>

          {isEqual(formData.data.classification, KEY_NOVELTY_ABSENCES) && isEqual(formData.action, CREATE) && (
            <div className="form ed-grid m-grid-2 form-grid">
              <Select
                isRequired
                placeholder
                isDisabled={!isEqual(formData.action, CREATE)}
                name="absence_type_id"
                label={formFields.absence_type_id}
                value={formData.data.classification_metadata.absence.absence_type_id}
                onChange={handleOnChangeAbsenceTypeID}
              >
                {Array.isArray(absenceTypes) &&
                  absenceTypes.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </Select>
            </div>
          )}
        </div>
      </div>

      <hr />

      <div className="s-mb-2 s-cross-center">
        <h4 className="s-mb-0 s-mr-1" ref={refSectionFormulation}>
          Parámetros y Formulación
        </h4>

        {formData.data.is_override && <Tag text="🔖 Personalizado" color="red" bgColor="s-bg-light-red s-mr-1" />}

        {formData.data.is_standard && !formData.data.is_blocked && (
          <TooltipContainer className="s-cross-start s-py-2px">
            <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
            <Tooltip>
              <div className="whitespace-nowrap">
                <p className="smaller s-mb-0">
                  No puedes modificar un concepto estándar de nomineros, pero puedes personalizarlo para que se cálcule
                  como desees.
                </p>
                <p className="smaller s-mb-0">Recuerda que siempre podrás restablecer la configuración por defecto.</p>
              </div>
            </Tooltip>
          </TooltipContainer>
        )}
      </div>

      <p className="small">
        Nombre de las variables que usa el concepto para establecer los valores de{' '}
        <span className="s-color-title">cantidad</span>, <span className="s-color-title">tarifa</span>,{' '}
        <span className="s-color-title">fecha inicial</span>, <span className="s-color-title">fecha final</span> y{' '}
        <span className="s-color-title">base</span> en el comprobante de nómina. Los parámetros solo pueden contener
        letras mayúsculas y guiones bajo.
      </p>

      {/* parameters and formulation directly of concepts*/}
      {!formData.data.is_override && (
        <div className="s-mb-2">
          <form className="ed-grid s-grid-2 lg-grid-3 form-grid s-mb-4">
            <FormInput
              isDisabled={isDisabled}
              label={formFields.quantity_param}
              name="quantity_param"
              id="quantity_param"
              value={formData.data.quantity_param}
              onChange={handleOnChangeInputText}
              align="left"
            />

            <FormInput
              isDisabled={isDisabled}
              label={formFields.rate_param}
              name="rate_param"
              id="rate_param"
              value={formData.data.rate_param}
              onChange={handleOnChangeInputText}
              align="left"
            />

            <FormInput
              isDisabled={isDisabled}
              label={formFields.base_param}
              name="base_param"
              id="base_param"
              value={formData.data.base_param}
              onChange={handleOnChangeInputText}
              align="left"
            />

            <FormInput
              isDisabled={isDisabled}
              label={formFields.begins_at_param}
              name="begins_at_param"
              id="begins_at_param"
              value={formData.data.begins_at_param}
              onChange={handleOnChangeInputText}
              align="left"
            />

            <FormInput
              isDisabled={isDisabled}
              label={formFields.ends_at_param}
              name="ends_at_param"
              id="ends_at_param"
              value={formData.data.ends_at_param}
              onChange={handleOnChangeInputText}
              align="left"
            />
          </form>

          <div className="small flex s-mb-4">
            Formula utilizada por el concepto para cálcular su valor.{' '}
            <LinkTextButton text={isDisabled ? 'Ver Formula' : 'Configurar formula'} onClick={moveToFormulaEditor} />
          </div>

          {isEqual(formData.action, EDIT) && formData.data.is_standard && !formData.data.is_blocked && (
            <button className="button ghost s-mr-1" onClick={enableOverriding}>
              Personalizar
            </button>
          )}

          {(isEqual(formData.action, CREATE) ||
            (isEqual(formData.action, EDIT) && !formData.data.is_standard && !formData.data.is_blocked)) && (
            <>
              <button className="button ghost s-mr-1" onClick={backListConcepts} disabled={isLoading}>
                Cancelar
              </button>
              <button className="button" onClick={handleSave} disabled={isLoading}>
                Guardar
              </button>
            </>
          )}
        </div>
      )}

      {/* Override parameters and formulation */}
      {formData.data.is_override && (
        <div className="s-mb-2">
          <form className="ed-grid s-grid-2 lg-grid-3 form-grid s-mb-4">
            <FormInput
              isDisabled={isEqual(formData.action, SHOW)}
              label={formFields.quantity_param}
              name="quantity_param"
              id="override_quantity_param"
              value={formData.data.override.quantity_param}
              onChange={handleOnChangeInputTextOverride}
              align="left"
            />

            <FormInput
              isDisabled={isEqual(formData.action, SHOW)}
              label={formFields.rate_param}
              name="rate_param"
              id="override_rate_param"
              value={formData.data.override.rate_param}
              onChange={handleOnChangeInputTextOverride}
              align="left"
            />

            <FormInput
              isDisabled={isEqual(formData.action, SHOW)}
              label={formFields.base_param}
              name="base_param"
              id="override_base_param"
              value={formData.data.override.base_param}
              onChange={handleOnChangeInputTextOverride}
              align="left"
            />

            <FormInput
              isDisabled={isEqual(formData.action, SHOW)}
              label={formFields.begins_at_param}
              name="begins_at_param"
              id="override_begins_at_param"
              value={formData.data.override.begins_at_param}
              onChange={handleOnChangeInputTextOverride}
              align="left"
            />

            <FormInput
              isDisabled={isEqual(formData.action, SHOW)}
              label={formFields.ends_at_param}
              name="ends_at_param"
              id="override_ends_at_param"
              value={formData.data.override.ends_at_param}
              onChange={handleOnChangeInputTextOverride}
              align="left"
            />
          </form>

          <div className="small flex s-mb-4">
            Formula utilizada por el concepto para cálcular su valor.{' '}
            <LinkTextButton
              text={isEqual(formData.action, SHOW) ? 'Ver Formula' : 'Configurar formula'}
              onClick={moveToFormulaEditor}
            />
          </div>

          {isEqual(formData.action, EDIT) && (
            <>
              {!isEmpty(formData?.data?.override?.id) && (
                <button className="button ghost s-mr-1" onClick={deleteOverride}>
                  Restablecer configuración estándar
                </button>
              )}

              <button className="button ghost blue" onClick={saveOverride}>
                Guardar Personalización
              </button>
            </>
          )}
        </div>
      )}

      {/* Procesos */}
      {!isEqual(formData.action, CREATE) && (
        <div className="s-mb-2">
          <hr />

          <h4 className="s-mb-2" ref={refSectionProcesses}>
            Procesos de cálculo
          </h4>

          <p className="small">
            {isEmpty(formData.processes) && !isEqual(formData.action, SHOW)
              ? 'Selecciona y agrega los procesos en donde debe ser incluido el concepto.'
              : 'Procesos en los que se incluye el concepto.'}
          </p>

          <Table
            heads={isEqual(formData.action, EDIT) ? ['Proceso', 'Acciones'] : ['Proceso']}
            className="table-template"
          >
            {Array.isArray(formData.processes) &&
              formData.processes.map(p => (
                <TableRow key={p.id}>
                  <div className="ed-grid gap-1 rows-gap">{p.description}</div>
                  {isEqual(formData.action, EDIT) ? (
                    <DeleteTextButton onClick={() => handleDeleteProcessType(p.code)} />
                  ) : null}
                </TableRow>
              ))}

            {isEqual(formData.action, EDIT) && !isEmpty(processTypeList) && (
              <TableRow classNameTd="s-bg-light-blue">
                <Select
                  isRequired
                  placeholder="Selecciona un tipo"
                  name="process_type_code"
                  value={processTypeCode}
                  onChange={e => {
                    setProcessTypeCode(e.target.value)
                  }}
                >
                  {processTypeList.map(item => (
                    <option key={item.id} value={item.code}>
                      {item.description}
                    </option>
                  ))}
                </Select>
                <button
                  className="button small"
                  disabled={isLoadingProcessType || isEmpty(processTypeCode)}
                  onClick={handleAddProcessType}
                >
                  Agregar
                </button>
              </TableRow>
            )}
          </Table>
        </div>
      )}

      {/* Bases */}
      {!isEqual(formData.action, CREATE) && (
        <div className="s-mb-2">
          <hr />

          <h4 className="s-mb-2">Bases</h4>

          <p className="small">
            {isEmpty(formData.processes) && !isEqual(formData.action, SHOW)
              ? 'Selecciona y agrega las bases en donde debe ser incluido el concepto.'
              : 'Bases en las que se incluye el concepto.'}
          </p>

          <Table
            heads={isEqual(formData.action, EDIT) ? ['Base', 'Usar', 'Acciones'] : ['Base', 'Usar']}
            className="table-template"
          >
            {Array.isArray(formData.bases) &&
              formData.bases.map(b => (
                <TableRow key={b.base_id}>
                  <Base base={b} />
                  <div className="ed-grid gap-1 rows-gap small">{baseConceptClassifications[b.classification]}</div>
                  {isEqual(formData.action, EDIT) ? (
                    <DeleteTextButton onClick={() => handleDeleteBase(b.base_id)} />
                  ) : null}
                </TableRow>
              ))}

            {isEqual(formData.action, EDIT) && !isEmpty(baseList) && (
              <TableRow classNameTd="s-bg-light-blue">
                <SelectBaseInput
                  isRequired
                  name="base_id"
                  id="base_id"
                  label=""
                  placeholder="Busca por nombre"
                  onSelect={setBaseID}
                  bases={baseList}
                />

                <Select
                  isRequired
                  placeholder="Selecciona un comportamiento"
                  name="base"
                  value={classificationBase}
                  onChange={e => {
                    setClassificationBase(e.target.value)
                  }}
                >
                  {listbaseConceptClassification.map(item => (
                    <option key={item.code} value={item.code}>
                      {item.description}
                    </option>
                  ))}
                </Select>
                <button
                  className="button small"
                  disabled={isLoadingBase || isEqual(baseID, 0) || isEmpty(classificationBase)}
                  onClick={handleAddBase}
                >
                  Agregar
                </button>
              </TableRow>
            )}
          </Table>
        </div>
      )}

      <div>
        {isEqual(formData.action, SHOW) && (
          <button className="button ghost" onClick={backListConcepts}>
            Atras
          </button>
        )}
      </div>
    </>
  )
}

export default ConceptSettingPanelForm
