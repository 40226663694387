import React, {useEffect, useState} from 'react'

import {PAYMENT_DEPOSITS, PAYMENT_EMPLOYEES} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import DropdownSelect from '../../Molecules/Dropsdown/DropdownSelect'
import ApprovalTable from '../../Molecules/Tables/ApprovalTable'
import EmployeesPaymentsTable from '../../Molecules/Tables/EmployeesPaymentsTable'
import {TableHeader} from '../../Molecules/Templates/TableHeader'
import {TableBody} from '../Containers/TableBody'

const OPTIONS = [
  {
    key: PAYMENT_EMPLOYEES,
    value: 'Empleados por pagar'
  },
  {
    key: PAYMENT_DEPOSITS,
    value: 'Depositos de pago'
  }
]

const PaymentsTable = ({isPaidFull, updateSummary}) => {
  const [activeOption, setActiveOption] = useState(isPaidFull ? PAYMENT_DEPOSITS : PAYMENT_EMPLOYEES)

  useEffect(() => {
    if (isPaidFull) setActiveOption(PAYMENT_DEPOSITS)
  }, [isPaidFull])

  return (
    <>
      <TableBody className="payments-table">
        {/* Cabecera de la tabla*/}
        <TableHeader>
          <div className="s-main-justify m-main-start s-cross-center">
            {isPaidFull ? (
              <h3 className="s-mb-0">Depositos de pago</h3>
            ) : (
              <DropdownSelect
                className="s-main-start s-column s-cross-start"
                selected={OPTIONS.find(option => isEqual(option.key, activeOption))?.value}
              >
                {OPTIONS.map(option => (
                  <DropdownItem
                    key={option.key}
                    text={option.value}
                    isActive={isEqual(option.key, activeOption)}
                    onClick={() => {
                      setActiveOption(option.key)
                    }}
                  />
                ))}
              </DropdownSelect>
            )}
          </div>
        </TableHeader>

        {/* Tabla para seleccionar a qué empleados se pagará  */}
        {!isPaidFull && isEqual(activeOption, PAYMENT_EMPLOYEES) ? (
          <EmployeesPaymentsTable setActiveOptionPage={setActiveOption} />
        ) : null}

        {/*  Tabla para ver los pagos por autorizar y autorizados */}
        {isEqual(activeOption, PAYMENT_DEPOSITS) && (
          <ApprovalTable setActiveOptionPage={setActiveOption} updateSummary={updateSummary} />
        )}
      </TableBody>
    </>
  )
}

export default PaymentsTable
