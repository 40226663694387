import PropTypes from 'prop-types'
import React from 'react'

import Icon from './Icon'

const TextIcon = ({text, icon, className, onClick}) => {
  return (
    <div onClick={onClick} className={`s-cross-center nowrap ${className}`}>
      <Icon svg={icon} className="flex-none s-mt-2px s-mr-4px" />
      <p className="s-mb-0">{text}</p>
    </div>
  )
}

TextIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

TextIcon.defaultProps = {
  onClick: () => {}
}

export default TextIcon
