import React from 'react'

import Animation from '../Templates/Animation'

const Fade = ({children, show, className, duration}) => {
  return (
    <Animation className={className} show={show} animationOut="fade-out" animationIn="fade-in" duration={duration}>
      {children}
    </Animation>
  )
}

export default Fade
