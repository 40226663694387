import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {isEqual} from '../../../helpers/utils'

const OAuthButton = ({icon, src, content, className}) => {
  return (
    <a
      className={`${className} s-cross-center s-main-center s-bg-body s-hover-bg-body-alt s-border-body cursor-pointer s-py-8px normal-radius decoration-none`}
      href={src}
    >
      <img src={icon} alt={icon} className={classNames({'s-mr-8px': !isEqual(content, '')})} />
      {!isEqual(content, '') && <span className="t4">{content}</span>}
    </a>
  )
}

OAuthButton.propTypes = {
  icon: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  content: PropTypes.string
}

OAuthButton.defaultProps = {
  content: ''
}

export default OAuthButton
