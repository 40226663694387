import React, {useContext, useRef, useState} from 'react'

import {TabsWrapperContext} from '../../../contexts/contexts'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isAnyEqual, isEqual} from '../../../helpers/utils'
import Breadcrumbs from '../../Atoms/Breadcrumbs'
import HelpMeButton from '../../Atoms/Buttons/HelpMeButton'
import Icon from '../../Atoms/Icon'
import Toolbar from '../../Molecules/Toolbar'
import FormulatorEditor from '../FormulatorEditor'

const ConceptSettingPanelFormula = ({formData, setFormData}) => {
  const {setTabActive} = useContext(TabsWrapperContext)
  const editorRef = useRef(null)
  const editorRefOverride = useRef(null)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const isDisabled =
    isEqual(formData.action, SHOW) ||
    (isEqual(formData.action, EDIT) &&
      ((formData.data.is_standard && !formData.data.is_override) || formData.data.is_blocked))

  const canShowEditor =
    isEqual(formData.action, CREATE) || (isAnyEqual(formData.action, [SHOW, EDIT]) && !formData.data.is_override)

  const canShowEditorOverride = isAnyEqual(formData.action, [SHOW, EDIT]) && formData.data.is_override

  const handleOnClickFullScreen = () => setIsFullScreen(!isFullScreen)

  const backForm = () => {
    if (isDisabled) {
      setTabActive(2)
      return
    }

    if (formData.data.is_override) {
      setFormData(state => ({
        ...state,
        data: {
          ...state.data,
          override: {
            ...state.data.override,
            formula: editorRefOverride.current.getValue()
          }
        }
      }))

      setTabActive(2)
      return
    }

    setFormData(state => ({
      ...state,
      data: {
        ...state.data,
        formula: editorRef.current.getValue()
      }
    }))
    setTabActive(2)
  }

  return (
    <>
      <Breadcrumbs>
        <p onClick={() => setTabActive(1)}>Conceptos</p>
        <p onClick={backForm}>
          {isEqual(formData.action, CREATE) ? 'Nuevo' : `${formData.data.code} ${formData.data.description}`}
        </p>
        <p>{formData.data.is_override ? 'Formula personalizada' : 'Formula'}</p>
      </Breadcrumbs>

      <div className={`container-full-screen container-formulator ${isFullScreen && 'expand'}`}>
        {canShowEditor && (
          <FormulatorEditor
            editorRef={editorRef}
            isDisabled={isDisabled}
            initialFormula={formData.data.formula}
            noveltySource={formData.data.classification}
            width="100%"
            height={isFullScreen ? '95%' : '600px'}
            theme="dracula"
            toolbar={
              <Toolbar>
                <HelpMeButton slug="como-funciona-el-formulador" className="s-mr-1" />
                <Icon
                  svg={isFullScreen ? 'arrows-pointing-in' : 'arrows-pointing-out'}
                  className="cursor-pointer"
                  onClick={handleOnClickFullScreen}
                />
              </Toolbar>
            }
          />
        )}

        {canShowEditorOverride && (
          <FormulatorEditor
            editorRef={editorRefOverride}
            isDisabled={isDisabled}
            initialFormula={formData.data.override.formula}
            noveltySource={formData.data.classification}
            width="100%"
            height={isFullScreen ? '95%' : '600px'}
            theme="dracula"
            toolbar={
              <Toolbar>
                <HelpMeButton slug="como-funciona-el-formulador" className="s-mr-1" />
                <Icon
                  svg={isFullScreen ? 'arrows-pointing-in' : 'arrows-pointing-out'}
                  className="cursor-pointer"
                  onClick={handleOnClickFullScreen}
                />
              </Toolbar>
            }
          />
        )}
      </div>
    </>
  )
}

export default ConceptSettingPanelFormula
