import React, {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {EmployeeContext, TabsWrapperContext} from '../../contexts/contexts'
import {FormDataContext} from '../../contexts/contexts'
import {isEmpty, isEqual, isNull, isUndefined} from '../../helpers/utils'
import routes from '../../config/routes'
import ValidatorForm from '../../helpers/validator'
import {notifyError} from '../../services/notification'
import {hasFoundContractType} from '../../helpers/socialsecurity'
import {selectors as employerSelectors} from '../../redux/ducks/employer'
import {selectors as dimensionSelectors} from '../../redux/ducks/dimension'
import {getCacheKeyGeneral, getLocalCache} from '../../helpers/cache'
import {createEmployee} from '../../services/employees'
import {joinRoute} from '../../helpers/routes'
import {uploaderAvatar} from '../../services/uploader'
import Employee from 'types/employee.type'
import {
  fieldNamesContract,
  fieldNamesEmployee,
  fieldNamesEmployeePayment,
  fieldNamesTax,
  rulesContract,
  rulesContractPeru,
  rulesEmployee,
  rulesEmployeePayment,
  rulesEmployeePaymentEmail,
  rulesTax
} from 'helpers/employee'
import {Contract, Tax, DimensionRelation} from 'types/contract.type'
import EmployeeRelationState from 'types/employee-relation.type'
import {ArrayContractTypeEntityType} from 'types/contract-type-entity-type.type'
import {ArraySalaryType} from 'types/salary-type.type'
import EmployeePayment from 'types/employee-payment.type'
import {COLOMBIA_CODE, PERU_CODE} from 'helpers/country'

enum Step {
  PersonalData = 1,
  Contract,
  SocialSecurity,
  Payment,
  Tax
}

type ErrorResponse = {
  hasErrors: boolean
  errors?: string
}

const NewEmployeeNavigationButton = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const dimensions = useSelector(dimensionSelectors.getList)

  const [loading, setLoading] = useState(false)
  const {formData} = useContext(FormDataContext)
  const {tabActive, setTabActive} = useContext(TabsWrapperContext)
  const {setFindEmployeeValue, setFindEmployeeID} = useContext(EmployeeContext)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractTypeEntityType =
    // @ts-ignore
    !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types
  // @ts-ignore
  const salaryTypes = !isNull(cacheGeneral) && cacheGeneral.data?.salaries_types

  const handlePersonalData = (): void => {
    const {hasErrors, errors} = validatePersonalData(formData)
    if (hasErrors) {
      dispatch(notifyError(errors))
      return
    }

    setTabActive(tabActive + 1)
  }

  const handleContractData = (): void => {
    const {hasErrors, errors} = validateContractData(formData, employerSession.code_iso_3, dimensions)
    if (hasErrors) {
      dispatch(notifyError(errors))
      return
    }

    setTabActive(tabActive + 1)
  }

  const handleSocialSecurityData = (): void => {
    const {hasErrors, errors} = validateSocialSecurityData(formData, contractTypeEntityType, employerSession.code_iso_3)
    if (hasErrors) {
      dispatch(notifyError(errors))
      return
    }

    setTabActive(tabActive + 1)
  }

  const handlePaymentData = (): void => {
    const {hasErrors, errors} = validatePaymentData(formData)
    if (hasErrors) {
      dispatch(notifyError(errors))
      return
    }

    setTabActive(tabActive + 1)
  }

  const isValidTaxData = (): boolean => {
    const {hasErrors, errors} = validateTaxData(formData)
    if (!hasErrors) return true

    dispatch(notifyError(errors))
    return false
  }

  const handleNextStep = (): void => {
    window.scrollTo({top: 0, behavior: 'smooth'})

    switch (tabActive) {
      case Step.PersonalData:
        handlePersonalData()
        break

      case Step.Contract:
        handleContractData()
        break

      case Step.SocialSecurity:
        handleSocialSecurityData()
        break

      case Step.Payment:
        handlePaymentData()
        break

      default:
        break
    }
  }

  // Not implemented for the MVP
  // const handleSkipStep = () => {
  //   window.scrollTo({top: 0, behavior: 'smooth'})

  //   if (isEqual(tabActive, Step.Payment)) resetPaymentData()

  //   setTabActive(tabActive + 1)
  // }

  // const resetPaymentData = () => {
  //   setFormData(state => ({
  //     ...state,
  //     payment_method: '',
  //     bank_id: 0,
  //     account_type: 'CUENTA DE AHORROS',
  //     account_number: '',
  //   }))
  // }

  const handleCancel = () => {
    history.push('/' + joinRoute(routes.employees.base))
  }

  const handleCreate = () => {
    if (!isValidTaxData()) return

    let payload = buildPayload(formData, contractTypeEntityType, salaryTypes, employerSession.code_iso_3)

    setLoading(true)

    // upload employee picture
    if (!isNull(formData.object_picture)) {
      uploaderAvatar(
        formData.object_picture,
        response => {
          payload.employee.picture = response[0].name_file
          payload.employee.thumbnail = response[1].name_file

          createNewEmployee(payload)
        },
        error => {
          dispatch(notifyError(error))
          setLoading(false)
        }
      )

      return
    }

    createNewEmployee(payload)
  }

  const createNewEmployee = payload => {
    createEmployee(
      payload,
      response => {
        setFindEmployeeValue(response.employee.identification_number)
        setFindEmployeeID(response.employee.id)
        setLoading(false)

        history.push('/' + joinRoute(routes.employees.base, routes.employees.query, response.employee.hash))
      },
      error => {
        dispatch(notifyError(error))
        setLoading(false)
      }
    )
  }

  return (
    <div className="s-column s-cross-center">
      <div className="s-column m-row m-main-center s-flex-gap-16px s-100">
        <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={handleCancel}>
          Cancelar
        </button>

        {/* Hidden Button for MVP */}
        {/* {isAnyEqual(tabActive, [Step.Payment]) && (
          <button
            type="button"
            className="button ghost cancel s-order-2"
            onClick={handleSkipStep}
          >
            Omitir este paso
          </button>
        )} */}

        {tabActive < Step.Tax && (
          <button type="button" className="button ghost s-order-1 m-order-3" onClick={handleNextStep}>
            Siguiente
          </button>
        )}

        {isEqual(tabActive, Step.Tax) && (
          <button type="button" className="button ghost s-order-1 m-order-3" onClick={handleCreate} disabled={loading}>
            {loading ? 'Loading...' : 'Crear'}
          </button>
        )}
      </div>
    </div>
  )
}

const validatePersonalData = (data: Employee): ErrorResponse => {
  const validator = new ValidatorForm(data, rulesEmployee)
  validator.setAttributeNames(fieldNamesEmployee)

  if (validator.fails()) {
    return {hasErrors: true, errors: validator.errors()}
  }

  return {hasErrors: false, errors: ''}
}

const validateContractData = (data: Contract, countryCode: string, dimensions: DimensionRelation[]): ErrorResponse => {
  const validator = new ValidatorForm(data, rulesContract)
  validator.setAttributeNames(fieldNamesContract)

  let globalErr: string[] = []
  if (validator.fails()) {
    globalErr.push(validator.errors())
  }

  // Validate additional fields Peru
  if (isEqual(countryCode, PERU_CODE)) {
    const validatorPeru = new ValidatorForm(data, rulesContractPeru)
    validatorPeru.setAttributeNames(fieldNamesContract)

    if (validatorPeru.fails()) {
      globalErr.push(validatorPeru.errors())
    }
  }

  // additional validations
  if (data.is_contract_extensible && isNull(data.expiration_date)) {
    globalErr.push('El campo Finalización de contrato es requerido para el tipo de contrato.')
  }

  // validate dimensions
  if (Array.isArray(dimensions)) {
    dimensions.forEach(record => {
      if (
        record.dimension.is_mandatory &&
        (isUndefined(data.dimensions[record.dimension.code]) || isEmpty(data.dimensions[record.dimension.code]))
      ) {
        globalErr.push(`El campo ${record.dimension.description} es requerido.`)
      }
    })
  }

  return {hasErrors: globalErr.length > 0, errors: globalErr.join('\n')}
}

const validateSocialSecurityData = (
  formData: EmployeeRelationState,
  contractTypeEntityType: ArrayContractTypeEntityType,
  countryCode: string
): ErrorResponse => {
  let response: ErrorResponse = {hasErrors: false, errors: ''}

  // validate workspace
  if (
    hasFoundContractType('ARL', formData.contract_type_id, contractTypeEntityType) &&
    isEqual(formData.workplace_id, 0)
  ) {
    response.hasErrors = true
    response.errors += '\nEl campo Centro de trabajo ARL es requerido para el tipo de contrato.'
  }

  // validate pensioner type for colombia
  if (
    isEqual(countryCode, COLOMBIA_CODE) &&
    formData.is_pensioner &&
    isEqual(formData.mapping.pila.subcontributor, 0)
  ) {
    response.hasErrors = true
    response.errors += '\nEl campo Tipo Pensionado es requerido porque marcaste el empleado como pensionado.'
  }

  // Validate additional fields Peru
  if (isEqual(countryCode, PERU_CODE)) {
    if (formData.afp_commission_type === '') {
      response.hasErrors = true
      response.errors += '\nEl campo Tipo de Comisión AFP es requerido para el tipo de contrato.'
    }
  }

  // validate entities
  formData.entities.forEach(e => {
    if (!e.is_show) return
    if (!e.is_required) return
    if (e.social_security_entity_id !== 0) return

    response.hasErrors = true
    response.errors += `\nEl campo ${e.description} es requerido para el tipo de contrato.`
  })

  return response
}

const validatePaymentData = (data: EmployeePayment): ErrorResponse => {
  if (!isEqual(data.payment_method, 'TRANSFERENCIA BANCARIA')) {
    return {hasErrors: false, errors: ''}
  }

  let rules = {...rulesEmployeePayment}

  if (isEqual(data.account_type, 'EMAIL')) {
    rules = {...rulesEmployeePaymentEmail}
  }

  const validator = new ValidatorForm(data, rules)
  validator.setAttributeNames(fieldNamesEmployeePayment)

  if (validator.fails()) {
    return {hasErrors: true, errors: validator.errors()}
  }

  return {hasErrors: false, errors: ''}
}

const validateTaxData = (data: Tax): ErrorResponse => {
  const validator = new ValidatorForm(data, rulesTax)
  validator.setAttributeNames(fieldNamesTax)

  if (validator.fails()) {
    return {hasErrors: true, errors: validator.errors()}
  }

  return {hasErrors: false, errors: ''}
}

const buildPayload = (
  formData: EmployeeRelationState,
  contractTypeEntityType: ArrayContractTypeEntityType,
  salaryTypes: ArraySalaryType,
  countryCode: number
) => {
  let payload = {
    employee: {
      identification_type_id: formData.identification_type_id,
      identification_number: formData.identification_number,
      first_name: formData.first_name,
      middle_name: formData.middle_name,
      last_name: formData.last_name,
      surname: formData.surname,
      email: formData.email,
      address: formData.address,
      phone: formData.phone,
      mobile: formData.mobile,
      gender: formData.gender,
      birthdate: formData.birthdate,
      birthplace: formData.birthplace,
      marital_status: formData.marital_status,
      picture: '',
      thumbnail: ''
    },
    contract: {
      contract_type_id: formData.contract_type_id,
      place_labor_municipality_id: formData.place_labor_municipality_id,
      hire_date: formData.hire_date,
      expiration_date: formData.expiration_date,
      trial_period_date: formData.trial_period_date,
      pay_frequency_id: formData.pay_frequency_id,
      mandatory_rest_day: 0,
      additional_rest_day: 0,
      alternate_code: formData.alternate_code,
      month_hours: formData.month_hours,
      workplace_id: formData.workplace_id,
      branch_office_id: formData.branch_office_id,
      is_foreigner_without_pension: formData.is_foreigner_without_pension,
      is_colombian_living_abroad: formData.is_colombian_living_abroad,
      has_colombian_living_abroad_pay_health: formData.has_colombian_living_abroad_pay_health,
      payment_method: formData.payment_method,
      method_taxes: Number(formData.method_taxes),
      rate_taxes: formData.rate_taxes,
      tax_relief_living_place: Number(formData.tax_relief_living_place),
      tax_relief_health: Number(formData.tax_relief_health),
      is_tax_dependents: formData.is_tax_dependents,
      job_id: formData.job_id,
      notes: formData.notes,
      organizational_struct_id: formData.organizational_struct_id,
      is_pensioner: formData.is_pensioner,
      has_family_allowance: formData.has_family_allowance,
      afp_commission_type: formData.afp_commission_type,
      mapping: {
        pila: {
          contributor: 0,
          subcontributor: formData.mapping.pila.subcontributor
        }
      },
      dimensions: formData.dimensions
    },
    salary: {
      salary_type_id: formData.salary_type_id,
      value: Number(formData.salary)
    },
    entities: [...formData.entities.filter(e => e.is_show && e.social_security_entity_id !== 0)]
  }

  // Add the field "additional_rest_day" if field "is_labor_saturday" = false, this mean
  // that the employee not working the saturday and that is his additional rest day
  if (!formData.is_labor_saturday) payload.contract.additional_rest_day = 6

  // Add bank account data if the pay method is "TRANSFERENCIA BANCARIA"
  if (isEqual(formData.payment_method, 'TRANSFERENCIA BANCARIA')) {
    // @ts-ignore
    payload.bank_account_history = {
      bank_id: formData.bank_id,
      account_type: formData.account_type,
      account_number: formData.account_number
    }
  }

  if (formData.is_tax_dependents) {
    // @ts-ignore
    payload.dependent = {
      identification_type_id: formData.dependent_identification_type_id,
      identification_number: formData.dependent_identification_number,
      first_name: formData.dependent_first_name,
      middle_name: formData.dependent_middle_name,
      last_name: formData.dependent_last_name,
      surname: formData.dependent_surname,
      relationship_type: formData.dependent_relationship_type
    }
  }

  if (!isEqual(countryCode, COLOMBIA_CODE)) {
    payload.contract.mapping = null
  }

  return payload
}

export default NewEmployeeNavigationButton
