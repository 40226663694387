import React from 'react'

import DatePickerInput from '../../../../components/Molecules/Pickers/DatePickerInput'
import useDateParameterForm from '../../hooks/form/useDateParameterForm'
import {useParameterValuesContext} from '../../providers/ParameterValues'
import EditParameterFormDates from './EditParameterFormDates'
import EditParameterFormFooter from './EditParameterFormFooter'

const DateParameterForm = () => {
  const {parameterValues} = useParameterValuesContext()
  const lastValue = parameterValues[parameterValues.length - 1]
  const {is_fixed} = lastValue

  const {setFormData, formData, handleSubmit} = useDateParameterForm({
    parameter: lastValue
  })

  return (
    <form onSubmit={handleSubmit}>
      <EditParameterFormDates formData={formData} setFormData={setFormData} isDisabled={is_fixed} />
      <div className="ed-grid s-grid-2 s-mb-2">
        <DatePickerInput
          label="Valor del parámetro"
          id="endDate"
          className="s-mb-4"
          value={formData.value}
          onChange={date => setFormData(state => ({...state, value: date}))}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isRequired
        />
      </div>
      <EditParameterFormFooter />
    </form>
  )
}

export default DateParameterForm
