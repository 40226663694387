import React from 'react'

import Badge from '../../Atoms/Badge'

const ProcessPercentageItem = ({type, status, percetage, description, onClick}) => {
  return (
    <article
      className={`process-percentage-item s-pxy-8px hover-change s-mb-0 cursor-pointer normal-radius ${status.toLowerCase()}`}
      onClick={onClick}
    >
      <h4 className="s-color-text s-mb-8px">{description}</h4>
      <div className="s-cross-center s-mb-8px">
        <p className="small s-color-light-text s-mb-0 s-mr-8px">{type}</p>
        <Badge text={status} />
      </div>
      <div className="s-cross-center nowrap">
        <div style={{'--percentage': percetage}} className="progress-bar s-100 s-h-8px small-radius s-mr-12px" />
        <p className="s-mb-0 smaller s-color-light-text">{percetage}</p>
      </div>
    </article>
  )
}

export default ProcessPercentageItem
