import PropTypes from 'prop-types'
import React from 'react'

import HeroIcons from '../../static/icons/HeroIcons'

const IconBackground = React.memo(({className, svg, size, bgColor, color, onClick, title, ...rest}) => {
  const icon = HeroIcons[svg] || HeroIcons.default

  return (
    icon && (
      <div className={`${bgColor} s-pxy-4px s-cross-center s-main-center small-radius ${className}`} title={title}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={size}
          height={size}
          fill={color}
          onClick={onClick}
          {...rest}
        >
          {icon}
        </svg>
      </div>
    )
  )
})

IconBackground.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  svg: PropTypes.string.isRequired
}

IconBackground.defaultProps = {
  size: '1em',
  className: '',
  title: '',
  color: 'white',
  bgColor: 's-bg-blue-900',
  onClick: () => {}
}

IconBackground.displayName = 'IconBackground'

export default IconBackground
