export const BOOLEAN_PARAMETER_FORM_RULES = {
  startDate: 'date|required',
  endDate: 'date',
  value: 'boolean|required'
}

export const DATE_PARAMETER_FORM_RULES = {
  startDate: 'date|required',
  endDate: 'date',
  value: 'date|required'
}

export const STRING_PARAMETER_FORM_RULES = {
  startDate: 'date|required',
  endDate: 'date',
  value: 'string|required'
}

export const ARRAY_PARAMETER_FORM_RULES = {
  startDate: 'date|required',
  endDate: 'date',
  value: 'array|required'
}

export const VALID_NUMBER_REGEX = /^-?\d+$/
export const VALID_STRING_REGEX = /^[a-zA-Z]+$/
