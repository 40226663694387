import React from 'react'

import {mediaToM} from '../../../helpers/media-queries'
import {useMedia} from '../../../hooks/useMedia'
import Icon from '../Icon'

const ProcessAvatar = ({icon, color, className}) => {
  const toM = useMedia(mediaToM)

  return (
    <div className={`user-avatar ${color} s-cross-center s-main-center ${className}`}>
      <Icon svg={icon} color="var(--white-color)" size={toM ? '16' : '24'} />
    </div>
  )
}

export default ProcessAvatar
