import React from 'react'

import ActionTextButton from '../Templates/ActionTextButton'

const DocumentTextButton = ({text, color, className, isDisable, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-${color} ${color} ${className}`}
      onClick={onClick}
      icon="document"
      text={text}
      isDisable={isDisable}
    />
  )
}

export default DocumentTextButton
