import PropTypes from 'prop-types'
import React from 'react'
import ReactTimeAgo from 'react-time-ago'

import {isEmpty, isNull} from '../../helpers/utils'
import PreviewMarkdown from './PreviewMarkdown'

const ErrorCard = ({type, description, createdAt}) => {
  const markdown = `__${type}__  
  ${description.replace(/\n/g, '  \n')}` /* Reemplaza \n con saltos de línea markdown */

  return (
    <article className="n-container s-mb-0 s-column nowrap">
      <PreviewMarkdown content={markdown} />
      <p className="s-mb-0 small s-color-light-text">
        {!isNull(createdAt) && !isEmpty(createdAt) && <ReactTimeAgo date={createdAt} />}
      </p>
    </article>
  )
}

ErrorCard.propTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string
}

ErrorCard.defaultProps = {
  type: '',
  description: '',
  createdAt: ''
}

export default ErrorCard
