import React, {useContext} from 'react'

import {FloatContainerContext} from '../../contexts/contexts'
import {isEmpty, isEqual} from '../../helpers/utils'
import Employee from './Templates/Employee'

const FloatEmployeeList = ({employees = [], onSelectEmployee = () => {}, activeIndex = 0}) => {
  const {setActive} = useContext(FloatContainerContext)

  const handleOnSelect = index => {
    setActive(false)
    onSelectEmployee(index)
  }

  return (
    <div className="float-container s-column s-flex-gap-12px size-m">
      {isEmpty(employees) ? (
        <div className="s-cross-center smaller s-color-light-text">Escribe para buscar</div>
      ) : (
        Array.isArray(employees) &&
        employees.map((employee, index) => (
          <div
            className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
              isEqual(index, activeIndex) && 'is-active'
            }`}
            key={employee?.contract_id || `INDEX-${index}`}
          >
            <Employee employee={employee} onSelectEmployee={employee => handleOnSelect(index)} />
          </div>
        ))
      )}
    </div>
  )
}

export default FloatEmployeeList
