import React, {forwardRef, useState} from 'react'

import {isEqual} from '../../../helpers/utils'
import RadioButton from '../../Atoms/Forms/RadioButton'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const initialState = {
  consolidated_key: 'prima-balance'
}

const ConsolidatedReportModal = forwardRef(({onGenerate = () => {}}, ref) => {
  const [formData, setFormData] = useState(initialState)

  const handleGenerate = () => {
    onGenerate(formData)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Consolidados</h3>
      <div className="ed-grid s-grid-1 rows-gap s-mb-4">
        <RadioButtonsWrapper label="Tipo">
          <RadioButton
            name="consolidated_key"
            label="Prima de Servicios"
            value="prima-balance"
            onChange={updateRadioButton}
            checked={isEqual(formData.consolidated_key, 'prima-balance')}
          />

          <RadioButton
            name="consolidated_key"
            label="Cesantías"
            value="cesantias-balance"
            onChange={updateRadioButton}
            checked={isEqual(formData.consolidated_key, 'cesantias-balance')}
          />

          <RadioButton
            name="consolidated_key"
            label="Vacaciones"
            value="vacations-balance"
            onChange={updateRadioButton}
            checked={isEqual(formData.consolidated_key, 'vacations-balance')}
          />
        </RadioButtonsWrapper>
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

ConsolidatedReportModal.displayName = 'ConsolidatedReportModal'

export default ConsolidatedReportModal
