import Select from 'components/Atoms/Forms/Select'
import TextArea from 'components/Atoms/Forms/TextArea'
import FormInput from 'components/Atoms/Templates/FormInput'
import DatePickerInput from 'components/Molecules/Pickers/DatePickerInput'
import {readBackendDate} from 'helpers/dates'
import {getFormatNumber} from 'helpers/format-number'
import {CREATE, EDIT} from 'helpers/payroll'
import {isEmpty, isEqual, isNull, isUndefined} from 'helpers/utils'
import ValidatorForm from 'helpers/validator'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {notifyError, notifySuccessful} from 'services/notification'
import {createSalary, updateSalary} from 'services/salaries'

const formFields = {
  contract_id: 'ID del contrato',
  salary_type_id: 'Tipo de Salario',
  value: 'Salario',
  begins_at: 'Fecha Inicial',
  note: 'Notas u observaciones'
}

const initialState = {
  id: 0,
  contract_id: 0,
  salary_type_id: 0,
  value: '',
  begins_at: null,
  note: ''
}

const rules = {
  contract_id: 'integer|required|not_in:0',
  salary_type_id: 'integer|required|not_in:0',
  value: 'numeric|required|min:1',
  begins_at: 'date|required',
  note: 'string'
}

const SalaryChangeForm = ({
  salaryTypes = null,
  hireDate = null,
  terminationDate = null,
  onCancel = () => {},
  onSuccessful = () => {},
  action = null
}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSalaryWarning, setHasSalaryWarning] = useState(false)

  useEffect(() => {
    if (isUndefined(action) || isNull(action) || isNull(action.type)) return

    if (isEqual(action.type, CREATE)) {
      setFormData({...initialState, contract_id: action.contractID})
      return
    }

    // action EDIT
    setFormData({...action.current, begins_at: readBackendDate(action.current.begins_at)})
  }, [action])

  const handleOnChangeText = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  const handleOnChangeNumber = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: Number(value)
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date
    }))
  }

  const handleWarningSalary = () => {
    if (isNull(action.previous) || isEmpty(formData.value)) return

    setHasSalaryWarning(formData.value < action.previous.value)
  }

  const handleOnClick = () => {
    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    // we always set the endsAt as Null because backend not required this field, if we send it
    // the backend response with error
    formData.ends_at = null

    setIsLoading(true)

    if (isEqual(action.type, CREATE)) {
      handleCreate()
      return
    }

    handleEdit()
  }

  const handleCreate = () => {
    createSalary(
      formData,
      response => {
        onSuccessful(response)
        dispatch(notifySuccessful('El salario ha sido creado correctamente 🎉'))
        setIsLoading(false)
        onCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  const handleEdit = () => {
    updateSalary(
      formData.id,
      formData,
      response => {
        onSuccessful(response)
        dispatch(notifySuccessful('El salario ha sido actualizado correctamente 🎉'))
        setIsLoading(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <form noValidate className="s-mb-2 ed-grid rows-gap form-grid">
      <div className="ed-grid m-grid-3 rows-gap">
        <Select
          isRequired
          label={formFields.salary_type_id}
          name="salary_type_id"
          id="salary_type_id"
          placeholder
          value={formData.salary_type_id}
          onChange={handleOnChangeNumber}
        >
          {Array.isArray(salaryTypes) &&
            salaryTypes.map(s => (
              <option key={s.id} value={s.id}>
                {s.description}
              </option>
            ))}
        </Select>

        <FormInput
          isRequired
          label={formFields.value}
          name="value"
          id="value"
          placeholder="0,000,000"
          align="right"
          autoComplete="nope"
          value={formData.value}
          onChange={handleOnChangeNumber}
          onBlur={handleWarningSalary}
        />

        <DatePickerInput
          isRequired
          label={formFields.begins_at}
          name="begins_at"
          id="begins_at"
          value={formData.begins_at}
          onChange={handleOnChangeBeginsAt}
          minDate={hireDate}
          maxDate={terminationDate}
          disabled={isEqual(action.type, EDIT) && isEqual(formData?.begins_at?.getTime(), hireDate?.getTime())}
        />

        <TextArea
          label={formFields.note}
          name="note"
          id="note"
          placeholder="Escribe una nota"
          value={formData.note}
          onChange={handleOnChangeText}
          align="left"
          className="m-cols-3"
        />
      </div>

      <div className="ed-grid s-grid-1 rows-gap s-center">
        {hasSalaryWarning ? (
          <p className="s-center s-bg-yellow s-py-1 s-px-2 normal-radius s-mb-0">
            <span role="img" aria-label="warning">
              ⚠️
            </span>{' '}
            ¡Cuidado! el nuevo salario es inferior al {isEqual(action.type, CREATE) ? 'actual ' : 'anterior '}
            {action?.previous?.value && getFormatNumber(action.previous.value)}.
          </p>
        ) : (
          <p className="smaller s-mb-0 s-py-1">
            Los campos con <span className="s-color-blue">*</span> son obligatorios
          </p>
        )}

        <div className="buttons-container">
          <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={onCancel}>
            Cancelar
          </button>
          <button
            type="button"
            className="button ghost s-order-1 m-order-3"
            onClick={handleOnClick}
            disabled={isLoading}
          >
            {isLoading
              ? isEqual(action.type, CREATE)
                ? 'Creando...'
                : 'Editando...'
              : isEqual(action.type, CREATE)
                ? 'Crear Salario'
                : 'Editar Salario'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default SalaryChangeForm
