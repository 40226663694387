import React from 'react'
import {useSelector} from 'react-redux'

// import Badge from '../../Atoms/Badge'
// import {getUserType} from '../../../helpers/get-user-type'
import {selectors as userSelectors} from '../../../redux/ducks/user'
import UserAvatar from '../../Atoms/Templates/UserAvatar'
import TextIcon from '../../Atoms/TextIcon'
import Container from '../../Wrappers/Container'

const ProfileHeader = () => {
  const user = useSelector(userSelectors.get)
  // const userType = getUserType('OWNER')

  // const cantLeaveCompanyModal = useRef()

  // const handleOpenCantLeaveCompanyModal = () => {
  //   cantLeaveCompanyModal.current.openModal()
  // }

  return (
    <>
      <Container className="s-main-justify s-cross-center s-flex-gap-24px">
        <div className="flex nowrap">
          <UserAvatar size="bigger" className="s-mr-16px" picture={user.picture} />
          <div>
            <h1 className="t2 s-mb-4px">{user.name}</h1>
            <div className="s-cross-center">
              <TextIcon className="s-cross-center s-mr-16px" icon="mail" text={user.email} />
              {/* <Badge text={userType.rol} color={userType.color} /> */}
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className="button delete"
          onClick={handleOpenCantLeaveCompanyModal}
        >
          Abandona empresa
        </button> */}
      </Container>
      {/* Modal para cuando el usuario quiere abandonar la empresa */}
      {/* <LeaveCompanyModal /> */}

      {/* Modal para cuando el usario no puede abandonar la empresa */}
      {/* <CantLeaveCompanyModal ref={cantLeaveCompanyModal} /> */}
    </>
  )
}

export default ProfileHeader
