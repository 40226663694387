import React from 'react'
import {Link} from 'react-router-dom'

import routes from '../../../config/routes'
import WarningImg from '../../../static/images/stories/warning.svg'
import Container from '../../Wrappers/Container'

const ElectronicPayrollNotEnableEmptyState = () => {
  return (
    <Container className="s-column s-center s-cross-center s-main-center">
      <div className="l-block" />
      <img className="s-mb-2" src={WarningImg} alt="Nómina electrónica" width="200px" height="200px" />
      <h3>!Vaya! Aún no estas habilitado</h3>
      <div className="s-color-light-text">
        <p>
          Habilita tu empresa{' '}
          <Link to={`${routes.settings.base}${routes.settings.payrollElectronic}`}>
            <span>Aquí</span>
          </Link>{' '}
          para que puedas transmitir tu nómina electrónica.
        </p>
      </div>
    </Container>
  )
}

export default ElectronicPayrollNotEnableEmptyState
