import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const FormInput = props => {
  const {label, type, placeholder, id, name, className, inputClasses, isRequired, isDisabled, align, ...rest} = props

  return (
    <div className={`form-item ${className}`}>
      <input
        className={classNames(inputClasses, `input s-${align}`)}
        disabled={isDisabled}
        required={isRequired}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

FormInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  align: PropTypes.string
}

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
  id: '',
  className: '',
  isRequired: false,
  isDisabled: false,
  align: 'left'
}

export default FormInput
