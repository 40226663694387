import {isEmpty} from '../../../helpers/utils'

export const getShortedParameterDescription = description => {
  if (isEmpty(description)) return ''

  if (description.length > 400) {
    return description.slice(0, 400) + '...'
  } else {
    return description
  }
}
