import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {STATUS_HISTORY, STATUS_PRE_PAYROLL} from '../../../helpers/payroll'
import {getPeriodRange} from '../../../helpers/period'
import {isAnyEqual, isEmpty, isEqual, len} from '../../../helpers/utils'
import useFilterAndSortTable from '../../../hooks/useFilterAndSortTable'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import Filter, {ALL_OPTION} from '../../Molecules/Filter'
import ProcessItem from '../../Molecules/Items/ProcessItem'
import NewProcess from '../../Molecules/NewProcess'
import SearchInputButton from '../../Molecules/SearchInputButton'
import Container from '../../Wrappers/Container'
import ButtonContainerTableHeader from '../Containers/ButtonContainerTableHeader'
import LastProcessEmptyState from '../Emptys/LastProcessEmptyState'
import NewProcessModal from '../Modals/NewProcessModal'

const FILTER_TYPE = 'TYPE'
const FILTER_PERIOD = 'PERIOD'
const FILTER_STATUS = 'STATUS'
const FILTER_IS_PAID_FULL = 'IS_PAID_FULL'

const DEFAULT_SORT_OPTION = {key: 'id', value: 'Fecha creación'}
const DEFAULT_SEARCH_COLS = ['hash', 'description', 'stage']

const INITIAL_STATE_NEW_PROCESS = {type: {}, periods: [], processes: []}

const ProcessPanel = ({periods, processes, isActiveMonth, setDashboardData}) => {
  const modalNewProcess = useRef()
  const employer = useSelector(employerSelectors.getSession)
  const config = useSelector(configProcessTypeSelectors.getAll)
  const configObj = useSelector(configProcessTypeSelectors.getObj)

  const [activeNewProcess, setActiveNewProcess] = useState(INITIAL_STATE_NEW_PROCESS)

  const {data, appendFilter, searchValue, setSearchValue} = useFilterAndSortTable(
    processes,
    DEFAULT_SORT_OPTION,
    DEFAULT_SEARCH_COLS
  )

  const handleOpenNewProcessModal = (processType, processesByType) => {
    setActiveNewProcess({type: processType, processes: processesByType, periods: periods})

    setTimeout(() => {
      modalNewProcess.current.openModal()
    }, 100)
  }

  const handleChangeFilterPeriod = option => {
    if (isEqual(ALL_OPTION.key, option.key)) {
      appendFilter({...ALL_OPTION, filterName: FILTER_PERIOD})
      return
    }

    appendFilter({
      filterName: FILTER_PERIOD,
      key: 'pay_period_id',
      value: Number(option.key)
    })
  }

  const handleChangeFilterType = option => {
    if (isEqual(ALL_OPTION.key, option.key)) {
      appendFilter({...ALL_OPTION, filterName: FILTER_TYPE})
      return
    }

    appendFilter({
      filterName: FILTER_TYPE,
      key: 'process_type_code',
      value: option.key
    })
  }

  const handleChangeFilterStatus = option => {
    if (isEqual(ALL_OPTION.key, option.key)) {
      appendFilter({...ALL_OPTION, filterName: FILTER_STATUS})
      return
    }

    appendFilter({
      filterName: FILTER_STATUS,
      key: 'status',
      value: option.key
    })
  }

  const handleChangeFilterIsPaidFull = option => {
    if (isEqual(ALL_OPTION.key, option.key)) {
      appendFilter({...ALL_OPTION, filterName: FILTER_IS_PAID_FULL})
      return
    }

    appendFilter({
      filterName: FILTER_IS_PAID_FULL,
      key: 'is_paid_full',
      value: Boolean(option.key)
    })
  }

  const handleSearch = e => {
    const value = e.target.value
    setSearchValue(value)
  }

  return (
    <Container>
      <div className="s-cross-center s-main-justify s-mb-3 s-h-32px">
        <h3 className="">Procesos</h3>

        <ButtonContainerTableHeader>
          <SearchInputButton id="payroll-search-a" value={searchValue} onChange={handleSearch} />
          {isActiveMonth && (
            <NewProcess className="s-mr-1" processes={data} config={config} onSelect={handleOpenNewProcessModal} />
          )}
        </ButtonContainerTableHeader>
      </div>
      <div>
        <ButtonContainerTableHeader className="s-main-end">
          {Array.isArray(periods) && len(periods) > 1 && (
            <Filter
              label="Periodo"
              options={buildFilterPeriods(periods)}
              onSelect={handleChangeFilterPeriod}
              showContent
            />
          )}

          <Filter label="Estado" options={buildFilterStatus()} onSelect={handleChangeFilterStatus} showContent />

          <Filter
            label="¿Pagado?"
            options={buildFilterIsPaidFull()}
            onSelect={handleChangeFilterIsPaidFull}
            showContent
          />

          <Filter
            label="Tipo"
            options={buildFilterTypes(config, employer?.code_iso_3)}
            onSelect={handleChangeFilterType}
            showContent
          />
        </ButtonContainerTableHeader>
      </div>

      <div className="s-pxy-1">
        {isEmpty(data) ? (
          <LastProcessEmptyState
            subtitle={
              <>
                ¿Qué tal si empiezas ahora?, da click en el botón <span className="s-color-title">+ Nuevo</span>
              </>
            }
            className="s-pt-4"
          />
        ) : (
          Array.isArray(data) &&
          data.map(process => (
            <ProcessItem
              key={process.hash}
              process={process}
              processType={configObj[process.process_type_code]}
              setDashboardData={setDashboardData}
            />
          ))
        )}
      </div>

      {/* Modal para agregar un proceso */}
      <NewProcessModal ref={modalNewProcess} data={activeNewProcess} />
    </Container>
  )
}

function buildFilterTypes(config, employerCountryCode) {
  let filters = []

  if (Array.isArray(config)) {
    config.forEach(p => {
      if (!Array.isArray(p?.config?.countries)) return

      if (!isAnyEqual(employerCountryCode, p?.config?.countries)) {
        return
      }

      filters.push({key: p.code, value: p.description})
    })
  }

  return filters
}

function buildFilterPeriods(periods) {
  let filters = []

  periods.forEach(p =>
    filters.push({
      key: p.pay_period.id,
      value: `Periodo: ${getPeriodRange(p.pay_period?.begins_at, p.pay_period?.ends_at)}`
    })
  )

  return filters
}

function buildFilterStatus() {
  return [
    {key: STATUS_PRE_PAYROLL, value: 'Abierto'},
    {key: STATUS_HISTORY, value: 'Cerrado'}
  ]
}

function buildFilterIsPaidFull() {
  return [
    {key: true, value: 'Si'},
    {key: false, value: 'No'}
  ]
}

export default ProcessPanel
