import {RequestWithEmployerAuthorization} from '../helpers/request'
import {isEqual} from '../helpers/utils'

const RESOURCE = '/pila'

export const generatePILA = (year, month, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(`${RESOURCE}/generate`, {year, month}, callback, callbackError)
}

export const getPILASummary = (year, month, callback) => {
  const request = new RequestWithEmployerAuthorization()

  request.getWithResponseObject(
    `${RESOURCE}/summary?year=${year}&month=${month}`,
    response => {
      if (isEqual(response.status, 204)) {
        callback(null)
        return
      }

      callback(response.data)
    },
    error => {
      console.error('summary pila:', error)
    }
  )
}

export const getPILAFile = (year, month, format, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURCE}?year=${year}&month=${month}&format=${format}`, callback, callbackErr)
}
