import {isEqual, isNull} from 'helpers/utils'
import PropTypes from 'prop-types'
import React, {useContext} from 'react'

import {TableTemplateContext} from '../../contexts/contexts'
import {mediaFromM} from '../../helpers/media-queries'
import {useMedia} from '../../hooks/useMedia'

const TableRow = props => {
  const {children, isActive, classNameTd} = props
  const fromM = useMedia(mediaFromM)
  const {heads} = useContext(TableTemplateContext)

  // if the children only has one element we return this
  if (!Array.isArray(children)) {
    return (
      <tr className={isActive ? 'active' : undefined}>
        {fromM ? (
          <td className={classNameTd}>{children}</td>
        ) : (
          <td className={classNameTd}>
            <div className="s-column s-flex-gap-12px">
              <div className="flex row-0">
                <p className="s-mb-0 s-color-lighter-text s-mr-4px">{heads[0]} : </p>
                {children}
              </div>
            </div>
          </td>
        )}
      </tr>
    )
  }

  // otherwise return the cells for each children
  return (
    <tr className={isActive ? 'active' : undefined}>
      {fromM ? (
        children.map((e, i) => {
          if (isNull(e) || isEqual(e, false)) return null

          return (
            <td key={i} className={classNameTd}>
              {children[i]}
            </td>
          )
        })
      ) : (
        <td className={classNameTd}>
          <div className="s-column s-flex-gap-12px">
            {children.map((e, i) => {
              if (isNull(e)) return null

              return (
                <div key={i} className={`flex row-${i}`}>
                  <p className="s-mb-0 s-color-lighter-text s-mr-4px">{heads[i]} : </p>
                  {children[i]}
                </div>
              )
            })}
          </div>
        </td>
      )}
    </tr>
  )
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired
}

export default TableRow
