import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import es from 'date-fns/locale/es'
import PropTypes from 'prop-types'
import React from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'

registerLocale('es', es)

const DatePickerInput = ({label, className, isRequired, id, disabled, name, value, onChange, ...rest}) => {
  return (
    <div className={`form-item date-picker ${className}`}>
      <DatePicker
        disabled={disabled}
        id={id}
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        required={isRequired}
        className="date-picker"
        locale="es"
        autoComplete="off"
        placeholderText="dd/MM/aaaa"
        {...rest}
      />
      {label && (
        <label htmlFor={id} className={isRequired && 'required'}>
          {label}
        </label>
      )}
    </div>
  )
}

DatePickerInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  id: PropTypes.string
}

DatePickerInput.defaulProps = {
  disabled: false,
  isRequired: false,
  id: ''
}

export default DatePickerInput
