import React, {forwardRef} from 'react'

import PayrollCalculate from '../../../static/images/stories/coffee-break.svg'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const PendingProcessModal = forwardRef((props, ref) => {
  const handleCloseModal = () => {
    props.fnButton()
    ref.current.closeModal()
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium">
      <h3>¡Uy!</h3>
      <p className="l-block">
        Esto esta tardando un poco más de lo planeado, pero no será mucho tiempo, ve por un cafe y nosotros te avisamos
        cuando terminemos.
      </p>
      <div>
        <img src={PayrollCalculate} alt="" style={{maxHeight: '200px'}} />
      </div>
      <button className="button" onClick={handleCloseModal}>
        Vale
      </button>
    </ModalTemplate>
  )
})

PendingProcessModal.displayName = 'PendingProcessModal'

export default PendingProcessModal
