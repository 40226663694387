import React from 'react'
import {useDispatch} from 'react-redux'

import FormChipsInput from '../../../../components/Atoms/Templates/FormChipsInput'
import {notifyError} from '../../../../services/notification'
import {VALID_NUMBER_REGEX} from '../../constants/form/rules'
import useTextArrayParameterForm from '../../hooks/form/useTextArrayParameterForm'
import {useParameterValuesContext} from '../../providers/ParameterValues'
import EditParameterFormDates from './EditParameterFormDates'
import EditParameterFormFooter from './EditParameterFormFooter'

const NumberArrayParameterForm = () => {
  const dispatch = useDispatch()
  const {parameterValues} = useParameterValuesContext()
  const lastValue = parameterValues[parameterValues.length - 1]
  const {is_fixed} = lastValue

  const {setFormData, formData, handleSubmit} = useTextArrayParameterForm({
    parameter: lastValue
  })

  const handleChange = newArray => {
    const areValidItems = newArray.every(value => VALID_NUMBER_REGEX.test(value))

    if (!areValidItems) {
      dispatch(notifyError('Solo puedes ingresar números en el arreglo'))
      return
    }

    setFormData(state => ({
      ...state,
      value: newArray
    }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <EditParameterFormDates formData={formData} setFormData={setFormData} isDisabled={is_fixed} />
      <FormChipsInput
        label="Valor de parámetro"
        value={formData.value}
        handleChange={handleChange}
        className="s-mb-4"
        isRequired
      />
      <EditParameterFormFooter />
    </form>
  )
}

export default NumberArrayParameterForm
