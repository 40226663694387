import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import useObjectURL from '../../../hooks/useObjectURL'
import {selectors as userSelectors} from '../../../redux/ducks/user'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {uploaderProfileAvatar} from '../../../services/uploader'
import {updateProfile} from '../../../services/user'
import PasswordInput from '../../Atoms/Forms/PasswordInput'
import FormInput from '../../Atoms/Templates/FormInput'
import DropContainer from '../../Molecules/DropContainer'
import Container from '../../Wrappers/Container'
import ModalCropImage from '../Modals/ModalCropImage'

const formFields = {
  name: 'Nombre completo',
  email: 'Correo electrónico',
  current_password: 'Contraseña actual',
  new_password: 'Nueva contraseña'
}

const ProfileForm = () => {
  const dispatch = useDispatch()
  const {object, setObject, objectURL} = useObjectURL(null)

  const modalCrop = useRef()

  const user = useSelector(userSelectors.get)
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeName = e => setName(e.target.value)
  const handleChangeEmail = e => setEmail(e.target.value)
  const handleChangeCurrentPassword = e => setCurrentPassword(e.target.value)
  const handleChangeNewPassword = e => setNewPassword(e.target.value)

  const handleCancelForm = () => {
    setName(user.name)
    setEmail(user.email)
    setObject(null)
    setCurrentPassword('')
    setNewPassword('')
  }

  const handleDropAvatar = async acceptedFiles => {
    if (isEmpty(acceptedFiles)) return

    setObject(acceptedFiles[0])
    modalCrop.current.openModal()
  }

  const handleCropAvatarComplete = image => {
    setObject(image)
    modalCrop.current.closeModal()
  }

  const handleCancelCropAvatar = () => {
    setObject(null)
    modalCrop.current.closeModal()
  }

  const handleUpdateProfile = () => {
    const rules = {
      name: 'required|string|min:3',
      email: 'required|email',
      current_password: 'min:5|required_with:new_password',
      new_password: 'min:5|required_with:current_password'
    }

    const payload = {
      name,
      email,
      current_password: currentPassword,
      new_password: newPassword,
      is_change_password: false,
      picture: ''
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    if (!isEmpty(currentPassword) && isEqual(currentPassword, newPassword)) {
      dispatch(notifyError('¡Upps! tu nueva contraseña y la actual no pueden ser iguales'))
      return
    }

    if (!isEmpty(currentPassword) && !isEmpty(newPassword)) {
      payload.is_change_password = true
    }

    setIsLoading(true)

    // upload avatar picture
    if (!isNull(object)) {
      uploaderProfileAvatar(
        object,
        response => {
          payload.picture = response[0].name_file

          update(payload)
        },
        error => {
          dispatch(notifyError(error))
          setIsLoading(false)
        }
      )

      return
    }

    update(payload)
  }

  const update = payload => {
    dispatch(
      updateProfile(
        payload,
        () => {
          // reset fields
          setObject(null)
          setCurrentPassword('')
          setNewPassword('')

          setIsLoading(false)
          dispatch(notifySuccessful('Listo, hemos actualizado tu información'))
        },
        error => {
          dispatch(notifyError(error))
          setIsLoading(false)
        }
      )
    )
  }

  return (
    <Container>
      <form className="ed-grid rows-gap s-gap-4 s-mb-0">
        <section className="s-mb-0">
          <h3>Datos básicos</h3>
          <div className="ed-grid m-grid-2 rows-gap s-gap-2 m-gap-4">
            <FormInput isRequired id="name" label="Nombre" value={name} onChange={handleChangeName} />
            <FormInput
              isRequired
              id="email"
              label="Correo electrónico"
              type="email"
              value={email}
              onChange={handleChangeEmail}
            />
            <DropContainer
              id="drop-container-profile"
              label="Avatar"
              tiny
              className="m-cols-2"
              handleFiles={handleDropAvatar}
              file={objectURL}
            />
          </div>
        </section>

        <section className="s-mb-0">
          <h3 className="s-mb-8px">Seguridad</h3>
          <p className="s-mb-16px small">Llena estos datos sólo si quieres cambiar de contraseña</p>
          <div className="ed-grid m-grid-2 rows-gap s-gap-2 m-gap-4">
            <PasswordInput
              id="current_password"
              label="Contraseña actual"
              value={currentPassword}
              onChange={handleChangeCurrentPassword}
            />
            <PasswordInput
              id="new_password"
              label="Nueva contraseña"
              value={newPassword}
              onChange={handleChangeNewPassword}
            />
          </div>
        </section>

        <div className="buttons-container">
          <button type="button" className="button cancel" onClick={handleCancelForm}>
            Cancelar
          </button>
          <button type="button" className="button" onClick={handleUpdateProfile} disabled={isLoading}>
            {isLoading ? 'Actualizando' : 'Actualiza la información'}
          </button>
        </div>
      </form>
      <ModalCropImage
        ref={modalCrop}
        title="Posiciona y redimensiona tu avatar"
        imageURL={objectURL}
        onCropImageComplete={handleCropAvatarComplete}
        onCancel={handleCancelCropAvatar}
      />
    </Container>
  )
}

export default ProfileForm
