import PropTypes from 'prop-types'
import React from 'react'

const TableHeader = props => {
  const {children} = props
  return (
    <header className="table-header s-pxy-16px m-pxy-24px lg-pxy-32px m-cross-center m-main-justify s-column m-row s-flex-gap-12px s-border-bottom-border m-border-bottom-transparent">
      {children}
    </header>
  )
}

TableHeader.propTypes = {
  children: PropTypes.node.isRequired
}

export {TableHeader}
