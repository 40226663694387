import {COLOMBIA_CODE, PERU_CODE} from 'helpers/country'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'

import {FormDataContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getCacheKeySocialSecurity, getLocalCache} from '../../../helpers/cache'
import {hasFoundContractType, hasFounds, isIntern, pensionerTypes} from '../../../helpers/socialsecurity'
import {isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as workplaceSelector} from '../../../redux/ducks/workplaces'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Select from '../../Atoms/Forms/Select'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const SocialSecurityEmployeeForm = () => {
  const workplaces = useSelector(workplaceSelector.getAll)
  const employerSession = useSelector(employerSelectors.getSession)

  const {formData, setFormData, updateFormField} = useContext(FormDataContext)

  const cacheSocSec = getLocalCache(getCacheKeySocialSecurity(employerSession.code_iso_3))
  const entities = !isNull(cacheSocSec) && cacheSocSec.data

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractTypeEntityType = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types
  const contractTypes = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types

  const showForm = () => hasFounds(formData.contract_type_id, contractTypeEntityType)

  const getEntitiesByType = entityCode => {
    if (!Array.isArray(entities)) return []
    const filtered = entities.filter(e => isEqual(e.entity, entityCode))[0]

    return filtered?.data || []
  }

  const showSelectFound = foundName => {
    return hasFoundContractType(foundName, formData.contract_type_id, contractTypeEntityType)
  }

  const handleOnChangeEntity = (e, abbreviation) => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      entities: [
        ...state.entities.map(e => {
          if (e.abbreviation !== abbreviation) return e
          return {
            ...e,
            social_security_entity_id: Number(value)
          }
        })
      ]
    }))
  }

  const handleOnChangeAffectPension = e => {
    updateFormField(e)

    // hide the pension found if exists
    if (isEqual(e.target.value, 'true')) {
      setFormData(state => ({
        ...state,
        entities: [
          ...state.entities.map(e => {
            if (e.abbreviation !== 'AFP') return e

            return {...e, is_show: false}
          })
        ]
      }))

      return
    }

    // show the pension if exists
    if (isEqual(e.target.value, 'false')) {
      setFormData(state => ({
        ...state,
        entities: [
          ...state.entities.map(e => {
            if (e.abbreviation !== 'AFP') return e

            return {...e, is_show: true}
          })
        ],
        // Reset PILA mapping if the employees is not pensioner
        mapping: {
          ...state.mapping,
          pila: {
            ...state.mapping.pila,
            subcontributor: 0
          }
        }
      }))
    }
  }

  const handleOnChangeIsColombianLivingAbroad = e => {
    updateFormField(e)

    // if isn't ColombianLivingAbroad reset the has_colombian_living_abroad_pay_health field
    if (isEqual(e.target.value, 'false'))
      setFormData(state => ({...state, has_colombian_living_abroad_pay_health: false}))
  }

  const handleOnChangePensionerType = e => {
    const target = e.target
    let value = target.value

    setFormData(state => ({
      ...state,
      mapping: {
        ...state.mapping,
        pila: {
          ...state.mapping.pila,
          subcontributor: Number(value)
        }
      }
    }))
  }

  return (
    <>
      <div className="form-grid ed-grid m-grid-2 lg-grid-3">
        {!showForm() ? (
          <div className="s-cols-1 m-cols-2 lg-cols-3 s-px-6px s-py-8px s-mb-0 s-center small s-color-light-text s-bg-body-alt">
            <span role="img" aria-label="" className="s-mr-8px">
              💡
            </span>
            <span>No necesitas registrar información de seguridad social para este contrato.</span>
          </div>
        ) : (
          <>
            {/* Fields only for COLOMBIA */}
            {isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
              <>
                {formData.is_document_foreign && !isIntern(formData.contract_type_id, contractTypes) && (
                  <RadioButtonsWrapper label="¿Es extranjero sin pensión?">
                    <RadioButton
                      name="is_foreigner_without_pension"
                      label="Sí"
                      value={true}
                      onChange={handleOnChangeAffectPension}
                      checked={isEqual(formData.is_foreigner_without_pension, true)}
                    />
                    <RadioButton
                      name="is_foreigner_without_pension"
                      label="No"
                      value={false}
                      onChange={handleOnChangeAffectPension}
                      checked={isEqual(formData.is_foreigner_without_pension, false)}
                    />
                  </RadioButtonsWrapper>
                )}

                {!formData.is_document_foreign && !isIntern(formData.contract_type_id, contractTypes) && (
                  <>
                    <RadioButtonsWrapper label="¿Es pensionado?">
                      <RadioButton
                        name="is_pensioner"
                        label="Sí"
                        value={true}
                        onChange={handleOnChangeAffectPension}
                        checked={isEqual(formData.is_pensioner, true)}
                      />
                      <RadioButton
                        name="is_pensioner"
                        label="No"
                        value={false}
                        onChange={handleOnChangeAffectPension}
                        checked={isEqual(formData.is_pensioner, false)}
                      />
                    </RadioButtonsWrapper>

                    {formData.is_pensioner && (
                      <Select
                        isRequired
                        label="Tipo Pensionado"
                        name="subcontributor"
                        id="subcontributor"
                        placeholder
                        value={formData.mapping.pila.subcontributor}
                        onChange={handleOnChangePensionerType}
                      >
                        {pensionerTypes.map(type => (
                          <option key={type.id} value={type.id}>
                            {`${type.id} - ${type.description}`}
                          </option>
                        ))}
                      </Select>
                    )}

                    <RadioButtonsWrapper label="¿Es colombiano en el extranjero?">
                      <RadioButton
                        name="is_colombian_living_abroad"
                        label="Sí"
                        value={true}
                        onChange={handleOnChangeIsColombianLivingAbroad}
                        checked={isEqual(formData.is_colombian_living_abroad, true)}
                      />
                      <RadioButton
                        name="is_colombian_living_abroad"
                        label="No"
                        value={false}
                        onChange={handleOnChangeIsColombianLivingAbroad}
                        checked={isEqual(formData.is_colombian_living_abroad, false)}
                      />
                    </RadioButtonsWrapper>
                  </>
                )}

                {formData.is_colombian_living_abroad && !isIntern(formData.contract_type_id, contractTypes) && (
                  <RadioButtonsWrapper label="¿Colombiano en el extranjero cotiza Salud?">
                    <RadioButton
                      name="has_colombian_living_abroad_pay_health"
                      label="Sí"
                      value={true}
                      onChange={updateFormField}
                      checked={isEqual(formData.has_colombian_living_abroad_pay_health, true)}
                    />
                    <RadioButton
                      name="has_colombian_living_abroad_pay_health"
                      label="No"
                      value={false}
                      onChange={updateFormField}
                      checked={isEqual(formData.has_colombian_living_abroad_pay_health, false)}
                    />
                  </RadioButtonsWrapper>
                )}
              </>
            )}

            {/* Fields only for PERU */}

            {isEqual(employerSession.code_iso_3, PERU_CODE) && (
              <Select
                isRequired
                label="Tipo comisión AFP"
                name="afp_commission_type"
                id="afp_commission_type"
                placeholder
                value={formData.afp_commission_type}
                onChange={updateFormField}
              >
                {['MIXTA', 'SOBRE FLUJO'].map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            )}

            {/* Social Security Founds */}

            {showSelectFound('ARL') && (
              <Select
                isRequired
                label="Centro de trabajo ARL"
                name="workplace_id"
                id="workplace_id"
                placeholder
                value={formData.workplace_id}
                onChange={updateFormField}
              >
                {Array.isArray(workplaces) &&
                  workplaces.map(workplace => (
                    <option key={workplace.id} value={workplace.id}>
                      {workplace.description}
                    </option>
                  ))}
              </Select>
            )}

            {Array.isArray(formData.entities) &&
              formData.entities.map(
                t =>
                  t.is_show && (
                    <Select
                      key={t.id}
                      isRequired={t.is_required}
                      label={t.description}
                      name={t.abbreviation}
                      id={t.abbreviation}
                      placeholder
                      value={t.social_security_entity_id}
                      onChange={e => handleOnChangeEntity(e, t.abbreviation)}
                    >
                      {getEntitiesByType(t.abbreviation).map(e => (
                        <option key={e.id} value={e.id}>
                          {e.short_name}
                        </option>
                      ))}
                    </Select>
                  )
              )}
          </>
        )}
      </div>

      {showForm() && (
        <p className="smaller s-main-center s-pt-4 s-mb-0">
          Los campos con <span className="s-color-blue">*</span> son obligatorios
        </p>
      )}
    </>
  )
}

export default SocialSecurityEmployeeForm
