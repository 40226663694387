import {fieldNamesEmployeePayment, rulesEmployeePayment, rulesEmployeePaymentEmail} from 'helpers/employee'
import ValidatorForm from 'helpers/validator'
import React, {forwardRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {updatePaymentMethod} from 'services/employees'
import {notifyError} from 'services/notification'

import {isEqual} from '../../../helpers/utils'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const PaymentDataEditModal = forwardRef(({banks, formData, setFormData, onUpdate}, ref) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const handleOnChangePaymentMethod = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      contract: {...state.contract, payment_method: value},
      // reset the fields of WireTransfer
      bank_account_history: {
        ...state.bank_account_history,
        bank_id: 0,
        account_type: 'CUENTA DE AHORROS',
        account_number: ''
      }
    }))
  }

  const handleOnChangeAccountType = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      // reset the account_number field
      bank_account_history: {
        ...state.bank_account_history,
        account_type: value,
        account_number: ''
      }
    }))
  }

  const handleOnChangeAccountNumber = e => {
    const value = e.target.value

    if (isEqual(formData.bank_account_history.account_type, 'EMAIL')) {
      setFormData(state => ({
        ...state,
        bank_account_history: {
          ...state.bank_account_history,
          account_number: value
        }
      }))
      return
    }

    const pattern = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '') || pattern.test(value)) {
      setFormData(state => ({
        ...state,
        bank_account_history: {
          ...state.bank_account_history,
          account_number: value
        }
      }))
    }
  }

  const updateFormField = e => {
    let value = e.target.value
    const name = e.target.name

    if (name.endsWith('_id')) value = Number(value)

    setFormData(state => ({
      ...state,
      bank_account_history: {...state.bank_account_history, [name]: value}
    }))
  }

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const handleUpdatePaymentData = () => {
    const payload = {payment_method: formData.contract.payment_method}

    if (isEqual(payload.payment_method, 'TRANSFERENCIA BANCARIA')) {
      payload.wire_transfer = {
        bank_id: formData.bank_account_history.bank_id,
        account_type: formData.bank_account_history.account_type,
        account_number: formData.bank_account_history.account_number
      }

      let rules = {...rulesEmployeePayment}

      if (isEqual(payload.wire_transfer.account_type, 'EMAIL')) {
        rules = {...rulesEmployeePaymentEmail}
      }

      const validator = new ValidatorForm(payload.wire_transfer, rules)
      validator.setAttributeNames(fieldNamesEmployeePayment)

      if (validator.fails()) {
        dispatch(notifyError(validator.errors()))
        return
      }
    }

    setIsLoading(true)

    updatePaymentMethod(
      formData.contract.id,
      payload,
      response => {
        setIsLoading(false)

        onUpdate(state => ({
          ...state,
          contract: {...state.contract, payment_method: response.payment_method},
          bank_account_history: {
            ...state.bank_account_history,
            bank_id: response.wire_transfer.bank_id,
            account_type: response.wire_transfer.account_type,
            account_number: response.wire_transfer.account_number
          }
        }))

        handleCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <ModalTemplate ref={ref} className="large">
      <h3 className="s-left s-mb-0">Datos de Pago</h3>
      <p className="small s-color-light-text s-mb-24px">Edita el método de pago del contrato.</p>
      <form className="s-mb-0">
        <div className="form-grid ed-grid m-grid-2 l-block">
          <Select
            label="Método de pago"
            name="payment_method"
            id="payment_method"
            value={formData.contract.payment_method}
            onChange={handleOnChangePaymentMethod}
            placeholder
          >
            {['CHEQUE', 'EFECTIVO', 'TRANSFERENCIA BANCARIA'].map(paymentMethod => (
              <option key={paymentMethod} value={paymentMethod}>
                {paymentMethod}
              </option>
            ))}
          </Select>

          {isEqual(formData.contract.payment_method, 'TRANSFERENCIA BANCARIA') && (
            <>
              <Select
                label="Banco"
                placeholder
                isRequired
                name="bank_id"
                id="bank_id"
                value={formData.bank_account_history.bank_id}
                onChange={updateFormField}
              >
                {Array.isArray(banks) &&
                  banks.map(bank => (
                    <option key={bank.id} value={bank.id}>
                      {bank.short_name}
                    </option>
                  ))}
              </Select>

              <Select
                label="Tipo de cuenta"
                placeholder
                isRequired
                name="account_type"
                id="account_type"
                value={formData.bank_account_history.account_type}
                onChange={handleOnChangeAccountType}
              >
                {['CUENTA DE AHORROS', 'CUENTA CORRIENTE', 'EMAIL'].map(accountType => (
                  <option key={accountType} value={accountType}>
                    {accountType}
                  </option>
                ))}
              </Select>
              <FormInput
                label="No. de cuenta"
                placeholder="12 3456 7890"
                isRequired
                name="account_number"
                id="account_number"
                value={formData.bank_account_history.account_number}
                onChange={handleOnChangeAccountNumber}
              />
            </>
          )}
        </div>

        <div className="s-column s-cross-center">
          <p className="smaller">
            Los campos con <span className="s-color-blue">*</span> son obligatorios
          </p>
          <div className="s-column m-row m-main-center s-flex-gap-16px s-100">
            <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={handleCancel}>
              Cancelar
            </button>
            <button
              type="button"
              className="button ghost s-order-1 m-order-3"
              onClick={handleUpdatePaymentData}
              disabled={isLoading}
            >
              {isLoading ? 'Actualizando...' : 'Actualizar'}
            </button>
          </div>
        </div>
      </form>
    </ModalTemplate>
  )
})

PaymentDataEditModal.displayName = 'PaymentDataEditModal'

export default PaymentDataEditModal
