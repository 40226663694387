import {isEmpty} from './utils'

// Internal statuses
export const INTERNAL_STATUS_DRAFT = 'DRAFT'
export const INTERNAL_STATUS_PROCESSING = 'PROCESSING'
export const INTERNAL_STATUS_SENT = 'SENT'
export const INTERNAL_STATUS_SEND_FAILED = 'SEND_FAILED'

// Alegra statuses
export const ALEGRA_STATUS_REGISTERED = 'REGISTERED'
export const ALEGRA_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE'
export const ALEGRA_STATUS_FAILED = 'FAILED'
export const ALEGRA_STATUS_SENT = 'SENT'

// DIAN statuses
export const DIAN_STATUS_ACCEPTED = 'ACCEPTED'
export const DIAN_STATUS_ACCEPTED_WITH_OBSERVATIONS = 'ACCEPTED_WITH_OBSERVATIONS'
export const DIAN_STATUS_REJECTED = 'REJECTED'

// statuses consolidated
export const ELECTRONIC_PAYROLL_DRAFT = 'DRAFT'
export const ELECTRONIC_PAYROLL_FAILED = 'FAILED'
export const ELECTRONIC_PAYROLL_IN_PROCESS = 'IN_PROCESS'
export const ELECTRONIC_PAYROLL_ACCEPTED = 'ACCEPTED'
export const ELECTRONIC_PAYROLL_REJECTED = 'REJECTED'

// electronicPayrollStatuses is the final status of the documents
// src: https://www.notion.so/N-mina-electr-nica-698b7df0a6f740f0bd1d90f754d8410a?pvs=4#dae8204d2b0e4151a8537e9701d7c6b4
export const electronicPayrollStatuses = {
  [keyStatus(INTERNAL_STATUS_DRAFT, '', '')]: ELECTRONIC_PAYROLL_DRAFT,
  [keyStatus(INTERNAL_STATUS_PROCESSING, '', '')]: ELECTRONIC_PAYROLL_IN_PROCESS,
  [keyStatus(INTERNAL_STATUS_SEND_FAILED, '', '')]: ELECTRONIC_PAYROLL_FAILED,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_REGISTERED, '')]: ELECTRONIC_PAYROLL_IN_PROCESS,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_WAITING_RESPONSE, '')]: ELECTRONIC_PAYROLL_IN_PROCESS,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_FAILED, '')]: ELECTRONIC_PAYROLL_FAILED,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_SENT, DIAN_STATUS_ACCEPTED)]: ELECTRONIC_PAYROLL_ACCEPTED,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_SENT, DIAN_STATUS_ACCEPTED_WITH_OBSERVATIONS)]:
    ELECTRONIC_PAYROLL_ACCEPTED,
  [keyStatus(INTERNAL_STATUS_SENT, ALEGRA_STATUS_SENT, DIAN_STATUS_REJECTED)]: ELECTRONIC_PAYROLL_REJECTED
}

export const electronicPayrollLabels = {
  [ELECTRONIC_PAYROLL_DRAFT]: 'Borrador',
  [ELECTRONIC_PAYROLL_FAILED]: 'Fallido',
  [ELECTRONIC_PAYROLL_IN_PROCESS]: 'En proceso',
  [ELECTRONIC_PAYROLL_ACCEPTED]: 'Aceptado',
  [ELECTRONIC_PAYROLL_REJECTED]: 'Rechazado'
}

function keyStatus(internal, alegra, DIAN) {
  return `${internal}/${alegra}/${DIAN}`
}

export function getElectronicPayrollLabel(document) {
  const key = getElectronicPayrollStatus(document)
  return electronicPayrollLabels[key]
}

export function getElectronicPayrollStatus(document) {
  return electronicPayrollStatuses[keyStatus(document.internal_status, document.alegra_status, document.dian_status)]
}

// Configuration to the object of the array earningElectronicPayroll and deductionElectronicPayroll
// {
//    path: "OtrosConceptos.OtroConcepto",
//    NIE: "NIE147",
//    tag: "OtroConcepto.ConceptoS",
//    isArray: true,
//    values: [
//      {
//         title: "Valor",
//         path: "ConceptoS",
//         isCurrency: true,
//         isRequired: true,
//      }
//    ],
// }
//
// Specification of the fields:
// - path (mandatory): path of the object, we can use dot "." to access to child properties, if we need to access directly
// the first element of an Array because we know that only has an element, we can pass directly the index 0, and mark
// the property isArray in false, e.g:  Transporte.0
// - NIE (mandatory): NIE of the electronic payroll item
// - tag (mandatory): Alegra Tag for the NIE
// - isArray (optional, default: false): indicates if the path returns an Array of elements.
// - values (mandatory): is an Arrray of objects to define the elements that we want to render as content for the NIE,
// every element is concatenated with the next and required a title and a path to render the value.
//    - title (mandatory): title of the value that we want to render. e.g: Días
//    - path: (mandatory): From the current position of the parent path, we can pass the child properties to render; if
//    we pass empty, it means that we don't have more children and we want to render the current parent. You can see the
//    NIE NIE157: ApoyoSost.
//    - isCurrency (optional): indicates if we want to render the value in a currency format.
//    - isRequired (optional): if there are two or more NIEs with the same parent path, and these are marked as isArray, we
//    need to define what of the values is the value requested to render the NIE element. You can see the NIEs NIE147 and
//    NIE148. These NIEs have the same parent path "OtrosConceptos.OtroConcepto" and they are marked as isArray. In this
//    scenario, we only need to render the NIEs when the value with the title "Valor" exists.

export const earningElectronicPayroll = [
  {
    path: 'Basico',
    NIE: 'NIE070',
    tag: 'SueldoTrabajado',
    values: [
      {title: 'Días', path: 'DiasTrabajados', isCurrency: false},
      {title: 'Valor', path: 'SueldoTrabajado', isCurrency: true}
    ]
  },
  {
    path: 'Transporte.0',
    NIE: 'NIE071',
    tag: 'AuxilioTransporte',
    values: [{title: 'Valor', path: 'AuxilioTransporte', isCurrency: true}]
  },
  {
    path: 'Transporte.0',
    NIE: 'NIE072',
    tag: 'ViaticoManuAlojS',
    values: [{title: 'Valor', path: 'ViaticoManuAlojS', isCurrency: true}]
  },
  {
    path: 'Transporte.0',
    NIE: 'NIE073',
    tag: 'ViaticoManuAlojNS',
    values: [{title: 'Valor', path: 'ViaticoManuAlojNS', isCurrency: true}]
  },
  {
    path: 'HEDs.HED',
    NIE: 'NIE078',
    tag: 'HED',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HENs.HEN',
    NIE: 'NIE083',
    tag: 'HEN',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HRNs.HRN',
    NIE: 'NIE088',
    tag: 'HRN',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HEDDFs.HEDDF',
    NIE: 'NIE093',
    tag: 'HEDDF',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HRDDFs.HRDDF',
    NIE: 'NIE098',
    tag: 'HRDDF',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HENDFs.HENDF',
    NIE: 'NIE103',
    tag: 'HENDF',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'HRNDFs.HRNDF',
    NIE: 'NIE108',
    tag: 'HRNDF',
    isArray: true,
    values: [
      {title: 'Horas', path: 'Cantidad', isCurrency: false},
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Vacaciones.VacacionesComunes',
    NIE: 'NIE112',
    tag: 'VacacionesComunes',
    isArray: true,
    values: [
      {title: 'Fecha Inicial', path: 'FechaInicio'},
      {title: 'Fecha Final', path: 'FechaFin'},
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Vacaciones.VacacionesCompensadas',
    NIE: 'NIE116',
    tag: 'VacacionesCompensadas',
    isArray: true,
    values: [
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Primas',
    NIE: 'NIE118',
    tag: 'Primas.Pago',
    values: [
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {path: 'Primas', NIE: 'NIE119', tag: 'Primas.PagoNS', values: [{title: 'Valor', path: 'PagoNS', isCurrency: true}]},
  {path: 'Cesantias', NIE: 'NIE120', tag: 'Cesantias.Pago', values: [{title: 'Valor', path: 'Pago', isCurrency: true}]},
  {
    path: 'Cesantias',
    NIE: 'NIE122',
    tag: 'Cesantias.PagoIntereses',
    values: [
      {title: 'Porcentaje', path: 'Porcentaje'},
      {title: 'Valor', path: 'PagoIntereses', isCurrency: true}
    ]
  },
  {
    path: 'Incapacidades.Incapacidad',
    NIE: 'NIE127',
    tag: 'Incapacidad',
    isArray: true,
    values: [
      {title: 'Tipo', path: 'Tipo'},
      {title: 'Fecha Inicial', path: 'FechaInicio'},
      {title: 'Fecha Final', path: 'FechaFin'},
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Licencias.LicenciaMP',
    NIE: 'NIE131',
    tag: 'LicenciaMP',
    isArray: true,
    values: [
      {title: 'Fecha Inicial', path: 'FechaInicio'},
      {title: 'Fecha Final', path: 'FechaFin'},
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Licencias.LicenciaR',
    NIE: 'NIE135',
    tag: 'LicenciaR',
    isArray: true,
    values: [
      {title: 'Fecha Inicial', path: 'FechaInicio'},
      {title: 'Fecha Final', path: 'FechaFin'},
      {title: 'Cantidad', path: 'Cantidad'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Licencias.LicenciaNR',
    NIE: 'NIE138',
    tag: 'LicenciaNR',
    isArray: true,
    values: [
      {title: 'Fecha Inicial', path: 'FechaInicio'},
      {title: 'Fecha Final', path: 'FechaFin'},
      {title: 'Valor', path: 'Pago', isCurrency: true}
    ]
  },
  {
    path: 'Bonificaciones.Bonificacion.0',
    NIE: 'NIE139',
    tag: 'BonificacionS',
    values: [{title: 'Valor', path: 'BonificacionS', isCurrency: true}]
  },
  {
    path: 'Bonificaciones.Bonificacion.0',
    NIE: 'NIE140',
    tag: 'BonificacionNS',
    values: [{title: 'Valor', path: 'BonificacionNS', isCurrency: true}]
  },
  {
    path: 'Auxilios.Auxilio.0',
    NIE: 'NIE141',
    tag: 'AuxilioS',
    isArray: false,
    values: [{title: 'Valor', path: 'AuxilioS', isCurrency: true}]
  },
  {
    path: 'Auxilios.Auxilio.0',
    NIE: 'NIE142',
    tag: 'AuxilioNS',
    isArray: false,
    values: [{title: 'Valor', path: 'AuxilioNS', isCurrency: true}]
  },
  {
    path: 'HuelgasLegales.HuelgaLegal',
    NIE: 'NIE145',
    tag: 'HuelgaLegal',
    isArray: true,
    values: [{title: 'Valor', path: 'Pago', isCurrency: true}]
  },
  {
    path: 'OtrosConceptos.OtroConcepto',
    NIE: 'NIE147',
    tag: 'OtroConcepto.ConceptoS',
    isArray: true,
    values: [
      {title: 'Descripción', path: 'DescripcionConcepto'},
      {title: 'Valor', path: 'ConceptoS', isCurrency: true, isRequired: true}
    ]
  },
  {
    path: 'OtrosConceptos.OtroConcepto',
    NIE: 'NIE148',
    tag: 'OtroConcepto.ConceptoNS',
    isArray: true,
    values: [
      {title: 'Descripción', path: 'DescripcionConcepto'},
      {title: 'Valor', path: 'ConceptoNS', isCurrency: true, isRequired: true}
    ]
  },
  {
    path: 'Compensaciones.Compensacion.0',
    NIE: 'NIE149',
    tag: 'CompensacionO',
    isArray: false,
    values: [{title: 'Valor', path: 'CompensacionO', isCurrency: true}]
  },
  {
    path: 'Compensaciones.Compensacion.0',
    NIE: 'NIE150',
    tag: 'CompensacionE',
    isArray: false,
    values: [{title: 'Valor', path: 'CompensacionE', isCurrency: true}]
  },
  {
    path: 'BonificacionesEPCTVs.BonoEPCTV.0',
    NIE: 'NIE151',
    tag: 'BonoEPCTV.PagoS',
    isArray: false,
    values: [{title: 'Valor', path: 'PagoS', isCurrency: true}]
  },
  {
    path: 'BonificacionesEPCTVs.BonoEPCTV.0',
    NIE: 'NIE152',
    tag: 'BonoEPCTV.PagoNS',
    isArray: false,
    values: [{title: 'Valor', path: 'PagoNS', isCurrency: true}]
  },
  {
    path: 'BonificacionesEPCTVs.BonoEPCTV.0',
    NIE: 'NIE153',
    tag: 'PagoAlimentacionS',
    isArray: false,
    values: [{title: 'Valor', path: 'PagoAlimentacionS', isCurrency: true}]
  },
  {
    path: 'BonificacionesEPCTVs.BonoEPCTV.0',
    NIE: 'NIE154',
    tag: 'PagoAlimentacionNS',
    isArray: false,
    values: [{title: 'Valor', path: 'PagoAlimentacionNS', isCurrency: true}]
  },
  {
    path: 'Comisiones.Comision',
    NIE: 'NIE155',
    tag: 'Comision',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'PagosTerceros.PagoTercero',
    NIE: 'NIE193',
    tag: 'Devengados.PagosTercero',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Anticipos.Anticipo',
    NIE: 'NIE194',
    tag: 'Devengados.Anticipo',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Dotacion',
    NIE: 'NIE156',
    tag: 'Dotacion',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'ApoyoSost',
    NIE: 'NIE157',
    tag: 'ApoyoSost',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Teletrabajo',
    NIE: 'NIE158',
    tag: 'Teletrabajo',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'BonifRetiro',
    NIE: 'NIE159',
    tag: 'BonifRetiro',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Indemnizacion',
    NIE: 'NIE160',
    tag: 'Indemnizacion',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Reintegro',
    NIE: 'NIE201',
    tag: 'Devengados.Reintegro',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  }
]

export const deductionElectronicPayroll = [
  {
    path: 'Salud',
    NIE: 'NIE163',
    tag: 'Salud',
    isArray: false,
    values: [
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Deduccion', isCurrency: true}
    ]
  },
  {
    path: 'FondoPension',
    NIE: 'NIE166',
    tag: 'FondoPension',
    isArray: false,
    values: [
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Deduccion', isCurrency: true}
    ]
  },
  {
    path: 'FondoSP',
    NIE: 'NIE168',
    tag: 'FondoSP.DeduccionSP',
    isArray: false,
    values: [
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'DeduccionSP', isCurrency: true}
    ]
  },
  {
    path: 'FondoSP',
    NIE: 'NIE170',
    tag: 'FondoSP.DeductionSub',
    isArray: false,
    values: [
      {title: 'Porcentaje', path: 'PorcentajeSub', isCurrency: false},
      {title: 'Valor', path: 'DeduccionSub', isCurrency: true}
    ]
  },
  {
    path: 'Sindicatos.Sindicato',
    NIE: 'NIE172',
    tag: 'Sindicato',
    isArray: true,
    values: [
      {title: 'Porcentaje', path: 'Porcentaje', isCurrency: false},
      {title: 'Valor', path: 'Deduccion', isCurrency: true}
    ]
  },
  {
    path: 'Sanciones.Sancion.0',
    NIE: 'NIE173',
    tag: 'SancionPublic',
    isArray: false,
    values: [{title: 'Valor', path: 'SancionPublic', isCurrency: true}]
  },
  {
    path: 'Sanciones.Sancion.0',
    NIE: 'NIE174',
    tag: 'SancionPriv',
    isArray: false,
    values: [{title: 'Valor', path: 'SancionPriv', isCurrency: true}]
  },
  {
    path: 'Libranzas.Libranza',
    NIE: 'NIE176',
    tag: 'Libranza',
    isArray: true,
    values: [
      {title: 'Descripción', path: 'Descripcion', isCurrency: false},
      {title: 'Valor', path: 'Deduccion', isCurrency: true}
    ]
  },
  {
    path: 'PagosTerceros.PagoTercero',
    NIE: 'NIE195',
    tag: 'Deducciones.PagoTercero',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Anticipos.Anticipo',
    NIE: 'NIE196',
    tag: 'Deducciones.Anticipo',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'OtrasDeducciones.OtraDeduccion',
    NIE: 'NIE197',
    tag: 'OtraDeduccion',
    isArray: true,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'PensionVoluntaria',
    NIE: 'NIE198',
    tag: 'PensionVoluntaria',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'RetencionFuente',
    NIE: 'NIE177',
    tag: 'RetencionFuente',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {path: 'AFC', NIE: 'NIE179', tag: 'AFC', isArray: false, values: [{title: 'Valor', path: '', isCurrency: true}]},
  {
    path: 'Cooperativa',
    NIE: 'NIE180',
    tag: 'Cooperativa',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'EmbargoFiscal',
    NIE: 'NIE181',
    tag: 'EmbargoFiscal',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'PlanComplementarios',
    NIE: 'NIE182',
    tag: 'PlanComplementarios',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Educacion',
    NIE: 'NIE183',
    tag: 'Educacion',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {
    path: 'Reintegro',
    NIE: 'NIE184',
    tag: 'Deducciones.Reintegro',
    isArray: false,
    values: [{title: 'Valor', path: '', isCurrency: true}]
  },
  {path: 'Deuda', NIE: 'NIE185', tag: 'Deuda', isArray: false, values: [{title: 'Valor', path: '', isCurrency: true}]}
]

export function getValueElectronicPayroll(obj, route) {
  if (isEmpty(route)) return obj

  const properties = route.split('.')

  return properties.reduce((accum, proper) => {
    if (accum && accum[proper] !== undefined) {
      return accum[proper]
    }

    return undefined
  }, obj)
}
