import PropTypes from 'prop-types'
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import ReactDOM from 'react-dom'

import {useKeyDownEsc} from '../../hooks/useKeyDownEsc'
import Fade from '../Atoms/Animations/Fade'
import Icon from '../Atoms/Icon'
import Animation from '../Atoms/Templates/Animation'

const ModalTemplate = forwardRef((props, ref) => {
  const {children, className, isDefaultOpen, isLockDefaultClose, onChangeOpen} = props
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  let keyDownEsc = useKeyDownEsc()

  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModal(),
      closeModal: () => closeModal()
    }
  })

  const openModal = () => {
    setIsOpen(true)
    onChangeOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    onChangeOpen(false)
  }

  const closeDefaultModal = () => {
    if (!isLockDefaultClose) closeModal()
  }

  useEffect(() => {
    if (keyDownEsc && !isLockDefaultClose) closeModal()
  }, [keyDownEsc, isLockDefaultClose])

  return ReactDOM.createPortal(
    // Here we using the component Animation and not Fade because the property display of
    // the class "modal-template" isn't animated, then we using Animation without an
    // animation name for that the component waiting for unmount, this allow show the
    // animation of the child
    <Animation show={isOpen}>
      <div className={`modal-template ${className}`}>
        <Fade show={isOpen}>
          <div className="wrapper">
            {!isLockDefaultClose && (
              <button className="close-button" onClick={closeDefaultModal}>
                <Icon className="icon" svg="cross" />
              </button>
            )}
            <section className="container">
              <div className="content">{children}</div>
            </section>
          </div>
          <div className="overlay" onClick={closeDefaultModal} />
        </Fade>
      </div>
    </Animation>,
    document.getElementById('modal-root')
  )
})

ModalTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
  onChangeOpen: PropTypes.func
}

ModalTemplate.defaultProps = {
  className: '',
  isDefaultOpen: false,
  onChangeOpen: () => {}
}

ModalTemplate.displayName = 'ModalTemplate'

export default ModalTemplate
