import PropTypes from 'prop-types'
import React from 'react'

import Badge from '../../../../components/Atoms/Badge'
import Breadcrumbs from '../../../../components/Atoms/Breadcrumbs'
import Icon from '../../../../components/Atoms/Icon'
import {BADGE_TYPES} from '../../data/badges'
import {useSelectedParameterContext} from '../../providers/SelectedParameter'

const SingleParameterHeader = () => {
  const {handleReturnToList, selectedParameter} = useSelectedParameterContext()

  const {abbreviation, data_type: dataType, is_mandatory_employer: isMandatory} = selectedParameter

  return (
    <header className="s-mb-2">
      <Breadcrumbs className="s-mb-1">
        <p onClick={handleReturnToList}>Parametros</p>
        <p className="smaller s-color-title s-mb-0 s-mr-8px">{abbreviation}</p>
      </Breadcrumbs>
      <div className="flex s-flex-gap-12px">
        <Badge text={BADGE_TYPES[dataType].label} color={BADGE_TYPES[dataType].color} className="s-w-fit" />
        {!isMandatory && (
          <div title="Administrado por nomineros" className="s-mr-1">
            <Icon svg="nomineros" size="10" />
          </div>
        )}
      </div>
    </header>
  )
}

export default SingleParameterHeader
