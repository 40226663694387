import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const RadioButtonsWrapper = ({children, label, isRequired, className}) => {
  return (
    <div className={`form-item ${className}`}>
      {label && <label className={classNames({required: isRequired})}>{label}</label>}
      <div className="s-cross-center s-flex-gap-32px flex-grow">{children}</div>
    </div>
  )
}

RadioButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

RadioButtonsWrapper.defaultProps = {}

export default RadioButtonsWrapper
