import React from 'react'
import ReactTimeAgo from 'react-time-ago'

import {COLOMBIA_CODE} from '../../../helpers/country'
import {readBackendDateAndTime} from '../../../helpers/dates'
import {
  deductionElectronicPayroll,
  earningElectronicPayroll,
  ELECTRONIC_PAYROLL_ACCEPTED,
  ELECTRONIC_PAYROLL_FAILED,
  ELECTRONIC_PAYROLL_IN_PROCESS,
  ELECTRONIC_PAYROLL_REJECTED,
  getValueElectronicPayroll
} from '../../../helpers/electronic-payroll'
import {getFormatCurrency} from '../../../helpers/format-number'
import {mediaFromM} from '../../../helpers/media-queries'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {useMedia} from '../../../hooks/useMedia'
import ElectronicPayrollDocumentHeader from '../../Molecules/ElectronicPayrollDocumentHeader'
import ElectronicPayrollItem from '../../Molecules/Items/ElectronicPayrollItem'

const ElectronicPayrollDocument = ({document, statusDocument}) => {
  const fromM = useMedia(mediaFromM)
  const data = document?.request_object?.governmentData

  return (
    <div>
      <ElectronicPayrollDocumentHeader
        businessName={data?.Empleador?.RazonSocial}
        nit={String(data?.Empleador?.NIT)}
        dv={String(data?.Empleador?.DV)}
        title={`Periodo ${data?.Periodo?.FechaLiquidacionInicio} al ${data?.Periodo?.FechaLiquidacionFin}`}
        cune={!isEqual(statusDocument, ELECTRONIC_PAYROLL_ACCEPTED) ? '' : document?.cune}
      >
        {/* Employee Title */}
        <section className="s-mb-2 m-mb-4 s-center m-left">
          <p className="normal s-color-title s-mb-0">
            {`${data?.Trabajador?.PrimerNombre} ${data?.Trabajador?.OtrosNombres} ${data?.Trabajador?.PrimerApellido} ${data?.Trabajador?.SegundoApellido}`}
          </p>
          <p className="smaller s-mb-0">{data?.Trabajador?.NumeroDocumento}</p>
        </section>
      </ElectronicPayrollDocumentHeader>

      {/* Employee Data */}
      <section className="m-cross-center m-main-justify s-mb-4">
        {fromM && (
          <div className="s-center m-left s-mb-2 m-mb-4 m-mb-0">
            <p className="smaller s-mb-0">{`Fecha de Ingreso: ${data?.Periodo?.FechaIngreso}`}</p>
            <p className="smaller s-mb-0">{`Sueldo: ${getFormatCurrency(
              data?.Trabajador?.Sueldo,
              COLOMBIA_CODE,
              false
            )} (Integral: ${data?.Trabajador?.SalarioIntegral ? 'SI' : 'NO'})`}</p>
            <p className="smaller s-mb-0">
              {`Trabajador: (Tipo: ${data?.Trabajador?.TipoTrabajador}, Subtipo: ${data?.Trabajador?.SubTipoTrabajador})`}
            </p>
            <p className="smaller s-mb-0">{`Clasificación: (Tipo Contrato: ${data?.Trabajador?.TipoContrato}, Tipo Trabajador: ${data?.Trabajador?.TipoTrabajador})`}</p>
            <p className="smaller s-mb-0">{`Pago: (Forma: ${data?.Pago?.Forma}, Método: ${data?.Pago?.Metodo})`}</p>
            {isEqual(data?.Pago?.Metodo, '42') && (
              <p className="smaller s-mb-0">{`Cuenta: (Tipo: ${data?.Pago?.TipoCuenta.split(' ').slice(
                -1
              )}, Número: ${data?.Pago?.NumeroCuenta}, Banco: ${data?.Pago?.Banco})`}</p>
            )}
            <p className="smaller s-mb-0">{`Lugar de Trabajo: (${data?.Trabajador?.LugarTrabajoPais} ${data?.Trabajador?.LugarTrabajoMunicipioCiudad})`}</p>
          </div>
        )}
        <div className="s-center m-left">
          <p className="small s-mb-0">Total</p>
          <p className="t1 s-mb-0 whitespace-nowrap">
            {getFormatCurrency(data?.ComprobanteTotal, COLOMBIA_CODE, false)}
          </p>
        </div>
      </section>

      {/* Earnings */}
      <div className="s-main-justify s-cross-center border-bottom s-mb-0">
        <span>Ingresos</span>
        <span>{getFormatCurrency(data?.DevengadosTotal, COLOMBIA_CODE, false)}</span>
      </div>

      <section className="s-color-text ">{getEarningRecords(data?.Devengados)}</section>

      {/* Deductions */}
      <div className="s-main-justify s-cross-center border-bottom s-mb-0">
        <span>Descuentos</span>
        <span>{getFormatCurrency(data?.DeduccionesTotal, COLOMBIA_CODE, false)}</span>
      </div>

      <section className="s-color-text s-mb-4">{getDeductionRecords(data?.Deducciones)}</section>

      <section>
        <p className="normal s-color-title s-mb-1">Actividad</p>
        <p className="s-mb-0 smaller s-color-light-text">
          Creado <ReactTimeAgo date={readBackendDateAndTime(document?.created_at)} />
        </p>

        {isEqual(statusDocument, ELECTRONIC_PAYROLL_FAILED) && (
          <p className="s-mb-0 smaller s-color-light-text">
            Envío fallidó <ReactTimeAgo date={readBackendDateAndTime(document?.updated_at)} /> con error{' '}
            <span className="s-color-text">{getMessageError(document?.response_object?.error)}</span>
          </p>
        )}

        {isEqual(statusDocument, ELECTRONIC_PAYROLL_IN_PROCESS) && (
          <p className="s-mb-0 smaller s-color-light-text">
            La DIAN esta procesando el documento <ReactTimeAgo date={readBackendDateAndTime(document?.updated_at)} />
          </p>
        )}

        {isEqual(statusDocument, ELECTRONIC_PAYROLL_ACCEPTED) && (
          <>
            <p className="s-mb-0 smaller s-color-light-text">
              Recibo por la DIAN <ReactTimeAgo date={readBackendDateAndTime(document?.updated_at)} /> con el estado{' '}
              <span className="s-color-text">{document?.response_object?.governmentResponse?.message}</span>
            </p>
            <p className="s-mb-0 smaller s-color-light-text">
              CUNE:{' '}
              <span className="s-color-text code-font">{fromM ? document?.cune : document?.cune.slice(0, 7)}</span>
            </p>
          </>
        )}

        {isEqual(statusDocument, ELECTRONIC_PAYROLL_REJECTED) && (
          <>
            <p className="s-mb-0 smaller s-color-light-text">
              Rechazado por la DIAN <ReactTimeAgo date={readBackendDateAndTime(document?.updated_at)} /> con el estado{' '}
              <span className="s-color-text">{document?.response_object?.governmentResponse?.message}</span>
            </p>
            {Array.isArray(document?.response_object?.governmentResponse?.errorMessages) && (
              <ul className="s-mb-0 smaller s-color-light-text">
                {document?.response_object?.governmentResponse?.errorMessages.map((msg, ix) => (
                  <li key={ix}>{msg}</li>
                ))}
              </ul>
            )}
          </>
        )}

        <p className="s-mb-0 smaller s-color-light-text">{document?.retry_number} reintentos de envío</p>
      </section>
    </div>
  )
}

function getEarningRecords(earnings) {
  return earningElectronicPayroll.map(struct => getRecords(earnings, struct))
}

function getDeductionRecords(deductions) {
  return deductionElectronicPayroll.map(struct => getRecords(deductions, struct))
}

function getRecords(data, struct) {
  const payroll = getValueElectronicPayroll(data, struct.path)

  if (!payroll) return null

  // if the struct is an Array we need to return an item for each element
  if (struct.isArray) {
    return payroll.map((p, ix) => {
      if (!p) return null

      const values = struct?.values.map(value => ({
        title: value?.title,
        content: getValueElectronicPayroll(p, value.path),
        isCurrency: value?.isCurrency ? true : false,
        isRequired: value?.isRequired
      }))

      const isValid = isEmpty(values.filter(v => v.isRequired && !v.content))

      if (!isValid) return null

      return (
        <ElectronicPayrollItem key={`${struct?.NIE}-${ix}`} NIE={struct?.NIE} tagName={struct?.tag} values={values} />
      )
    })
  }

  // if not is Array we need to check if the employee has payroll for the path.
  const values = struct?.values
    .map(value => {
      const content = getValueElectronicPayroll(payroll, value.path)

      if (!content) return null

      return {
        title: value?.title,
        content: content,
        isCurrency: value?.isCurrency ? true : false
      }
    })
    .filter(value => !isNull(value))

  // if the employee has not payroll for the path we skip
  if (isEmpty(values)) return null

  return <ElectronicPayrollItem key={struct?.NIE} NIE={struct?.NIE} tagName={struct?.tag} values={values} />
}

function getMessageError(error) {
  const regex = /message:\s\((.*)\)$/gi
  const matches = [...error.matchAll(regex)]

  if (matches?.[0]?.[1]) {
    return matches[0][1]
  }

  return error
}

export default ElectronicPayrollDocument
