import {caches, getLocalCache} from '../helpers/cache'
import {Request} from '../helpers/request'
import {isEqual, isNull, isUndefined} from '../helpers/utils'

export const getCache = countryCodeISO3 => {
  caches.forEach(cacheName => {
    const key = `${countryCodeISO3}:${cacheName}`.toUpperCase()

    let cache = getLocalCache(key)

    // if the cache doesn't exists or cache hasn't the field updated_at, then get the cache
    if (isNull(cache) || isUndefined(cache.updated_at)) {
      getCacheByType(key)
      return
    }

    // get the last updated date of the cache.
    getCacheUpdatedAtByType(key, lastUpdatedAt => {
      // if the last updated date is different to the local cache date, then get the last cache
      if (isEqual(lastUpdatedAt, cache.updated_at)) return

      getCacheByType(key)
    })
  })
}

const getCacheByType = type => {
  const request = new Request()
  request.get(`/caches/${type}`, response => localStorage.setItem(type, JSON.stringify(response)))
}

const getCacheUpdatedAtByType = (type, callback) => {
  const request = new Request()
  request.get(`/caches/updated-at/${type}`, response => callback(response))
}
