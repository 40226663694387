import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/payslips'

export const getPayslipEmployees = (hash, queryParams, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${hash}/employees?${queryParams}`, callback, callbackErr)
}

export const getPayslipSummary = (hash, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${hash}/summary`, callback, callbackErr)
}

export const getPayslipDetail = (hash, contractHash) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/processes/${hash}/contracts/${contractHash}/detail`, resolve, reject)
  })
}

export const generatePayslipReport = (hash, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.generateFile(`${RESOURCE}/processes/${hash}/generate-reports`, payload, callback, callbackErr)
}

export const generatePayslipReportPromise = (hash, payload) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.generateFile(`${RESOURCE}/processes/${hash}/generate-reports`, payload, resolve, reject)
  })
}

export const sendPayslipToReceipt = (hash, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(`${RESOURCE}/processes/${hash}/send`, payload, callback, callbackErr)
}
