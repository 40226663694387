import PropTypes from 'prop-types'
import React from 'react'

import Container from '../../Wrappers/Container'

const TableBody = props => {
  const {className, children} = props
  return <Container className={`table-template ${className}`}>{children}</Container>
}

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

TableBody.defaultProps = {
  className: ''
}

export {TableBody}
