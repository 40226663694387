import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {isEmpty} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {selectors as jobSelectors} from '../../../redux/ducks/jobs'
import {selectors as notificationSelectors} from '../../../redux/ducks/notification'
import {createJob} from '../../../services/jobs'
import {notifyError} from '../../../services/notification'
import JobItem from '../../Atoms/Items/JobItem'
import FormInput from '../../Atoms/Templates/FormInput'
import IconPanelContainer from '../Containers/IconPanelContainer'

const formFields = {
  description: 'Nombre del cargo',
  code: 'Código del cargo'
}

const JobsPanel = () => {
  const dispatch = useDispatch()
  const jobs = useSelector(jobSelectors.getAll)
  const notification = useSelector(notificationSelectors.get)

  const [code, setCode] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    // clean the form when the jobs change or if an error notification is generated
    setCode('')
    setDescription('')
  }, [jobs, notification])

  const updateDescription = e => {
    const target = e.target
    setDescription(target.value)
  }

  const updateCode = e => {
    const target = e.target
    setCode(target.value)
  }

  const handleCreateJob = () => {
    const rules = {
      code: 'required|string|min:1|max:10',
      description: 'required|string|min:3'
    }

    const validator = new ValidatorForm({code, description}, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    dispatch(createJob({code, description}))
  }

  return (
    <IconPanelContainer icon="briefcase" title="Cargos de trabajo">
      <div className="flex s-flex-gap-8px s-mb-12px">
        <div className="ed-grid s-grid-3 s-gap-1">
          <FormInput isRequired name="code" id="code" placeholder="Código" value={code} onChange={updateCode} />
          <FormInput
            className="s-cols-2"
            isRequired
            name="description"
            id="description"
            placeholder="Nombre del cargo"
            value={description}
            onChange={updateDescription}
          />
        </div>
        <button type="button" className="button small whitespace-nowrap" onClick={handleCreateJob}>
          Crear cargo
        </button>
      </div>
      <div className="flex s-flex-gap-8px">{!isEmpty(jobs) && jobs.map(job => <JobItem key={job.id} job={job} />)}</div>
    </IconPanelContainer>
  )
}

export default JobsPanel
