import PropTypes from 'prop-types'
import React, {useState} from 'react'

import Icon from '../Icon'
import FormInput from '../Templates/FormInput'

const PasswordInput = ({className, label, id, ...rest}) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <div className={`s-relative ${className}`}>
      <FormInput
        label={label || 'Contraseña'}
        id={id}
        name="password"
        type={showPassword ? 'text' : 'password'}
        placeholder="••••••••••"
        {...rest}
      />
      <Icon
        onClick={toggleShowPassword}
        svg={showPassword ? 'eye-off' : 'eye'}
        className="s-absolute s-right-16px s-bottom-10px cursor-pointer"
      />
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
}

export default PasswordInput
