import PropTypes from 'prop-types'
import React from 'react'

const RadioButton = ({label, className, name, value, ...rest}) => {
  return (
    <label className={`radio-button ${className}`}>
      <input name={name} value={value} type="radio" {...rest} />
      {label && <span>{label}</span>}
    </label>
  )
}

RadioButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any
}

RadioButton.defaultProps = {
  label: '',
  className: ''
}

export default RadioButton
