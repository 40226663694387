import {URL_BASE_HELP} from 'helpers/routes'
import {isEqual} from 'helpers/utils'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'

const PreviewMarkdown = ({content = '', showLineNumbers = true, toolbar = null, className = ''}) => {
  return (
    <div className={className}>
      {toolbar}
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        // eslint-disable-next-line react/no-children-prop
        children={content}
        components={{
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                // eslint-disable-next-line react/no-children-prop
                children={String(children).replace(/\n$/, '')}
                style={dracula}
                language={match[1]}
                PreTag="div"
                showLineNumbers={showLineNumbers}
                {...props}
              />
            ) : (
              <code
                className={className}
                style={{
                  fontFamily: 'Ubuntu Mono',
                  backgroundColor: 'var(--blue-100)',
                  padding: '2px 4px'
                }}
                {...props}
              >
                {children}
              </code>
            )
          },

          a: ({node, children, ...props}) => {
            if (props.href.startsWith(URL_BASE_HELP)) {
              return (
                <a data-href={props.href} className="cursor-pointer">
                  {children}
                </a>
              )
            }

            return <a {...props}>{children}</a>
          },

          // rewrite table
          table: ({node, children, ...props}) => {
            return (
              <table {...props} className="table-markdown">
                {children}
              </table>
            )
          },

          h4: ({node, children, ...props}) => {
            return (
              <h4 className="border-bottom s-pb-1" {...props}>
                {children}
              </h4>
            )
          },

          // Rewrite <h2> when has a <a> for render video player
          h2: ({node, children, ...props}) => {
            try {
              if (isEqual(children[0]?.type, 'a')) {
                const a = children[0]

                return (
                  <div className="react-player s-mb-3">
                    <ReactPlayer url={a.props.href} width="100%" height="100%" className="player" />
                  </div>
                )
              }
            } catch (e) {
              console.error(e)
            }

            return <h2 {...props}>{children}</h2>
          }
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  )
}

export default PreviewMarkdown
