import PropTypes from 'prop-types'
import React from 'react'

import {isZeroDate, shortFormatDate} from '../../../helpers/dates'
import {isEqual} from '../../../helpers/utils'
import Icon from '../../Atoms/Icon'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import FloatContainer from '../../Wrappers/FloatContainer'

const ContractSelect = ({contracts, contractSelected, onChangeContractSelected}) => {
  return (
    <div className="s-relative">
      <FloatContainer>
        {/* hover-change */}
        <button className="action-button background-transition s-cross-center s-main-center cursor-pointer s-border-body-alt outline-none  s-bg-body-alt s-hover-bg-body normal-radius s-px-8px m-ml-4px s-mb-0 blue">
          <span className="smaller s-color-text content s-mr-8px">{formatContent(contractSelected.contract)}</span>
          <Icon svg="down" size="0.625rem" className="icon" color="var(--text-color)" />
        </button>
        <div className="float-container">
          {contracts.map(c => (
            <DropdownItem
              key={c.contract.id}
              text={formatContent(c.contract)}
              isActive={isEqual(c.contract.id, contractSelected.contract.id)}
              onClick={() => {
                onChangeContractSelected(c)
              }}
            />
          ))}
        </div>
      </FloatContainer>
    </div>
  )
}

const formatContent = contract => {
  let content = `${shortFormatDate(contract.hire_date)}`

  if (isZeroDate(contract.termination_date)) return `${content} - Actual`

  return `${content} - ${shortFormatDate(contract.termination_date)}`
}

ContractSelect.propTypes = {
  contracts: PropTypes.array.isRequired,
  onChangeContractSelected: PropTypes.func.isRequired,
  contractSelected: PropTypes.object.isRequired
}

ContractSelect.defaultProps = {
  contracts: [],
  onChangeContractSelected: () => {},
  contractSelected: {}
}

export default ContractSelect
