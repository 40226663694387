import {isNumber} from 'helpers/utils'
import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

import SingleParameterView from '../components/parameters/SingleParameterView'

const SelectedParameterContext = createContext({})

const SelectedParameterProvider = ({children}) => {
  const [selectedParameter, setSelectedParameter] = useState(null)

  const handleReturnToList = () => {
    setSelectedParameter(null)
  }

  return (
    <SelectedParameterContext.Provider
      value={{
        selectedParameter,
        setSelectedParameter,
        handleReturnToList
      }}
    >
      {!isNumber(selectedParameter?.id) && children}
      {isNumber(selectedParameter?.id) && <SingleParameterView />}
    </SelectedParameterContext.Provider>
  )
}

export const useSelectedParameterContext = () => useContext(SelectedParameterContext)

SelectedParameterProvider.propTypes = {
  children: PropTypes.node
}

SelectedParameterProvider.defaultProps = {
  children: null
}

export default SelectedParameterProvider
