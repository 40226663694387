export const MASTER_EMPLOYEES_MODAL_DATA = {
  key: 'MASTER_EMPLOYEES',
  description: 'Maestro empleados',
  icon: 'excel',
  isVisible: true
}
export const VACATIONS_BOOK_MODAL_DATA = {
  key: 'VACATIONS_BOOK',
  description: 'Libro de vacaciones',
  icon: 'excel',
  isVisible: true
}
