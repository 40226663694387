import React from 'react'

import {EDIT_PARAMETER_FORM_LIST} from '../../constants/form'
import {useEditingStateContext} from '../../providers/EditingState'
import {useSelectedParameterContext} from '../../providers/SelectedParameter'

const EditParameterForm = () => {
  const {isEditing} = useEditingStateContext()
  const {
    selectedParameter: {data_type: dataType}
  } = useSelectedParameterContext()

  if (!isEditing) return null

  return <>{EDIT_PARAMETER_FORM_LIST[dataType]}</>
}

export default EditParameterForm
