import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import routes, {payrollDateRoute, QUERY_PARAM_HASH_CONTRACT, routeYearMonth} from '../../../config/routes'
import {PayrollContext} from '../../../contexts/contexts'
import {mediaFromM} from '../../../helpers/media-queries'
import {isEmpty, isNull} from '../../../helpers/utils'
import {useMedia} from '../../../hooks/useMedia'
import EmployeeWrapper from '../../Wrappers/EmployeeWrapper'
import PhonePanel from '../../Wrappers/PhonePanel'
import TranslatePhonePanel from '../../Wrappers/TranslatePhonePanel'
import ElectronicPayrollDocumentEmptyState from '../Emptys/ElectronicPayrollDocumentEmptyState'
import ElectronicPayrollEmptyState from '../Emptys/ElectronicPayrollEmptyState'
import ElectronicPayrollNotEnableEmptyState from '../Emptys/ElectronicPayrollNotEnableEmptyState'
import ElectronicPayrollDocumentSection from '../Panels/ElectronicPayrollDocumentSection'
import ElectronicPayrollSkeleton from '../Skeleton/ElectronicPayrollSkeleton'
import EmployeesElectronicPayrollSection from './EmployeesElectronicPayrollSection'
import SummaryElectronicPayrollPanel from './SummaryElectronicPayrollPanel'

const ElectronicPayrolSection = ({
  isEnable = false,
  isLoadingSummary = true,
  summary = null,
  onUpdateSummaryPromise = () => {}
}) => {
  const fromM = useMedia(mediaFromM)
  const {search} = useLocation()
  const history = useHistory()
  const {payrollDate} = useContext(PayrollContext)

  const [currentQueryParams, setCurrentQueryParams] = useState(null)

  useEffect(() => {
    setCurrentQueryParams(new URLSearchParams(search))
  }, [search])

  if (!isEnable) return <ElectronicPayrollNotEnableEmptyState />

  return isLoadingSummary ? (
    <ElectronicPayrollSkeleton />
  ) : isNull(summary) ? (
    <ElectronicPayrollEmptyState />
  ) : (
    <main className="ed-grid rows-gap">
      <PhonePanel
        container={{
          className: 'ed-grid m-grid-2 lg-grid-3 s-gap-0 m-gap-2 lg-gap-4'
        }}
        onClickComeBack={() => {
          history.push(`${payrollDateRoute(payrollDate)}${routes.payroll.payrollElectronic}`)
        }}
      >
        <EmployeeWrapper>
          <div className="ed-grid rows-gap s-gap-2 m-gap-4">
            <EmployeesElectronicPayrollSection year={payrollDate?.year} month={payrollDate?.month} />
          </div>

          <TranslatePhonePanel className="ed-grid rows-gap lg-cols-2 s-gap-2 m-gap-4 s-cross-start mt-none">
            {fromM && (
              <SummaryElectronicPayrollPanel
                data={summary}
                year={payrollDate?.year}
                month={payrollDate?.month}
                onUpdateSummaryPromise={onUpdateSummaryPromise}
              />
            )}

            {currentQueryParams?.has(QUERY_PARAM_HASH_CONTRACT) ? (
              <ElectronicPayrollDocumentSection
                onUpdateSummaryPromise={onUpdateSummaryPromise}
                contractHash={currentQueryParams?.get(QUERY_PARAM_HASH_CONTRACT)}
                year={payrollDate?.year}
                month={payrollDate?.month}
              />
            ) : (
              <ElectronicPayrollDocumentEmptyState />
            )}
          </TranslatePhonePanel>
        </EmployeeWrapper>
      </PhonePanel>
    </main>
  )
}

export default ElectronicPayrolSection
