import React, {useRef, useState} from 'react'

import {isEmpty, isNull} from '../../../helpers/utils'
import AddActionButton from '../../Atoms/Buttons/AddActionButton'
import PayrollNote from '../../Molecules/PayrollNote'
import Container from '../../Wrappers/Container'
import NotesPayrollEmptyState from '../Emptys/NotesPayrollEmptyState'
import NewNoteModal from '../Modals/NewNoteModal'

const initialStateNote = {
  isEdit: false,
  id: 0,
  employer_month_id: 0,
  title: '',
  description: ''
}

const NotesPayrollPanel = ({notes, employerMonthID, onAdd, onDelete, onEdit, userID}) => {
  const [note, setNote] = useState({
    ...initialStateNote,
    employer_month_id: employerMonthID
  })

  const notesModal = useRef()

  const handleOpenNewNoteModal = () => {
    setNote({...initialStateNote, employer_month_id: employerMonthID})
    notesModal.current.openModal()
  }

  const hadleOpenEditNoteModal = noteToEdit => {
    setNote({
      ...noteToEdit,
      isEdit: true
    })

    notesModal.current.openModal()
  }

  return (
    <Container className="s-rows-2">
      <div className="s-main-justify s-cross-center s-mb-8px">
        <h3 className="s-mb-0">Notas</h3>
        {/* Mostrar sólo cuando ya haya notas creadas */}
        {Array.isArray(notes) && !isEmpty(notes) && <AddActionButton onClick={handleOpenNewNoteModal} />}
      </div>

      {!Array.isArray(notes) || isNull(notes) || isEmpty(notes) ? (
        <NotesPayrollEmptyState onAdd={handleOpenNewNoteModal} />
      ) : (
        <div className="ed-grid row-gap s-gap-1">
          {notes.map(n => (
            <PayrollNote key={n.id} onDelete={onDelete} onEdit={hadleOpenEditNoteModal} userID={userID} note={n} />
          ))}
        </div>
      )}

      <NewNoteModal ref={notesModal} note={note} setNote={setNote} onAdd={onAdd} onEdit={onEdit} />
    </Container>
  )
}

export default NotesPayrollPanel
