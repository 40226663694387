import PropTypes from 'prop-types'
import React, {useState} from 'react'

import Tooltip from '../../Atoms/Templates/Tooltip'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import TooltipContainer from '../../Organisms/Containers/TooltipContainer'
import Icon from '../Icon'

const TextArea = props => {
  const {label, type, placeholder, id, name, className, isRequired, isDisabled, textarea, value, heightSize, ...rest} =
    props

  const [isPreviewMarkdown, setIsPreviewMarkdown] = useState(false)

  const handleTooglePreviewMarkdown = () => {
    setIsPreviewMarkdown(!isPreviewMarkdown)
  }

  return (
    <div className={`form-item ${className}`}>
      {isDisabled || isPreviewMarkdown ? (
        <div className={`${isPreviewMarkdown ? 'markdown-preview' : 'markdown'} ${heightSize}-height`}>
          <PreviewMarkdown content={value} />
        </div>
      ) : (
        <textarea
          className={`textarea ${textarea.className}`}
          disabled={isDisabled}
          required={isRequired}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          {...rest}
        ></textarea>
      )}

      {label && (
        <label htmlFor={id}>
          <span className="flex s-cross-center s-flex-gap-12px">
            {label}
            {!isDisabled && (
              <>
                {' (usa markdown)'}
                <TooltipContainer className="s-cross-center">
                  <Icon
                    svg={isPreviewMarkdown ? 'edit' : 'eye'}
                    color="var(--title-color)"
                    size="20"
                    className="cursor-pointer"
                    onClick={handleTooglePreviewMarkdown}
                  />
                  <Tooltip>
                    <div className="whitespace-nowrap">
                      <span>{isPreviewMarkdown ? 'Modo de Edición' : 'Vista previa'}</span>
                    </div>
                  </Tooltip>
                </TooltipContainer>
              </>
            )}
          </span>
        </label>
      )}
    </div>
  )
}

TextArea.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  heightSize: PropTypes.string,
  textarea: PropTypes.shape({
    className: PropTypes.string
  })
}

TextArea.defaultProps = {
  type: 'text',
  placeholder: '',
  id: '',
  className: '',
  heightSize: 's',
  isRequired: false,
  isDisabled: false,
  textarea: {
    className: ''
  }
}

export default TextArea
