import React, {forwardRef, useState} from 'react'

import ContractBasicDataForm from '../../Organisms/Forms/ContractBasicDataForm'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const ContractEditModal = forwardRef(({data, onUpdate, contractTypes}, ref) => {
  const [isResetData, setIsResetData] = useState(false)

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const handleOnChangeOpenModal = isOpen => {
    if (isOpen) return

    // reset state when the modal is closed
    setIsResetData(true)
  }

  return (
    <ModalTemplate ref={ref} className="jumbo" onChangeOpen={handleOnChangeOpenModal}>
      <h3 className="s-left s-mb-0">Contrato</h3>
      <p className="small s-color-light-text s-mb-24px">Edita la información del contrato.</p>
      <ContractBasicDataForm
        data={data}
        isResetData={isResetData}
        onCancel={handleCancel}
        onUpdate={onUpdate}
        contractTypes={contractTypes}
      />
    </ModalTemplate>
  )
})

ContractEditModal.displayName = 'ContractEditModal'

export default ContractEditModal
