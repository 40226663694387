import {createDuck} from 'redux-duck'

import {capitalize} from '../../helpers/string-format'

const duck = createDuck('app/user')

// Define action types
export const SET_USER_DATA = duck.defineType('SET_USER_DATA')
export const EDIT_USER = duck.defineType('EDIT_USER')
export const SIGN_IN = duck.defineType('SIGN_IN')
export const SIGN_OUT = duck.defineType('SIGN_OUT')

// Define action creators
export const actions = {
  set: duck.createAction(SET_USER_DATA),
  edit: duck.createAction(EDIT_USER),
  signIn: duck.createAction(SIGN_IN),
  signOut: duck.createAction(SIGN_OUT)
}

// Define selectors
export const selectors = {
  get: store => store.userReducer
}

const initialState = {}

export default duck.createReducer(
  {
    [SIGN_IN]: (state, {payload}) => ({
      ...state,
      ...payload
    }),
    [SET_USER_DATA]: (state, {payload}) => ({
      ...state,
      ...payload,
      name: capitalize(payload.name)
    }),
    [EDIT_USER]: (state, {payload}) => ({
      ...state,
      ...payload
    })
  },
  initialState
)
