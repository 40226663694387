import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

import {isEmpty, isEqual, isUndefined} from '../../../helpers/utils'

const Select = ({name, children, id, className, label, placeholder, isRequired, isDisabled, value, onChange}) => {
  const [placeholderShown, setPlaceholderShown] = useState(placeholder)

  const handleOnChange = e => {
    onChange(e)
    setPlaceholderShown(false)
  }

  useEffect(() => {
    // show the placeholder if the select was reseted
    if (isEqual(value, 0) || isEqual(value, '')) setPlaceholderShown(true)
  }, [value])

  return (
    <div className={`form-item ${className}`}>
      <select
        disabled={isDisabled}
        className={placeholderShown && (isEmpty(value) || isUndefined(value)) ? 'has-placeholder' : undefined}
        name={name}
        required={isRequired}
        onChange={handleOnChange}
        id={id}
        value={value}
        autoComplete="off"
      >
        {placeholder && (
          <option hidden value="">
            {/* Utiliza un placeholder generado con la etiqueta label para evitar escribir siempre el contenido */}
            {/* La validación de female es para el sustantivo femenino*/}
            {typeof placeholder === 'boolean' || placeholder === 'female'
              ? `Elige ${placeholder === 'female' ? 'una' : 'un'} ${label.split(' ')[0].toLowerCase()}`
              : // Si no se quiere un placeholder genérico, entonces se escribe a mano
                placeholder}
          </option>
        )}
        {children}
      </select>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
}

Select.defaultProps = {
  isRequired: false,
  isDisabled: false,
  placeholder: '',
  id: '',
  label: '',
  className: '',
  value: '',
  onChange: () => {}
}

export default Select
