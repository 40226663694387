import Icon from 'components/Atoms/Icon'
import {getCacheKeySocialSecurity, getLocalCache} from 'helpers/cache'
import {readBackendDate, shortFormatDate} from 'helpers/dates'
import {CREATE, EDIT} from 'helpers/payroll'
import {titleFunds} from 'helpers/socialsecurity'
import {isEqual, isNull, isUndefined, len} from 'helpers/utils'
import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  deleteEmployeeSocialSecurityEntity,
  getEmployeesEntitiesByContractIDAndFundType
} from 'services/employeeSocialSecurityEntities'
import {notifyError, notifySuccessful} from 'services/notification'

import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import SocialSecurityFundChangeForm from '../../Organisms/Forms/SocialSecurityFundChangeForm'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import NoveltyContainer from '../Containers/NoveltyContainer'
import ConfirmModal from './ConfirmModal'

export const initialStateAction = {
  type: null,
  contractID: 0,
  current: null,
  previous: null
}

const SocialSecurityFundEditModal = forwardRef(({data, onUpdate, fundType}, ref) => {
  const dispatch = useDispatch()
  const confirmModal = useRef()

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [funds, setFunds] = useState([])
  const [action, setAction] = useState(initialStateAction)
  const [deleteID, setDeleteID] = useState(0)

  const employerSession = useSelector(employerSelectors.getSession)
  const cacheSocSec = getLocalCache(getCacheKeySocialSecurity(employerSession.code_iso_3))
  const entities = !isNull(cacheSocSec) && cacheSocSec.data

  useEffect(() => {
    if (isUndefined(data?.contract?.id)) return
    if (isEqual(fundType?.id, 0)) return

    getEmployeesEntitiesByContractIDAndFundType(
      data?.contract?.id,
      fundType?.id,
      response => setFunds(response),
      error => dispatch(notifyError(error))
    )
    setAction(initialStateAction)
  }, [data.contract.id, fundType.id])

  const getEntitiesByType = entityCode => {
    if (!Array.isArray(entities)) return []
    const filtered = entities.filter(e => isEqual(e.entity, entityCode))[0]

    return filtered?.data || []
  }

  const handleCancel = () => setIsOpenForm(false)

  const handleNew = () => {
    setAction({
      type: CREATE,
      contractID: data?.contract?.id,
      current: null,
      previous: funds[funds.length - 1]?.employee_entity_history // the last record
    })

    setIsOpenForm(true)
  }

  const handleEditRecord = ix => {
    setAction({
      type: EDIT,
      contractID: data?.contract?.id,
      current: funds[ix]?.employee_entity_history,
      previous: isEqual(ix, 0) ? null : funds[ix - 1]?.employee_entity_history
    })

    setIsOpenForm(true)
  }

  const handleSuccessful = () => {
    setAction(initialStateAction)
    setIsOpenForm(false)
    refreshState()
  }

  const handleOpenConfirmDelete = recordID => {
    setDeleteID(recordID)
    confirmModal.current.openModal()
  }

  const handleDelete = () => {
    confirmModal.current.closeModal()

    deleteEmployeeSocialSecurityEntity(
      deleteID,
      data?.contract?.id,
      response => {
        setDeleteID(0)
        refreshState()
        dispatch(notifySuccessful('El registro ha sido eliminado correctamente 🎉'))
      },
      error => {
        setDeleteID(0)
        dispatch(notifyError(error))
      }
    )
  }

  const refreshState = () => {
    // when the new register is created, edited or deleted we prefer query the backend
    // for get all funds again because Backend do changes in various registers with a specific logic
    getEmployeesEntitiesByContractIDAndFundType(
      data?.contract?.id,
      fundType?.id,
      response => {
        setFunds(response)

        // Update status of the contract in <EmployeeInformationGrid/>
        const lastRecord = response[response.length - 1]
        onUpdate(state => ({
          ...state,
          employee_entity_history: [
            ...state.employee_entity_history.filter(e => e.entity_type_code !== fundType?.code),
            lastRecord
          ]
        }))
      },
      error => dispatch(notifyError(error))
    )
  }

  const handleOnChangeOpenModal = isOpen => {
    if (isOpen) return

    setIsOpenForm(false)
  }

  return (
    <>
      <ModalTemplate ref={ref} className="large" onChangeOpen={handleOnChangeOpenModal}>
        <h3 className="s-left s-mb-0">{titleFunds[fundType?.code]}</h3>
        <p className="small s-color-light-text s-mb-24px">
          {`Consulta, agrega, actualiza o elimina las entidades de ${titleFunds[fundType?.code]} del contrato.`}
        </p>
        <div className="s-main-end s-mb-1">
          {!isOpenForm && (
            <button type="button" className="button s-order-1 m-order-3 small" onClick={handleNew}>
              <div className="s-cross-center s-main-center">
                <Icon svg="plus" className="s-mr-4px" />
                <span className="overflow-ellipsis">Nuevo</span>
              </div>
            </button>
          )}
        </div>

        <NoveltyContainer
          isOpenForm={isOpenForm}
          form={
            <SocialSecurityFundChangeForm
              fundType={fundType}
              funds={getEntitiesByType(fundType.code)}
              hireDate={readBackendDate(data?.contract?.hire_date)}
              terminationDate={readBackendDate(data?.contract?.termination_date)}
              onCancel={handleCancel}
              onSuccessful={handleSuccessful}
              action={action}
            />
          }
          table={
            <Table
              heads={['Nombre', 'Fecha Inicial', 'Fecha Final', '¿Actual?', '']}
              classNameTh="m-center"
              className="table-template small whitespace-nowrap"
            >
              {Array.isArray(funds) &&
                funds.map((fund, ix) => (
                  <TableRow key={fund.employee_entity_history.id}>
                    <p className="m-center">{fund.social_security_entity_name}</p>
                    <p className="m-center">{shortFormatDate(fund.employee_entity_history.begins_at)}</p>
                    <p className="m-center">{shortFormatDate(fund.employee_entity_history.ends_at)}</p>
                    <p className="m-center">{fund.employee_entity_history.is_current ? 'Si' : 'No'}</p>
                    <p className="s-cross-center">
                      {fund.employee_entity_history.is_current && (
                        <>
                          <Icon
                            svg="edit"
                            className="s-mr-1 cursor-pointer s-color-blue blue"
                            onClick={() => handleEditRecord(ix)}
                          />
                          {len(funds) > 1 && (
                            <Icon
                              svg="trash"
                              className="s-color-red red cursor-pointer"
                              onClick={() => handleOpenConfirmDelete(fund.employee_entity_history.id)}
                            />
                          )}
                        </>
                      )}
                    </p>
                  </TableRow>
                ))}
            </Table>
          }
        />
      </ModalTemplate>

      <ConfirmModal
        ref={confirmModal}
        title="Eliminar Registro"
        content="¿Estás seguro de eliminar el fondo de seguridad social?, recuerda que esta acción no se puede revetir."
        confirmFunction={handleDelete}
      />
    </>
  )
})

SocialSecurityFundEditModal.displayName = 'SocialSecurityFundEditModal'

export default SocialSecurityFundEditModal
