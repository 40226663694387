import PropTypes from 'prop-types'
import React, {useCallback, useState} from 'react'
import Cropper from 'react-easy-crop'

import {getCroppedImg} from '../../helpers/canvas'

const CropImage = ({title, image, aspectRatio, onCropImageComplete, onCancel}) => {
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels)
      onCropImageComplete(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [image, croppedAreaPixels, onCropImageComplete])

  return (
    <div className="s-center">
      <h3 className="t3 l-block">{title}</h3>
      <div className="crop-container s-center s-to-center s-mb-32px">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <p className="s-mb-24px s-color-light-text small">Arrastra para posicionar y has zoom para redimensionar</p>
      <button type="button" className="button ghost s-mr-16px cancel" onClick={onCancel}>
        Cancelar
      </button>
      <button type="button" className="button ghost" onClick={handleCroppedImage}>
        Cortar
      </button>
    </div>
  )
}

CropImage.propTypes = {
  title: PropTypes.string.isRequired,
  onCropImageComplete: PropTypes.func,
  onCancel: PropTypes.func
}

CropImage.defaultProps = {
  title: 'Posiciona y redimensiona',
  aspectRatio: 1
}

export default CropImage
