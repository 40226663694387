import React from 'react'

import {isEqual, isUndefined, len} from '../../../helpers/utils'
import FloatContainer from '../../Wrappers/FloatContainer'
import CategoryTypeItem from '../Items/CategoryTypeItem'

const SelectCategory = ({config, categories, activeCategory, onSelect, className}) => {
  if (!Array.isArray(categories) || isEqual(len(categories), 0)) return null

  return (
    <div className={`novelty-select s-relative z-normal ${className}`}>
      <FloatContainer
        container={{
          className: 'items-container'
        }}
      >
        <CategoryTypeItem
          isSelected
          icon={config[activeCategory].icon}
          type={config[activeCategory].name}
          caption={config[activeCategory].caption}
        />

        <div className="float-container ed-grid row-gap s-gap-1">
          {categories.map(category => {
            const opt = config[category]
            if (isUndefined(opt)) return null

            return (
              <CategoryTypeItem
                key={category}
                isActive={isEqual(category, activeCategory)}
                isComingSoon={opt?.is_coming_soon}
                isDisabled={opt?.is_coming_soon}
                icon={opt?.icon}
                type={opt?.name}
                caption={opt?.caption}
                onClick={() => onSelect(category)}
              />
            )
          })}
        </div>
      </FloatContainer>
    </div>
  )
}

export default SelectCategory
