import React from 'react'

import {getMonth} from '../../../helpers/dates'
import {isEmpty} from '../../../helpers/utils'
import MonthProgress from '../../Molecules/MonthProgress'
import Container from '../../Wrappers/Container'
import MonthProgressEmptyState from '../Emptys/MonthProgressEmptyState'

const DashboardProgress = ({processes, month}) => {
  return (
    <Container>
      <h3>Progreso de {getMonth(month)}</h3>

      {isEmpty(processes) ? <MonthProgressEmptyState /> : <MonthProgress processes={processes} />}
    </Container>
  )
}

export default DashboardProgress
