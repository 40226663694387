import React from 'react'

import PayslipImg from '../../../static/images/stories/paysheet.svg'
import Icon from '../../Atoms/Icon'
import Container from '../../Wrappers/Container'

const ElectronicPayrollEmptyState = () => {
  return (
    <Container className="s-column s-center s-cross-center s-main-center">
      <div className="l-block" />
      <img className="s-mb-2" src={PayslipImg} alt="Nómina electrónica" width="200px" height="200px" />
      <h3>Aún no tienes documentos</h3>
      <div className="s-color-light-text">
        Da click en el botón{' '}
        <span className="s-color-text">
          <Icon svg="bolt" size="12" className="s-mr-2px" />
          Preparar
        </span>{' '}
        y tus documentos de nómina electrónica aparecerán aquí.
      </div>
    </Container>
  )
}

export default ElectronicPayrollEmptyState
