import {createDuck} from 'redux-duck'

import {
  CONCEPT_DEDUCTION,
  CONCEPT_DEDUCTION_SYSTEM,
  CONCEPT_EARNING,
  CONCEPT_EARNING_SYSTEM
} from '../../helpers/concepts'
import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/concepts')

// Define action types
export const SET_CONCEPTS = duck.defineType('SET_CONCEPTS')
export const APPEND_CONCEPTS = duck.defineType('APPEND_CONCEPTS')
export const SET_ABS_TYPE_CONCEPTS = duck.defineType('SET_ABS_TYPE_CONCEPTS')
export const APPEND_ABS_TYPE_CONCEPTS = duck.defineType('APPEND_ABS_TYPE_CONCEPTS')
export const APPEND_PROCESS_CONCEPTS = duck.defineType('APPEND_PROCESS_CONCEPTS')
export const REMOVE_PROCESS_CONCEPTS = duck.defineType('REMOVE_PROCESS_CONCEPTS')

// Define action creators
export const actions = {
  set: duck.createAction(SET_CONCEPTS),
  appendConcepts: duck.createAction(APPEND_CONCEPTS),
  setAbsenceTypeConcept: duck.createAction(SET_ABS_TYPE_CONCEPTS),
  appendAbsenceTypeConcept: duck.createAction(APPEND_ABS_TYPE_CONCEPTS),
  appendProcessConcept: duck.createAction(APPEND_PROCESS_CONCEPTS),
  removeProcessConcept: duck.createAction(REMOVE_PROCESS_CONCEPTS)
}

// Define selectors
export const selectors = {
  getConcepts: store => store.conceptReducer.concepts,
  getLeaveConcepts: store => store.conceptReducer.leave_concepts,
  getVacationConcepts: store => store.conceptReducer.vacation_concepts,
  getAbsenceConcepts: store => store.conceptReducer.absence_concepts,
  getProcessConcepts: store => store.conceptReducer.process_concepts,
  getExcludeConcepts: store => {
    const reducer = store.conceptReducer
    return [...reducer.leave_concepts, ...reducer.vacation_concepts, ...reducer.absence_concepts]
  },
  getNoveltyConcepts: store => {
    const reducer = store.conceptReducer
    const Concepts = reducer.concepts
    const excludeConcepts = [...reducer.leave_concepts, ...reducer.vacation_concepts, ...reducer.absence_concepts]
    return Concepts.filter(
      concept =>
        ((!excludeConcepts.find(excludeConceptID => excludeConceptID === concept.id) &&
          (concept.concept_type_description === CONCEPT_EARNING ||
            concept.concept_type_description === CONCEPT_EARNING_SYSTEM)) ||
          concept.concept_type_description === CONCEPT_DEDUCTION ||
          concept.concept_type_description === CONCEPT_DEDUCTION_SYSTEM) &&
        concept.is_fixed === false
    )
  },
  getAbsenceConceptSetting: store => {
    const reducer = store.conceptReducer
    const concepts = reducer.concepts
    const absConcepts = reducer.absence_type_concepts

    let objConcept = {}
    concepts.forEach(concept => (objConcept[concept.id] = concept))

    let result = {
      list: [],
      types: {}
    }
    absConcepts.forEach(tc => {
      result.list.push(objConcept[tc.concept_id])
      result.types[tc.concept_id] = tc.absence_type_id
    })

    return result
  }
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_CONCEPTS]: (state, {payload}) => ({
      ...state,
      ...payload
    }),
    [APPEND_CONCEPTS]: (state, {payload}) => ({
      ...state,
      concepts: [...state.concepts, payload]
    }),
    [SET_ABS_TYPE_CONCEPTS]: (state, {payload}) => ({
      ...state,
      absence_type_concepts: [...payload]
    }),
    [APPEND_ABS_TYPE_CONCEPTS]: (state, {payload}) => ({
      ...state,
      absence_type_concepts: [...state.absence_type_concepts, payload],
      absence_concepts: [...state.absence_concepts, payload.concept_id]
    }),
    [APPEND_PROCESS_CONCEPTS]: (state, {payload}) => ({
      ...state,
      process_concepts: [
        ...state.process_concepts.map(pc => {
          if (pc.process_type_id !== payload.process_type_id) return pc

          return {...pc, concept_ids: [...pc.concept_ids, payload.concept_id]}
        })
      ]
    }),
    [REMOVE_PROCESS_CONCEPTS]: (state, {payload}) => ({
      ...state,
      process_concepts: [
        ...state.process_concepts.map(pc => {
          if (pc.process_type_id !== payload.process_type_id) return pc

          return {...pc, concept_ids: [...pc.concept_ids.filter(c => c !== payload.concept_id)]}
        })
      ]
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState
  },
  initialState
)
