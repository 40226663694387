import es from 'date-fns/locale/es'
import PropTypes from 'prop-types'
import React from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'

registerLocale('es', es)

const MonthYearPickerInput = ({
  label,
  className,
  isRequired,
  id,
  disabled,
  name,
  value,
  onChange,
  minDate,
  maxDate,
  ...rest
}) => {
  return (
    <div className={`form-item month-year-picker ${className}`}>
      <DatePicker
        disabled={disabled}
        id={id}
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        required={isRequired}
        className="date-picker"
        locale="es"
        autoComplete="off"
        placeholderText="MM/aaaa"
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
      {label && (
        <label htmlFor={id} className={isRequired && 'required'}>
          {label}
        </label>
      )}
    </div>
  )
}

MonthYearPickerInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date)
}

MonthYearPickerInput.defaulProps = {
  isRequired: false,
  id: '',
  onChange: () => {}
}

export default MonthYearPickerInput
