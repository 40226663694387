import React from 'react'

import SelectButton from '../../../components/Molecules/Selects/SelectButton'
import {mediaToM} from '../../../helpers/media-queries'
import {useMedia} from '../../../hooks/useMedia'
import useGetEmployeesReportoptions from '../hooks/useGetEmployeesReportoptions'
import {useEmployeesSectionModalsWrapperContext} from '../providers/EmployeesSectionModalsWrapper'

const EmployeesPanelReports = () => {
  const toM = useMedia(mediaToM)
  const {EMPLOYEES_REPORT_OPTIONS} = useGetEmployeesReportoptions()
  const {isDownloadingReport} = useEmployeesSectionModalsWrapperContext()

  return (
    <SelectButton
      isDisable={isDownloadingReport}
      classButton={`ghost ${toM && 'small'} s-w-full`}
      iconName="document"
      description={isDownloadingReport ? 'Descargando...' : 'Reportes'}
      options={EMPLOYEES_REPORT_OPTIONS}
    />
  )
}

export default EmployeesPanelReports
