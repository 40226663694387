import {isObject, isUndefined} from 'helpers/utils'

import {KEY_ORGANIZATIONAL_STRUCTURE_ID, KEY_PAY_FREQUENCY_ID, KEY_PLACE_LABOR_MUNICIPALITY_ID} from './const'

export function getDescriptions(payload, formData) {
  if (!isObject(payload)) {
    throw new Error('the payload must be an object')
  }

  if (!isObject(formData)) {
    throw new Error('the formData must be an object')
  }

  let descriptions = {}
  let fields = [
    {key: KEY_ORGANIZATIONAL_STRUCTURE_ID, desc: 'area'},
    {key: KEY_PLACE_LABOR_MUNICIPALITY_ID, desc: 'municipality_description'},
    {key: KEY_PAY_FREQUENCY_ID, desc: 'pay_frequency'}
  ]

  for (const field of fields) {
    if (!isUndefined(payload[field.key])) {
      descriptions[field.desc] = formData[field.desc] || ''
    }
  }

  return descriptions
}
