import Proptypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'

import {EmployeeContext, PhonePanelContext} from '../../../contexts/contexts'
import {mediaToM} from '../../../helpers/media-queries'
import {isEmpty, isEqual, len} from '../../../helpers/utils'
import {useMedia} from '../../../hooks/useMedia'
import EmployeesPanelReports from '../../../modules/employees-panel/components/EmployeesPanelReports'
import SearchInput from '../../Atoms/Forms/SearchInput'
import Filter from '../../Molecules/Filter'
import SidebarEmployee from '../../Molecules/SidebarEmployee'
import SidebarEmployeeSkeleton from '../../Molecules/Skeleton/SidebarEmployeeSkeleton'
import Sort from '../../Molecules/Sort'
import Container from '../../Wrappers/Container'

const EmployeesPanel = props => {
  const {
    loading,
    tag,
    limitEmployees,
    sortOptions,
    defaultSortOption,
    filterOptions,
    showAddEmployeeButton,
    onChangeSort,
    onChangeFilter,
    onChangeSearch,
    onClickAddEmployee,
    onSelectEmployee,
    onScrollEnd,
    employees
  } = props

  const {setActive} = useContext(PhonePanelContext)
  const {findEmployeeValue, findEmployeeID} = useContext(EmployeeContext)
  const toTablet = useMedia(mediaToM)
  const [activeEmployeeID, setActiveEmployeeID] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const employeeContainer = useRef()

  useEffect(() => {
    if (isEmpty(findEmployeeValue) || isEqual(findEmployeeID, 0)) return

    setSearchValue(findEmployeeValue)
    onChangeSearch(findEmployeeValue)
    setActiveEmployeeID(findEmployeeID)
  }, [findEmployeeValue, findEmployeeID]) // eslint-disable-line

  const handleClickAddEmployee = () => {
    if (toTablet) setActive(true)

    setActiveEmployeeID(0)
    onClickAddEmployee()
  }

  const handleSelectEmployee = employee => {
    setActiveEmployeeID(employee.id)
    onSelectEmployee(employee)
  }

  const handleSort = option => {
    resetScroll()
    setActiveEmployeeID(0)
    onChangeSort(option)
  }

  const handleFilter = option => {
    resetScroll()
    setActiveEmployeeID(0)
    onChangeFilter(option)
  }

  const handleSearch = e => {
    const value = e.target.value
    setActiveEmployeeID(0)
    setSearchValue(value)

    // Only launch the event if the length value is greater than 3 or equal to zero (reset)
    if (len(value) > 3 || len(value) === 0) {
      resetScroll()
      onChangeSearch(value)
    }
  }

  const handleScroll = e => {
    // no notify onScrollEnd if the status is "loading" because the scroll generated is of
    // the component <SidebarEmployeeSkeleton /> and it isn't of employee list
    if (loading) return

    // no notify onScrollEnd if the employee list reached its maximum limit for avoid more
    // request to backend
    if (len(employees) >= limitEmployees) return

    // if the scroll is reseted by others handlers to top 0 then returns, because it isn't
    // a scroll of the employee list
    const container = employeeContainer.current

    if (!container) return
    if (isEqual(container.scrollTop, 0)) return

    // In some cases the sum of offsetHeight and scrollTop not reach the scrollHeight by one only pixel,
    // then we always adjustment 5 pixel to make sure us of request the next page
    const pixelAdjustment = 5

    // call props onScrollEnd() if the scroll is in the bottom of the container
    if (Math.ceil(container.offsetHeight + container.scrollTop + pixelAdjustment) >= container.scrollHeight) {
      onScrollEnd()
    }
  }

  const resetScroll = () => {
    const container = employeeContainer.current
    if (!container) return

    container.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <Container className="sticky-element s-h-vh employees-panel">
      <div className={`panel-section s-mb-16px m-mb-24px ${showAddEmployeeButton ? 'has-button' : ''}`}>
        <header className="s-mb-16px m-mb-24px s-cross-center s-main-justify">
          <h4 className="s-mb-0">Empleados</h4>
          <div className="flex">
            <Filter options={filterOptions} onSelect={handleFilter} className="s-mr-12px" />

            <Sort options={sortOptions} defaultSortOption={defaultSortOption} onSelect={handleSort} />
          </div>
        </header>
        <SearchInput
          placeholder="Busca por nombre, cédula o código"
          className="s-mb-16px m-mb-24px"
          value={searchValue}
          onChange={handleSearch}
        />

        {showAddEmployeeButton && (
          <div className="ed-grid s-grid-2 s-gap-2">
            <button className="button overflow-ellipsis" type="button" onClick={handleClickAddEmployee}>
              Nuevo
            </button>

            <EmployeesPanelReports />
          </div>
        )}
      </div>

      <div
        className={`panel-section ed-grid row-gap s-gap-1 s-cross-start ${showAddEmployeeButton ? 'has-button' : ''}`}
        ref={employeeContainer}
        onScroll={handleScroll}
      >
        {loading ? (
          <SidebarEmployeeSkeleton />
        ) : isEmpty(employees) ? (
          <div className="s-px-6px s-py-8px s-mb-0 s-center small s-color-light-text s-bg-body-alt">
            <span role="img" aria-label="" className="s-mr-8px">
              🥺
            </span>
            <span>¡Vaya! no encontramos ningún empleado.</span>
          </div>
        ) : (
          employees.map(employee => (
            <SidebarEmployee
              isActive={isEqual(employee.id, activeEmployeeID)}
              key={employee.id}
              employee={employee}
              tag={tag}
              onSelectEmployee={handleSelectEmployee}
            />
          ))
        )}

        {len(employees) >= limitEmployees && (
          <div className="s-px-6px s-py-8px s-mb-0 s-center small s-color-light-text s-bg-body-alt">
            <span role="img" aria-label="" className="s-mr-8px">
              👀
            </span>
            <span>¡Vaya! parece que no encuentras lo que buscas, mejor usa los filtros o el buscador.</span>
          </div>
        )}
      </div>
    </Container>
  )
}

EmployeesPanel.propTypes = {
  loading: Proptypes.bool,
  tag: Proptypes.object,
  limitEmployees: Proptypes.number,
  showAddEmployeeButton: Proptypes.bool,
  onClickAddEmployee: Proptypes.func,
  filterOptions: Proptypes.array,
  sortOptions: Proptypes.array,
  onChangeFilter: Proptypes.func,
  onChangeSort: Proptypes.func,
  onSelectEmployee: Proptypes.func,
  onChangeSearch: Proptypes.func,
  onScrollEnd: Proptypes.func,
  isLoadingDownloadReport: Proptypes.bool,
  employees: Proptypes.array
}

EmployeesPanel.defaultProps = {
  employees: [],
  loading: false,
  tag: {
    descriptionField: 'contract_status',
    colorField: 'contract_status_color'
  },
  limitEmployees: 100,
  showAddEmployeeButton: false,
  onClickAddEmployee: () => {},
  filterOptions: [],
  sortOptions: [],
  onChangeFilter: () => {},
  onChangeSort: () => {},
  onSelectEmployee: () => {},
  onChangeSearch: () => {},
  onScrollEnd: () => {},
  isLoadingDownloadReport: false
}

export default EmployeesPanel
