import React from 'react'

const Loader = () => {
  return (
    <svg
      viewBox="0 0 300 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="loader"
      className="nomineros-loader"
    >
      <g className="loader">
        <path d="M100 200L-2.98023e-06 250V150L100 200Z" fill="#4A78D2">
          <animateTransform
            id="left-bottom"
            attributeName="transform"
            dur="2s"
            type="translate"
            values="0 0; 0 -150; 100 -100; 0 -150; 0 0"
            begin="0;left-bottom.end+.5s"
          />
        </path>
        <path d="M100 50L0 0V100L100 150V50Z" fill="#4A78D2">
          <animateTransform
            id="left-top"
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 0; 100 50; 0 0"
            begin=".5s;left-top.end+1.5s"
          />
        </path>
        <path d="M200 50L300 0V100L200 50Z" fill="#5FB6FC">
          <animateTransform
            id="right-top"
            attributeName="transform"
            dur="2s"
            type="translate"
            values="0 0; 0 150; -100 100; 0 150; 0 0"
            begin="0;right-top.end+.5s"
          />
        </path>
        <path d="M300 150L200 100V200L300 250V150Z" fill="#5FB6FC">
          <animateTransform
            id="right-bottom"
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 0; -100 -50; 0 0"
            begin=".5s;right-bottom.end+1.5s"
          />
        </path>
        <path d="M200 100L100 50V150L200 200V100Z" fill="#5B9FFA">
          <animate id="center" attributeName="opacity" dur="2s" values="1;0;1" begin="0;center.end+.5s" />
        </path>
      </g>
    </svg>
  )
}

export default Loader
