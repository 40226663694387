import '../../styles/monaco-editor-themes/dracula.json'

import Editor, {useMonaco} from '@monaco-editor/react'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../helpers/cache'
import {isEqual} from '../../helpers/utils'
import {selectors as employerSelectors} from '../../redux/ducks/employer'
import {getDocumentationByEmployerID as getFuntionsDocumentation} from '../../services/formulatorFunctions'
import FullLoader from './Loaders/FullLoader'

const FILE_FORMULA = 'formula.js'

const FormulatorEditor = ({
  width = '100%',
  height = '100%',
  theme = 'light',
  initialFormula,
  noveltySource = '',
  isDisabled = false,
  editorRef = null,
  onMount = () => {},
  toolbar = null
}) => {
  const mocanoEditor = useMonaco()
  const employer = useSelector(employerSelectors.getSession)

  // FileDocs must be different for each employer, so we make sure of use the correct documentation
  // for each employer, remember that the employer has variables of a country and it can has
  // its own custom formulas
  const FILE_DOCS = `docs${employer?.code_iso_3}_${employer?.id}.js`

  // We use two files "docs.js" and "formula.js", The docs.js contains the JSDoc for document
  // the variables and funtions of the formulator, this file is hide and it is seleted one time
  // for load the documentation and it is available for use it in the suggestions in formula.js file
  const [isLoadingEditor, setIsLoadingEditor] = useState(true)
  const [isLoadingDocumentation, setIsLoadingDocumentation] = useState(true)
  const [fileName, setFileName] = useState(FILE_DOCS)
  const [documentation, setDocumentation] = useState('')

  useEffect(() => {
    if (mocanoEditor) {
      // Load custom themes, we can find more themes here https://bitwiser.in/monaco-themes/
      import('../../styles/monaco-editor-themes/dracula.json').then(data =>
        mocanoEditor.editor.defineTheme('dracula', data)
      )
    }
  }, [mocanoEditor])

  useEffect(() => {
    setIsLoadingDocumentation(true)
    getFuntionsDocumentation(employer.id, response => {
      setDocumentation(`
      ${response || ''}
      
     /** 
      * Es la variable que se debe usar para asignar el resultado de una formula 
      * @type {number} resultado
      */
      const resultado;

     /**
      * @typedef  VariablesNomineros
      * @type {object}
      ${getLocalCache(getCacheKeyGeneral(employer?.code_iso_3))?.data?.doc_global_variables}
      *
      ${getLocalCache(getCacheKeyGeneral(employer?.code_iso_3))?.data?.doc_variables_by_novelty?.[noveltySource] || ''}
      */

     /** @type {VariablesNomineros} 
      * Usa las variables para formular tus conceptos. 
      */
      var $;
      `)

      setIsLoadingDocumentation(false)
      onMount()
    })
  }, [employer, noveltySource]) // eslint-disable-line

  useEffect(() => {
    if (isEqual(fileName, FILE_DOCS)) setIsLoadingEditor(true)
    if (isEqual(fileName, FILE_FORMULA)) setIsLoadingEditor(false)

    editorRef.current !== null && editorRef.current.focus()
  }, [fileName, editorRef, FILE_DOCS])

  // Monaco editor always persist the files data still if the component is dismounted, this
  // represent a problem for we, because if the user changes the company and these have differents
  // countries we will have various files of documentation, one per country and we could have
  // unexpected behaviors of the suggestions, for example we could suggest variables of COL
  // for a company of PER, for solve this we always delete the all models persited by monaco
  // before the mount the editor.
  const handleBeforeMountEditor = monaco => {
    monaco.editor.getModels().forEach(model => model.dispose())
  }

  const handleOnMountEditor = (editor, monaco) => {
    // when the editor is mount, we render the file docs.js for load in the editor's memory
    // the all documentation of the formulator, after we load the file formula.js for that
    // the user can add its formulation.
    setFileName(FILE_DOCS)
    setFileName(FILE_FORMULA)
    editorRef.current = editor
  }

  return (
    <div className="s-relative s-h-full m-rows-2 lg-rows-2 m-cols-3 lg-cols-3 s-border-border normal-radius s-pxy-2">
      {isLoadingEditor && <FullLoader isAbsolute hasSolidColor style={{minHeight: height}} />}
      {isLoadingDocumentation || (
        <>
          {toolbar}
          <Editor
            saveViewState={false}
            keepCurrentModel={false}
            defaultLanguage="javascript"
            defaultValue={isEqual(fileName, FILE_FORMULA) ? initialFormula : documentation}
            path={fileName}
            width={width}
            height={height}
            theme={theme}
            onMount={handleOnMountEditor}
            beforeMount={handleBeforeMountEditor}
            // all options reference https://microsoft.github.io/monaco-editor/docs.html#interfaces/editor.IStandaloneEditorConstructionOptions.html
            options={{
              padding: {top: 16},
              readOnly: isDisabled,
              fontFamily: 'Ubuntu Mono',
              fontSize: 16,
              lineNumbers: 'on',
              lineNumbersMinChars: 5,
              wordWrap: 'on',
              suggest: {
                preview: true, // Enable or disable the rendering of the suggestion preview.
                previewMode: 'subwordSmart', // Configures the mode of the preview. "prefix" | "subword" | "subwordSmart"
                showClasses: false,
                showColors: false,
                showConstants: true,
                showConstructors: false,
                showDeprecated: false,
                showEnumMembers: false,
                showEnums: false,
                showEvents: false,
                showFields: true, // fields of the object
                showFiles: false,
                showFolders: false,
                showFunctions: false, // disable because also suggest JS standard functions
                showIcons: true,
                showInlineDetails: true,
                showInterfaces: false,
                showIssues: false,
                showKeywords: false,
                showMethods: false,
                showModules: false,
                showOperators: true,
                // showProperties allows suggest the nomineros' functions, we need create
                // the documentation function as const. ej: "const = SI(cond, if_true, if_false) => {}
                showProperties: true,
                showReferences: false,
                showSnippets: false,
                showStatusBar: false,
                showStructs: false,
                showTypeParameters: false,
                showUnits: false,
                showUsers: false,
                showValues: false,
                showVariables: false, // disabled because also suggest JS Interfaces
                showWords: false
              },
              contextmenu: false,
              minimap: {
                enabled: false
              }
            }}
          />
        </>
      )}
    </div>
  )
}

export default FormulatorEditor
