import {PropTypes} from 'prop-types'
import React, {createContext, useContext} from 'react'

const ParameterValuesContext = createContext({})

const ParameterValuesProvider = ({children, parameterValues}) => {
  return (
    <ParameterValuesContext.Provider
      value={{
        parameterValues
      }}
    >
      {children}
    </ParameterValuesContext.Provider>
  )
}

export const useParameterValuesContext = () => useContext(ParameterValuesContext)

ParameterValuesProvider.propTypes = {
  children: PropTypes.node
}

ParameterValuesProvider.defaultProps = {
  children: null
}

export default ParameterValuesProvider
