import React from 'react'

import ActionTextButton from '../Templates/ActionTextButton'

const EditTextButton = ({text = 'Editar', color = 'blue', className, isDisable, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-${color} ${color} ${className}`}
      onClick={onClick}
      icon="edit"
      text={text}
      isDisable={isDisable}
    />
  )
}

export default EditTextButton
