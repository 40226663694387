import React, {forwardRef, useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyBanks, getLocalCache} from '../../../helpers/cache'
import {getFormatCurrency} from '../../../helpers/format-number'
import {CASH, CHECK, PAYMENT_DEPOSITS, WIRE_TRANSFER} from '../../../helpers/payroll'
import {capitalize} from '../../../helpers/string-format'
import {isEqual, isNull} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {createDeposit} from '../../../services/depositPayment'
import {getEmployerBanks} from '../../../services/employerBanks'
import {notifyError} from '../../../services/notification'
import Select from '../../Atoms/Forms/Select'
import PaymentSummary from '../../Molecules/PaymentSummary'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const PaySummaryModal = forwardRef(
  ({contractsIDs, employeesToPay, totalNetPay, paymentMethod, banks, setActiveOptionPage}, ref) => {
    const dispatch = useDispatch()
    const employerSession = useSelector(employerSelectors.getSession)
    const {activeProcess} = useContext(PayrollContext)

    const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
    const allBankFiles = !isNull(cacheBanks) && cacheBanks.data?.bank_files

    const [bankAccountID, setBankAccountID] = useState(0)
    const [bankFileID, setBankFileID] = useState(0)
    const [employerAccounts, setEmployerAccounts] = useState([])
    const [bankFiles, setBankFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      getEmployerBanks(response => setEmployerAccounts(response.filter(a => a.is_active)))
    }, [])

    useEffect(() => {
      setBankAccountID(0)
      setBankFileID(0)
    }, [paymentMethod])

    const handleOnChangeBankAccountID = e => {
      setBankAccountID(e.target.value)

      const options = e.target.options
      const i = options.selectedIndex
      const bankID = options[i].getAttribute('data-bank-id')

      setBankFileID(0)
      setBankFiles(!allBankFiles ? [] : allBankFiles.filter(b => isEqual(b.bank_id, Number(bankID))))
    }

    const handleOnChangeBankFileID = e => {
      setBankFileID(e.target.value)
    }

    const handleGenerateDepositPay = () => {
      const payload = {
        process_id: activeProcess.id,
        payment_method: paymentMethod,
        employer_bank_id: Number(bankAccountID),
        bank_file_id: Number(bankFileID),
        contract_ids: contractsIDs
      }

      // Validate fields required for payment method WIRE TRANSFER
      if (isEqual(paymentMethod, WIRE_TRANSFER)) {
        const validatorWireTransfer = new ValidatorForm(payload, {
          employer_bank_id: 'integer|required|not_in:0',
          bank_file_id: 'integer|required|not_in:0'
        })
        validatorWireTransfer.setAttributeNames({
          employer_bank_id: 'Cuenta Bancaria',
          bank_file_id: 'Archivo de Banco'
        })

        if (validatorWireTransfer.fails()) {
          dispatch(notifyError(validatorWireTransfer.errors()))
          return
        }
      }

      // Validate other fields Payload

      const validator = new ValidatorForm(payload, {
        process_id: 'integer|required|not_in:0',
        payment_method: `required|string|in:${WIRE_TRANSFER},${CHECK},${CASH}`,
        contract_ids: 'array'
      })

      validator.setAttributeNames({
        process_id: 'Número del proceso',
        payment_method: 'Método de pago',
        contract_ids: 'Lista de contratos'
      })

      if (validator.fails()) {
        dispatch(notifyError(validator.errors()))
        return
      }

      setIsLoading(true)
      createDeposit(
        payload,
        response => {
          setIsLoading(false)
          setActiveOptionPage(PAYMENT_DEPOSITS)
        },
        error => {
          dispatch(notifyError(error))
          setIsLoading(false)
        }
      )
    }

    return (
      <ModalTemplate ref={ref} className="s-center">
        <h3 className="s-mb-24px">Deposito de pago</h3>

        <PaymentSummary
          employeesLength={employeesToPay}
          amountToPay={`${getFormatCurrency(
            totalNetPay,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}`}
          paymentMethod={capitalize(paymentMethod)}
        />

        {isEqual(paymentMethod, WIRE_TRANSFER) && (
          <div className="form-grid ed-grid s-mb-4">
            <Select
              isRequired
              label="Cuenta Bancaria"
              name="account_bank"
              id="account_bank"
              placeholder={'female'}
              value={bankAccountID}
              onChange={handleOnChangeBankAccountID}
            >
              {Array.isArray(employerAccounts) &&
                employerAccounts.map(account => (
                  <option key={account.id} value={account.id} data-bank-id={account.bank_id}>
                    {`${banks.find(b => isEqual(b.id, account.bank_id))?.short_name}
                      (${account.account_number})
                    `}
                  </option>
                ))}
            </Select>

            <Select
              isRequired
              label="Archivo de Banco"
              name="file_bank"
              id="file_bank"
              placeholder
              value={bankFileID}
              onChange={handleOnChangeBankFileID}
            >
              {Array.isArray(bankFiles) &&
                bankFiles.map(bankFile => (
                  <option key={bankFile.id} value={bankFile.id}>
                    {bankFile.description}
                  </option>
                ))}
            </Select>
          </div>
        )}

        <div className="buttons-container">
          <button type="button" className="button" onClick={handleGenerateDepositPay} disabled={isLoading}>
            {isLoading ? 'Generando...' : 'Generar'}
          </button>
        </div>
      </ModalTemplate>
    )
  }
)

PaySummaryModal.displayName = 'PaySummaryModal'

export default PaySummaryModal
