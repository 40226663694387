import React, {useRef, useState} from 'react'

import {readBackendDate, shortFormatDate} from '../../../helpers/dates'
import {isNull} from '../../../helpers/utils'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import PersonalDataEmployeeEditModal from '../Modals/PersonalDataEmployeeEditModal'

const PersonaInformationData = ({data, onUpdate}) => {
  const initialState = {...data, birthdate: readBackendDate(data?.birthdate)}
  const [formData, setFormData] = useState(initialState)

  const editModal = useRef()

  const handleOnEdit = () => {
    setFormData(initialState)
    editModal.current.openModal()
  }

  return (
    <>
      <EmployeeInformationContainer title="Datos personales" icon="user" editFunction={handleOnEdit}>
        <PanelInformationItem title="Género" text={data.gender} />
        <PanelInformationItem title="Estado civil" text={data.marital_status} />
        <PanelInformationItem title="Teléfono" text={data.phone} />
        <PanelInformationItem title="Celular" text={data.mobile} />
        <PanelInformationItem
          title="Correo electrónico"
          text={data.email}
          classesText="overflow-wrap-anywhere"
          twoColumns
        />
        <PanelInformationItem title="Dirección" text={data.address} twoColumns />

        {!isNull(shortFormatDate(data.birthdate)) && (
          <PanelInformationItem
            title="Fecha de nacimiento"
            text={`${shortFormatDate(data.birthdate)} ${data.birthplace}`}
            twoColumns
          />
        )}
      </EmployeeInformationContainer>
      <PersonalDataEmployeeEditModal
        ref={editModal}
        formData={formData}
        setFormData={setFormData}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default PersonaInformationData
