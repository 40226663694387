import {isNull} from 'helpers/utils'
import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'

import {FloatContainerContext} from '../../contexts/contexts'
import {useClickOutside} from '../../hooks/useClickOutside'
import {useKeyDownEsc} from '../../hooks/useKeyDownEsc'
import FadePopUp from '../Atoms/Animations/FadePopUp'

const FloatContainer = ({children, container, isInputTheContainer}) => {
  // Estado para mostrar el contenedor flotante
  const [active, setActive] = useState(false)
  // Referencia activador
  const trigger = useRef()
  // Referencia contenido
  const content = useRef()
  // Detectamos si se dio click fuera del contenido
  const clickOutsideContent = useClickOutside(content)
  // Detectamos si se dio clck fuera del activador
  const clickOutsideTrigger = useClickOutside(trigger)
  // Detectamos la tecla de ESC
  let keyDownEsc = useKeyDownEsc()

  const handleBlurInput = e => {
    if (isInputTheContainer && !isNull(e.relatedTarget)) {
      setActive(false)
    }
  }

  const handleFocusInput = () => {
    if (isInputTheContainer) setActive(true)
  }

  // Activamos o desactivamos el contenido del click
  const handleToggleActiveClick = () => {
    if (isInputTheContainer) {
      setActive(true)
      return
    }

    setActive(!active)
  }

  // Validamos si se dio click fuera del contenido y del activador o presionó la tecla ESC para cerrar la ventana
  useEffect(() => {
    if ((clickOutsideContent && clickOutsideTrigger) || keyDownEsc) {
      setActive(false)
    }
  }, [clickOutsideContent, clickOutsideTrigger, keyDownEsc])

  return (
    <FloatContainerContext.Provider value={{setActive}}>
      <div ref={trigger} onClick={handleToggleActiveClick} onFocus={handleFocusInput} onBlur={handleBlurInput}>
        {children[0]}
      </div>
      <FadePopUp show={active} className={container.className}>
        <div ref={content}>{children[1]}</div>
      </FadePopUp>
    </FloatContainerContext.Provider>
  )
}

FloatContainer.propTypes = {
  container: PropTypes.shape({
    className: PropTypes.string
  })
}

FloatContainer.defaultProps = {
  container: {
    className: ''
  }
}

export default FloatContainer
