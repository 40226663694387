import React from 'react'

import PasswordInput from '../components/Atoms/Forms/PasswordInput'
import WelcomeContainer from '../components/Wrappers/WelcomeContainer'
import WritePasswordGraphic from '../static/images/stories/write-password.svg'

const NewPassword = () => {
  return (
    <WelcomeContainer title="Nueva contraseña">
      {/* Comienzo: recuperar contraseña*/}
      <p className="small s-mt-2 s-mb-0">
        Tu nueva contraseña debe ser distinta a alguna que hayas usado con anteoridad
      </p>

      <div className="s-main-center">
        <img src={WritePasswordGraphic} alt="Sujeto que olvidó su contraseña. Seguramente es muy descuidado." />
      </div>

      <PasswordInput label="Nueva contraseña" />
      <PasswordInput label="Repite tu nueva contraseña" />

      <input type="submit" value="Restablece tu contraseña" className="button full" />
    </WelcomeContainer>
  )
}

export default NewPassword
