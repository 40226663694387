import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {isEmpty, isEqual} from '../../../../helpers/utils'
import ValidatorForm from '../../../../helpers/validator'
import {notifyError} from '../../../../services/notification'
import {generateVacationsBookReport} from '../../../../services/reportsTemp'
import {
  TYPE_SELECTION,
  VACATIONS_BOOK_FORM_FIELD_NAMES,
  VACATIONS_BOOK_FORM_INITIAL_STATE,
  VACATIONS_BOOK_FORM_RULES
} from '../../constants/form'
import {useDownloadLinkReportContext} from '../../providers/DownloadLinkReport'
import {useEmployeesSectionModalsWrapperContext} from '../../providers/EmployeesSectionModalsWrapper'

const useVacationsBookForm = ({modalRef}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(VACATIONS_BOOK_FORM_INITIAL_STATE)
  const {setisDownloadingReport} = useEmployeesSectionModalsWrapperContext()
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(VACATIONS_BOOK_FORM_INITIAL_STATE)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const validator = new ValidatorForm(formData, VACATIONS_BOOK_FORM_RULES)
    validator.setAttributeNames(VACATIONS_BOOK_FORM_FIELD_NAMES)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    if (isEqual(formData.selectionType, TYPE_SELECTION) && isEmpty(formData.contractIds)) {
      dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
      return
    }

    setisDownloadingReport(true)

    const payload = {
      request_date: formData.requestDate,
      contract_ids: formData.contractIds
    }

    generateVacationsBookReport(
      payload,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setisDownloadingReport(false)

        modalRef.current.closeModal()
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setisDownloadingReport(false)
      }
    )
  }

  return {
    formData,
    setFormData,
    resetForm,
    handleSubmit
  }
}

export default useVacationsBookForm
