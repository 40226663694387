import React, {useEffect, useState} from 'react'

import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatBaseList from '../FloatBaseList'

const SelectBaseInput = ({
  id = 'base_id',
  name = 'base',
  label = 'Base',
  placeholder = 'Escribe para buscar',
  initialValue = '',
  isRequired = false,
  isDisabled = false,
  bases = [],
  onSelect = () => {},
  ...rest
}) => {
  const [value, setValue] = useState(initialValue)
  const [list, setList] = useState(bases)
  const [listIndex, setListIndex] = useState({active: 0, max: len(bases)})
  const [sizeBases, setSizeBases] = useState(len(bases))

  // when the bases changes we need reset the state, an external component can change the list
  // when already process it the value of the selector.
  useEffect(() => {
    if (len(bases) === sizeBases) return

    setSizeBases(len(bases))
    setList(bases)
    setListIndex({active: 0, max: len(bases)})
    setValue('')
  }, [bases, sizeBases])

  const handleFilter = searchValue => {
    const result = bases.filter(base => {
      return (
        base.description.toLowerCase().includes(searchValue.toLowerCase()) ||
        base.base.toLowerCase().includes(searchValue.toLowerCase())
      )
    })

    const size = isEmpty(result) ? 0 : len(result) - 1

    setList(result)
    setListIndex({active: 0, max: size})
  }

  const handleOnChangeInput = newValue => {
    setValue(newValue)

    // clear the base because the user change the input value and not to selected a
    // new baseID
    onSelect(0)

    handleFilter(newValue)
  }

  const handleKeyPress = e => {
    if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

    e.preventDefault()

    if (isEqual(e.key, 'Enter')) {
      handleOnSelect(null)
      return
    }

    moveIndexPosition(e.key)
  }

  const moveIndexPosition = move => {
    setListIndex(currentIndex => {
      if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
        return currentIndex
      }

      if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
        return currentIndex
      }

      const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

      return {active: newActiveIndex, max: currentIndex?.max}
    })
  }

  const handleOnSelect = newIndex => {
    const ix = isNull(newIndex) ? listIndex.active : newIndex
    const baseSelected = list[ix]

    setList([baseSelected])
    setListIndex({active: 0, max: 0})
    setValue(baseSelected?.base)
    onSelect(baseSelected?.id)
  }

  return (
    <div>
      <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
        <FormInput
          type="search"
          isRequired={isRequired}
          isDisabled={isDisabled}
          label={label}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            handleOnChangeInput(e.target.value)
          }}
          autoComplete="off"
          onKeyDown={handleKeyPress}
          {...rest}
        />
        {!isDisabled && <FloatBaseList bases={list} onSelect={handleOnSelect} activeIndex={listIndex?.active} />}
      </FloatContainer>
    </div>
  )
}

export default SelectBaseInput
