import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {
  ELECTRONIC_PAYROLL_ACCEPTED,
  ELECTRONIC_PAYROLL_DRAFT,
  ELECTRONIC_PAYROLL_FAILED,
  ELECTRONIC_PAYROLL_REJECTED,
  electronicPayrollLabels,
  getElectronicPayrollStatus
} from '../../../helpers/electronic-payroll'
import {mediaFromM} from '../../../helpers/media-queries'
import {isEqual} from '../../../helpers/utils'
import {useMedia} from '../../../hooks/useMedia'
import useObjectURL from '../../../hooks/useObjectURL'
import {
  generateElectronicPayrollDetailReport,
  generateElectronicPayrollDocumentsReport,
  getElectronicPayrollDocuments,
  getXMLElectronicPayroll,
  prepareElectronicPayroll,
  regenerateDIANRejectedElectronicPayroll,
  resendFailedElectronicPayroll,
  sendElectronicPayroll
} from '../../../services/electronicPayroll'
import {notifyError} from '../../../services/notification'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import ActionButton from '../../Atoms/Templates/ActionButton'
import Tooltip from '../../Atoms/Templates/Tooltip'
import DropdownButton from '../../Molecules/Dropsdown/DropdownButton'
import DropdownSelect from '../../Molecules/Dropsdown/DropdownSelect'
import TooltipContainer from '../Containers/TooltipContainer'
import ElectronicPayrollXMLModal from '../Modals/ElectronicPayrollXMLModal'
import ElectronicPayrollDocument from '../Sections/ElectronicPayrollDocument'
import ElectronicPayrollDocumentSkeleton from '../Skeleton/ElectronicPayrollDocumentSkeleton'

const ElectronicPayrollDocumentSection = ({
  contractHash = '',
  year = 0,
  month = 0,
  onUpdateSummaryPromise = () => {}
}) => {
  const fromM = useMedia(mediaFromM)
  const dispatch = useDispatch()
  const refModalXML = useRef()
  const downloadLinkReport = useRef()
  const {setObject, objectURL} = useObjectURL(null)

  const [isLoading, setIsLoading] = useState(false)
  const [documents, setDocuments] = useState(null)
  const [activeDocument, setActiveDocument] = useState(null)
  const [activeStatus, setActiveStatus] = useState(ELECTRONIC_PAYROLL_DRAFT)
  const [xml, setXML] = useState('')
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')

  useEffect(() => fetchDocuments(contractHash, year, month), [contractHash, year, month])

  const handlePrepareDocument = () => {
    const payload = {year: year, month: month, contract_ids: [activeDocument.contract_id]}

    setIsLoading(true)
    // TODO add logic to prepare depending of the document type, can be regular or replacement are endpoint differents

    prepareElectronicPayroll(payload)
      .then(() => getElectronicPayrollDocuments(contractHash, year, month))
      .then(documents => {
        updateDocumentState(documents)
        return onUpdateSummaryPromise()
      })
      .then()
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  const handleSentDocument = () => {
    const payload = {year: year, month: month, document_ids: [activeDocument.id]}
    setIsLoading(true)

    // TODO add logic to send depending of the document type, can be regular or replacement are endpoint differents
    sendElectronicPayroll(payload)
      .then(() => getElectronicPayrollDocuments(contractHash, year, month))
      .then(documents => {
        updateDocumentState(documents)
        return onUpdateSummaryPromise()
      })
      .then()
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  const handleRetry = () => {
    const payload = {year: year, month: month, document_ids: [activeDocument.id]}
    setIsLoading(true)

    resendFailedElectronicPayroll(payload)
      .then(() => getElectronicPayrollDocuments(contractHash, year, month))
      .then(documents => {
        updateDocumentState(documents)
        return onUpdateSummaryPromise()
      })
      .then()
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  const handleRegenerate = () => {
    const payload = {year: year, month: month, contract_ids: [activeDocument.contract_id]}
    setIsLoading(true)

    regenerateDIANRejectedElectronicPayroll(payload)
      .then(() => getElectronicPayrollDocuments(contractHash, year, month))
      .then(documents => {
        updateDocumentState(documents)
        return onUpdateSummaryPromise()
      })
      .then()
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  const fetchDocuments = (contractHash, year, month) => {
    setIsLoading(true)

    getElectronicPayrollDocuments(contractHash, year, month)
      .then(response => updateDocumentState(response))
      .finally(() => setIsLoading(false))
  }

  const updateDocumentState = newState => {
    setDocuments(newState)
    updateActiveDocument(newState[0])
  }

  const updateActiveDocument = document => {
    const status = getElectronicPayrollStatus(document)

    setActiveDocument(document)
    setActiveStatus(status)

    if (isEqual(status, ELECTRONIC_PAYROLL_ACCEPTED)) {
      getXMLElectronicPayroll(document.id)
        .then(encodedXML => setXML(atob(encodedXML)))
        .catch(err => dispatch(notifyError(err)))
    }
  }

  const handleOnClickGenerateDetailReport = () => {
    setIsLoadingDownloadReport(true)

    generateElectronicPayrollDetailReport(year, month, activeDocument.contract_id)
      .then(({data, headers}) => downloadReport(data, headers))
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const handleOnClickGenerateDocumentsReport = () => {
    setIsLoadingDownloadReport(true)

    generateElectronicPayrollDocumentsReport(year, month, activeDocument.contract_id)
      .then(({data, headers}) => downloadReport(data, headers))
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const downloadReport = (file, headers) => {
    setObject(file)
    setFileNameDownloaded(headers['x-file-name'])

    downloadLinkReport.current.click()

    // after of clicked then reset the state
    setObject(null)
    setFileNameDownloaded('')
  }

  const handleOpenDIANReport = () => {
    window.open(`${process.env.REACT_APP_URL_DIAN}/document/searchqr?documentkey=${activeDocument?.cune}`, '_blank')
  }

  const handleShowXML = () => refModalXML.current.openModal()

  return isLoading ? (
    <ElectronicPayrollDocumentSkeleton />
  ) : (
    documents && (
      <section className="n-container-none-padding s-px-2 s-pb-2 m-px-4 m-pb-4">
        <header className="s-main-justify s-cross-center s-py-2 hr" style={{minHeight: '64px'}}>
          <DropdownSelect className="s-mr-1" selected={getLabel(activeDocument)}>
            {documents.map((document, ix) => (
              <DropdownItem
                key={document.id}
                text={getLabel(document)}
                isActive={isEqual(document.id, activeDocument.id)}
                onClick={() => {
                  updateActiveDocument(documents[ix])
                }}
              />
            ))}
          </DropdownSelect>

          <div className="s-cross-center">
            {isEqual(activeStatus, ELECTRONIC_PAYROLL_DRAFT) && activeDocument.is_current && (
              <>
                <TooltipContainer>
                  <ActionButton
                    isMain
                    content={fromM ? 'Prepara' : ''}
                    icon="bolt"
                    className="s-mr-1"
                    onClick={handlePrepareDocument}
                  />
                  <Tooltip className="whitespace-nowrap">
                    <span>Prepara este documento</span>
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer>
                  <ActionButton
                    content={fromM ? 'Envia' : ''}
                    icon="rocket"
                    className="s-mr-1"
                    onClick={handleSentDocument}
                  />
                  <Tooltip className="whitespace-nowrap">
                    <span>Envia este documento</span>
                  </Tooltip>
                </TooltipContainer>
              </>
            )}

            {isEqual(activeStatus, ELECTRONIC_PAYROLL_FAILED) && activeDocument.is_current && (
              <TooltipContainer>
                <ActionButton
                  isMain
                  content={fromM ? 'Reenvia' : ''}
                  icon="arrows-up-down"
                  className="s-mr-1"
                  onClick={handleRetry}
                />
                <Tooltip className="whitespace-nowrap">
                  <span>Reintenta enviarlo</span>
                </Tooltip>
              </TooltipContainer>
            )}

            {isEqual(activeStatus, ELECTRONIC_PAYROLL_REJECTED) && activeDocument.is_current && (
              <TooltipContainer>
                <ActionButton
                  isMain
                  content={fromM ? 'Repreparar' : ''}
                  icon="bolt"
                  className="s-mr-1"
                  onClick={handleRegenerate}
                />
                <Tooltip className="whitespace-nowrap">
                  <span>Nuevo documento</span>
                </Tooltip>
              </TooltipContainer>
            )}

            {isEqual(activeStatus, ELECTRONIC_PAYROLL_ACCEPTED) && (
              <>
                <TooltipContainer>
                  <ActionButton icon="code" content={fromM ? 'XML' : ''} onClick={handleShowXML} className="s-mr-1" />
                  <Tooltip className="whitespace-nowrap">
                    <span>Ver XML</span>
                  </Tooltip>
                </TooltipContainer>
                <TooltipContainer>
                  <ActionButton
                    icon="external-link"
                    content={fromM ? 'DIAN' : ''}
                    onClick={handleOpenDIANReport}
                    className="s-mr-1"
                  />
                  <Tooltip className="whitespace-nowrap">
                    <span>Reporte DIAN</span>
                  </Tooltip>
                </TooltipContainer>
              </>
            )}

            <DropdownButton
              icon="document"
              content={fromM ? 'Reportes' : ''}
              sizeFloatContainer="m"
              isDisabled={isLoadingDownloadReport}
            >
              <DropdownItem text="Lista de documentos" onClick={handleOnClickGenerateDocumentsReport} />
              <DropdownItem text="Detalle" onClick={handleOnClickGenerateDetailReport} />
            </DropdownButton>
          </div>
        </header>
        <hr className="s-mb-48px" />
        <ElectronicPayrollDocument document={activeDocument} statusDocument={activeStatus} />

        <ElectronicPayrollXMLModal ref={refModalXML} xml={xml} />

        {/* This link is hidden, we only use for download the reports */}
        <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
          file downloaded
        </a>
      </section>
    )
  )
}

function getLabel(document) {
  let status = getElectronicPayrollStatus(document)

  return `[${document.seq_contract}] ${electronicPayrollLabels[status]}`
}

export default ElectronicPayrollDocumentSection
