import React, {forwardRef, useState} from 'react'

import {isEqual} from '../../../helpers/utils'
import RadioButton from '../../Atoms/Forms/RadioButton'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

// report taxes
const REPORT_TAX_CONTINGENT = 'TAX_CONTINGENT'
const REPORT_TAX_BASES = 'TAX_BASES'
const REPORT_TAX_DETAIL = 'TAX_DETAIL'

const initialState = {
  report_name: 'TAX_DETAIL'
}

const TaxReportModal = forwardRef(({onGenerate = () => {}, isHistoryProcess}, ref) => {
  const [formData, setFormData] = useState(initialState)

  const handleGenerate = () => {
    onGenerate(formData)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center large" onChangeOpen={resetForm}>
      <h3>Reportes de Retención</h3>
      <div className="ed-grid s-grid-1 rows-gap s-mb-4">
        <RadioButtonsWrapper label="Reporte">
          <div>
            <RadioButton
              name="report_name"
              label="Detalle de Retención"
              value={REPORT_TAX_DETAIL}
              onChange={updateRadioButton}
              checked={isEqual(formData.report_name, REPORT_TAX_DETAIL)}
            />
            <p className="small s-color-light-text s-left s-mb-0">Detalle del cálculo de retención del proceso.</p>
          </div>

          <div>
            <RadioButton
              name="report_name"
              label="Bases de Retención del mes"
              value={REPORT_TAX_BASES}
              onChange={updateRadioButton}
              checked={isEqual(formData.report_name, REPORT_TAX_BASES)}
            />
            <p className="small s-color-light-text s-left s-mb-0">Considera solo información historica del mes.</p>
          </div>

          {isHistoryProcess && (
            <div>
              <RadioButton
                name="report_name"
                label="Retención Contingente"
                value={REPORT_TAX_CONTINGENT}
                onChange={updateRadioButton}
                checked={isEqual(formData.report_name, REPORT_TAX_CONTINGENT)}
              />
              <p className="small s-color-light-text s-left s-mb-0">
                Considera solo información historica y usa el tipo de corte definido en el párametro{' '}
                <span className="code-font">TIPO_CORTE_RETEFUENTE_CONTINGENTE</span>
              </p>
            </div>
          )}
        </RadioButtonsWrapper>
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

TaxReportModal.displayName = 'TaxReportModal'

export default TaxReportModal
