import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import SummaryPaymentsPanel from '../components/Organisms/Panels/SummaryPaymentsPanel'
import PaymentSkeleton from '../components/Organisms/Skeleton/PaymentSkeleton'
import PaymentsTable from '../components/Organisms/Tables/PaymentsTable'
import {PayrollContext} from '../contexts/contexts'
import {isEqual, isUndefined} from '../helpers/utils'
import {getSummaryDepositsByProcessID} from '../services/depositPayment'
import {notifyError} from '../services/notification'
import {markAsPaidFull} from '../services/processHeader'

const Payments = () => {
  const dispatch = useDispatch()
  const {activeProcess, setActiveProcess} = useContext(PayrollContext)
  const [summary, setSummary] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) // eslint-disable-line

  useEffect(() => {
    getSummaryDepositsByProcessID(activeProcess.id, response => {
      setSummary(response)
      setIsLoading(false)
    })
  }, [activeProcess.id])

  // Update the process as "is_paid_full"
  useEffect(() => {
    if (activeProcess.is_paid_full) return

    if (isUndefined(summary.total_employees) || isUndefined(summary.employees_paid)) return

    if (!isEqual(summary.total_employees, summary.employees_paid)) return

    markAsPaidFull(
      activeProcess.id,
      () => setActiveProcess({...activeProcess, is_paid_full: true}),
      error => dispatch(notifyError(error))
    )
  }, [activeProcess, summary])

  return isLoading ? (
    <PaymentSkeleton />
  ) : (
    <main className="ed-grid rows-gap">
      {!activeProcess.is_paid_full && <SummaryPaymentsPanel summary={summary} />}

      <PaymentsTable updateSummary={setSummary} isPaidFull={activeProcess.is_paid_full} />
    </main>
  )
}

export default Payments
