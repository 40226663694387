import React from 'react'

import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {isEqual} from '../../../../helpers/utils'
import {BOOLEAN_PARAMETER_VALUE} from '../../data/form/value'
import useBooleanParameterForm from '../../hooks/form/useBooleanParameterForm'
import {useParameterValuesContext} from '../../providers/ParameterValues'
import EditParameterFormDates from './EditParameterFormDates'
import EditParameterFormFooter from './EditParameterFormFooter'

const BooleanParameterForm = () => {
  const {parameterValues} = useParameterValuesContext()
  const lastValue = parameterValues[parameterValues.length - 1]
  const {is_fixed} = lastValue

  const {setFormData, formData, handleSubmit} = useBooleanParameterForm({
    parameter: lastValue
  })

  const updateRadioButton = e => {
    const selectedValue = e.target.value

    if (isEqual(selectedValue, formData.value)) return

    setFormData(state => ({
      ...state,
      value: selectedValue
    }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <EditParameterFormDates formData={formData} setFormData={setFormData} isDisabled={is_fixed} />
      <RadioButtonsWrapper label="Valor del parámetro" className="s-mb-4" isRequired>
        <RadioButton
          name="value"
          label="Si"
          value={BOOLEAN_PARAMETER_VALUE.TRUE}
          onChange={updateRadioButton}
          checked={isEqual(formData.value, BOOLEAN_PARAMETER_VALUE.TRUE)}
        />
        <RadioButton
          name="value"
          label="No"
          value={BOOLEAN_PARAMETER_VALUE.FALSE}
          onChange={updateRadioButton}
          checked={isEqual(formData.value, BOOLEAN_PARAMETER_VALUE.FALSE)}
        />
      </RadioButtonsWrapper>
      <EditParameterFormFooter />
    </form>
  )
}

export default BooleanParameterForm
