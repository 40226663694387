import React, {useRef} from 'react'
import {useSelector} from 'react-redux'

import {PERU_CODE} from '../../../helpers/country'
import {isEqual} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import IconPanelContainer from '../Containers/IconPanelContainer'
import BasicCompanyInformationModal from '../Modals/BasicCompanyInformationModal'
import CompanyInformationSection from '../Sections/CompanyInformationSection'

const BasicCompanyInformationPanel = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const modal = useRef()

  const handleOpenModal = () => {
    modal.current.openModal()
  }

  return (
    <div>
      <IconPanelContainer
        icon="building"
        title="Información básica"
        // editFunction={handleOpenModal}
        container={{
          className: 'ed-grid s-grid-2 rows-gap s-gap-2'
        }}
      >
        <PanelInformationItem
          title="NIT"
          text={`${employerSession.nit} - ${employerSession.dv}`}
          classesText="code-font"
          colorText="s-color-title"
        />
        <PanelInformationItem title="Sigla" text={employerSession.short_name} />
        <PanelInformationItem twoColumns title="Razón social" text={employerSession.business_name} />
        <PanelInformationItem title="Sitio web" text={employerSession.web} />
        {isEqual(employerSession.code_iso_3, PERU_CODE) && (
          <PanelInformationItem title="Tipo Empresa" text={employerSession.type} />
        )}
        {/* <CompanyInformationSection title="Logo de la empresa" classes="s-cols-2">
          <PanelInformationItem title="Logo" text="Foo" />
          <PanelInformationItem title="Thumbnail" text="Bar" />
        </CompanyInformationSection> */}
      </IconPanelContainer>
      <BasicCompanyInformationModal ref={modal} />
    </div>
  )
}

BasicCompanyInformationPanel.propTypes = {}

export default BasicCompanyInformationPanel
