import React from 'react'

import Icon from '../../Atoms/Icon'

const ComeBackHeader = ({onClick}) => {
  return (
    <header className="come-back-header transform-transition s-fixed s-100 s-left-0 z-tooltip s-bg-container s-px-16px s-border-bottom-border">
      <div onClick={onClick} className="s-py-12px s-cross-center cursor-pointer">
        <Icon svg="left-arrow" className="s-mr-4px" />
        <h4 className="s-mb-0">Regresar</h4>
      </div>
    </header>
  )
}

export default ComeBackHeader
