import React from 'react'

import TextIcon from '../../Atoms/TextIcon'

const MainEmployeInformationItem = ({text, icon, title, child}) => {
  return (
    <div className="smaller s-main-center s-cross-center s-column">
      {child}
      <p className="s-mb-0">{text}</p>
      <TextIcon className="s-color-light-text" icon={icon} text={title} />
    </div>
  )
}

export default MainEmployeInformationItem
