import PropTypes from 'prop-types'
import React from 'react'

import {getMonth} from '../../../helpers/dates'
import {getAvatar} from '../../../helpers/static-images'
import {fullName} from '../../../helpers/string-format'
import {isEmpty} from '../../../helpers/utils'
import UserAvatar from '../../Atoms/Templates/UserAvatar'

const EmployeeBirthday = ({
  employeeID,
  first_name,
  middle_name,
  last_name,
  surname,
  gender,
  thumbnail,
  month,
  birthday,
  onClick
}) => {
  return (
    <article
      className="hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px hover-change flex s-mb-0 s-cross-center nowrap"
      onClick={onClick}
    >
      <UserAvatar picture={isEmpty(thumbnail) ? getAvatar(employeeID, gender) : thumbnail} className="s-mr-8px" />
      <div className="overflow-ellipsis s-100">
        <p className="small s-mb-0 flex s-cross-center nowrap">
          <span className="s-mr-8px overflow-ellipsis">{fullName({first_name, middle_name, last_name, surname})}</span>
        </p>

        <div className="s-cross-center smaller s-color-light-text">
          <p className="s-mb-0 s-mr-8px">{`🎉 ${getMonth(month)} ${birthday}`}</p>
        </div>
      </div>
    </article>
  )
}

EmployeeBirthday.propTypes = {
  first_name: PropTypes.string.isRequired,
  middle_name: PropTypes.string,
  last_name: PropTypes.string.isRequired,
  surname: PropTypes.string,
  gender: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  month: PropTypes.number.isRequired,
  birthday: PropTypes.number.isRequired,
  onClick: PropTypes.func
}

EmployeeBirthday.defaultProps = {
  first_name: '',
  middle_name: '',
  last_name: '',
  surname: '',
  gender: '',
  thumbnail: '',
  month: 0,
  birthday: 1,
  onClick: () => {}
}

export default EmployeeBirthday
