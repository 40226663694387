import {RequestWithAuthorization, RequestWithEmployerAuthorization} from '../helpers/request'

// the images are saved in a root folder
export const uploaderLogo = (image, callbackResponse) => {
  const request = new RequestWithAuthorization()

  let data = new FormData()
  data.append('file', image)
  data.append('folder', 'logos')
  data.append('is_public', true)
  data.append('width', 0)

  request.post('/uploader/avatars', data, response => callbackResponse(response))
}

// the images are saved in a root folder
export const uploaderProfileAvatar = (image, callbackResponse, callbackError) => {
  const request = new RequestWithAuthorization()

  let data = new FormData()
  data.append('file', image)
  data.append('folder', 'avatars')
  data.append('is_public', true)
  data.append('width', 0)

  request.post('/uploader/avatars', data, response => callbackResponse(response), callbackError)
}

// the images are saved in a folder per employer
export const uploaderAvatar = (image, callbackResponse, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  let data = new FormData()
  data.append('file', image)
  data.append('folder', 'avatars')
  data.append('is_public', true)
  data.append('width', 0)

  request.post('/uploader/avatars', data, response => callbackResponse(response), callbackError)
}
