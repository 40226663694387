import {createDuck} from 'redux-duck'

import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/areas')

// Define action types
export const SET_AREAS = duck.defineType('SET_AREAS')

// Define action creators
export const actions = {
  set: duck.createAction(SET_AREAS)
}

// Define selectors
export const selectors = {
  getAll: store => store.areasReducer.all
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_AREAS]: (state, {payload}) => ({
      ...state,
      all: [...payload]
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState
  },
  initialState
)
