import {useEffect, useState} from 'react'

import {getParameterEmployerDetailByID, getParameterStandardDetailByID} from '../../../services/processParameter'
import {useSelectedParameterContext} from '../providers/SelectedParameter'

const useGetParameterValues = ({parameter, isMandatory}) => {
  const [isLoading, setisLoading] = useState(true)
  const [isError, setisError] = useState(false)
  const [data, setData] = useState([])
  const {selectedParameter} = useSelectedParameterContext()

  const {id} = parameter

  const handleFetchData = () => {
    const handleError = error => {
      setisError(true)
      setisLoading(false)
      console.error('Error fetching parameter employer detail:', error)
    }

    const handleLoadData = response => {
      setData(response)
      setisLoading(false)
    }

    if (isMandatory) {
      getParameterEmployerDetailByID(id, handleLoadData, handleError)

      return
    }

    getParameterStandardDetailByID(id, handleLoadData, handleError)
  }

  useEffect(() => {
    handleFetchData()
  }, [selectedParameter])

  return {data, isLoading, isError}
}

export default useGetParameterValues
