import './styles/styles.scss'

import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es.json'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'

import App from './config/App'
import store from './redux/store'
TimeAgo.addDefaultLocale(es)

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
