import {RequestWithEmployerAuthorization} from '../helpers/request'

const resource = '/process-parameters'

export const getProcessParameters = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(resource, callback, error => console.error('process parameters:', error))
}

export const getGlobalParameters = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${resource}/global-parameters`, callback, error => console.error('global parameters:', error))
}

export const getParameterStandardDetailByID = (parameterID, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${resource}/${parameterID}/standard-details`, callback, callbackError)
}

export const getParameterEmployerDetailByID = (parameterID, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${resource}/${parameterID}/employer-details`, callback, callbackError)
}

export const updateParameterEmployerDetailValueByID = (parameterID, payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${resource}/${parameterID}/employer-details`, payload, callback, callbackError)
}
