import React from 'react'
import {Redirect, Route} from 'react-router-dom'

import {isAuthenticated, isAuthenticatedWithEmployer} from '../../helpers/session'
import {redirectRoute} from '../routes'

const PrivateEmployer = ({children, ...rest}) => {
  if (!isAuthenticated()) {
    return <Redirect to={redirectRoute.public} />
  }

  // only check the employer, if the flow is in this point is because the user
  // has the first token - isAuthenticated()
  if (!isAuthenticatedWithEmployer()) {
    return <Redirect to={redirectRoute.private} />
  }

  return <Route {...rest}>{children}</Route>
}

export default PrivateEmployer
