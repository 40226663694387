import {isUndefined} from 'helpers/utils'

const routes = {
  employees: {
    base: '/empleados',
    new: '/nuevo',
    query: '/consultar'
  },
  employers: '/empresas',
  settings: {
    base: '/configuraciones',
    employer: '/empresa',
    payrollElectronic: '/nomina-electronica',
    organizationalStructure: '/estructura-organizacional',
    parameters: '/parametros-nomina',
    concepts: '/conceptos-nomina',
    accounting: '/contabilidad',
    bases: '/bases',
    processes: '/procesos'
  },
  forgotPassword: '/recuperar',
  home: '/',
  login: '/login',
  newEmployer: '/crear-empresa',
  oauth: '/oauth',
  payroll: {
    base: '/nominas',
    processes: '/procesos',
    payrollElectronic: '/electronicas',
    novelties: '/novedades',
    payslip: '/comprobantes',
    payments: '/pagos'
  },
  profile: '/mi-perfil',
  registry: '/registro',
  resetPassword: '/restablecer'
}

export const QUERY_PARAM_PAYSLIP = 'comprobante'
export const QUERY_PARAM_HASH_CONTRACT = 'contrato'

export const redirectRoute = {
  public: routes.login,
  private: routes.employers,
  privateEmployer: routes.home
}

export const activeMonthRoute = activeMonth => {
  if (isUndefined(activeMonth) || isUndefined(activeMonth?.month_number)) {
    return routes.payroll.base
  }

  return `${routes.payroll.base}/${routeYearMonth(activeMonth?.year_number, activeMonth?.month_number)}`
}

export const payrollDateRoute = payrollDate => {
  if (isUndefined(payrollDate) || isUndefined(payrollDate?.month)) {
    return routes.payroll.base
  }

  return `${routes.payroll.base}/${routeYearMonth(payrollDate?.year, payrollDate?.month)}`
}

export const processRoute = (year, month, hash) => {
  return `${routes.payroll.base}/${routeYearMonth(year, month)}${routes.payroll.processes}/${hash}`
}

export const electronicPayrollRoute = (year, month) => {
  return `${routes.payroll.base}/${routeYearMonth(year, month)}${routes.payroll.payrollElectronic}`
}

export const routeYearMonth = (year, month) => {
  const monthFormat = month.toString().padStart(2, '0')
  return `${year}-${monthFormat}`
}

export default routes
