import {COLOMBIA_CODE} from 'helpers/country'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {getFormatCurrency} from '../../../helpers/format-number'
import {isEqual} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import RetentionEditModal from '../Modals/RetentionEditModal'

const RetentionPanel = ({data, onUpdate}) => {
  const employerSession = useSelector(employerSelectors.getSession)
  const dependent = data?.dependent

  const [formData, setFormData] = useState(data)

  const editModal = useRef()

  const handleOnEdit = () => {
    setFormData(data)
    editModal.current.openModal()
  }

  return (
    isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
      <>
        <EmployeeInformationContainer title="Retención en la fuente" icon="folder" editFunction={handleOnEdit}>
          <PanelInformationItem title="Método de retención" text={`${data.contract.method_taxes}`} />
          {isEqual(data.contract.method_taxes, 2) && (
            <PanelInformationItem title="% de retención" text={`${data.contract.rate_taxes}`} />
          )}
          <PanelInformationItem
            title="Deducibles vivienda"
            text={`${getFormatCurrency(
              data.contract.tax_relief_living_place,
              employerSession.code_iso_3,
              !employerSession.not_include_decimals_in_calculation
            )}`}
          />
          <PanelInformationItem
            title="Deducibles medicina"
            text={`${getFormatCurrency(
              data.contract.tax_relief_health,
              employerSession.code_iso_3,
              !employerSession.not_include_decimals_in_calculation
            )}`}
          />
          <PanelInformationItem title="Tiene dependientes" text={data.contract.is_tax_dependents ? 'Sí' : 'No'} />
          {data.contract.is_tax_dependents && (
            <PanelInformationItem
              twoColumns
              title="Dependiente"
              text={`${dependent?.first_name} ${dependent?.middle_name} ${dependent?.last_name} ${dependent?.surname} (${dependent?.relationship_type})`}
            />
          )}
        </EmployeeInformationContainer>
        <RetentionEditModal ref={editModal} formData={formData} setFormData={setFormData} onUpdate={onUpdate} />
      </>
    )
  )
}

export default RetentionPanel
