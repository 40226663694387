/* eslint-disable no-console */
import {NOTIFICATION_RUNNER_DEBUGGER} from '../helpers/notification-user'
import {RequestWithEmployerAuthorization} from '../helpers/request'
import {isEqual, isNull} from '../helpers/utils'
import {actions as notificationsUser} from '../redux/ducks/notificationUsers'
import store from '../redux/store'

const RESOURCE = '/notifications'

export const getNotificationsUser = (page, limit) => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}?page=${page}&limit=${limit}`,
    response => {
      if (isNull(response)) {
        response = []
      }

      dispatch(notificationsUser.set(response))
    },
    error => console.error('notifications user:', error)
  )
}

export const markNotificationUserAsRead = (id, callback) => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/mark-as-read/${id}`, null, response => {
    dispatch(notificationsUser.markAsRead(id))
    callback(response)
  })
}

export const markAllNotificationUserAsRead = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/mark-all-as-read`, null, response => dispatch(notificationsUser.markAllAsRead()))
}

export const getHasNotificationsUser = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/summary`,
    response => dispatch(notificationsUser.setHasNewNotification(response.has_notification)),
    error => console.error('notifications user:', error)
  )
}

export const markSummaryNotificationUserAsRead = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/mark-summary-as-read`, null, response =>
    dispatch(notificationsUser.setHasNewNotification(false))
  )
}

export const addNotificationUserFromWebsocket = message => dispatch => {
  const employerSession = store.getState().employerReducer?.session

  // discard message if the id of the employer message is different to the employer session id
  if (!isEqual(message?.employer_id, employerSession?.id)) return

  // discard message in the redux notificationsUser if the notification is NOTIFICATION_RUNNER_DEBUGGER, only print in console
  if (isEqual(message?.type, NOTIFICATION_RUNNER_DEBUGGER)) {
    console.groupCollapsed('Concepto:', message?.content?.concept_code)
    console.table(message?.content?.arguments, 'Value')
    console.groupEnd()
    return
  }

  dispatch(notificationsUser.add(message))
}
