import React, {useContext, useEffect} from 'react'
import {Redirect, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom'

import routes, {redirectRoute} from '../config/routes'
import PrivateEmployer from '../config/Routes/PrivateEmployer'
import {PayrollContext} from '../contexts/contexts'
import {isUndefined, len} from '../helpers/utils'
import ElectronicPayroll from './ElectronicPayroll'
import PayrollDashboard from './PayrollDashboard'
import PayrollProcesses from './PayrollProcesses'

const PayrollMonth = () => {
  let {path} = useRouteMatch()
  let {month} = useParams()
  let history = useHistory()

  const {setPayrollDate} = useContext(PayrollContext)

  useEffect(() => {
    if (isUndefined(month)) return

    const paramYearMonth = month.split('-')
    if (len(paramYearMonth) !== 2) {
      history.push(redirectRoute.privateEmployer)
      return
    }

    const yearParam = Number(paramYearMonth[0])
    const monthParam = Number(paramYearMonth[1])

    if (isNaN(yearParam) || isNaN(monthParam)) {
      history.push(redirectRoute.privateEmployer)
      return
    }

    setPayrollDate({year: yearParam, month: monthParam})

    // clean up after this effect (unmount component):
    return function cleanup() {
      setPayrollDate(null)
    }
  }, [month]) // eslint-disable-line

  return (
    <Switch>
      <PrivateEmployer exact path={`${path}${routes.payroll.processes}/:hash`}>
        <PayrollProcesses />
      </PrivateEmployer>

      <PrivateEmployer exact path={`${path}${routes.payroll.payrollElectronic}`}>
        <ElectronicPayroll />
      </PrivateEmployer>

      <PrivateEmployer exact path={path}>
        <PayrollDashboard />
      </PrivateEmployer>

      <Redirect to={redirectRoute.privateEmployer} />
    </Switch>
  )
}

export default PayrollMonth
