import {Contract, Tax} from '../types/contract.type'
import EmployeePayment from '../types/employee-payment.type'
import EmployeeRelationState from '../types/employee-relation.type'
import Employee from '../types/employee.type'
import EmployeeSocialSecurity, {Entity} from '../types/social-security.type'

//----------------------------------------------------------------------------------------
// Employee
//----------------------------------------------------------------------------------------

export const initialStateEmployee: Employee = {
  identification_type_id: 0,
  identification_number: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  surname: '',
  marital_status: '',
  email: '',
  phone: '',
  mobile: '',
  birthdate: null,
  birthplace: '',
  gender: '',
  address: '',
  picture: '',
  thumbnail: ''
}

export const rulesEmployee = {
  identification_type_id: 'integer|required|not_in:0',
  identification_number: 'required',
  first_name: 'required|string|min:2',
  middle_name: 'string|min:2',
  last_name: 'required|string|min:2',
  surname: 'string|min:2',
  marital_status: 'string|in:CASADO,DIVORCIADO,SOLTERO,UNION LIBRE,VIUDO',
  email: 'email',
  phone: 'string',
  mobile: 'string',
  birthdate: 'date',
  birthplace: 'string',
  gender: 'required|string|in:FEMENINO,MASCULINO',
  address: 'string'
}

export const fieldNamesEmployee = {
  identification_type_id: 'Tipo de identificación',
  identification_number: 'No. de identificatión',
  first_name: 'Primer nombre',
  middle_name: 'Segundo nombre',
  last_name: 'Primer apellido',
  surname: 'Segundo apellido',
  marital_status: 'Estado civil',
  email: 'Correo electrónico',
  phone: 'Teléfono',
  mobile: 'Celular',
  birthdate: 'Fecha de nacimiento',
  birthplace: 'Lugar de nacimiento',
  gender: 'Género',
  address: 'Dirección'
}

//----------------------------------------------------------------------------------------
// Contract
//----------------------------------------------------------------------------------------

export const initialStateContract: Contract = {
  contract_type_id: 0,
  branch_office_id: 0,
  place_labor_municipality_id: 0,
  job_id: 0,
  hire_date: null,
  expiration_date: null,
  trial_period_date: null,
  pay_frequency_id: 0,
  is_labor_saturday: false,
  salary_type_id: 0,
  salary: '',
  alternate_code: '',
  month_hours: 235,
  is_contract_extensible: false,
  notes: '',
  organizational_struct_id: '',
  is_pensioner: false,
  mapping: {pila: {contributor: 0, subcontributor: 0}},
  // Peru Fields
  has_family_allowance: false,
  dimensions: {}
}

export const rulesContract = {
  contract_type_id: 'integer|required|not_in:0',
  branch_office_id: 'integer|required|not_in:0',
  place_labor_municipality_id: 'integer|required|not_in:0',
  job_id: 'integer|required|not_in:0',
  hire_date: 'date|required',
  expiration_date: 'date|after_or_equal:hire_date',
  trial_period_date: 'date|after:hire_date',
  pay_frequency_id: 'integer|required|not_in:0',
  is_labor_saturday: 'boolean|required',
  salary_type_id: 'integer|required|not_in:0',
  salary: 'numeric|required',
  alternate_code: 'string',
  month_hours: 'numeric|min:8|max:240',
  notes: 'string',
  organizational_struct_id: 'string|required|min:1|max:21',
  is_pensioner: 'boolean|required'
}

export const rulesContractPeru = {
  has_family_allowance: 'boolean|required'
}

export const fieldNamesContract = {
  contract_type_id: 'Tipo de contrato',
  branch_office_id: 'Sucursal',
  place_labor_municipality_id: 'Ciudad de trabajo',
  job_id: 'Cargo',
  hire_date: 'Inicio de contrato',
  expiration_date: 'Finalización de contrato',
  trial_period_date: 'Finalización período de prueba',
  pay_frequency_id: 'Frecuencia de pago',
  is_labor_saturday: '¿Sábado Laborable?',
  salary_type_id: 'Tipo de salario',
  salary: 'Salario',
  alternate_code: 'Código alterno de empresa',
  month_hours: 'Horas trabajadas al mes',
  notes: 'Notas u observaciones',
  organizational_struct_id: 'Área',
  is_pensioner: '¿Es pensionado?',
  has_family_allowance: '¿Tiene asignación familiar?'
}

//----------------------------------------------------------------------------------------
// Tax
//----------------------------------------------------------------------------------------

export const initialStateTax: Tax = {
  method_taxes: '1',
  rate_taxes: 0.0,
  tax_relief_health: 0,
  tax_relief_living_place: 0,
  is_tax_dependents: false,
  is_tax_dependents_raw: 'FALSE',
  dependent_identification_type_id: 0,
  dependent_identification_number: '',
  dependent_first_name: '',
  dependent_middle_name: '',
  dependent_last_name: '',
  dependent_surname: '',
  dependent_relationship_type: ''
}

export const rulesTax = {
  method_taxes: 'integer|required|in:1,2',
  rate_taxes: 'numeric|required_if:method_taxes,2|min:0',
  tax_relief_health: 'numeric|min:0',
  tax_relief_living_place: 'numeric|min:0',
  is_tax_dependents: 'boolean',
  is_tax_dependents_raw: 'string',
  dependent_identification_type_id: 'required_if:is_tax_dependents_raw,TRUE|integer',
  dependent_identification_number: 'required_if:is_tax_dependents_raw,TRUE|string|min:2',
  dependent_first_name: 'required_if:is_tax_dependents_raw,TRUE|string|min:2',
  dependent_middle_name: 'string|min:2',
  dependent_last_name: `required_if:is_tax_dependents_raw,TRUE|string|min:2`,
  dependent_surname: 'string|min:2',
  dependent_relationship_type: 'required_if:is_tax_dependents_raw,TRUE|string|min:2'
}

export const fieldNamesTax = {
  method_taxes: 'Método de Retención',
  rate_taxes: '% de retención',
  tax_relief_health: 'Deducible medicina pagada',
  tax_relief_living_place: 'Deducible de vivienda',
  is_tax_dependents: 'Deducible dependiente',
  dependent_identification_type_id: 'Tipo identificación del dependiente',
  dependent_identification_number: 'Número identificación del dependiente',
  dependent_first_name: 'Primer nombre del dependiente',
  dependent_middle_name: 'Segundo nombre del dependiente',
  dependent_last_name: 'Primer apellido del dependiente',
  dependent_surname: 'Segundo apellido del dependiente',
  dependent_relationship_type: 'Parentesco del dependiente'
}

export const rulesTaxEdition = {
  method_taxes: 'integer|required|in:1,2',
  rate_taxes: 'numeric|required_if:method_taxes,2|min:0',
  tax_relief_health: 'numeric|min:0',
  tax_relief_living_place: 'numeric|min:0',
  is_tax_dependents: 'boolean',
  is_tax_dependents_raw: 'string',
  'dependent.identification_type_id': 'required_if:is_tax_dependents_raw,TRUE|integer',
  'dependent.identification_number': 'required_if:is_tax_dependents_raw,TRUE|string|min:2',
  'dependent.first_name': 'required_if:is_tax_dependents_raw,TRUE|string|min:2',
  'dependent.middle_name': 'string|min:2',
  'dependent.last_name': `required_if:is_tax_dependents_raw,TRUE|string|min:2`,
  'dependent.surname': 'string|min:2',
  'dependent.relationship_type': 'required_if:is_tax_dependents_raw,TRUE|string|min:2'
}

export const fieldNamesTaxEdition = {
  method_taxes: 'Método de Retención',
  rate_taxes: '% de retención',
  tax_relief_health: 'Deducible medicina pagada',
  tax_relief_living_place: 'Deducible de vivienda',
  is_tax_dependents: 'Deducible dependiente',
  'dependent.identification_type_id': 'Tipo identificación del dependiente',
  'dependent.identification_number': 'Número identificación del dependiente',
  'dependent.first_name': 'Primer nombre del dependiente',
  'dependent.middle_name': 'Segundo nombre del dependiente',
  'dependent.last_name': 'Primer apellido del dependiente',
  'dependent.surname': 'Segundo apellido del dependiente',
  'dependent.relationship_type': 'Parentesco del dependiente'
}

//----------------------------------------------------------------------------------------
// Social Security
//----------------------------------------------------------------------------------------

export const initialStateEmployeeSocialSecurity: EmployeeSocialSecurity = {
  workplace_id: 0,
  entities: [],
  is_foreigner_without_pension: false,
  is_colombian_living_abroad: false,
  has_colombian_living_abroad_pay_health: false,
  // Peru Fields
  afp_commission_type: ''
}

//----------------------------------------------------------------------------------------
// Payments
//----------------------------------------------------------------------------------------

export const initialStateEmployeePayment: EmployeePayment = {
  payment_method: 'TRANSFERENCIA BANCARIA',
  bank_id: 0,
  account_type: 'CUENTA DE AHORROS',
  account_number: ''
}

export const rulesEmployeePayment = {
  bank_id: 'required|integer|not_in:0',
  account_type: 'required|in:CUENTA DE AHORROS,CUENTA CORRIENTE,EMAIL',
  account_number: 'required|numeric'
}

export const rulesEmployeePaymentEmail = {
  ...rulesEmployeePayment,
  account_number: 'required|email'
}

export const fieldNamesEmployeePayment = {
  payment_method: 'Método de pago',
  bank_id: 'Banco',
  account_type: 'Tipo de cuenta',
  account_number: 'No. de cuenta'
}

//----------------------------------------------------------------------------------------
// Relations
//----------------------------------------------------------------------------------------

export const initialStateEmployeeRelation: EmployeeRelationState = {
  ...initialStateEmployee,
  ...initialStateContract,
  ...initialStateTax,
  ...initialStateEmployeeSocialSecurity,
  ...initialStateEmployeePayment,
  // Calculated fields
  is_contract_extensible: false,
  is_document_alphanumeric: false,
  is_document_foreign: false,
  branch_office_department: 0,

  // Object picture employee
  object_picture: null
}

export default initialStateEmployeeRelation
