import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />

const Notification = props => {
  const handleClose = e => props.onClose()
  return props.message !== undefined ? (
    <Snackbar open={props.message !== undefined} onClose={handleClose}>
      <Alert onClose={handleClose} severity={props.severity}>
        {props.message.split('\n').map((i, key) => {
          return <div key={key}>{i}</div>
        })}
      </Alert>
    </Snackbar>
  ) : null
}

export default Notification
