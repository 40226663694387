import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {activeMonthRoute} from '../../../config/routes'
import {capitalize} from '../../../helpers/string-format'
import {selectors as employerMonthSelector} from '../../../redux/ducks/employerMonth'
import {selectors as userSelectors} from '../../../redux/ducks/user'
import PayrollCalculate from '../../../static/images/stories/payroll-calculate.svg'
import Calculator from '../../../static/images/utils/calculator.svg'
import Icon from '../../Atoms/Icon'
import Container from '../../Wrappers/Container'

const DashboardWelcome = () => {
  const user = useSelector(userSelectors.get)
  const activeMonth = useSelector(employerMonthSelector.active)

  return (
    <Container className="m-cols-2 flex nowrap">
      <div className="s-column flex-grow">
        <h3 className="s-mb-8px">Hola, {capitalize(user.name)}</h3>
        <p className="s-mb-16px small s-mr-8px">Hoy es un excelente día para calcular la nómina</p>
        <Link className="s-to-bottom s-cross-center" to={activeMonthRoute(activeMonth)}>
          <span className="s-mr-8px">Revisa la nómina</span>
          <Icon svg="right-arrow" />
        </Link>
      </div>
      <img
        className="from-m s-mt-64px s-to-right"
        src={PayrollCalculate}
        alt="Persona calculando la nómina con nomineros, la mejor plataforma para gestión de nóminas"
        width="200px"
        height="200px"
      />
      <img
        className="to-m s-to-right s-h-64px s-w-64px"
        src={Calculator}
        alt="Persona calculando la nómina con nomineros, la mejor plataforma para gestión de nóminas"
      />
    </Container>
  )
}

export default DashboardWelcome
