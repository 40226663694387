import React, {useContext, useRef} from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'

import TabTemplateBasic from '../components/Atoms/Templates/TabTemplateBasic'
import StagePaymentEmptyState from '../components/Organisms/Emptys/StagePaymentEmptyState'
import StagePaysheetEmptyState from '../components/Organisms/Emptys/StagePaysheetEmptyState'
import PanelTemplate from '../components/Wrappers/PanelTemplate'
import {PayrollContext, TabsWrapperContext} from '../contexts/contexts'
import {isZeroDate} from '../helpers/dates'
import {mediaFromM} from '../helpers/media-queries'
import {
  objStageHash,
  processStage,
  STAGE_NOVELTIES,
  STAGE_NOVELTY_HASH,
  STAGE_PAYMENT_HASH,
  STAGE_PAYSHEET,
  STAGE_PAYSHEET_HASH,
  STATUS_PRE_PAYROLL
} from '../helpers/payroll'
import {isEqual} from '../helpers/utils'
import {useMedia} from '../hooks/useMedia'
import {selectors as configProcessTypeSelectors} from '../redux/ducks/processTypeConfig'
import Novelties from './Novelties'
import Payments from './Payments'
import PaySheet from './Paysheet'

const PayrollProcessTabs = () => {
  const fromM = useMedia(mediaFromM)

  const {setTabActive} = useContext(TabsWrapperContext)
  const {url} = useRouteMatch()
  const {hash, search} = useLocation()
  const history = useHistory()
  const {activeProcess} = useContext(PayrollContext)
  const el = useRef(null)

  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const configProcess = configObj[activeProcess?.process_type_code]

  useEffect(() => {
    const elObserved = el.current

    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle('is-sticky', e.intersectionRatio < 1)
      },
      // we set a margin negative that is the distant from top viewport downwards, when the
      // elObserved (tabs-processes) has that top distant then the class is-sticky is applied
      {rootMargin: '-150px 0px', threshold: [1]}
    )

    if (elObserved) observer.observe(elObserved)

    return () => {
      if (elObserved) observer.unobserve(elObserved)
    }
  }, [el])

  useEffect(() => {
    setTabActive(objStageHash[hash])
  }, [hash])

  return (
    <>
      <div className="tabs-processes" ref={el}>
        <div className="tab-container s-cross-center">
          {Array.isArray(configProcess.config.rules.stages) &&
            configProcess.config.rules.stages.map((stage, ix) => (
              <TabTemplateBasic
                key={stage}
                index={processStage[stage].order}
                className="tab-item"
                onClick={() =>
                  history.push({
                    pathname: url,
                    hash: processStage[stage].hash,
                    search
                  })
                }
              >
                {`${ix + 1}. ${processStage[stage].name}`}
              </TabTemplateBasic>
            ))}
        </div>
        {fromM && <div className="name-process s-color-title">{activeProcess?.description}</div>}
      </div>

      <PanelTemplate index={objStageHash[STAGE_NOVELTY_HASH]} onlyHidden>
        {Array.isArray(configProcess.config.rules.stages) &&
        isEqual(configProcess.config.rules.stages[0], STAGE_NOVELTIES) ? (
          <Novelties />
        ) : null}
      </PanelTemplate>

      <PanelTemplate index={objStageHash[STAGE_PAYSHEET_HASH]} onlyHidden>
        {isEqual(activeProcess?.stage, STAGE_NOVELTIES) ||
        (isEqual(activeProcess?.stage, STAGE_PAYSHEET) && isZeroDate(activeProcess?.updated_at)) ? (
          <StagePaysheetEmptyState processName={activeProcess?.process_type_description} />
        ) : (
          <PaySheet />
        )}
      </PanelTemplate>

      <PanelTemplate index={objStageHash[STAGE_PAYMENT_HASH]} onlyHidden>
        {isEqual(activeProcess?.status, STATUS_PRE_PAYROLL) ? <StagePaymentEmptyState /> : <Payments />}
      </PanelTemplate>
    </>
  )
}

export default PayrollProcessTabs
