import React from 'react'

import {
  PARAM_ARRAY_NUMBER,
  PARAM_ARRAY_STRING,
  PARAM_BOOLEAN,
  PARAM_DATE,
  PARAM_NUMBER,
  PARAM_STRING
} from '../../../helpers/parameter'
import BooleanParameterForm from '../components/form/BooleanParameterForm'
import DateParameterForm from '../components/form/DateParameterForm'
import NumberArrayParameterForm from '../components/form/NumberArrayParameterForm'
import NumberParameterForm from '../components/form/NumberParameterForm'
import TextArrayParameterForm from '../components/form/TextArrayParameterForm'
import TextParameterForm from '../components/form/TextParameterForm'

export const EDIT_PARAMETER_FORM_LIST = {
  [PARAM_NUMBER]: <NumberParameterForm />,
  [PARAM_STRING]: <TextParameterForm />,
  [PARAM_BOOLEAN]: <BooleanParameterForm />,
  [PARAM_DATE]: <DateParameterForm />,
  [PARAM_ARRAY_NUMBER]: <NumberArrayParameterForm />,
  [PARAM_ARRAY_STRING]: <TextArrayParameterForm />
}
