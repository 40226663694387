import PropTypes from 'prop-types'
import React from 'react'

const PaymentSummary = props => {
  const {employeesLength, amountToPay, paymentMethod, className} = props
  return (
    <div className={className}>
      <p className="s-mb-12px">
        Empleados: <span className="s-color-blue">{employeesLength}</span>
      </p>
      <p className="s-mb-12px">
        Total a pagar: <span className="s-color-blue">{amountToPay}</span>
      </p>
      <p className="l-block">
        Método de pago: <span className="s-color-blue">{paymentMethod}</span>
      </p>
    </div>
  )
}

PaymentSummary.propTypes = {
  employeesLength: PropTypes.number.isRequired,
  amountToPay: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  className: PropTypes.string
}

PaymentSummary.defaultProps = {
  className: ''
}

export default PaymentSummary
